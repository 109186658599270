import React, { useState } from 'react';
import { Button } from '@cimpress/react-components';
import { downloadAsFile } from '../Tools/DownloadAsFile';

interface Props {
  isDownloadable?: boolean;
  downloadLink: string;
  downloadFilename: string;
  label?: string;
  size?: 'lg' | 'default' | 'sm';
}

export const DownloadButton = ({
  isDownloadable,
  downloadLink,
  downloadFilename,
  label = 'Download',
  size = 'lg',
}: Props): JSX.Element => {
  const [isDownloading, setIsDownloading] = useState(false);

  const download = async () => {
    setIsDownloading(true);

    await downloadAsFile(downloadLink, downloadFilename);

    setIsDownloading(false);
  };

  return (
    <Button
      onClick={download}
      variant="primary"
      className="download-button"
      size={size}
      disabled={!isDownloadable || isDownloading}
    >
      {isDownloading ? 'Downloading..' : label}
    </Button>
  );
};
