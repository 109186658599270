import React from 'react';
import { Card } from '@cimpress/react-components';
import { MonolithComboColorGroup, MonolithComboPreview } from '../../api/Cams/model/MonolithAssetsMetadata';
import { NoPreview } from '../../components/NoPreview';
import { MonolithComboColorSwatch } from './MonolithComboColorSwatch';

interface Props {
  combo: MonolithComboPreview;
  colorGroup?: MonolithComboColorGroup;
}

export const MonolithComboColorVariantTile = ({ combo, colorGroup }: Props): JSX.Element => {
  return (
    <Card className="card-combo">
      <div className="asset-header">
        <h3 className="card-title card-combo-title">
          <span>
            {combo.colorItemType === 'Combo' ? 'Combo ' : 'Image '}
            {combo.comboId}
          </span>

          {colorGroup && (
            <>
              <span style={{ flex: 1 }}></span>
              {colorGroup.colors.map((color, i) => {
                return <MonolithComboColorSwatch key={i} rgbArray={color.rgb} cmykArray={color.cmyk} />;
              })}
            </>
          )}
        </h3>
      </div>
      <div className="asset-preview" style={{ minWidth: '350px', minHeight: '250px', margin: 0, padding: 0 }}>
        {combo.preview?.fileUrl !== undefined ? (
          <a href={combo.preview.fileUrl} target="_blank" rel="noopener noreferrer">
            <img src={combo.preview.fileUrl} className="template-preview-image" alt="Asset preview" />
          </a>
        ) : (
          <NoPreview />
        )}
      </div>
    </Card>
  );
};
