import React from 'react';
import './AssetCategorySelector.scss';
import { Dropdown } from '@cimpress/react-components';
import { AssetCategory } from '../AssetCategory';
import { saveAssetCategory } from '../../Tools/LocalStorage';
import { TenantDescription } from '../../api/model/TenantDescription';

interface Props {
  assetCategory: AssetCategory;
  onBeforeAssetCategoryChanged?: (newAssetCategory: AssetCategory) => boolean;
  onAssetCategoryChanged: (newAssetCategory: AssetCategory) => void;
  tenantDescription: TenantDescription;
  defaultAssetCategories?: AssetCategory[];
  customSuffix?: string;
}

interface AssetCategorySelectionItem {
  assetCategory: AssetCategory;
  label: string;
}

export const AssetCategorySelector = ({
  assetCategory,
  onAssetCategoryChanged,
  tenantDescription,
  defaultAssetCategories = [
    'design',
    'pattern',
    'background',
    'font schema',
    'color palette',
    'clipart',
    'creative asset',
  ],
  onBeforeAssetCategoryChanged = () => true,
  customSuffix,
}: Props): JSX.Element => {
  const friendlyAssetKindName = assetCategory.format({
    capitalLetter: true,
    plurality: 'plural',
    suffix: customSuffix,
  });

  const availableAssetKinds: AssetCategorySelectionItem[] = [];
  const addAssetCategory = (assetCategory: AssetCategory, availableAssetKinds: AssetCategorySelectionItem[]): void => {
    availableAssetKinds.push({
      assetCategory: assetCategory,
      label: assetCategory.format({ plurality: 'plural', capitalLetter: true, suffix: customSuffix }),
    });
  };

  const settingsAllowedCategories = tenantDescription.settings.features?.allowedAssetCategories;

  // There are no tenant settings so we want to add all asset kinds/types.
  if (!settingsAllowedCategories || settingsAllowedCategories?.length === 0) {
    defaultAssetCategories.forEach((defaultCategory: AssetCategory) => {
      addAssetCategory(defaultCategory, availableAssetKinds);
    });
  } else {
    defaultAssetCategories.forEach((assetCategory: AssetCategory) => {
      const defaultAssetCategory = settingsAllowedCategories.find(
        (defaultCategory) => defaultCategory.toLowerCase() === assetCategory.toLowerCase(),
      );
      if (defaultAssetCategory !== undefined) {
        addAssetCategory(assetCategory, availableAssetKinds);
      }
    });
  }

  // save asset category on initial load
  saveAssetCategory(assetCategory);

  const handleAssetCategory = (newAssetCategory: AssetCategory): void => {
    if (!onBeforeAssetCategoryChanged(newAssetCategory)) {
      return;
    }
    if (newAssetCategory === assetCategory) {
      return;
    }
    saveAssetCategory(newAssetCategory);
    onAssetCategoryChanged(newAssetCategory);
  };

  return (
    <Dropdown
      disabled={availableAssetKinds.length === 1}
      title={
        <h2 className="asset-kind-selector-header-title" data-testid="asset-category-picker-title">
          {friendlyAssetKindName}
        </h2>
      }
      className="asset-kind-selector-header"
    >
      {availableAssetKinds.map((assetCategorySelectionItem) => {
        return (
          <button
            key={assetCategorySelectionItem.assetCategory}
            onClick={() => handleAssetCategory(assetCategorySelectionItem.assetCategory)}
            disabled={assetCategorySelectionItem.assetCategory === assetCategory}
          >
            {assetCategorySelectionItem.label}
          </button>
        );
      })}
    </Dropdown>
  );
};
