import React from 'react';
import './AssetCard.scss';
import { Card, Tooltip } from '@cimpress/react-components';
import { AssetPreview } from '../../components/AssetPreview';
import { formatDateTime } from '../../Tools/DateTimeHelpers';
import { CreativeAssetVersion } from '../../api/Cams/model/CreativeAssetVersion';
import { DownloadButton } from '../../components/DownloadButton';

interface Props {
  asset: CreativeAssetVersion;
  physicalSpecId?: string;
}

export const AssetCard = ({ asset, physicalSpecId }: Props): JSX.Element => {
  const isCorrupted = asset.uploadInfo?.hash === '0';
  const downloadAssetLink = asset.uploadInfo?.fileUrl;
  const downloadFileName = asset.uploadInfo?.fileName;
  return (
    <Card className="asset-card-style">
      <div className="asset-preview">
        <AssetPreview assetVersion={asset} navigationUri={downloadAssetLink} physicalSpecId={physicalSpecId} />
      </div>
      <div className="asset-detail">
        <div>
          <div className="asset-header">
            <h3 className="text-primary card-title">{asset.uploadInfo?.fileName}</h3>
          </div>

          <div className="asset-additional-info">
            <p className="card-text">
              Last modified by {asset.createdByFullname}
              <br />
              <Tooltip direction={'right'} contents={formatDateTime(asset.created)}>
                {formatDateTime(asset.created, true)}
              </Tooltip>
            </p>
          </div>
        </div>
        <div>
          {isCorrupted ? (
            <div className="corrupted-linked-asset">
              <span>
                <i className="fa fa-exclamation-triangle"></i>
              </span>
              This linked asset is corrupted.
            </div>
          ) : (
            <DownloadButton
              isDownloadable={true}
              downloadFilename={downloadFileName}
              downloadLink={downloadAssetLink}
            />
          )}
        </div>
      </div>
    </Card>
  );
};
