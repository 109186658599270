import React from 'react';
import { Link } from 'react-router-dom';
import { Tenant } from '../api/model/Tenant';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { getVariableAssetVersionPageRoute } from '../Routes/GenericRoutes';

interface Props {
  assetId: string;
  versionNumber?: number;
  tenant: Tenant;
  assetCategory: AssetCategory;
}

export const DifferentAssetVersionPublished = ({
  assetId,
  versionNumber,
  tenant,
  assetCategory,
}: Props): JSX.Element => {
  return (
    <>
      {versionNumber && (
        <p style={{ textAlign: 'right', marginTop: '0.5em' }}>
          <span style={{ color: 'red' }}>
            Another&nbsp;
            <Link
              to={getVariableAssetVersionPageRoute(
                assetId,
                versionNumber,
                tenant.contentAuthoringAccountId,
                tenant.contentAuthoringAreaId,
                assetCategory,
              )}
              className="simple-link"
            >
              version ({versionNumber})
            </Link>
            &nbsp;is published.
          </span>
        </p>
      )}
    </>
  );
};
