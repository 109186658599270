import React, { useState } from 'react';
import { Modal } from '@cimpress/react-components';

const NoTenantsModal = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <Modal
      show={isModalOpen}
      data-testid="no-tenants-modal"
      title="No Tenants available"
      closeButton={true}
      onRequestHide={() => setIsModalOpen(false)}
      footer={
        <button className="btn btn-default" onClick={() => setIsModalOpen(false)}>
          Close
        </button>
      }
    >
      <p>You don&apos;t have access to any Tenants. Please contact Content Management support.</p>
      <p>
        <ul>
          <li>
            Slack: <a href="https://cimpress.slack.com/archives/CKHTF34E5">#mcp-support-content-management</a>
          </li>
          <li>
            E-mail:{' '}
            <a href="mailto:mcp-support-content-m-aaaabguvp44tcz75ulklo7hc24@cimpress.slack.com">
              mcp-support-content-m-aaaabguvp44tcz75ulklo7hc24@cimpress.slack.com
            </a>
          </li>
        </ul>
      </p>
      <p>
        Or contact <a href="mailto:protonsquad@cimpress.com">Proton squad</a> via email.
      </p>
    </Modal>
  );
};

export default NoTenantsModal;
