import { TextField } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';
import { generatePreviewUrlForColor } from '../Tools/CimDocUtil';
import { Color } from '../Uploads/ColorPalette';
import './CMYKColorSelectionComponent.scss';

interface Props {
  onColorChange: (colorPalette: Color) => void;
  colorName: string;
  initialValue: string;
}

export const CMYKColorSelectionComponent = ({ colorName, onColorChange, initialValue }: Props): JSX.Element => {
  const [cmykColor, setColor] = useState<string>(initialValue);
  const [c, setC] = useState<string>('');
  const [m, setM] = useState<string>('');
  const [y, setY] = useState<string>('');
  const [k, setK] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    if (initialValue) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialValue = initialValue.replace('cmyk(', '').replace(')', '');
      const values = initialValue.split(',');
      setC(values[0]);
      setM(values[1]);
      setY(values[2]);
      setK(values[3]);
    }
  }, [initialValue]);

  useEffect(() => {
    setColor(`cmyk(${c},${m},${y},${k})`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [c, m, y, k]);

  useEffect(() => {
    if (areColorsValid()) {
      setPreviewUrl(generatePreviewUrlForColor(cmykColor, 25));
    }
    onColorChange({ cmyk: cmykColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmykColor]);

  const preventMinus = (e: any) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const areColorsValid = (): boolean => {
    if (c === '' || m === '' || y === '' || k === '') {
      return false;
    }
    const colors = [+c, +m, +y, +k];
    for (const color of colors) {
      if (color > 100 || color < 0) {
        return false;
      }
    }
    return true;
  };
  const handleOnCChange = (cValue: string) => {
    setC(cValue);
  };
  const handleOnMChange = (mValue: string) => {
    setM(mValue);
  };

  const handleOnYChange = (yValue: string) => {
    setY(yValue);
  };

  const handleOnKChange = (kValue: string) => {
    setK(kValue);
  };

  return (
    <div className="row">
      <div className="col-sm-2 color-name">{colorName}</div>
      <div className="col-sm-2 color-input">
        <TextField
          type="number"
          min="0"
          step="1"
          max="100"
          label="c"
          name="c"
          value={c}
          data-testid={`${colorName}-c-input`}
          onChange={(e) => handleOnCChange(e.target.value)}
          autoComplete="off"
          onKeyPress={preventMinus}
          className={+c > 100 || +c < 0 ? 'border-red' : ''}
        />
      </div>
      <div className="col-sm-2">
        <TextField
          type="number"
          min="0"
          step="1"
          max="100"
          name="m"
          label="m"
          value={m}
          data-testid={`${colorName}-m-input`}
          onChange={(e) => handleOnMChange(e.target.value)}
          autoComplete="off"
          onKeyPress={preventMinus}
          className={+m > 100 || +m < 0 ? 'border-red' : ''}
          //+m <= 100 && +m >= 0 ?
        />
      </div>
      <div className="col-sm-2">
        <TextField
          type="number"
          min="0"
          step="1"
          max="100"
          name="y"
          label="y"
          value={y}
          data-testid={`${colorName}-y-input`}
          onChange={(e) => handleOnYChange(e.target.value)}
          autoComplete="off"
          onKeyPress={preventMinus}
          className={+y > 100 || +y < 0 ? 'border-red' : ''}
        />
      </div>
      <div className="col-sm-2">
        <TextField
          type="number"
          min="0"
          step="1"
          max="100"
          name="k"
          label="k"
          value={k}
          data-testid={`${colorName}-k-input`}
          onChange={(e) => handleOnKChange(e.target.value)}
          autoComplete="off"
          onKeyPress={preventMinus}
          className={+k > 100 || +k < 0 ? 'border-red' : ''}
        />
      </div>
      <div>{previewUrl && c && m && y && k && <img className="color-preview" src={previewUrl} alt="Preview" />}</div>
    </div>
  );
};
