import { Card } from '@cimpress/react-components';
import React from 'react';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';

interface UnknownLargeTileProps {
  asset: AnyAsset;
}

export const UnknownLargeTile = ({}: UnknownLargeTileProps): JSX.Element => {
  return (
    <Card className="unknown-tile">
      <span>Unknown asset</span>
    </Card>
  );
};
