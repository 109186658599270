import React, { useEffect, useState } from 'react';
import { isSuccessfulResponse } from '../../../Tools/ResponseHelper';
import { Accordion } from '@cimpress/react-components';
import ClearFilterButton from '../Components/ClearFilterButton';
import { FacetValue } from '../../../api/Cams/model/FacetValue';
import * as CamsApi from '../../../api/Cams/cams-api';
import { defaultPageNumber, maxPageSize } from '../../Model/Constants';
import { ApiResponseOrError } from '../../../api/ApiResponseOrError';
import { QueryStringState } from '../../QueryString/QueryStringState';
import { CheckboxList } from '../Components/CheckboxList';

interface Props {
  pageState: QueryStringState;
  label: string;
  onFilterChanged: (items: string[]) => void;
}

export const AccessibleToFilterPanel = ({ pageState, label, onFilterChanged }: Props): JSX.Element => {
  const [filteredItems, setFilteredItems] = useState<FacetValue[]>([]);

  const loadFilterValues = (): Promise<ApiResponseOrError<FacetValue[]>> => {
    if (pageState.tenant.settings.features.accessibleTo.length === 0) {
      return Promise.resolve({ data: [], responseType: 'success', statusCode: 200 });
    }

    return CamsApi.getFacetValues(
      pageState.tenant,
      'AccessibleTo',
      maxPageSize,
      defaultPageNumber,
      undefined,
      pageState.assetKinds,
      pageState.assetType,
      pageState.filters,
      pageState.search,
    );
  };

  const loadAllFilters = async () => {
    const facetValues = await loadFilterValues();

    if (isSuccessfulResponse(facetValues)) {
      const checkBoxItems = facetValues.data;

      pageState.filters.accessibleTo.forEach((element: string) => {
        if (checkBoxItems.find((x) => x.value == element) === undefined) {
          checkBoxItems.unshift({ label: `${element}`, value: element, usesCount: 0 });
        }
      });

      setFilteredItems(checkBoxItems);
    }
  };

  useEffect(() => {
    loadAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  return (
    <Accordion
      data-testid="accessibleTo-filters-panel"
      title={
        <>
          <span>{label}</span>
          <ClearFilterButton selectedItems={pageState.filters.accessibleTo} onFilterChanged={onFilterChanged} />
        </>
      }
      className="cams-accordion-card"
      defaultOpen
    >
      <CheckboxList
        allItems={filteredItems}
        selectedItems={pageState.filters.accessibleTo}
        onItemSelected={onFilterChanged}
        showUses={false}
        titleExtractor={(x) => x.value}
      />
    </Accordion>
  );
};
