import { Snackbar } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';

interface Props {
  message?: string;
  keepOpen?: boolean;
}

export const Toast = ({ message, keepOpen }: Props): JSX.Element => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const style = 'info';

  useEffect(() => {
    if (!message) {
      return;
    }

    setToastMessage(message);
    setShowSnackbar(true);
  }, [message]);

  return (
    <Snackbar
      show={showSnackbar}
      status={style}
      delay={keepOpen ? undefined : 7000}
      onHideSnackbar={() => setShowSnackbar(false)}
    >
      {toastMessage}
    </Snackbar>
  );
};
