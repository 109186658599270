import React, { useState, useEffect } from 'react';
import TenantPickerModal from './TenantPickerModal';
import { TenantDescription } from '../api/model/TenantDescription';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { tenantRoutePrefix } from '../Tools/Routes';
import * as LocalStorage from '../Tools/LocalStorage';
import { Tenant } from '../api/model/Tenant';
import { useNavigationButtons } from '../customHooks/useNavigationButtons';
import {
  parseAssetCategoryFromPathname,
  parseTenantFromPathname,
} from '../AssetsListPage/QueryString/QueryStringUtils';
import { getVariableAssetPageRoute } from '../Routes/GenericRoutes';
import { AssetCategory } from '../AssetsListPage/AssetCategory';

export const getTenantName = (tenant: TenantDescription): string => {
  return `${tenant.accountName}: ${tenant.contentAreaName}`;
};

interface Props {
  availableTenants: TenantDescription[] | undefined;
  onTenantSelected: (tenantDescription: TenantDescription) => void;
}

export const TenantPicker = ({ availableTenants, onTenantSelected }: Props): JSX.Element => {
  const [tenantDescription, setTenantDescription] = useState<TenantDescription>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const match = useRouteMatch<{ accountId: string; areaId: string }>(tenantRoutePrefix);
  const history = useHistory();
  const location = useLocation();
  const assetCategory = parseAssetCategoryFromPathname(location.pathname);

  const tenantFromLocalStorage = LocalStorage.getActiveTenant();

  const tenantFromRoute: Tenant | undefined =
    match?.params.accountId && match?.params.areaId
      ? {
          contentAuthoringAccountId: match?.params.accountId,
          contentAuthoringAreaId: match?.params.areaId,
        }
      : undefined;

  const onInitializeTenant = (tenant: TenantDescription) => {
    setIsModalOpen(false);
    LocalStorage.saveActiveTenant({
      contentAuthoringAccountId: tenant.contentAuthoringAccountId,
      contentAuthoringAreaId: tenant.contentAuthoringAreaId,
    });
    setTenantDescription(tenant);
    onTenantSelected(tenant);

    if (tenantFromRoute !== undefined) {
      return;
    }
    goToProperPage(tenant);
  };

  const onUserSelectedTenant = (tenant: TenantDescription) => {
    setIsModalOpen(false);
    LocalStorage.saveActiveTenant({
      contentAuthoringAccountId: tenant.contentAuthoringAccountId,
      contentAuthoringAreaId: tenant.contentAuthoringAreaId,
    });
    setTenantDescription(tenant);
    onTenantSelected(tenant);

    goToProperPage(tenant);
  };

  const goToProperPage = (tenant: TenantDescription) => {
    const allowedCategories = tenant.settings.features.allowedAssetCategories;
    const allowedCategoryFromPathname = allowedCategories?.find(
      (x) => x.toLowerCase() === assetCategory?.toLowerCase(),
    ) as AssetCategory;
    const allowedCategoryFromLocalStorage = allowedCategories?.find(
      (x) => x.toLowerCase() === LocalStorage.getAssetCategory()?.toLowerCase(),
    ) as AssetCategory;
    const category: AssetCategory =
      allowedCategories !== null
        ? allowedCategoryFromPathname || allowedCategoryFromLocalStorage || (allowedCategories[0] as AssetCategory)
        : assetCategory || LocalStorage.getAssetCategory() || 'design';

    history.push(
      getVariableAssetPageRoute(
        tenant.contentAuthoringAccountId,
        tenant.contentAuthoringAreaId,
        category,
        history.location.search ? new URLSearchParams(history.location.search).toString() : undefined,
      ),
    );
  };

  useEffect(() => {
    if (!availableTenants || tenantDescription) {
      return;
    }

    const initializeTenant = () => {
      const requestedTenant = tenantFromRoute ?? tenantFromLocalStorage;
      const selectedTenantDescription = requestedTenant
        ? availableTenants.find(
            (x) =>
              x.contentAuthoringAccountId === requestedTenant.contentAuthoringAccountId &&
              x.contentAuthoringAreaId === requestedTenant.contentAuthoringAreaId,
          )
        : undefined;

      // Tenant is already selected and user has access to it, just show it in the picker
      if (selectedTenantDescription) {
        onInitializeTenant(selectedTenantDescription);
        return;
      }

      switch (availableTenants.length) {
        case 1:
          // User has access to a single tenant - no need to show picker modal, just select it
          onInitializeTenant(availableTenants[0]);
          break;
        default:
          // Ask user to pick a tenant
          setIsModalOpen(true);
      }
    };

    initializeTenant();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTenants]);

  useNavigationButtons(() => {
    const currentTenantFromRoute: Tenant | undefined = parseTenantFromPathname(history.location.pathname);
    const requestedTenant = currentTenantFromRoute ?? tenantFromLocalStorage;
    const selectedTenantDescription = requestedTenant
      ? availableTenants?.find(
          (x) =>
            x.contentAuthoringAccountId === requestedTenant.contentAuthoringAccountId &&
            x.contentAuthoringAreaId === requestedTenant.contentAuthoringAreaId,
        )
      : undefined;

    if (selectedTenantDescription) {
      LocalStorage.saveActiveTenant({
        contentAuthoringAccountId: selectedTenantDescription.contentAuthoringAccountId,
        contentAuthoringAreaId: selectedTenantDescription.contentAuthoringAreaId,
      });
      setTenantDescription(selectedTenantDescription);
      onTenantSelected(selectedTenantDescription);
      return;
    }
  }, [availableTenants]);

  return (
    <>
      <button onClick={() => setIsModalOpen(true)} data-testid="tenant-picker-button">
        Tenant: {tenantDescription ? `${getTenantName(tenantDescription)}` : 'Not Selected'}
      </button>

      {availableTenants && availableTenants.length > 0 && (
        <TenantPickerModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          availableTenants={availableTenants}
          selected={tenantDescription}
          onSelected={onUserSelectedTenant}
        />
      )}
    </>
  );
};
