import config from '../../config';
import { Upload } from './models/Upload';
import * as RequestUtils from '../RequestUtils';
import { ApiResponseOrError } from '../ApiResponseOrError';

const uploadsApi = config.uploadsApiUrl;

export const uploads = async (
  blob: Blob,
  fileName: string,
  tenant = 'default',
  deleteAfterDays = 1,
): Promise<ApiResponseOrError<Upload[]>> => {
  const url = new URL(
    `${uploadsApi}/v1/uploads?tenant=${tenant}&deleteAfterDays=${deleteAfterDays}&process={ "type": "storeOnly"}`,
  );
  const formData = new FormData();
  formData.append('File', blob, fileName);
  formData.append('type', 'application/json');
  const config = RequestUtils.createFileUploadRequestConfig();

  return await RequestUtils.doPostRequest<Upload[]>(url, formData, config);
};

export const readJson = async <T>(url: URL): Promise<ApiResponseOrError<T>> => {
  return await RequestUtils.doGetRequest<T>(url);
};
