import { Card, Modal } from '@cimpress/react-components';
import React, { useContext, useState } from 'react';
import './ClipartHeader.scss';
import '../detail-header.scss';
import { AssetTags } from '../components/AssetTags';
import { AssetCollections } from '../components/AssetCollections';
import { AppContext } from '../App';
import { ClipartManagementAsset } from '../api/Cams/model/Clipart/ClipartManagementAsset';
import { ClipartManagementAssetVersion } from '../api/Cams/model/Clipart/ClipartManagementAssetVersion';
import { AssetPreview } from '../components/AssetPreview';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { generatePreviewUrl } from '../Tools/CimDocUtil';
import { getClipartManagementAssetsPageRoute } from '../Routes/ClipartManagementAssetRoutes';
import { Tenant } from '../api/model/Tenant';
import { DownloadButton } from '../components/DownloadButton';
import { serializeQueryParams } from '../AssetsListPage/QueryString/QueryStringUtils';
import { PublishedToggle } from '../components/PublishedToggle';
import { AssetVersion } from '../components/AssetVersion';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { DifferentAssetVersionPublished } from '../components/DifferentAssetVersionPublished';
import { DesignConceptIdEdit } from '../CreativeAssetDetailPage/components/DesignConceptIdEdit';
import { AssetAuditInfo } from '../components/AssetAuditInfo';
import { AssetDestinations } from '../components/AssetDestinations';

interface Props {
  clipart: ClipartManagementAsset;
  clipartVersion: ClipartManagementAssetVersion;
  firstVersion: ClipartManagementAssetVersion;
  isLatestVersion: boolean;
  isPublishButtonEnabled: boolean;
  onPublish: (tenant: Tenant, published: boolean) => Promise<ApiResponseOrError<any>>;
  onSaveTags: (tenant: Tenant, keywords: string[]) => Promise<ApiResponseOrError<ClipartManagementAsset>>;
  onSaveCollections: (tenant: Tenant, collections: string[]) => Promise<ApiResponseOrError<ClipartManagementAsset>>;
  onSaveDestinations: (tenant: Tenant, destinations: string[]) => Promise<ApiResponseOrError<ClipartManagementAsset>>;
}

export const ClipartHeader = ({
  clipart,
  clipartVersion,
  firstVersion,
  isLatestVersion,
  isPublishButtonEnabled,
  onPublish,
  onSaveTags,
  onSaveCollections,
  onSaveDestinations,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const downloadLink = clipartVersion.masterFile.uploadInfo.fileUrl;
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const clipartContentVersion = clipartVersion?.contentAsset;
  const isDifferentVersionPublished =
    clipart.discoverableVersionNumber !== null && clipart.discoverableVersionNumber !== clipartVersion.versionNumber;

  return (
    <Card>
      <div className="row header-wrapper">
        <div className="col-sm-4">
          <div className="preview-wrapper" style={{ minHeight: '200px' }}>
            <AssetPreview assetVersion={clipartVersion} />
          </div>
          <div className="card-text detail-header-description">
            <AssetVersion
              assetVersion={clipartVersion}
              tenant={tenant}
              assetCategory={'clipart'}
              isLatestVersion={isLatestVersion}
            />

            <AssetAuditInfo
              assetVersion={clipartVersion}
              tenant={tenant}
              assetCategory={'clipart'}
              isLatestVersion={isLatestVersion}
              firstVersion={firstVersion as AnyAssetVersion}
            />
            <p></p>
            {clipartContentVersion?.uploadInfo !== undefined && (
              <p>Content asset id: {clipartContentVersion.uploadInfo.id}</p>
            )}
          </div>
        </div>
        <div className="col-sm-8 detail-header-wrapper">
          <div className="detail-header-title-row">
            <h1 className="text-primary detail-header-name" data-testid="clipart-name-header">
              {clipart.isDeleted ? (
                <span style={{ color: 'red' }}>
                  {clipartVersion.masterFile?.uploadInfo?.fileName} (Asset was deleted)
                </span>
              ) : (
                <>{clipartVersion.masterFile?.uploadInfo?.fileName}</>
              )}
            </h1>
            {
              <PublishedToggle
                publishedInit={clipartVersion.isDiscoverable}
                updateMethod={onPublish}
                getterFromResponse={(resp) => resp.data.isDiscoverable}
                enabledInit={isPublishButtonEnabled}
                showConfirmation={isDifferentVersionPublished}
              />
            }
          </div>
          <div className="card-text">
            {isDifferentVersionPublished && (
              <DifferentAssetVersionPublished
                assetId={clipart.id}
                versionNumber={clipart.discoverableVersionNumber}
                tenant={tenant}
                assetCategory={'clipart'}
              />
            )}
            <div className="detail-header-tags-wrapper">
              <DesignConceptIdEdit asset={clipart} />
            </div>
            <div className="detail-header-tags-wrapper">
              <AssetTags
                entityTags={clipart.contentTags}
                facetName="ContentTags"
                componentLabel="Tags"
                updateMethod={onSaveTags}
                disabled={!tenant.userPermissions.canWrite}
                enableAutocomplete={tenant.settings.features.hideTagsFilter !== true}
                getterFromResponse={(resp: ApiResponse<ClipartManagementAsset>) => resp.data.contentTags ?? []}
                getAssetsListWithTagFilterRoute={(tag) =>
                  getClipartManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { tags: [tag] } }),
                  )
                }
              />
            </div>
            <div className="detail-header-tags-wrapper">
              <AssetCollections
                entityCollections={clipart.contentCollections}
                facetName="ContentCollections"
                componentLabel="Collections"
                updateMethod={onSaveCollections}
                disabled={!tenant.userPermissions.canWrite}
                getterFromResponse={(resp) => resp.data.contentCollections}
                getAssetsListWithCollectionFilterRoute={(collection) =>
                  getClipartManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { collections: [collection] } }),
                  )
                }
              />
            </div>
            {tenant.settings.features.accessibleTo.length > 0 && (
              <div className="detail-header-tags-wrapper">
                <AssetDestinations
                  entityDestinations={clipart.accessibleTo}
                  componentLabel="Accessible to"
                  disabled={!tenant.userPermissions.canPublish}
                  updateMethod={onSaveDestinations}
                  getterFromResponse={(resp: ApiResponse<ClipartManagementAsset>) => resp.data.accessibleTo ?? []}
                  getAssetsListWithDestinationFilterRoute={(accessibleTo) =>
                    getClipartManagementAssetsPageRoute(
                      tenant.contentAuthoringAccountId,
                      tenant.contentAuthoringAreaId,
                      serializeQueryParams({ filters: { accessibleTo: [accessibleTo] } }),
                    )
                  }
                />
              </div>
            )}
          </div>
          <div className="card-text">
            <div className="detail-header-tags-wrapper">
              <div className="detail-header-buttons">
                <DownloadButton
                  downloadFilename={clipartVersion.masterFile.uploadInfo.fileName}
                  downloadLink={downloadLink}
                  isDownloadable={true}
                />
              </div>
              <div className="preview-buttons">
                {/* {clipartVersion?.contentAsset && (
                  <PreviewInDexButton tenant={tenant} assets={[clipartVersion?.contentAsset]} />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        status="info"
        show={previewModalOpen}
        onRequestHide={() => setPreviewModalOpen(false)}
        title={`Clipart preview`}
        closeButton={true}
      >
        <img
          src={generatePreviewUrl(downloadLink ?? '', 1200)}
          className="detail-preview-image"
          alt="Clipart preview"
        />
      </Modal>
    </Card>
  );
};
