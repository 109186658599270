import { Snackbar } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';
import { ApiResponseOrError } from '../api/ApiResponseOrError';

interface Props {
  response?: ApiResponseOrError<any>;
}

export const ApiErrorToast = ({ response }: Props): JSX.Element => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!response) {
      return;
    }

    if (response.responseType === 'error') {
      setErrorMessage(response.content || 'Unexpected error occured.');
      setShowSnackbar(true);
    }
  }, [response]);

  return (
    <Snackbar show={showSnackbar} status="danger" delay={5000} onHideSnackbar={() => setShowSnackbar(false)}>
      {errorMessage}
    </Snackbar>
  );
};
