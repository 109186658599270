import React, { useEffect, useState } from 'react';
import { isSuccessfulResponse } from '../../../Tools/ResponseHelper';
import { Accordion } from '@cimpress/react-components';
import ClearFilterButton from '../Components/ClearFilterButton';
import { FacetValue } from '../../../api/Cams/model/FacetValue';
import * as CamsApi from '../../../api/Cams/cams-api';
import { defaultPageNumber, maxPageSize } from '../../Model/Constants';
import { CheckboxList } from '../Components/CheckboxList';
import { SearchInput } from '../Components/SearchInput';
import { QueryStringState } from '../../QueryString/QueryStringState';

interface Props {
  pageState: QueryStringState;
  label: string;
  onFilterChanged: (items: string[]) => void;
}

export const ProducNameFilterPanel = ({ pageState, label, onFilterChanged }: Props): JSX.Element => {
  const [allItems, setAllItems] = useState<FacetValue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const loadProductNames = async (searchValue: string | undefined) => {
    setSearchValue(searchValue ?? '');
    return await CamsApi.getFacetValues(
      pageState.tenant,
      'ProductNames',
      maxPageSize,
      defaultPageNumber,
      searchValue,
      pageState.assetKinds,
      pageState.assetType,
      pageState.filters,
      pageState.search,
    );
  };

  const loadAllFilters = async () => {
    const facetValues = await loadProductNames(searchValue);

    if (isSuccessfulResponse(facetValues)) {
      const checkBoxItems = facetValues.data;

      pageState.filters.productNames.forEach((element) => {
        if (checkBoxItems.find((x) => x.value == element) === undefined) {
          checkBoxItems.unshift({ label: `${element}`, value: element, usesCount: 0 });
        }
      });

      setAllItems(checkBoxItems);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  return (
    <Accordion
      data-testid="productname-filters-panel"
      title={
        <>
          <span>{label}</span>
          <ClearFilterButton selectedItems={pageState.filters.productNames} onFilterChanged={onFilterChanged} />
        </>
      }
      className="cams-accordion-card"
      defaultOpen
    >
      <SearchInput
        onFiltered={setAllItems}
        searchType="remote-call"
        onSearch={(searchValue) => loadProductNames(searchValue)}
      />

      {!isLoading && allItems.length === 0 && <span>No product names found.</span>}

      <CheckboxList
        allItems={allItems}
        selectedItems={pageState.filters.productNames}
        onItemSelected={onFilterChanged}
        showUses={true}
        titleExtractor={(x) => x.value}
      />
    </Accordion>
  );
};
