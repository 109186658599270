import React, { useContext, useEffect, useState } from 'react';
import { Tenant } from '../api/model/Tenant';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import * as CamsApi from '../api/Cams/cams-api';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { CategoryKindLocalizations, CategoryLocalizationData } from './CategoryLocalization';
import './CategoryLocalization.scss';
import CategoryLocalizationTable from './CategoryLocalizationTable';
import { Button } from '@cimpress/react-components';
import { UploadCategoryLocalizationControl } from './UploadCategoryLocalizationControl';
import { ApiResponseOrError } from '../api/ApiResponseOrError';
import { ApiErrorToast } from '../components/ApiErrorToast';
import { downloadBlobAsFile } from '../Tools/DownloadAsFile';

const downloadFilename = 'Localizations.csv';

const CategoryLocalizationPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [uploadErrorResponse, setUploadErrorResponse] = useState<ApiResponseOrError<any>>();
  const [localizationData, setLocalizationData] = useState<CategoryLocalizationData>();
  const [patternsLocalizations, setPatternsLocalizations] = useState<CategoryKindLocalizations>();
  const [clipartsLocalizations, setClipartsLocalizations] = useState<CategoryKindLocalizations>();

  const fetchCategoryLocalizations = async (tenant: Tenant) => {
    setIsLoading(true);
    const [request] = CamsApi.getCategoryLocalization(tenant);
    const response = await request;

    if (isSuccessfulResponse(response)) {
      const categoryLocalizationsData = response.data;

      setLocalizationData(categoryLocalizationsData);
      setPatternsLocalizations(categoryLocalizationsData.localizations['pattern']);
      setClipartsLocalizations(categoryLocalizationsData.localizations['clipart']);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCategoryLocalizations(tenant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const onNewVersionCreated = async (errorResponse?: ApiResponseOrError<any>) => {
    setUploadErrorResponse(errorResponse);
    setIsCreatingNewVersion(false);
    await fetchCategoryLocalizations(tenant);
  };

  const areLocalizationsLoaded = !isLoading && localizationData && (patternsLocalizations || clipartsLocalizations);

  async function onDownloadClick() {
    const response = await CamsApi.downloadCategoryLocalizationFile(tenant);
    if (isSuccessfulResponse(response)) {
      const fileContent = await response.data;

      downloadBlobAsFile(fileContent, downloadFilename);
    }
  }

  return (
    <div className="col-sm-12">
      {areLocalizationsLoaded && isCreatingNewVersion && (
        <UploadCategoryLocalizationControl onUpload={onNewVersionCreated} />
      )}
      <div className="category-localization-section">
        <h2>Category Localization</h2>
        {areLocalizationsLoaded && (
          <div>
            <Button onClick={onDownloadClick} variant="primary" className="download-button" size="default">
              Download localizations
            </Button>
            {isCreatingNewVersion ? (
              <Button variant="primary" className="btn-danger" onClick={() => setIsCreatingNewVersion(false)}>
                Cancel
              </Button>
            ) : (
              <Button
                variant="primary"
                data-testid="upload-new-version-button"
                onClick={() => setIsCreatingNewVersion(true)}
                disabled={!tenant.userPermissions.canWrite}
              >
                Upload new localizations
              </Button>
            )}
          </div>
        )}
      </div>
      {isLoading && <Loading />}
      {!isLoading && localizationData && (patternsLocalizations || clipartsLocalizations) && (
        <div className="category-localization-table-wrapper">
          {patternsLocalizations && (
            <>
              <h3>Patterns</h3>
              <CategoryLocalizationTable categoryKind="pattern" categoryLocalizationData={localizationData} />
            </>
          )}
          {clipartsLocalizations && (
            <>
              <h3>Cliparts</h3>
              <CategoryLocalizationTable categoryKind="clipart" categoryLocalizationData={localizationData} />
            </>
          )}
        </div>
      )}

      <ApiErrorToast response={uploadErrorResponse} />
    </div>
  );
};

export default CategoryLocalizationPage;
