import React from 'react';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';
import { DesignTile } from './Designs/DesignTile';
import { Tile } from './GenericAssetViews/Tile';
import { UnknownTile } from './UnknownTile';
import './AssetTile.scss';
import { AssetCategory } from '../AssetCategory';

interface Props {
  asset: AnyAsset;
  assetCategory: AssetCategory;
  onAssetSelected: (asset: AnyAsset, isSelected: boolean) => void;
}

export const AssetTile = ({ asset, assetCategory, onAssetSelected }: Props): JSX.Element => {
  switch (asset.assetType) {
    case 'management': {
      switch (asset.assetKind) {
        case 'design':
          return <DesignTile design={asset} onAssetSelected={onAssetSelected} />;
        case 'pattern':
        case 'clipart':
        case 'background':
        case 'fontSchema':
        case 'colorPalette':
          return <Tile asset={asset} onAssetSelected={onAssetSelected} assetCategory={assetCategory} />;
        default:
          return <UnknownTile asset={asset} />;
      }
    }
    case 'creative':
      return <Tile asset={asset} onAssetSelected={onAssetSelected} assetCategory={assetCategory} />;
    default:
      return <UnknownTile asset={asset} />;
  }
};
