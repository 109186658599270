import { AssetCategory } from './AssetsListPage/AssetCategory';

interface AssetCategoryConfig {
  hasLocalization: boolean;
  canBeFilteredByDesignConcept: boolean;
  canBeFilteredByDesignUseCase: boolean;
  canBeFilteredByProductNamesFilter: boolean;
  canBeFilteredByPublishStatus: boolean;
  downloadable: boolean;
  isFileNameVisible: boolean;
  isCreateNewButtonLabelPlural: boolean;
  isBulkCreateAllowed: boolean;
  hasUniqueDesignConceptId: boolean;
}

const assetCategorySettings: { [assetCategory: string]: AssetCategoryConfig } = {
  patterns: {
    hasLocalization: true,
    canBeFilteredByDesignConcept: false,
    canBeFilteredByDesignUseCase: false,
    canBeFilteredByProductNamesFilter: false,
    canBeFilteredByPublishStatus: true,
    downloadable: true,
    isFileNameVisible: true,
    isCreateNewButtonLabelPlural: true,
    isBulkCreateAllowed: true,
    hasUniqueDesignConceptId: false,
  },
  cliparts: {
    hasLocalization: true,
    canBeFilteredByDesignConcept: true,
    canBeFilteredByDesignUseCase: false,
    canBeFilteredByProductNamesFilter: false,
    canBeFilteredByPublishStatus: true,
    downloadable: true,
    isFileNameVisible: true,
    isCreateNewButtonLabelPlural: true,
    isBulkCreateAllowed: true,
    hasUniqueDesignConceptId: true,
  },
  backgrounds: {
    hasLocalization: false,
    canBeFilteredByDesignConcept: true,
    canBeFilteredByDesignUseCase: false,
    canBeFilteredByProductNamesFilter: false,
    canBeFilteredByPublishStatus: true,
    downloadable: true,
    isFileNameVisible: true,
    isCreateNewButtonLabelPlural: true,
    isBulkCreateAllowed: true,
    hasUniqueDesignConceptId: false,
  },
  designs: {
    hasLocalization: false,
    canBeFilteredByDesignConcept: true,
    canBeFilteredByDesignUseCase: true,
    canBeFilteredByProductNamesFilter: true,
    canBeFilteredByPublishStatus: false, //We get this from CAM tenant settings for designs
    downloadable: true,
    isFileNameVisible: true,
    isCreateNewButtonLabelPlural: false,
    isBulkCreateAllowed: false,
    hasUniqueDesignConceptId: false,
  },
  creative: {
    hasLocalization: false,
    canBeFilteredByDesignConcept: true,
    canBeFilteredByDesignUseCase: false,
    canBeFilteredByProductNamesFilter: false,
    canBeFilteredByPublishStatus: false,
    downloadable: true,
    isFileNameVisible: true,
    isCreateNewButtonLabelPlural: false,
    isBulkCreateAllowed: false,
    hasUniqueDesignConceptId: false,
  },
  fontSchema: {
    hasLocalization: false,
    canBeFilteredByDesignConcept: true,
    canBeFilteredByDesignUseCase: false,
    canBeFilteredByProductNamesFilter: false,
    canBeFilteredByPublishStatus: true,
    downloadable: false,
    isFileNameVisible: false,
    isCreateNewButtonLabelPlural: false,
    isBulkCreateAllowed: false,
    hasUniqueDesignConceptId: true,
  },
  colorPalette: {
    hasLocalization: false,
    canBeFilteredByDesignConcept: true,
    canBeFilteredByDesignUseCase: false,
    canBeFilteredByProductNamesFilter: false,
    canBeFilteredByPublishStatus: true,
    downloadable: false,
    isFileNameVisible: false,
    isCreateNewButtonLabelPlural: false,
    isBulkCreateAllowed: false,
    hasUniqueDesignConceptId: true,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAssetCategorySetting = (assetCategory: AssetCategory): AssetCategoryConfig => {
  switch (assetCategory) {
    case 'pattern':
      return assetCategorySettings.patterns;
    case 'clipart':
      return assetCategorySettings.cliparts;
    case 'background':
      return assetCategorySettings.backgrounds;
    case 'creative asset':
      return assetCategorySettings.creative;
    case 'font schema':
      return assetCategorySettings.fontSchema;
    case 'color palette':
      return assetCategorySettings.colorPalette;
    case 'design':
      return assetCategorySettings.designs;
  }
};
