import { Card } from '@cimpress/react-components';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AnyAsset } from '../api/Cams/model/AnyAsset';
import { TenantDescription } from '../api/model/TenantDescription';
import { AppContext } from '../App';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { AssetPreview } from '../components/AssetPreview';
import { getRouteByAssetCategory } from '../Tools/NavigationRoutesUtility';
import './DesignConceptAssetTile.scss';

interface DesignConceptAssetTileProps {
  asset: AnyAsset;
  assetCategory: AssetCategory;
  showCategoryLabel?: boolean;
  showInCard?: boolean;
}

interface DesignConceptAssetTileInternalProps {
  tenant: TenantDescription;
  asset: AnyAsset;
  assetCategory: AssetCategory;
  showCategoryLabel?: boolean;
}

const DesignConceptAssetTileInternal = ({
  tenant,
  asset,
  assetCategory,
  showCategoryLabel,
}: DesignConceptAssetTileInternalProps) => {
  return (
    <>
      <div className="dc-asset-tile-header">
        {showCategoryLabel && <h2>{assetCategory.format({ capitalLetter: true })}</h2>}
        <span className="publish-status">{asset.latestVersion.isDiscoverable ? 'Published' : 'Unpublished'}</span>
      </div>
      <Link to={`${getRouteByAssetCategory(tenant, assetCategory)}${asset.id}`}>
        <div className="preview-wrapper">
          <AssetPreview assetVersion={asset.latestVersion} width={500} />
        </div>
      </Link>
    </>
  );
};

export const DesignConceptAssetTile = ({
  asset,
  assetCategory,
  showCategoryLabel = true,
  showInCard = true,
}: DesignConceptAssetTileProps): JSX.Element => {
  const { tenant } = useContext(AppContext);
  return showInCard ? (
    <Card className="asset-tile">
      <DesignConceptAssetTileInternal
        tenant={tenant}
        asset={asset}
        assetCategory={assetCategory}
        showCategoryLabel={showCategoryLabel}
      />
    </Card>
  ) : (
    <div className="asset-tile">
      <DesignConceptAssetTileInternal
        tenant={tenant}
        asset={asset}
        assetCategory={assetCategory}
        showCategoryLabel={showCategoryLabel}
      />
    </div>
  );
};
