import React from 'react';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';
import { DesignLargeTile } from './Designs/DesignLargeTile';
import { LargeTile } from './GenericAssetViews/LargeTile';
import { UnknownLargeTile } from './UnknownLargeTile';
import './AssetLargeTile.scss';
import { AssetCategory } from '../AssetCategory';
interface AssetLargeTileProps {
  asset: AnyAsset;
  assetCategory: AssetCategory;
  onAssetSelected: (asset: AnyAsset, isSelected: boolean) => void;
}

export const AssetLargeTile = ({ asset, assetCategory, onAssetSelected }: AssetLargeTileProps): JSX.Element => {
  switch (asset.assetType) {
    case 'management': {
      switch (asset.assetKind) {
        case 'design':
          return <DesignLargeTile design={asset} onAssetSelected={onAssetSelected} />;
        case 'pattern':
        case 'clipart':
        case 'fontSchema':
        case 'colorPalette':
        case 'background':
          return <LargeTile asset={asset} onAssetSelected={onAssetSelected} assetCategory={assetCategory} />;
        default:
          return <UnknownLargeTile asset={asset} />;
      }
    }
    case 'creative':
      return <LargeTile asset={asset} onAssetSelected={onAssetSelected} assetCategory={assetCategory} />;
    default:
      return <UnknownLargeTile asset={asset} />;
  }
};
