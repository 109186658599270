import config from '../../config';
import { throwOnApiError } from '../../Tools/ResponseHelper';
import * as RequestUtils from '../RequestUtils';
import { AgsComponent } from './model/Ags/models';

const agsApiHost = config.agsApiUrl;
const camRequestor = config.camRequestor;

export const getComposedTemplateUrl = async (
  targetDocumentUrl: string,
  components: AgsComponent[],
): Promise<string> => {
  const url = new URL(`${agsApiHost}/api/v1/Composite`);
  const axiosConfig = RequestUtils.axiosDefaultConfig();

  const requestBody = {
    TargetDocumentUrl: targetDocumentUrl,
    requester: camRequestor,
    Components: components,
  };

  const composedTemplateResponseData = await throwOnApiError(
    RequestUtils.doPostRequest<any>(url, requestBody, axiosConfig),
  );

  const uploadsUrl = new URL('https://uploads.documents.cimpress.io/v1/uploads?deleteAfterDays=1');
  const uploadsResponseData = await throwOnApiError(
    RequestUtils.doPostRequest<{ uploadId: string }[]>(uploadsUrl, composedTemplateResponseData, axiosConfig),
  );

  const uploadId = uploadsResponseData[0].uploadId;

  return `https://uploads.documents.cimpress.io/v1/uploads/${uploadId}`;
};
