import { DexAsset } from './DexAsset';

const getLexicographicallyLast = (strings: string[]): string => {
  return strings.reduce((prev, curr) => (prev.localeCompare(curr) < 0 ? curr : prev));
};

export const sortAssetsLexicographically = (assets: DexAsset[]): DexAsset[] => {
  return assets.sort((a, b) =>
    getLexicographicallyLast(a.categories).localeCompare(getLexicographicallyLast(b.categories)),
  );
};

export const sortAssetsCustomOrder = (assets: DexAsset[], customOrder: string[]): DexAsset[] => {
  if (!(customOrder && customOrder.length > 0)) {
    return sortAssetsLexicographically(assets);
  }

  const customOrderDictionary: { [category: string]: number } = {};
  customOrder.forEach((c, i) => (customOrderDictionary[c] = i));

  return assets.sort((a, b) => {
    const categoryA = a.category;
    const categoryB = b.category;

    // if both categories have defined order, we sort by that order
    if (customOrderDictionary[categoryA] !== undefined && customOrderDictionary[categoryB] !== undefined) {
      return customOrderDictionary[categoryA] - customOrderDictionary[categoryB];
    }

    // if only one category has defined order, it goes in front of the other
    if (customOrderDictionary[categoryA] !== undefined) {
      return -1;
    }
    if (customOrderDictionary[categoryB] !== undefined) {
      return 1;
    }

    // handle Uncategorized
    if (categoryA === 'Uncategorized' && categoryB === 'Uncategorized') {
      return 0;
    }
    if (categoryA === 'Uncategorized') {
      return 1;
    }
    if (categoryB === 'Uncategorized') {
      return -1;
    }

    // else we default to alphabetical sort
    return categoryA.localeCompare(categoryB);
  });
};
