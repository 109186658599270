import React, { useContext, useEffect, useState } from 'react';
import { NoPreview } from './NoPreview';
import * as dpsApi from '../api/Dps/dps-api';
import { CreativeAssetVersion, isCreativeAssetVersion } from '../api/Cams/model/CreativeAssetVersion';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { UploadInfo } from '../api/Cams/model/UploadInfo';
import { sherbertImageExtensions } from '../Tools/GlobalConstants';
import { isManagementAssetVersion } from '../api/Cams/model/ManagementAssetVersion';
import { isFontSchemaManagementAssetVersion } from '../api/Cams/model/FontSchema/FontSchemaManagementAssetVersion';
import { generatePreviewUrlForColorPalette, generatePreviewUrlForFonts } from '../Tools/CimDocUtil';
import { AppContext } from '../App';
import { getFontSchema } from '../Tools/FontSchemaUtils';
import { isColorPaletteManagementAssetVersion } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAssetVersion';
import { getColorPalette } from '../Tools/ColorPaletteUtils';
import './AssetPreview.scss';

type AssetPreviewSize = 'thumb' | 'webPreview' | 'original';

interface Props {
  assetVersion: AnyAssetVersion;
  navigationUri?: string;
  showAsLink?: boolean;
  width?: number;
  physicalSpecId?: string;
}

export const AssetPreview = ({
  assetVersion,
  navigationUri,
  width,
  physicalSpecId,
  showAsLink,
}: Props): JSX.Element => {
  const [backgroundColor, setBackgroundColor] = useState<dpsApi.Rgba>();
  const [imageUri, setImageUri] = useState<string>();
  const { tenant } = useContext(AppContext);

  useEffect(() => {
    const getBackground = async () => {
      if (physicalSpecId === undefined) {
        return;
      }

      const response = await dpsApi.getBackgroundRgba(physicalSpecId, dpsApi.Rgba.WhiteColor);
      setBackgroundColor(response);
    };
    getImageUri(assetVersion);
    getBackground();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetVersion, physicalSpecId]);

  const widthToSize = (width?: number): AssetPreviewSize => {
    if (!width) return 'webPreview';

    if (width <= 500) return 'thumb';

    return 'webPreview';
  };

  const getImageUri = async (assetVersion: AnyAssetVersion) => {
    let uri = undefined;
    if (isCreativeAssetVersion(assetVersion)) {
      uri = identifyBestFitPreview(assetVersion, width);
    }

    if (isFontSchemaManagementAssetVersion(assetVersion)) {
      setBackgroundColor(dpsApi.Rgba.WhiteColor);
      const fontSchema = await getFontSchema(assetVersion);
      uri = await generatePreviewUrlForFonts(fontSchema, tenant.settings.fontConfiguration.fontRepository, width);
    } else if (isColorPaletteManagementAssetVersion(assetVersion)) {
      const colorPalette = await getColorPalette(assetVersion);
      uri = await generatePreviewUrlForColorPalette(colorPalette, width);
    } else if (isManagementAssetVersion(assetVersion)) {
      uri = identifyBestFitPreview(assetVersion.masterFile, width);
    }

    setImageUri(uri);
  };
  const uploadIsImage = (uploadInfo: UploadInfo | undefined): boolean => {
    if (!uploadInfo || !uploadInfo.fileName) return false;

    const fileName = uploadInfo.fileName.toLowerCase();
    return sherbertImageExtensions.some((ext) => fileName.endsWith(ext));
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [imageUri]);

  const buildSherbertPreviewUrl = (fileUrl: string, size?: AssetPreviewSize): string => {
    if (size === 'original') return fileUrl;

    const url = new URL(fileUrl);
    url.pathname = `${url.pathname}/${size || 'webPreview'}`;
    return url.toString();
  };

  const identifyBestFitPreview = (assetVersion?: CreativeAssetVersion, width?: number): string | undefined => {
    // for SVG use svg itself
    if (
      assetVersion?.uploadInfo?.fileName?.endsWith('.svg') ||
      // AES images hotfix
      assetVersion?.uploadInfo?.fileName?.endsWith('.svg%22')
    ) {
      return assetVersion?.uploadInfo?.fileUrl;
    }

    const size = widthToSize(width);
    if (assetVersion?.previewInfo) {
      return buildSherbertPreviewUrl(assetVersion.previewInfo.fileUrl, size);
    }

    if (assetVersion && uploadIsImage(assetVersion.uploadInfo)) {
      return buildSherbertPreviewUrl(assetVersion.uploadInfo.fileUrl, size);
    }

    return undefined;
  };

  return (
    <>
      {imageUri &&
        (navigationUri || showAsLink ? (
          <a href={navigationUri || imageUri} style={{ display: 'contents' }} target="_blank" rel="noopener noreferrer">
            <img
              src={imageUri}
              className="template-preview-image"
              alt="Asset preview"
              style={backgroundColor !== undefined ? { background: backgroundColor.toCssRgba() } : undefined}
            />
          </a>
        ) : (
          <img
            src={imageUri}
            className="template-preview-image"
            alt="Asset preview"
            style={backgroundColor !== undefined ? { background: backgroundColor.toCssRgba() } : undefined}
          />
        ))}
      {!imageUri && <NoPreview />}
    </>
  );
};
