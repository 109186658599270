export interface DesignConceptSort {
  columnName: DesignConceptColumnNameToSort;
  sortOrder: SortOrder;
}

const DesignConceptColumnNamesToSort = ['assetCount', 'designConceptId'] as const;
type DesignConceptColumnNameToSort = typeof DesignConceptColumnNamesToSort[number];

export const isDesignConceptColumnNameToSort = (
  columnName?: string | null,
): columnName is DesignConceptColumnNameToSort => {
  return DesignConceptColumnNamesToSort.includes(columnName as DesignConceptColumnNameToSort);
};

const SortOrders = ['asc', 'desc'] as const;
type SortOrder = typeof SortOrders[number];

export const isSortOrder = (order?: string | null): order is SortOrder => {
  return SortOrders.includes(order as SortOrder);
};
