import React, { useContext } from 'react';
import { ActionResult, createErrorResponse, createSuccessResponse } from '../Tools/ActionResult';
import { FileToUploadWithProgress } from './FileToUploadWithProgress';
import {
  createOrUpdateBackgroundAsset,
  createOrUpdateClipartAsset,
  createOrUpdatePatternAsset,
} from '../api/Cams/CamsApiFacade';
import { AppContext } from '../App';
import { UploadsControlBatch } from './UploadsControlBatch';
import * as Logger from '../Tools/Logging';
import { Asset } from '../api/Cams/model/Asset';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { useHistory } from 'react-router-dom';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';
import { getAssetCategorySetting } from '../AssetCategoryConfig';

interface Props {
  onCloseClick: () => void;
  assetCategory: AssetCategory;
  designConceptId?: string;
}

export const CreateBatchAssetControl = ({ onCloseClick, assetCategory, designConceptId }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const history = useHistory();

  const handleFileUpload = async (
    fileToUpload: FileToUploadWithProgress,
    tags: string[],
    collections: string[],
    onProgressUpdated: (updatedFiles: FileToUploadWithProgress) => void,
  ): Promise<Asset<AnyAssetVersion>> => {
    fileToUpload.status = 'Running';
    onProgressUpdated(fileToUpload);

    switch (assetCategory) {
      case 'pattern':
        return await createOrUpdatePatternAsset(tenant, fileToUpload, tags, collections);
      case 'clipart':
        return await createOrUpdateClipartAsset(tenant, fileToUpload, tags, collections, designConceptId);
      case 'background':
        return await createOrUpdateBackgroundAsset(tenant, fileToUpload, tags, collections, designConceptId);

      default:
        throw new Error('Unsupported for batch uploads');
    }
  };

  const handleSuccess = (
    uploadFile: FileToUploadWithProgress,
    clipart: Asset<AnyAssetVersion>,
  ): Asset<AnyAssetVersion> => {
    Logger.info(`Upload completed for ${uploadFile.contentFile.name}`);
    uploadFile.status = 'Success';
    return clipart;
  };

  const handleError = (uploadFile: FileToUploadWithProgress, e: any): Error => {
    Logger.error(`Upload failed for ${uploadFile.contentFile.name}`, e);
    uploadFile.status = 'Failed';
    let message = e?.message;
    try {
      message = JSON.parse(e.message)?.detail;
    } catch (e) {}
    uploadFile.error = { name: 'UploadFailed', message: message };
    return e as Error;
  };

  const navigateToDetailPage = (assetInfo: (Error | Asset<AnyAssetVersion>)[]) => {
    if (assetInfo[0] instanceof Error) {
      return;
    } else
      history.push(
        getVariableAssetDetailPageRoute(
          tenant.contentAuthoringAccountId,
          tenant.contentAuthoringAreaId,
          assetCategory,
          assetInfo[0].id,
        ),
      );
  };

  const handleUpload = async (
    uploads: FileToUploadWithProgress[],
    tags: string[],
    collections: string[],
    onProgressUpdated: (updatedFiles: FileToUploadWithProgress) => void,
  ): Promise<ActionResult<any>> => {
    try {
      Logger.info(`Starting upload for ${uploads.length} ${assetCategory} files`);

      Promise.all(
        uploads.map((uploadFile) => {
          return handleFileUpload(uploadFile, tags, collections, onProgressUpdated)
            .then((clipart) => handleSuccess(uploadFile, clipart))
            .catch((e) => handleError(uploadFile, e))
            .finally(() => onProgressUpdated(uploadFile));
        }),
      ).then((x) => {
        // Redirect to asset details page if only one asset was created, otherwise stay on the asset list page
        if (uploads.length === 1) {
          navigateToDetailPage(x);
        }
        Logger.info(
          `Upload finished for ${uploads.length} files. ${
            x.filter((y) => (y as Asset<AnyAssetVersion>).assetType === undefined).length
          } files out of ${uploads.length} have failed.`,
        );
      });

      return createSuccessResponse();
    } catch (e) {
      return createErrorResponse(e);
    }
  };
  return (
    <>
      <UploadsControlBatch
        handleUpload={handleUpload}
        onCloseClick={() => onCloseClick()}
        // allow multiple uploads only on the category index page, or when there is no constraint of uniqueness
        allowMultipleUploads={!designConceptId || !getAssetCategorySetting(assetCategory).hasUniqueDesignConceptId}
      />
    </>
  );
};
