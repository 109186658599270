import { Button, Tag } from '@cimpress/react-components';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import './AssetTags.scss';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiErrorToast } from './ApiErrorToast';
import { AppContext } from '../App';
import { Tenant } from '../api/model/Tenant';
import * as CamsApi from '../api/Cams/cams-api';
import { FacetName } from '../api/Cams/model/FacetName';
import { CamsAutocomplete } from './CamsAutocomplete';
import { splitBySemicolon } from '../Tools/TagsUtil';
import { validateNewTags } from '../Tools/TagValidation';
import { Link } from 'react-router-dom';

interface Props<T> {
  entityTags?: string[];
  facetName: FacetName;
  componentLabel: string;
  enableAutocomplete: boolean;
  disabled?: boolean;
  updateMethod: (tenant: Tenant, tags: string[]) => Promise<ApiResponseOrError<T>>;
  getterFromResponse: (response: ApiResponse<T>) => string[];
  getAssetsListWithTagFilterRoute: (tag: string) => string;
}

export const AssetTags = <T,>({
  entityTags,
  facetName,
  componentLabel,
  enableAutocomplete,
  disabled,
  updateMethod,
  getterFromResponse,
  getAssetsListWithTagFilterRoute,
}: Props<T>): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [tags, setTags] = useState<string[]>(entityTags || []);
  const [saveResponse, setSaveResponse] = useState<ApiResponseOrError<T>>();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTags(entityTags || []);
  }, [entityTags]);

  useEffect(() => {
    if (disabled) {
      setIsEditing(false);
    }
  }, [disabled]);

  const save = async (updatedTags: string[]) => {
    const response = (await updateMethod(tenant, updatedTags)) as ApiResponse<T>;

    setSaveResponse(response);

    if (isSuccessfulResponse(response)) {
      return getterFromResponse(response);
    }

    return false;
  };

  const onRemoveClick = async (value: string) => {
    const updatedTags = [...tags];
    const removedItemIndex = updatedTags.findIndex((tag) => tag === value);

    if (removedItemIndex > -1) {
      updatedTags.splice(removedItemIndex, 1);
      setTags(updatedTags);
      await save(updatedTags);
    }
  };

  const validateTags = (newTags: string) => {
    const newTagsArray = splitBySemicolon(newTags);
    return validateNewTags(newTagsArray);
  };

  const addTag = async (newTags: string) => {
    const newTagsArray = splitBySemicolon(newTags);
    const validationResult = validateNewTags(newTagsArray);

    if (validationResult.isValid === false) {
      const error: ApiResponseOrError<any> = {
        content: validationResult.message || '',
        statusCode: 400,
        responseType: 'error',
      };
      setSaveResponse(error);

      return;
    }

    const updatedTags = tags ? [...tags, ...newTagsArray] : newTagsArray;
    const savedTags = await save(updatedTags);
    if (savedTags) {
      setTags(savedTags);
    }
  };

  const onDoneClick = async () => {
    setIsEditing(false);
    setIsLoading(true);
    if (value) {
      await addTag(value);
    }
    setIsLoading(false);
    setValue('');
  };

  return (
    <div data-testid="asset-tags">
      <h3>{componentLabel}</h3>
      <div className="tags-list">
        {tags.map((tag) => {
          if (!isEditing) {
            return (
              <Link key={tag} to={getAssetsListWithTagFilterRoute(tag)} className="tag-link">
                <Tag value={tag} size="lg" />
              </Link>
            );
          } else {
            return (
              <Tag
                key={tag}
                value={tag}
                size="lg"
                removable={isEditing}
                onRemoveClick={(value?: ReactNode) => onRemoveClick(value?.toString() || '')}
              />
            );
          }
        })}
        {isEditing && (
          <CamsAutocomplete
            minLength={2}
            onItemSelected={addTag}
            loadData={CamsApi.getFacetValues}
            enableAutocomplete={enableAutocomplete}
            facetName={facetName}
            labelName={componentLabel}
            validateNewValue={validateTags}
            disabled={disabled}
            onValueChange={(v) => setValue(v)}
          />
        )}

        {!isEditing && (
          <Button
            disabled={disabled || isLoading}
            onClick={() => setIsEditing(true)}
            variant="link"
            className="edit-button"
          >
            {tags.length > 0 ? (
              <>
                <i className="fa fa-pencil"></i>
                <span>Edit tags</span>
              </>
            ) : (
              'Add tags'
            )}
          </Button>
        )}

        {isEditing && (
          <>
            <Button onClick={onDoneClick} className="inline-edit-button-action">
              <span>Done</span>
            </Button>
          </>
        )}
      </div>

      <ApiErrorToast response={saveResponse} />
    </div>
  );
};
