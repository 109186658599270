import SumoLogger, { SumoLoggerOptions } from 'sumo-logger';
import auth from '../auth';
import config from '../config';

const loggingOptions = {
  endpoint: config.sumo.endpoint,
  sourceCategory: config.sumo.category,
  sourceName: config.sumo.name,
  batchSize: 5000, // number of characters
  interval: 5000, // miliseconds
} as SumoLoggerOptions;
const logger = new SumoLogger(loggingOptions);

enum Severity {
  None = 'None',
  Error = 'Error',
  Warn = 'Warning',
  Info = 'Info',
  Debug = 'Debug',
}

function constructError(error: any): Error {
  const errorObj: Error = { ...error };
  if (error?.stack) {
    errorObj.stack = error.stack;
  }
  if (error?.message) {
    errorObj.message = error.message;
  }

  return errorObj;
}

function getUserCanonicalId(): string {
  const profile = auth.getProfile();
  return (profile ?? {})['https://claims.cimpress.io/canonical_id'] ?? '(Unknown User ID)';
}

function log(severity: Severity, message: string, error?: any): void {
  if (error) {
    logger.log({
      severity: severity,
      msg: message,
      userId: getUserCanonicalId(),
      cause: constructError(error),
      path: window.location.pathname + window.location.search,
    });
  } else {
    logger.log({
      severity: severity,
      msg: message,
      userId: getUserCanonicalId(),
      path: window.location.pathname + window.location.search,
    });
  }
}

export function debug(message: string): void {
  log(Severity.Debug, message);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function warning(message: string, error: any): void {
  log(Severity.Warn, message, error);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function error(message: string, error: any): void {
  log(Severity.Error, message, error);
}

export function info(message: string): void {
  log(Severity.Info, message);
}

export function flushLogs(): void {
  logger.flushLogs();
}
