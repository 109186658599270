import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import * as RequestUtils from '../RequestUtils';
import { ClipartAssetRequestBody } from './model/Clipart/ClipartAssetRequestBody';
import { ClipartAssetVersionRequestBody } from './model/Clipart/ClipartAssetVersionRequestBody';
import { ClipartManagementAsset } from './model/Clipart/ClipartManagementAsset';
import { ClipartManagementAssetVersion } from './model/Clipart/ClipartManagementAssetVersion';
import { UpdateClipartAssetRequestBody } from './model/Clipart/UpdateClipartAssetRequestBody';
const camsApiHost = config.camsApiUrl;

export async function createClipartAsset(
  tenant: Tenant,
  requestBody: ClipartAssetRequestBody,
): Promise<ApiResponseOrError<ClipartManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/cliparts`,
  );
  const config = RequestUtils.axiosDefaultConfig();

  return await RequestUtils.doPostRequest<ClipartManagementAsset>(url, requestBody, config);
}

export async function createClipartAssetVersion(
  tenant: Tenant,
  clipartId: string,
  requestBody: ClipartAssetRequestBody,
): Promise<ApiResponseOrError<ClipartManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/cliparts/${clipartId}/versions`,
  );
  const config = RequestUtils.axiosDefaultConfig();
  return await RequestUtils.doPostRequest<ClipartManagementAsset>(url, requestBody, config);
}

export async function updateClipartAsset(
  id: string,
  tenant: Tenant,
  requestBody: UpdateClipartAssetRequestBody,
): Promise<ApiResponseOrError<ClipartManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/cliparts/${id}`,
  );

  return await RequestUtils.doPatchRequest<ClipartManagementAsset>(url, requestBody);
}

export async function updateClipartVersionAsset(
  id: string,
  version: number,
  tenant: Tenant,
  requestBody: ClipartAssetVersionRequestBody,
): Promise<ApiResponseOrError<ClipartManagementAssetVersion>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/cliparts/${id}/versions/${version}:publish`,
  );

  return await RequestUtils.doPatchRequest<ClipartManagementAssetVersion>(url, requestBody);
}
