import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import * as RequestUtils from '../RequestUtils';
import { AvailableFonts } from './dtos/AvailableFonts';

const cdnRendingFontUrl = config.renderingFontCdnUrl;

export const getAvailableFonts = async (fontRepositoryUrl: string): Promise<ApiResponseOrError<AvailableFonts[]>> => {
  const url = new URL(`${cdnRendingFontUrl}?fontRepositoryUrl=${fontRepositoryUrl}&fontFlavor=print`);
  return await RequestUtils.doGetRequest<AvailableFonts[]>(url);
};
