import pako from 'pako';
import { CimDoc } from '@design-stack/cimdoc-design-lib/types';
import { v4 as uuidv4 } from 'uuid';
import { FontSchema } from '../Uploads/FontSchema';
import { ColorPalette } from '../Uploads/ColorPalette';
import config from '../config';

const previewHost = config.previewUrl;

function createTransientDocumentUrl(doc: CimDoc): string {
  const json = JSON.stringify(doc);
  const deflated = pako.deflateRaw(json, { to: 'string' });
  const base64 = window.btoa(deflated);
  return base64;
}

function createCimDoc(uri: string): CimDoc {
  return {
    version: '2',
    document: {
      panels: [
        {
          id: '73e5a813-8c22-4012-b8c6-586915621b8f',
          name: 'front',
          width: '300mm',
          height: '300mm',
          itemReferences: [
            {
              id: 'myItemRef',
              type: 'panel',
              url: 'https://items.documents.cimpress.io/v1/tileimage',
              data: {
                image: {
                  id: '3f2b38e4-b97a-4b5b-b9ac-3e4372a61669',
                  printUrl: uri,
                  previewUrl: uri,
                  originalSourceUrl: uri,
                  position: {
                    width: '100mm',
                    height: '100mm',
                  },
                  horizontalAlignment: 'left',
                  verticalAlignment: 'top',
                  pageNumber: 1,
                },
              },
              zIndex: 5,
              position: {
                x: '0mm',
                y: '0mm',
                width: '300mm',
                height: '300mm',
              },
            },
          ],
          decorationTechnology: 'offsetOrDigital',
        },
      ],
    },
    fontRepositoryUrl: 'https://fonts.documents.cimpress.io/prod',
  };
}

const createCimdocTextElement = (
  fontCategory: string,
  fontFamily: string,
  fontStyle: string,
  positionY: number,
  height: number,
) => {
  return {
    id: uuidv4(),
    position: {
      x: '0mm',
      y: `${positionY}mm`,
      width: '50mm',
      height: `${height}mm`,
    },
    zIndex: 1,
    textFields: [
      {
        content: fontFamily.length > 13 && fontCategory !== 'Tertiary' ? fontFamily.slice(0, 12) + '...' : fontFamily,
        type: 'inline',
        fontFamily: fontFamily,
        fontStyle: ToFontStyle(fontStyle.toLocaleLowerCase() as FontStyle),
        fontSize: fontCategory === 'Primary' ? '16pt' : fontCategory === 'Secondary' ? '13pt' : '11pt',
        color: 'rgb(#000000)',
      },
    ],
  };
};

const FontStyles = ['normal', 'bold', 'italic', 'bolditalic'] as const;
type FontStyle = typeof FontStyles[number];

const ToFontStyle = (fontStyle: FontStyle) => {
  switch (fontStyle) {
    case 'bolditalic':
      return 'bold,italic';
    default:
      return fontStyle;
  }
};

export const generatePreviewUrlForColorPalette = (
  colorPalette: ColorPalette | undefined,
  width?: number,
): string | undefined => {
  if (!colorPalette) return '';

  const colors = Object.entries(colorPalette);
  //colors is an array of [string, any]. e.g. 0: "Primary", 1: "{cmyk: 'cmyk(20,30,50,100)'}"
  //to get the actual cmyk value e.g. cmyk(20,30,50,100) we map from the colors array to an array of string;
  const cmykColors = colors.map((x) => x[1].cmyk);
  const shapes = [];
  let index = 0;
  for (const color of cmykColors) {
    shapes.push({
      type: 'rectangle' as const,
      zIndex: 0,
      id: 'color-palette-preview',
      position: {
        x: `${index}mm`,
        y: '0mm',
        width: '2mm',
        height: '10mm',
      },
      color: color,
      itemType: 'shape',
    });
    index += 2;
  }
  const cimDoc = {
    version: '2',
    document: {
      panels: [
        {
          id: 'e8e3e476-afcd-4536-8bc5-5734a61cbff9',
          name: 'cam-preview-panel',
          width: '10mm',
          height: '10mm',
          decorationTechnology: 'offsetOrDigital',
          shapes: shapes,
        },
      ],
    },
    fontRepositoryUrl: '',
  };
  return generatePreviewUrlInternal(cimDoc, width);
};

export const generatePreviewUrlForColor = (color: string, width?: number, size?: string): string | undefined => {
  const cimDoc = {
    version: '2',
    document: {
      panels: [
        {
          id: 'e8e3e476-afcd-4536-8bc5-5734a61cbff9',
          name: 'cam-preview-panel',
          width: '10mm',
          height: '10mm',
          decorationTechnology: 'offsetOrDigital',
          shapes: [
            {
              type: 'rectangle' as const,
              zIndex: 0,
              id: 'color-palette-preview',
              position: {
                x: '0mm',
                y: '0mm',
                width: size ?? '10mm',
                height: size ?? '10mm',
              },
              color: `${color}`,
              itemType: 'shape',
            },
          ],
        },
      ],
    },
    fontRepositoryUrl: '',
  };
  return generatePreviewUrlInternal(cimDoc, width);
};

export const generatePreviewUrlForFonts = (
  fontSchema: FontSchema | undefined,
  fontRepoUrl: string,
  width?: number,
): string | undefined => {
  //y: The Y coordinate of the top-left corner of the image placeholder rectangle.
  //To not to overlap two text
  let positionY = 2;
  //height: The height of the image placeholder rectangle.
  //To provide space between two elements
  let height = 7;

  if (!fontSchema || !fontSchema.schemata) {
    return undefined;
  }
  const textElements = Object.entries(fontSchema.schemata).map(([k, v]) => {
    const textElement = createCimdocTextElement(k, v.fontFamily, v.fontStyle, positionY, height);
    positionY = positionY + 5;
    height = height + 5;
    return textElement;
  });
  const cimDoc = {
    version: '2',
    document: {
      panels: [
        {
          id: 'e8e3e476-afcd-4536-8bc5-5734a61cbff9',
          name: 'cam-preview-panel',
          width: '50mm',
          height: `${height + 4}mm`,
          textAreas: textElements,
          decorationTechnology: 'offsetOrDigital',
        },
      ],
    },
    fontRepositoryUrl: fontRepoUrl,
  };
  return generatePreviewUrlInternal(cimDoc, width);
};

export function generatePreviewUrl(uri: string, width?: number): string {
  const cimdoc = createCimDoc(uri);
  return generatePreviewUrlInternal(cimdoc, width);
}

const generatePreviewUrlInternal = (cimDoc: CimDoc, width?: number) => {
  const docUrl = createTransientDocumentUrl(cimDoc);
  const udsUrl = `https://storage.documents.cimpress.io/v3/documents/transient?document=${encodeURIComponent(docUrl)}`;
  const previewUrl = `${previewHost}?documentUri=${encodeURIComponent(udsUrl)}&width=${
    width || 330
  }&bgColor=transparent`;
  return previewUrl;
};
