import { TenantDescription } from '../api/model/TenantDescription';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { getAssetCategoryPathName } from '../Routes/GenericRoutes';

export const getRouteByAssetCategory = (tenant: TenantDescription, assetCategory: AssetCategory): string => {
  const navigationLink = `/accounts/${tenant.contentAuthoringAccountId}/contentarea/${
    tenant.contentAuthoringAreaId
  }/${getAssetCategoryPathName(assetCategory)}/`;
  return navigationLink;
};
