import { SelectWrapper } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Font } from '../Uploads/FontSchema';

interface Props {
  font: { fontFamily: string; fontStyle: string };
  fontCategory: string;
  availableFonts: { [fontFamily: string]: string[] };
  disableInput: boolean;
  isRequired: boolean;
  onFontChange: (fontCategory: string, font: Font) => void;
}

export const FontSelectionComponent = ({
  font,
  fontCategory,
  availableFonts,
  disableInput,
  isRequired,
  onFontChange,
}: Props): JSX.Element => {
  const [fontState, setFontState] = useState<Font>({
    fontFamily: font.fontFamily,
    fontStyle: font.fontStyle,
  });
  const [fontStyles, setFontStyles] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [isFontStyleRequired, setIsFontRequired] = useState(
    fontState?.fontFamily && fontState?.fontStyle ? true : false,
  );

  const onFontFamilyChange = (selected: { value: string; label: string }) => {
    setFontState((prev) => ({ ...prev, fontFamily: selected?.value }));
    if (!selected) {
      setFontState((prev) => ({ ...prev, fontStyle: selected }));
      setIsFontRequired(false);
    } else {
      setIsFontRequired(true);
    }
    setFontStyles(selected ? availableFonts[selected.value].map((x) => ({ value: x, label: x })) : []);
  };

  const onFontStyleChange = (selected: { value: string; label: string }) => {
    setFontState((prev) => ({ ...prev, fontStyle: selected?.value }));
  };

  useEffect(() => {
    onFontChange(fontCategory, fontState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontState]);

  useEffect(() => {
    setFontStyles(
      Object.keys(availableFonts).length !== 0
        ? availableFonts[font.fontFamily]?.map((x) => ({ value: x, label: x })) ?? []
        : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFonts]);
  return (
    <div className="row">
      <div className="col-sm-5" data-testid="font-family-input">
        <SelectWrapper
          selectedSelect={ReactSelect}
          required={isRequired}
          disabled={disableInput}
          style={{ overflow: 'hidden', width: '250px' }}
          label={`${fontCategory} Font Family`}
          value={fontState.fontFamily}
          options={Object.keys(availableFonts).map((x) => ({ value: x, label: x }))}
          onChange={onFontFamilyChange}
          isLoading={false}
        />
      </div>
      <div className="col-sm-5 col-md-offset-1">
        <SelectWrapper
          selectedSelect={ReactSelect}
          disabled={disableInput}
          required={isFontStyleRequired}
          style={{ overflow: 'hidden', width: '250px' }}
          label={`${fontCategory} Font Style`}
          value={fontState.fontStyle}
          options={fontStyles}
          onChange={onFontStyleChange}
          isLoading={false}
        />
      </div>
    </div>
  );
};
