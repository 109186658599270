import React from 'react';
import { Modal } from '@cimpress/react-components';

interface Props {
  isModalOpen: boolean;
  onSetModelOpen: (hide: boolean) => void;
  title: string;
  closeButton: boolean;
  component: JSX.Element;
  className?: string;
}

export const CreateOrUpdateAssetModal = ({
  isModalOpen,
  onSetModelOpen,
  title,
  closeButton,
  component,
  className,
}: Props): JSX.Element => {
  return (
    <Modal
      show={isModalOpen}
      onRequestHide={() => onSetModelOpen(false)}
      title={title}
      closeButton={closeButton}
      size="lg"
      data-testid="create-new-asset-modal"
      className={className ? className : ''}
    >
      {component}
    </Modal>
  );
};
