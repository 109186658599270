import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import { useLocation, useParams } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { Button, Tooltip, ReactTablev6 } from '@cimpress/react-components';
import { compareFormattedDates, formatDateTime } from '../Tools/DateTimeHelpers';
import { AppContext } from '../App';
import { Tenant } from '../api/model/Tenant';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { parseQueryStringState } from '../AssetsListPage/QueryString/QueryStringUtils';
import { getRouteByAssetCategory } from '../Tools/NavigationRoutesUtility';

interface QueryStringParams {
  id: string;
}

const AssetVersionsPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();

  const [versions, setVersions] = useState<AnyAssetVersion[]>([]);
  const [, setDesignVersionsError] = useState<string>();
  const location = useLocation();
  const { availableTenants } = useContext(AppContext);
  const queryStringState = parseQueryStringState(location, false, availableTenants);
  const loadAssetVersions = async (tenant: Tenant, id: string) => {
    const response = await CamsApi.getAssetVersions(tenant, id);
    setDesignVersionsError(getResponseError(response));
    if (isSuccessfulResponse(response)) {
      const designVersions = response.data as AnyAssetVersion[];
      //sort in descending order and set version
      setVersions(designVersions.sort((x, y) => 0 - (x.versionNumber > y.versionNumber ? 1 : -1)));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAssetVersions(tenant, id);
    };

    fetchData();
  }, [tenant, id]);

  type tableCellType = { original: AnyAssetVersion };

  const columns = [
    {
      Header: 'Version',
      accessor: 'versionNumber',
    },
    {
      Header: 'Created',
      id: 'created',
      Cell: ({ original: version }: tableCellType) => {
        const tooltip = (
          <Tooltip direction={'right'} contents={formatDateTime(version.created)}>
            {formatDateTime(version.created, true)}
          </Tooltip>
        );
        return tooltip;
      },
      sortMethod: (left, right) => {
        const dateComparisonResult = compareFormattedDates(left, right);

        return dateComparisonResult;
      },
    },
    {
      Header: 'Created By',
      accessor: 'createdByFullname',
    },
    {
      Header: '',
      id: 'link',
      Cell: ({ original: version }: tableCellType) => {
        const link = (
          <Link
            className="simple-link see-history-link"
            to={`${getRouteByAssetCategory(tenant, queryStringState.assetCategory)}${id}?version=${
              version.versionNumber
            }`}
          >
            <Button variant="primary" size="sm">
              Show version
            </Button>
          </Link>
        );
        return link;
      },
      style: { justifyContent: 'center' },
    },
  ] as Column<AnyAssetVersion>[];

  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12">
          <h2 style={{ marginBottom: '15px' }} data-testid="asset-versions-title">
            {queryStringState.assetCategory.format({ capitalLetter: true })} Versions
          </h2>
        </div>
      </div>

      {versions.length > 0 && (
        <div className="row section">
          <div className="col-sm-12">
            <ReactTablev6
              sortable={false}
              showPagination={false}
              minRows={0}
              columns={columns}
              data={versions}
              collapseOnDataChange={false}
              className={`simple-table`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetVersionsPage;
