import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const defaultDateFormat = 'MMMM Do YYYY, h:mm:ss a';

export function formatDateTime(value: Date | string | undefined | null, fromNow = false): string {
  if (!value) {
    return 'N/A';
  }

  return fromNow ? dayjs.utc(value).local().fromNow() : dayjs.utc(value).local().format(defaultDateFormat);
}

function parseFormattedDate(formattedDate: string): dayjs.Dayjs {
  return dayjs(formattedDate, defaultDateFormat);
}

export function compareFormattedDates(left: string, right: string): number {
  const leftDate = parseFormattedDate(left);
  const rightDate = parseFormattedDate(right);

  if (!leftDate.isValid() && rightDate.isValid()) {
    return -1;
  }

  if (!rightDate.isValid() && leftDate.isValid()) {
    return 1;
  }

  if (!rightDate.isValid() && !leftDate.isValid()) {
    return 0;
  }

  if (leftDate.isSame(rightDate)) {
    return 0;
  }

  return leftDate.isAfter(rightDate) ? 1 : -1;
}
