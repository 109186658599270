import React from 'react';
import { CreativeAssetVersion } from '../../api/Cams/model/CreativeAssetVersion';
import { AssetCard } from './AssetCard';

interface Props {
  assets: CreativeAssetVersion[];
}

export const DesignAssets = ({ assets }: Props): JSX.Element => {
  return (
    <>
      <h2 className="section-title">Artwork source files</h2>

      {assets.map((asset) => {
        return <AssetCard asset={asset} key={`${asset.assetId}_${asset.versionNumber}`} />;
      })}
    </>
  );
};
