import React, { useState } from 'react';
import { TextField } from '@cimpress/react-components';
import { FacetValue } from '../../../api/Cams/model/FacetValue';
import { isSuccessfulResponse } from '../../../Tools/ResponseHelper';
import { useDebounce } from '../../../customHooks/useDebounce';
import { ApiResponseOrError } from '../../../api/ApiResponseOrError';
import { useUpdatedEffect } from '../../../customHooks/useUpdatedEffect';

interface Props {
  onFiltered: (items: FacetValue[]) => void;
  onCleared?: () => void;
}

interface InPlaceSearchProps extends Props {
  allItems: FacetValue[];
  searchType: 'in-results';
}

interface RemoteCallProps extends Props {
  searchType: 'remote-call';
  onSearch: (searchValue: string) => Promise<ApiResponseOrError<FacetValue[]>>;
}

export const SearchInput = (props: InPlaceSearchProps | RemoteCallProps): JSX.Element => {
  const onFiltered = props.onFiltered;

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput, 500);

  useUpdatedEffect(() => {
    searchInputTerm(debouncedSearchInput || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput]);

  const searchInputTerm = async (input: string) => {
    const inputLower = input.toLowerCase();
    if (props.searchType === 'remote-call') {
      const facetValuesResponse = await props.onSearch(inputLower);

      if (isSuccessfulResponse(facetValuesResponse)) {
        const facetValues = facetValuesResponse.data;
        onFiltered(facetValues);
      }
    }

    if (props.searchType === 'in-results') {
      if (inputLower !== '') {
        const filteredFacets = props.allItems.filter(
          (i) => i.value.toLowerCase().includes(inputLower) || i.label.toLowerCase().includes(inputLower),
        );
        onFiltered(filteredFacets);
      }
    }
  };

  const handleOnChange = (value: string) => {
    setSearchInput(value);
    if (value.length === 0) {
      if (props.onCleared !== undefined) {
        props.onCleared();
      }
    }
  };

  return (
    <>
      <TextField
        name="search"
        label="Search"
        value={searchInput}
        onChange={(e) => handleOnChange(e.target.value)}
        autoComplete="off"
      />
    </>
  );
};
