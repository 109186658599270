import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { tenantRoutePrefix } from '../Tools/Routes';

export const getVariableAssetPageRoute = (
  accountId?: string,
  contentAreaId?: string,
  assetCategory?: AssetCategory,
  queryString?: string,
): string => {
  const path = `${tenantRoutePrefix}:assetCategory`;
  if (accountId && contentAreaId && assetCategory) {
    const updatedPath = path
      .replace(':accountId', accountId)
      .replace(':areaId', contentAreaId)
      .replace(':assetCategory', getAssetCategoryPathName(assetCategory));
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
};

export const getVariableAssetVersionsPageRoute = (
  assetId?: string,
  accountId?: string,
  contentAreaId?: string,
  assetCategory?: AssetCategory,
  queryString?: string,
): string => {
  const path = `${tenantRoutePrefix}:assetCategory/:id/versions`;
  if (accountId && contentAreaId && assetCategory && assetId) {
    const updatedPath = path
      .replace(':accountId', accountId)
      .replace(':areaId', contentAreaId)
      .replace(':id', assetId)
      .replace(':assetCategory', getAssetCategoryPathName(assetCategory));
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
};

export const getVariableAssetVersionPageRoute = (
  assetId?: string,
  assetVersionNumber?: number,
  accountId?: string,
  contentAreaId?: string,
  assetCategory?: AssetCategory,
  queryString?: string,
): string => {
  const path = `${tenantRoutePrefix}:assetCategory/${assetId}?version=${assetVersionNumber}`;
  if (accountId && contentAreaId && assetCategory) {
    const updatedPath = path
      .replace(':accountId', accountId)
      .replace(':areaId', contentAreaId)
      .replace(':assetCategory', getAssetCategoryPathName(assetCategory));
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
};

export const getAssetCategoryPathName = (assetCategory: AssetCategory): string => {
  switch (assetCategory) {
    case 'clipart':
      return 'cliparts';
    case 'design':
      return 'designs';
    case 'pattern':
      return 'patterns';
    case 'creative asset':
      return 'creative';
    case 'font schema':
      return 'fonts';
    case 'background':
      return 'backgrounds';
    case 'color palette':
      return 'colors';
  }
};

export const getVariableAssetDetailPageRoute = (
  accountId?: string,
  contentAreaId?: string,
  assetCategory?: AssetCategory,
  assetId?: string,
  queryString?: string,
): string => {
  const path = `${tenantRoutePrefix}:assetCategory/:assetId`;
  if (accountId && contentAreaId && assetCategory && assetId) {
    const updatedPath = path
      .replace(':accountId', accountId)
      .replace(':areaId', contentAreaId)
      .replace(':assetId', assetId)
      .replace(':assetCategory', getAssetCategoryPathName(assetCategory));
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
};

export const getUnknownAssetPageRoute = (
  accountId?: string,
  contentAreaId?: string,
  assetId?: string,
  assetKind?: string,
): string => {
  const path = `${tenantRoutePrefix}unknown-asset/:assetId?assetKind=:assetKind`;
  if (accountId && contentAreaId && assetId) {
    const updatedPath = path
      .replace(':accountId', accountId)
      .replace(':areaId', contentAreaId)
      .replace(':assetId', assetId)
      .replace(':assetKind', assetKind || '');
    return updatedPath;
  } else {
    return path;
  }
};

export const getConfigurationPageRoute = (accountId?: string, contentAreaId?: string): string => {
  const path = `${tenantRoutePrefix}configuration`;
  if (accountId && contentAreaId) {
    const updatedPath = path.replace(':accountId', accountId).replace(':areaId', contentAreaId);
    return updatedPath;
  } else {
    return path;
  }
};

export const getCategoryLocalizationRoute = (accountId?: string, contentAreaId?: string): string => {
  const path = `${tenantRoutePrefix}configuration/category-localization`;
  if (accountId && contentAreaId) {
    const updatedPath = path.replace(':accountId', accountId).replace(':areaId', contentAreaId);
    return updatedPath;
  } else {
    return path;
  }
};
