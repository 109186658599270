import React, { useContext, useEffect, useState } from 'react';
import { getCategoryLocalization } from '../api/Cams/cams-api';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { AppContext } from '../App';
import { CategoryLocalizationData } from '../CategoryLocalization/CategoryLocalization';
import { Link } from 'react-router-dom';
import { getCategoryLocalizationRoute } from '../Routes/GenericRoutes';
import './MissingLocalizationNotification.scss';
import { AssetKind } from '../api/Cams/model/AssetKind';
import { getCategoryListMessage, getUnlocalizedCategories } from './MissingLocalizationUtils';

export interface Props {
  assetKind: AssetKind;
  categories: string[];
}

export const AssetMissingLocalizationNotification = ({ assetKind, categories }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [categoryLocalizations, setCategoryLocalizations] = useState<CategoryLocalizationData>();
  const categoriesString = JSON.stringify(categories);
  useEffect(() => {
    const [request, abort] = getCategoryLocalization(tenant);
    request.then((response) => {
      if (isSuccessfulResponse(response)) {
        const data = response.data;
        setCategoryLocalizations(data);
      }
    });
    return abort;
  }, [tenant, categoriesString]);
  const localizationPageUrl = getCategoryLocalizationRoute(
    tenant.contentAuthoringAccountId,
    tenant.contentAuthoringAreaId,
  );

  const unlocalizedCategories = getUnlocalizedCategories(assetKind, categories, categoryLocalizations);
  const unlocalizedCategoryList = getCategoryListMessage(unlocalizedCategories, 3);
  const showNotification = unlocalizedCategories.length > 0;

  return (
    <>
      {!!showNotification && (
        <div className="alert alert-warning margin-bottom-0px" data-testid="asset-missing-localization-notification">
          Localization of this asset is incomplete. Some categories are missing localization: {unlocalizedCategoryList}.
          Please provide missing localizations at <Link to={localizationPageUrl}>Category Localization page</Link>
        </div>
      )}
    </>
  );
};
