import React, { useState, useEffect } from 'react';
import { Select, Modal } from '@cimpress/react-components';
import { TenantDescription } from '../api/model/TenantDescription';
import './TenantPickerModal.scss';
import { getTenantName } from './TenantPicker';

const tenantKey = (tenant: TenantDescription) => {
  return `${tenant.contentAuthoringAccountId}_${tenant.contentAuthoringAreaId}`;
};

const mapTenantToSelectItem = (tenant?: TenantDescription) => {
  if (!tenant) {
    return {
      label: '',
      value: '',
    };
  }

  return {
    label: `${getTenantName(tenant)}`,
    value: tenantKey(tenant),
  };
};

interface Props {
  availableTenants: TenantDescription[];
  selected?: TenantDescription;
  isOpen: boolean;
  onClose: () => void;
  onSelected: (selected: TenantDescription) => void;
}

const TenantPickerModal = ({ isOpen, onClose, availableTenants, selected, onSelected }: Props): JSX.Element => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    const options = availableTenants.map((tenant) => {
      return mapTenantToSelectItem(tenant);
    });
    setOptions(options);
  }, [availableTenants]);

  const onChange = (selected: { label: string; value: string }) => {
    if (selected) {
      const selectedTenant = availableTenants.find((t) => tenantKey(t) === selected.value);
      if (selectedTenant) {
        onSelected(selectedTenant);
        onClose();
      }
    }
  };

  return (
    <Modal
      show={isOpen}
      onRequestHide={onClose}
      title="Select a Tenant"
      closeButton={true}
      className="tenant-picker-modal"
      footer={
        <button className="btn btn-default" onClick={onClose}>
          Close
        </button>
      }
    >
      <p>
        Before you begin, you will need to choose which tenant you are reading or creating templates for. You can
        reselect your current tenant at any time via the header.
      </p>
      <Select
        isClearable={false}
        label="Select a Tenant"
        value={mapTenantToSelectItem(selected)}
        options={options}
        onChange={onChange as any}
      />
    </Modal>
  );
};

export default TenantPickerModal;
