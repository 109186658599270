import { Card, Checkbox, Tooltip } from '@cimpress/react-components';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { DesignManagementAsset } from '../../../api/Cams/model/Design/DesignManagementAsset';
import { AppContext } from '../../../App';
import { DesignAssetPreview } from '../../../components/DesignAssetPreview';
import { getDesignManagementAssetDetailsPageRoute } from '../../../Routes/DesignManagementAssetRoutes';
import { formatDateTime } from '../../../Tools/DateTimeHelpers';
import { getDesignName } from '../../../Tools/DesignHelpers';

interface Props {
  design: DesignManagementAsset;
  onAssetSelected: (asset: DesignManagementAsset, isSelected: boolean) => void;
}

export const DesignTile = ({ design, onAssetSelected }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [isSelected, setIsSelected] = useState(false);
  const latestVersion = design.latestVersion;

  const onSelected = (isSelected: boolean) => {
    setIsSelected(isSelected);
    onAssetSelected(design, isSelected);
  };

  return (
    <Card className="asset-tile">
      <Checkbox
        className={`checkbox ${isSelected ? 'checkbox-checked' : ''}`}
        checked={isSelected}
        label={!isSelected ? `Select to download` : `Selected to download`}
        onChange={() => {
          onSelected(!isSelected);
        }}
      />
      <Link
        to={getDesignManagementAssetDetailsPageRoute(
          tenant.contentAuthoringAccountId,
          tenant.contentAuthoringAreaId,
          design.id,
        )}
      >
        <div className="preview-wrapper">
          <DesignAssetPreview designVersion={latestVersion} physicalSpecId={design.designPhysicalSpecId} width={500} />
        </div>
        <p>{getDesignName(design, latestVersion, 'File name missing')}</p>
        <p>
          <Tooltip direction={'bottom'} contents={formatDateTime(latestVersion.created)}>
            Last Modified: {formatDateTime(latestVersion.created, true)} by {latestVersion.createdByFullname}
          </Tooltip>
        </p>
      </Link>
    </Card>
  );
};
