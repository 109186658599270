import React, { useContext, useEffect, useState } from 'react';
import { getCategoryLocalization } from '../api/Cams/cams-api';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { AppContext } from '../App';
import { CategoryLocalizationData } from '../CategoryLocalization/CategoryLocalization';
import { Link } from 'react-router-dom';
import { getCategoryLocalizationRoute } from '../Routes/GenericRoutes';
import './MissingLocalizationNotification.scss';
import { getMissingLocalizations } from './MissingLocalizationUtils';

export const TenantMissingLocalizationNotification = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [categoryLocalizations, setCategoryLocalizations] = useState<CategoryLocalizationData>();
  useEffect(() => {
    const [request, abort] = getCategoryLocalization(tenant);
    request.then((response) => {
      if (isSuccessfulResponse(response)) {
        const data = response.data;
        setCategoryLocalizations(data);
      }
    });
    return abort;
  }, [tenant]);
  const missingLocalizations = getMissingLocalizations(categoryLocalizations);
  const localizationPageUrl = getCategoryLocalizationRoute(
    tenant.contentAuthoringAccountId,
    tenant.contentAuthoringAreaId,
  );
  const showNotification = tenant.userPermissions.canPublish && missingLocalizations.length > 0;
  return (
    <>
      {!!showNotification && (
        <div className="alert alert-warning" data-testid="tenant-missing-localization-notification">
          {missingLocalizations.length} category localizations are missing. Please provide missing values at{' '}
          <Link to={localizationPageUrl}>Category Localization page</Link>
        </div>
      )}
    </>
  );
};
