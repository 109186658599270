import React, { useContext } from 'react';
import { ActionResult, createErrorResponse, createSuccessResponse } from '../Tools/ActionResult';
import { FileToUpload } from './FileToUpload';
import { UploadsControl } from './UploadsControl';
import { createOrUpdateClipartAsset } from '../api/Cams/CamsApiFacade';
import { AppContext } from '../App';
import { ClipartManagementAsset } from '../api/Cams/model/Clipart/ClipartManagementAsset';

interface Props {
  clipartAssetId: string;
  masterAssetId: string;
  onUpload: (assetId: string, version: number) => void;
  designConceptId?: string;
}

export const UpdateClipartAssetControl = ({
  clipartAssetId,
  masterAssetId,
  onUpload,
  designConceptId,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const handleFileUpload = async (fileToUpload: FileToUpload): Promise<ClipartManagementAsset> => {
    //use creative asset to link to clipartasset
    return await createOrUpdateClipartAsset(
      tenant,
      fileToUpload,
      undefined,
      undefined,
      designConceptId,
      clipartAssetId,
      masterAssetId,
    );
  };

  const handleUpload = async (uploads: FileToUpload[]): Promise<ActionResult<never>> => {
    try {
      if (uploads.length !== 1) {
        return createErrorResponse(new Error('Please provide single artwork file.'));
      }

      const fileToUpload = uploads[0];
      const asset = await handleFileUpload(fileToUpload);

      onUpload(asset.id, asset.latestVersion.versionNumber);
      return createSuccessResponse();
    } catch (e) {
      return createErrorResponse(e);
    }
  };
  return <UploadsControl allowMultiple={false} handleUpload={handleUpload} uploadButtonText={'Create new version'} />;
};
