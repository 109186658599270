import React, { useEffect, useState } from 'react';
import { AssetsFilters } from '../Model/AssetsFilters';
import { CreatedByFilterPanel } from './CreatedBy/CreatedByFilterPanel';
import { AssetKind } from '../../api/Cams/model/AssetKind';
import { TagsFilterPanel } from './Tags/TagsFilterPanel';
import { AssetType } from '../../api/Cams/model/AssetType';
import { CollectionsFilterPanel } from './Collections/CollectionsFilterPanel';
import { AssetCategory } from '../AssetCategory';
import { DesignConceptFilterPanel } from './DesignConcepts/DesignConceptFilterPanel';
import { PublishedFilterPanel } from './Published/PublishedFilterPanel';
import { TenantDescription } from '../../api/model/TenantDescription';
import { ProducNameFilterPanel } from './ProductNames/ProductNameFilterPanel';
import { DesignUseCaseFilterPanel } from './DesignUseCases/DesignUseCaseNameFilterPanel';
import { QueryStringState } from '../QueryString/QueryStringState';
import { getAssetCategorySetting } from '../../AssetCategoryConfig';
import { AccessibleToFilterPanel } from './AccessibleTo/AccessibleToFilterPanel';

interface Props {
  assetKinds: AssetKind[];
  assetType: AssetType;
  pageState: QueryStringState;
  assetCategory: AssetCategory;
  initialFilters: AssetsFilters;
  tenant: TenantDescription;
  onFiltersChanged: (filters: AssetsFilters) => void;
  isTagReload?: boolean;
  onTagReload?: (value: boolean) => void;
  isCollectionReload?: boolean;
  onCollectionReload?: (value: boolean) => void;
}

export const AssetsListFilterSidebar = ({
  assetKinds,
  assetType,
  assetCategory,
  initialFilters,
  tenant,
  pageState,
  onFiltersChanged,
  isTagReload,
  onTagReload,
  isCollectionReload,
  onCollectionReload,
}: Props): JSX.Element => {
  const [filters, setFilters] = useState<AssetsFilters>(initialFilters);
  const assetCategorySetting = getAssetCategorySetting(assetCategory);
  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const onCreatedByChanged = (createdBy: string[]): void => {
    handleFilterChanged(createdBy, 'createdBy');
  };

  const onPublishedChanged = (published: string[]): void => {
    handleFilterChanged(published, 'published');
  };

  const onTagsChanged = (tags: string[]): void => {
    handleFilterChanged(tags, 'tags');
  };

  const onDesignConceptIdsChanged = (designConceptIds: string[]): void => {
    handleFilterChanged(designConceptIds, 'designConceptId');
  };

  const onDesignUseCaseNamesChanged = (designUseCaseNames: string[]): void => {
    handleFilterChanged(designUseCaseNames, 'designUseCaseNames');
  };

  const onCollectionsChanged = (collections: string[]): void => {
    handleFilterChanged(collections, 'collections');
  };

  const onProductNameChanged = (productNames: string[]): void => {
    handleFilterChanged(productNames, 'productNames');
  };

  const onAccessibleToChanged = (accessibleTo: string[]): void => {
    handleFilterChanged(accessibleTo, 'accessibleTo');
  };

  const handleFilterChanged = (value: string[], key: keyof AssetsFilters): void => {
    const updatedfilters = { ...filters, [key]: value };
    setFilters(updatedfilters);
    onFiltersChanged(updatedfilters);
  };

  return (
    <>
      <CreatedByFilterPanel
        assetKinds={assetKinds}
        assetType={assetType}
        selectedItems={filters.createdBy}
        tenant={tenant}
        onFilterChanged={onCreatedByChanged}
      />
      {(assetCategorySetting.canBeFilteredByPublishStatus ||
        (assetCategory == 'design' && tenant.settings.features.showDesignPublishStatusFilter)) && (
        <PublishedFilterPanel pageState={pageState} label="Is Published" onFilterChanged={onPublishedChanged} />
      )}
      {tenant.settings.features.hideTagsFilter !== true && (
        <TagsFilterPanel
          pageState={pageState}
          facetName={assetType === 'management' ? 'ContentTags' : 'Tags'}
          label="Tags"
          onFilterChanged={onTagsChanged}
          isReload={isTagReload}
          onIsReload={onTagReload}
        />
      )}
      {tenant.settings.features.showDesignConceptFilter && assetCategorySetting.canBeFilteredByDesignConcept && (
        <DesignConceptFilterPanel
          pageState={pageState}
          label="Design Concept Ids"
          onFilterChanged={onDesignConceptIdsChanged}
        />
      )}
      {tenant.settings.features.showDesignUseCaseFilter && assetCategorySetting.canBeFilteredByDesignUseCase && (
        <DesignUseCaseFilterPanel
          pageState={pageState}
          label="Design Use Case names"
          onFilterChanged={onDesignUseCaseNamesChanged}
        />
      )}
      {assetCategorySetting.canBeFilteredByProductNamesFilter && tenant.settings.features.showProductNamesFilter && (
        <ProducNameFilterPanel pageState={pageState} label="Product Names" onFilterChanged={onProductNameChanged} />
      )}

      <CollectionsFilterPanel
        assetType={assetType}
        facetName={assetType === 'management' ? 'ContentCollections' : 'Collections'}
        onFilterChanged={onCollectionsChanged}
        label="Collections"
        selectedCollections={filters.collections}
        tenant={tenant}
        isReload={isCollectionReload}
        onIsReload={onCollectionReload}
      />

      {assetCategory !== 'design' &&
        assetCategory !== 'creative asset' &&
        tenant.settings.features.accessibleTo.length > 0 && (
          <AccessibleToFilterPanel
            pageState={pageState}
            label="Accessible To"
            onFilterChanged={onAccessibleToChanged}
          />
        )}
    </>
  );
};
