import { throwOnErrorAndUnwrap } from '../../Tools/ActionResult';
import { throwOnApiError } from '../../Tools/ResponseHelper';
import { FileToUpload } from '../../Uploads/FileToUpload';
import { Tenant } from '../model/Tenant';
import { PatternManagementAsset } from './model/Pattern/PatternManagementAsset';
import * as CamsApi from './cams-api';
import * as CamsCreativeApi from './cams-api-creative';
import * as CamsDesignsApi from './cams-api-designs';
import * as CamsPatternsApi from './cams-api-patterns';
import * as CamsClipartsApi from './cams-api-cliparts';
import * as CamsBackgroundsApi from './cams-api-backgrounds';
import * as CamsFontSchemaApi from './cams-api-fonts';
import * as CamsColorPaletteApi from './cams-api-colorPalettes';
import { PatternAssetRequestBody } from './model/Pattern/PatternAssetRequestBody';
import { DesignManagementAsset as DesignAsset } from './model/Design/DesignManagementAsset';
import { DesignAssetRequestBody } from './model/Design/DesignAssetRequestBody';
import { CreativeAsset } from './model/CreativeAsset';
import { CreativeAssetCommand } from './model/CreativeAssetCommand';
import { DesignAssetVersionRequestBody } from './model/Design/DesignAssetVersionRequestBody';
import { checkAssetDuplicates } from '../../Tools/UploadTools';
import { CreativeAssetVersion } from './model/CreativeAssetVersion';
import { ClipartManagementAsset } from './model/Clipart/ClipartManagementAsset';
import { ClipartAssetRequestBody } from './model/Clipart/ClipartAssetRequestBody';
import { BackgroundManagementAsset } from './model/Background/BackgroundManagementAsset';
import { BackgroundAssetRequestBody } from './model/Background/BackgroundAssetRequestBody';
import { FontSchemaManagementAsset } from './model/FontSchema/FontSchemaManagementAsset';
import { FontSchemaAssetRequestBody } from './model/FontSchema/FontSchemaAssetRequestBody';
import { ColorPaletteManagementAsset } from './model/ColorPalette/ColorPaletteManagementAsset';
import { ColorPaletteAssetRequestBody } from './model/ColorPalette/ColorPaletteAssetRequestBody';
import { UpdateFontSchemaAssetRequestBody } from './model/FontSchema/UpdateFontSchemaAssetRequestBody';
import { UpdateColorPaletteAssetRequestBody } from './model/ColorPalette/UpdateColorPaletteAssetRequestBody';
import { UpdateClipartAssetRequestBody } from './model/Clipart/UpdateClipartAssetRequestBody';
import { UpdateBackgroundAssetRequestBody } from './model/Background/UpdateBackgroundAssetRequestBody';

export async function createOrUpdatePatternAsset(
  tenant: Tenant,
  creativeAssetFile: FileToUpload,
  tags?: string[],
  collections?: string[],
  patternId?: string,
  creativeAssetId?: string,
): Promise<PatternManagementAsset> {
  if (patternId === undefined) {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAsset(tenant, creativeAssetFile.contentFile, creativeAssetFile.previewFile),
    );
    const requestBody: PatternAssetRequestBody = {
      masterFile: { assetId: creativeAsset.id, versionNumber: creativeAsset.latestVersion.versionNumber },
      contentTags: tags,
      contentCollections: collections,
    };

    const patternAsset = await throwOnApiError(CamsPatternsApi.createPatternAsset(tenant, requestBody));
    return patternAsset;
  } else {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAssetVersion(
        tenant,
        creativeAssetFile.contentFile,
        creativeAssetFile.previewFile,
        creativeAssetId,
      ),
    );
    const requestBody: PatternAssetRequestBody = {
      masterFile: { assetId: creativeAsset.assetId, versionNumber: creativeAsset.versionNumber },
    };
    await throwOnApiError(CamsPatternsApi.createPatternAssetVersion(tenant, patternId, requestBody));
    return await throwOnApiError(CamsApi.getAsset<PatternManagementAsset>(tenant, patternId));
  }
}

export async function createOrUpdateFontSchemaAsset(
  tenant: Tenant,
  creativeAssetFile: FileToUpload,
  tags?: string[],
  collections?: string[],
  designConceptId?: string,
  assetId?: string,
  creativeAssetId?: string,
): Promise<FontSchemaManagementAsset> {
  if (assetId === undefined) {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAsset(tenant, creativeAssetFile.contentFile, creativeAssetFile.previewFile),
    );
    const requestBody: FontSchemaAssetRequestBody = {
      masterFile: { assetId: creativeAsset.id, versionNumber: creativeAsset.latestVersion.versionNumber },
      contentTags: tags,
      contentCollections: collections,
    };

    const fontSchemaAsset = await throwOnApiError(CamsFontSchemaApi.createFontSchemaAsset(tenant, requestBody));
    if (designConceptId) {
      const updateRequestBody: UpdateFontSchemaAssetRequestBody = {
        designConceptId: designConceptId,
      };
      await throwOnApiError(CamsFontSchemaApi.updateFontSchemaAsset(fontSchemaAsset.id, tenant, updateRequestBody));
    }
    return fontSchemaAsset;
  } else {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAssetVersion(
        tenant,
        creativeAssetFile.contentFile,
        creativeAssetFile.previewFile,
        creativeAssetId,
      ),
    );
    const requestBody: FontSchemaAssetRequestBody = {
      masterFile: { assetId: creativeAsset.assetId, versionNumber: creativeAsset.versionNumber },
    };
    await throwOnApiError(CamsFontSchemaApi.createFontSchemaAssetVersion(tenant, assetId, requestBody));
    return await throwOnApiError(CamsApi.getAsset<FontSchemaManagementAsset>(tenant, assetId));
  }
}

export async function createOrUpdateColorPaletteAsset(
  tenant: Tenant,
  creativeAssetFile: FileToUpload,
  tags?: string[],
  collections?: string[],
  designConceptId?: string,
  assetId?: string,
  creativeAssetId?: string,
): Promise<ColorPaletteManagementAsset> {
  if (assetId === undefined) {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAsset(tenant, creativeAssetFile.contentFile, creativeAssetFile.previewFile),
    );
    const requestBody: ColorPaletteAssetRequestBody = {
      masterFile: { assetId: creativeAsset.id, versionNumber: creativeAsset.latestVersion.versionNumber },
      contentTags: tags,
      contentCollections: collections,
    };
    const colorPaletteAsset = await throwOnApiError(CamsColorPaletteApi.createColorPaletteAsset(tenant, requestBody));
    if (designConceptId) {
      const updateRequestBody: UpdateColorPaletteAssetRequestBody = {
        designConceptId: designConceptId,
      };
      await throwOnApiError(
        CamsColorPaletteApi.updateColorPaletteAsset(colorPaletteAsset.id, tenant, updateRequestBody),
      );
    }
    return colorPaletteAsset;
  } else {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAssetVersion(
        tenant,
        creativeAssetFile.contentFile,
        creativeAssetFile.previewFile,
        creativeAssetId,
      ),
    );
    const requestBody: ColorPaletteAssetRequestBody = {
      masterFile: { assetId: creativeAsset.assetId, versionNumber: creativeAsset.versionNumber },
    };
    await throwOnApiError(CamsColorPaletteApi.createColorPaletteAssetVersion(tenant, assetId, requestBody));
    return await throwOnApiError(CamsApi.getAsset<ColorPaletteManagementAsset>(tenant, assetId));
  }
}

export async function createOrUpdateClipartAsset(
  tenant: Tenant,
  creativeAssetFile: FileToUpload,
  tags?: string[],
  collections?: string[],
  designConceptId?: string,
  clipartId?: string,
  creativeAssetId?: string,
): Promise<ClipartManagementAsset> {
  if (clipartId === undefined) {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAsset(tenant, creativeAssetFile.contentFile, creativeAssetFile.previewFile),
    );
    const requestBody: ClipartAssetRequestBody = {
      masterFile: { assetId: creativeAsset.id, versionNumber: creativeAsset.latestVersion.versionNumber },
      contentTags: tags,
      contentCollections: collections,
    };

    const clipartAsset = await throwOnApiError(CamsClipartsApi.createClipartAsset(tenant, requestBody));
    if (designConceptId) {
      const updateRequestBody: UpdateClipartAssetRequestBody = {
        designConceptId: designConceptId,
      };
      await throwOnApiError(CamsClipartsApi.updateClipartAsset(clipartAsset.id, tenant, updateRequestBody));
    }
    return clipartAsset;
  } else {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAssetVersion(
        tenant,
        creativeAssetFile.contentFile,
        creativeAssetFile.previewFile,
        creativeAssetId,
      ),
    );
    const requestBody: ClipartAssetRequestBody = {
      masterFile: { assetId: creativeAsset.assetId, versionNumber: creativeAsset.versionNumber },
    };
    await throwOnApiError(CamsClipartsApi.createClipartAssetVersion(tenant, clipartId, requestBody));
    return await throwOnApiError(CamsApi.getAsset<ClipartManagementAsset>(tenant, clipartId));
  }
}

export async function createOrUpdateBackgroundAsset(
  tenant: Tenant,
  creativeAssetFile: FileToUpload,
  tags?: string[],
  collections?: string[],
  designConceptId?: string,
  backgroundId?: string,
  creativeAssetId?: string,
): Promise<BackgroundManagementAsset> {
  if (backgroundId === undefined) {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAsset(tenant, creativeAssetFile.contentFile, creativeAssetFile.previewFile),
    );
    const requestBody: BackgroundAssetRequestBody = {
      masterFile: { assetId: creativeAsset.id, versionNumber: creativeAsset.latestVersion.versionNumber },
      contentTags: tags,
      contentCollections: collections,
    };

    const backgroundAsset = await throwOnApiError(CamsBackgroundsApi.createBackgroundAsset(tenant, requestBody));
    if (designConceptId) {
      const updateRequestBody: UpdateBackgroundAssetRequestBody = {
        designConceptId: designConceptId,
      };
      await throwOnApiError(CamsBackgroundsApi.updateBackgroundAsset(backgroundAsset.id, tenant, updateRequestBody));
    }
    return backgroundAsset;
  } else {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAssetVersion(
        tenant,
        creativeAssetFile.contentFile,
        creativeAssetFile.previewFile,
        creativeAssetId,
      ),
    );
    const requestBody: BackgroundAssetRequestBody = {
      masterFile: { assetId: creativeAsset.assetId, versionNumber: creativeAsset.versionNumber },
    };
    await throwOnApiError(CamsBackgroundsApi.createBackgroundAssetVersion(tenant, backgroundId, requestBody));
    return await throwOnApiError(CamsApi.getAsset<BackgroundManagementAsset>(tenant, backgroundId));
  }
}

export async function createOrUpdateCreativeAsset(
  tenant: Tenant,
  creativeAssetFile: FileToUpload,
  creativeAssetId?: string,
): Promise<CreativeAsset> {
  if (creativeAssetId === undefined) {
    const creativeAsset = await throwOnApiError(
      CamsCreativeApi.createCreativeAsset(tenant, creativeAssetFile.contentFile, creativeAssetFile.previewFile),
    );
    return creativeAsset;
  } else {
    await throwOnApiError(
      CamsCreativeApi.createCreativeAssetVersion(
        tenant,
        creativeAssetFile.contentFile,
        creativeAssetFile.previewFile,
        creativeAssetId,
      ),
    );
    return await throwOnApiError(CamsApi.getAsset<CreativeAsset>(tenant, creativeAssetId));
  }
}

interface UploadedCreativeAsset {
  file: FileToUpload;
  creativeAsset: CreativeAsset;
}

export async function createOrUpdateDesignAsset(
  tenant: Tenant,
  masterFile: FileToUpload,
  requestBody?: Omit<DesignAssetRequestBody, 'creativeAssets'>,
  linkedFilesToUpload?: FileToUpload[],
  designId?: string,
  creativeAssetId?: string,
): Promise<DesignAsset> {
  const masterCreativeAsset: UploadedCreativeAsset = {
    creativeAsset: await createOrUpdateCreativeAsset(tenant, masterFile, creativeAssetId),
    file: masterFile,
  };

  let linkedCreativeAssets: UploadedCreativeAsset[] = [];
  let linkedExistingCreativeAssetCommands: CreativeAssetCommand[] = [];
  if (linkedFilesToUpload) {
    const duplicatesCheck = await throwOnErrorAndUnwrap(checkAssetDuplicates(tenant, linkedFilesToUpload));

    const assetsWithoutDuplicates = duplicatesCheck.filter((check) => check.existingAssets.length === 0);
    linkedFilesToUpload = assetsWithoutDuplicates.map((asset) => asset.upload);

    linkedCreativeAssets =
      linkedFilesToUpload === undefined || linkedFilesToUpload.length === 0
        ? []
        : await Promise.all(
            linkedFilesToUpload.map(async (lf) => {
              return {
                creativeAsset: await createOrUpdateCreativeAsset(tenant, lf),
                file: lf,
              };
            }),
          );

    linkedExistingCreativeAssetCommands = duplicatesCheck
      .filter((check) => check.existingAssets.length !== 0)
      .map((x) => {
        const version: CreativeAssetVersion = x.existingAssets.find(
          (y) => y.versionNumber === Math.max(...x.existingAssets.map((z) => z.versionNumber)),
        ) as CreativeAssetVersion;
        return {
          creativeAssetId: version.assetId,
          fileName: x.upload.contentFile.name,
          isMasterFile: false,
          versionNumber: version.versionNumber,
        };
      });
  }

  const allUploadedAssets = linkedCreativeAssets.concat([masterCreativeAsset]);
  const creativeAssetsCommand: CreativeAssetCommand[] = allUploadedAssets
    .map((uploadedAsset) => {
      return {
        creativeAssetId: uploadedAsset.creativeAsset.id,
        fileName: uploadedAsset.file.contentFile.name,
        isMasterFile: uploadedAsset.file === masterFile,
        versionNumber: uploadedAsset.creativeAsset.latestVersion.versionNumber,
      };
    })
    .concat(linkedExistingCreativeAssetCommands);

  if (designId === undefined) {
    const body: DesignAssetRequestBody = {
      ...requestBody,
      creativeAssets: creativeAssetsCommand,
    };
    const designAsset = await throwOnApiError(CamsDesignsApi.createDesignAsset(tenant, body));
    return designAsset;
  } else {
    const body: DesignAssetVersionRequestBody = {
      creativeAssets: creativeAssetsCommand,
    };
    await throwOnApiError(CamsDesignsApi.createDesignAssetVersion(tenant, designId, body));
    return await throwOnApiError(CamsApi.getAsset<DesignAsset>(tenant, designId));
  }
}
