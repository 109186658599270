import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import * as CamsClipartsApi from '../api/Cams/cams-api-cliparts';
import './ClipartDetailPage.scss';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ClipartHeader } from './ClipartHeader';
import { Button } from '@cimpress/react-components';
import { UpdateClipartAssetControl } from '../Uploads/UpdateClipartAssetControl';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import { Toast } from '../components/Toast';
import { ClipartManagementAsset } from '../api/Cams/model/Clipart/ClipartManagementAsset';
import { ClipartManagementAssetVersion } from '../api/Cams/model/Clipart/ClipartManagementAssetVersion';
import { DeleteAsset } from '../components/DeleteAsset';
import { Tenant } from '../api/model/Tenant';
import { parseCategories } from '../Tools/CategoryUtils';
import { AssetMissingLocalizationNotification } from '../MissingLocalizationNotification/AssetMissingLocalizationNotification';
import { AssetCategory, ToAssetCategory } from '../AssetsListPage/AssetCategory';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ClipartDetailPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();

  const history = useHistory();

  const query = useQuery();
  const version = query.get('version') || undefined;
  const message = query.get('message') || undefined;

  const assetCategory: AssetCategory = 'clipart';

  const [clipart, setClipart] = useState<ClipartManagementAsset>();
  const [clipartVersion, setClipartVersion] = useState<ClipartManagementAssetVersion>();
  const [firstVersion, setFirstVersion] = useState<ClipartManagementAssetVersion>();
  const [, setAssetError] = useState<string>();
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState(true);
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(true);

  const loadAsset = async (id: string, version?: string | number) => {
    setIsLoading(true);
    setIsDeleteButtonShown(tenant.userPermissions.canWrite);
    setIsPublishButtonEnabled(tenant.userPermissions.canPublish);
    const response = await CamsApi.getAsset<ClipartManagementAsset>(tenant, id);
    setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      if (response.data.assetKind !== 'clipart') {
        history.push(
          getVariableAssetDetailPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            ToAssetCategory(response.data.assetType, response.data.assetKind),
            id,
          ),
        );
        return;
      }
      const clipart = response.data as ClipartManagementAsset;
      setClipart(clipart);

      const usedClipartVersion = await getUsedClipartVersion(clipart, version);

      const firstVersion =
        usedClipartVersion.versionNumber === 1 ? usedClipartVersion : await getUsedClipartVersion(clipart, 1);
      setFirstVersion(firstVersion);

      setIsPublishButtonEnabled((isPublishButtonEnabled) => isPublishButtonEnabled && !clipart.isDeleted);
      setClipartVersion(usedClipartVersion);
    }
  };

  const getUsedClipartVersion = async (clipart: ClipartManagementAsset, version: string | number | undefined) => {
    let usedClipartVersion = clipart.latestVersion;

    const versionNumber = parseInt(version?.toString() || '');
    if (versionNumber) {
      const versionResponse = await CamsApi.getAssetVersion(tenant, id, versionNumber);
      if (isSuccessfulResponse(versionResponse)) {
        usedClipartVersion = versionResponse.data as ClipartManagementAssetVersion;
      }
    }
    return usedClipartVersion;
  };

  const handleUpdate = async () => {
    await loadAsset(id);
  };

  const handlePublish = async (tenant: Tenant, id: string, version: number, published: boolean) => {
    setIsLoading(true);
    const publishResponse = await CamsClipartsApi.updateClipartVersionAsset(id, version, tenant, {
      isDiscoverable: published,
    });
    if (!isSuccessfulResponse(publishResponse)) {
      setIsLoading(false);
      return publishResponse;
    }

    const getAssetResponse = await CamsApi.getAsset<ClipartManagementAsset>(tenant, id);
    if (isSuccessfulResponse(getAssetResponse)) {
      setClipart(getAssetResponse.data);
      setClipartVersion(publishResponse.data);
    }
    setIsLoading(false);

    return publishResponse;
  };

  const handleSaveProperty = async (tenant: Tenant, id: string, property: { [key: string]: string[] }) => {
    const response = await CamsClipartsApi.updateClipartAsset(id, tenant, property);
    if (response.responseType === 'success') {
      setClipart(response.data);
      const usedClipartVersion = await getUsedClipartVersion(response.data, version);
      setClipartVersion(usedClipartVersion);
    }
    return response;
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAsset(id, version);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, version]);

  const onAssetVersionCreated = async (assetId: string, version: number) => {
    setIsCreatingNewVersion(false);
    await loadAsset(assetId, version);
  };

  const isLatestVersion = clipart?.latestVersion.versionNumber === clipartVersion?.versionNumber;

  return (
    <div className="container-fluid container-stretch">
      <AssetMissingLocalizationNotification
        assetKind={assetCategory}
        categories={parseCategories(clipart?.contentCollections)}
      />
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="clipart-details-title">Clipart detail</h2>
          {clipart && isLatestVersion && (
            <div className="detail-header-buttons">
              {isCreatingNewVersion ? (
                <Button variant="primary" className="btn-danger" onClick={() => setIsCreatingNewVersion(false)}>
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="upload-new-version-button"
                  data-testid="upload-new-version-button"
                  onClick={() => setIsCreatingNewVersion(true)}
                  disabled={!tenant.userPermissions.canWrite}
                >
                  Upload new version
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      {clipart && isCreatingNewVersion && (
        <UpdateClipartAssetControl
          clipartAssetId={clipart.id}
          masterAssetId={clipart.latestVersion?.masterFile?.assetId}
          onUpload={onAssetVersionCreated}
        />
      )}

      {isLoading && <Loading />}

      {!clipart && !isLoading && <h4 data-testid="detail-not-found">Clipart not found.</h4>}

      {clipart && clipartVersion && firstVersion && (
        <div className="row section">
          <div className="col-sm-12">
            <ClipartHeader
              clipart={clipart}
              clipartVersion={clipartVersion}
              firstVersion={firstVersion}
              isLatestVersion={isLatestVersion}
              isPublishButtonEnabled={isPublishButtonEnabled}
              onPublish={(tenant, published) =>
                handlePublish(tenant, clipart.id, clipartVersion.versionNumber, published)
              }
              onSaveTags={(tenant, keywords) => handleSaveProperty(tenant, clipart.id, { contentTags: keywords })}
              onSaveCollections={(tenant, collections) =>
                handleSaveProperty(tenant, clipart.id, { contentCollections: collections })
              }
              onSaveDestinations={(tenant, destinations) =>
                handleSaveProperty(tenant, clipart.id, { accessibleTo: destinations })
              }
            />
          </div>
        </div>
      )}

      {clipart && !clipart.isDeleted && (
        <div className="row section">
          <div className="col-sm-6">
            <DeleteAsset
              assetId={clipart.id}
              assetCategory={assetCategory}
              name={`${clipartVersion?.masterFile?.uploadInfo?.fileName ?? 'this'} ${'clipart'.format()}`}
              onDelete={handleUpdate}
              showButton={isDeleteButtonShown}
              disabled={!clipart.isDeletable || isLoading}
            />
          </div>
        </div>
      )}
      {message && <Toast message={message} />}
    </div>
  );
};

export default ClipartDetailPage;
