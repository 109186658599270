import { VariantInfo } from './VariantInfo';
import { PanelInfo } from './PanelInfo';

export interface Ensemble {
  variantInfos: VariantInfo[];
  panels: PanelInfo[];
  version: number;
  isProminentVariant?: boolean;
}

export const hasColorVariants = (ensemble: Ensemble): boolean => {
  return (
    ensemble.variantInfos !== undefined &&
    ensemble.variantInfos[0] !== undefined &&
    ensemble.variantInfos[0].variantData !== undefined
  );
};
