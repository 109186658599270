import { ApiError, ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { FileToUpload } from '../Uploads/FileToUpload';
import * as CamsCreativeApi from '../api/Cams/cams-api-creative';
import { CalculateSha256Hash } from './Hashing';
import { isSuccessfulResponse } from './ResponseHelper';
import { ActionResult, createErrorResponse, createSuccessResponse } from './ActionResult';
import { CreativeAssetVersion } from '../api/Cams/model/CreativeAssetVersion';
import { Tenant } from '../api/model/Tenant';

interface HashCheckResultInternal {
  asset: FileToUpload;
  response: ApiResponseOrError<CreativeAssetVersion[]>;
}

interface HashCheckResult {
  upload: FileToUpload;
  existingAssets: CreativeAssetVersion[];
}

function toHashCheckResult(hashCheckInternal: HashCheckResultInternal): HashCheckResult {
  return {
    existingAssets: (hashCheckInternal.response as ApiResponse<CreativeAssetVersion[]>).data,
    upload: hashCheckInternal.asset,
  };
}

export async function checkAssetDuplicates(
  tenant: Tenant,
  assets: FileToUpload[],
): Promise<ActionResult<HashCheckResult[]>> {
  if (!assets || assets.length === 0) {
    return createSuccessResponse([]);
  }

  const promises: Promise<HashCheckResultInternal>[] = [];
  for (const asset of assets) {
    const promise = CalculateSha256Hash(asset.contentFile)
      .then((hash) => CamsCreativeApi.getAssetVersionsByHash(tenant, hash))
      .then((response) => {
        return { asset: asset, response: response } as HashCheckResultInternal;
      });

    promises.push(promise);
  }

  try {
    const result = await Promise.all(promises);
    const erroneousResponses = result.filter((response) => !isSuccessfulResponse(response.response));
    if (erroneousResponses.length > 0) {
      return createErrorResponse(
        // TODO: combine errors properly
        new Error(erroneousResponses.map((errResponse) => (errResponse.response as ApiError).content).join(' ; ')),
      );
    }

    const hashCheckResults = result.map(toHashCheckResult);
    return createSuccessResponse(hashCheckResults);
  } catch (e) {
    return createErrorResponse(e);
  }
}
