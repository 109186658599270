import { Tenant } from './Tenant';
import { TenantSettings } from './TenantSettings';

export interface UserPermissions {
  canWrite: boolean;
  canPublish: boolean;
}

export interface TenantDescription extends Tenant {
  accountId: string;
  contentAreaId: string;
  accountName: string;
  contentAreaName: string;
  description: string;
  contentAuthoringAccountName: string;
  contentAuthoringAreaName: string;
  settings: TenantSettings;

  userPermissions: UserPermissions;
}

export const EmptyTenant: TenantDescription = {
  accountId: '',
  accountName: '',
  contentAreaId: '',
  contentAreaName: '',
  contentAuthoringAccountId: '',
  contentAuthoringAccountName: '',
  contentAuthoringAreaId: '',
  contentAuthoringAreaName: '',
  description: '',
  settings: {
    features: {
      retrieveTagUsageCounts: false,
      showDesignConceptFilter: false,
      showDesignPublishStatusFilter: false,
      showProductNamesFilter: false,
      doInitialTagsLoad: false,
      allowedAssetCategories: [],
      hideTagsFilter: false,
      showDesignUseCaseFilter: false,
      showDesignConceptsTableLink: false,
      accessibleTo: [],
    },
    fontConfiguration: {
      fontRepository: '',
      disabledFonts: [],
    },
  },
  userPermissions: {
    canWrite: false,
    canPublish: false,
  },
};
