import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { isCreativeAssetVersion } from '../api/Cams/model/CreativeAssetVersion';
import { isManagementAssetVersion } from '../api/Cams/model/ManagementAssetVersion';
import { UploadInfo } from '../api/Cams/model/UploadInfo';

export const getAssetVersionName = (assetVersion: AnyAssetVersion): string => {
  if (isCreativeAssetVersion(assetVersion)) {
    return assetVersion.uploadInfo?.fileName || 'File name missing';
  }

  if (isManagementAssetVersion(assetVersion)) {
    return assetVersion.masterFile?.uploadInfo?.fileName || 'File name missing';
  }

  return 'File name unknown';
};

export const getAssetUploadInfo = (assetVersion: AnyAssetVersion): UploadInfo | null => {
  if (isCreativeAssetVersion(assetVersion)) {
    return assetVersion.uploadInfo;
  }

  if (isManagementAssetVersion(assetVersion)) {
    return assetVersion.masterFile?.uploadInfo;
  }

  return null;
};
