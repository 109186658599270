import React from 'react';
import { Button } from '@cimpress/react-components';
import Dropzone from 'react-dropzone';
import './DropzoneWrapper.scss';

interface Props {
  onDrop: (acceptedFiles: File[]) => void;
  allowMultiple: boolean;
  FilesToUploadList: JSX.Element;
  disabled: boolean;
}

export const DropzoneWrapper = ({ onDrop, allowMultiple, FilesToUploadList, disabled }: Props): JSX.Element => {
  return (
    <Dropzone onDrop={onDrop} multiple={allowMultiple} noDragEventsBubbling={true} disabled={disabled}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} data-testid="dropzone-input" />
          <div className={allowMultiple ? 'well upload-zone' : 'well upload-zone upload-zone-smaller'}>
            {FilesToUploadList}
            <Button variant="primary" disabled={disabled}>
              Select File
            </Button>
            <i className="fa fa-5x fa-cloud-upload background-text" />
            <p className="background-text">Drag and Drop or Select Files to Upload</p>
          </div>
        </div>
      )}
    </Dropzone>
  );
};
