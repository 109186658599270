import { Asset } from '../Asset';
import { FontSchemaManagementAssetVersion } from './FontSchemaManagementAssetVersion';

export interface FontSchemaManagementAsset extends Asset<FontSchemaManagementAssetVersion> {
  assetKind: 'fontSchema';
  assetType: 'management';
  isDeletable: boolean;
  designConceptId?: string;
  discoverableVersionNumber?: number;
}

export const isFontSchemaManagementAsset = (x: Asset<any>): x is FontSchemaManagementAsset => {
  return x.assetType === 'management' && x.assetKind === 'fontSchema';
};
