import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import * as CamsDesignsApi from '../api/Cams/cams-api-designs';
import './DesignPropertiesPage.scss';
import { useParams, useHistory } from 'react-router-dom';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { Button, Card, Modal, TextField } from '@cimpress/react-components';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import { DesignAssetPreview } from '../components/DesignAssetPreview';
import { useFormik } from 'formik';
import { DesignManagementAsset } from '../api/Cams/model/Design/DesignManagementAsset';
import { getDesignManagementAssetDetailsPageRoute } from '../Routes/DesignManagementAssetRoutes';
import { getDesignName } from '../Tools/DesignHelpers';

interface QueryStringParams {
  id: string;
}

const DesignPropertiesPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();

  const [design, setDesign] = useState<DesignManagementAsset>();
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      DesignConceptId: '',
      DesignUseCaseId: '',
      Strategist: '',
    },
    onSubmit: async () => {
      setModalIsOpen(true);
    },
  });

  const closeModal = async (isConfirmed: boolean) => {
    setModalIsOpen(false);

    if (isConfirmed) {
      setIsLoading(true);
      const response = await CamsDesignsApi.updateDesignAsset(design?.id || '', tenant, formik.values);
      if (isSuccessfulResponse(response)) {
        history.push(
          getDesignManagementAssetDetailsPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            design?.id || '',
            undefined,
            'Properties were saved',
          ),
        );
      }

      setIsLoading(false);
    }
  };

  const loadDesign = async (id: string) => {
    setIsLoading(true);
    const response = await CamsApi.getAsset<DesignManagementAsset>(tenant, id);
    setIsLoading(false);

    if (isSuccessfulResponse(response)) {
      const design = response.data;

      formik.setValues({
        DesignConceptId: design?.designConceptId || '',
        DesignUseCaseId: design?.designUseCaseId || '',
        Strategist: design?.strategist || '',
      });

      setDesign(design);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadDesign(id);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id]);

  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="design-properties-title">Design properties</h2>
        </div>
      </div>

      {isLoading && <Loading />}

      {!design && !isLoading && <h4>Design not found.</h4>}

      {design && (
        <div className="row section">
          <div className="col-sm-12">
            <Card>
              <div className="row design-header-wrapper">
                <div className="col-sm-4">
                  <div className="preview-wrapper">
                    <DesignAssetPreview
                      designVersion={design.latestVersion}
                      showAsLink={true}
                      physicalSpecId={design.designPhysicalSpecId}
                    />
                  </div>
                </div>
                <div className="col-sm-8 design-detail-wrapper">
                  <h1 className="text-primary design-name-header">
                    {design.isDeleted ? (
                      <span className="text-danger">
                        {getDesignName(design, design.latestVersion)} (Design was deleted)
                      </span>
                    ) : (
                      <>{getDesignName(design, design.latestVersion)}</>
                    )}
                  </h1>
                  {design.isLocked && (
                    <p className="text-danger" data-testid="design-properties-locked-message">
                      Design is locked, modification is disabled
                    </p>
                  )}
                  <div className="card-text design-description">
                    <form onSubmit={formik.handleSubmit} data-testid="design-properties-form">
                      <fieldset>
                        <div className="row">
                          <div className="col-md-6">
                            <TextField
                              label="Design Concept ID"
                              type="text"
                              value={formik.values.DesignConceptId}
                              name="DesignConceptId"
                              onChange={formik.handleChange}
                              status={formik.errors.DesignConceptId ? 'error' : ''}
                              helpText={formik.errors.DesignConceptId}
                              disabled={design.isLocked}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              label="DUC ID"
                              type="text"
                              value={formik.values.DesignUseCaseId}
                              name="DesignUseCaseId"
                              onChange={formik.handleChange}
                              status={formik.errors.DesignUseCaseId ? 'error' : ''}
                              helpText={formik.errors.DesignUseCaseId}
                              disabled={design.isLocked}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextField
                              label="Strategist"
                              type="text"
                              value={formik.values.Strategist}
                              name="Strategist"
                              onChange={formik.handleChange}
                              status={formik.errors.Strategist ? 'error' : ''}
                              helpText={formik.errors.Strategist}
                              disabled={design.isLocked}
                            />
                          </div>
                        </div>
                      </fieldset>
                      <Button
                        className="properties-back"
                        type="default"
                        href={getDesignManagementAssetDetailsPageRoute(
                          tenant.contentAuthoringAccountId,
                          tenant.contentAuthoringAreaId,
                          design.id,
                        )}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" disabled={design.isLocked}>
                        Save
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}

      <Modal
        status="warning"
        show={modalIsOpen}
        onRequestHide={() => setModalIsOpen(false)}
        title={`Confirm properties save`}
        closeButton={true}
        footer={
          <>
            <button className="btn btn-default" onClick={() => closeModal(false)}>
              Cancel
            </button>
            <button className="btn btn-danger" onClick={() => closeModal(true)}>
              Save
            </button>
          </>
        }
      >
        Caution! Please note that for changes in the metadata to be published to website you will need to open this
        design and publish it using ACE.
      </Modal>
    </div>
  );
};

export default DesignPropertiesPage;
