import config from '../../config';
import { isSuccessfulResponse } from '../../Tools/ResponseHelper';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { doGetRequest } from '../RequestUtils';
import { DesignPhysicalSpec } from './DesignPhysicalSpec';

const dpsApiHost = config.dpsApiUrl;

async function getDesignPhysicalSpec(designPhysicalSpecId: string): Promise<ApiResponseOrError<DesignPhysicalSpec>> {
  const url = new URL(`${dpsApiHost}/api/v1/DesignPhysicalSpecs/${designPhysicalSpecId}`);

  return await doGetRequest<DesignPhysicalSpec>(url);
}

const toRgba = (color: string, opacity: 'transparent' | 'opaque'): Rgba => {
  const colorValuesText = color.substring('rgb('.length, color.length - ')'.length);
  const colorValues = colorValuesText.split(',').map((c) => Number.parseInt(c));
  const opacityValue = opacity === 'transparent' ? 128 : 255;
  return new Rgba(colorValues[0], colorValues[1], colorValues[2], opacityValue);
};

export class Rgba {
  constructor(public red: number, public green: number, public blue: number, public opacity: number) {
    this.ensureValidColor(red);
    this.ensureValidColor(green);
    this.ensureValidColor(blue);
    this.ensureValidColor(opacity);
  }

  static WhiteColor: Rgba = new Rgba(255, 255, 255, 0);

  private ensureValidColor(value: number): void {
    if (value < 0 || value > 255) {
      throw new Error(`Wrong color value used: ${value}`);
    }
  }

  toCssRgba(): string {
    const cssOpacity = (this.opacity / 255).toPrecision(2);
    return `rgba(${this.red},${this.green},${this.blue},${cssOpacity})`;
  }

  toDterRgba(): string {
    return `rgba(${this.red},${this.green},${this.blue},${this.opacity})`;
  }
}

export async function getBackgroundRgba(physicalSpecId: string, defaultRgba: Rgba): Promise<Rgba> {
  if (!physicalSpecId) return defaultRgba;

  const physicalSpecResponse = await getDesignPhysicalSpec(physicalSpecId);

  if (isSuccessfulResponse(physicalSpecResponse)) {
    return toRgba(physicalSpecResponse.data.spec.substrate.color, physicalSpecResponse.data.spec.substrate.opacity);
  }
  return defaultRgba;
}
