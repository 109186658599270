import { Asset } from '../Asset';
import { ClipartManagementAssetVersion } from './ClipartManagementAssetVersion';

export interface ClipartManagementAsset extends Asset<ClipartManagementAssetVersion> {
  assetKind: 'clipart';
  assetType: 'management';
  isDeletable: boolean;
  designConceptId?: string;
  discoverableVersionNumber?: number;
}

export const isClipartManagementAsset = (x: Asset<any>): x is ClipartManagementAsset => {
  return x.assetType === 'management' && x.assetKind === 'clipart';
};
