import React from 'react';
import { Column } from 'react-table';
import { ReactTablev6 } from '@cimpress/react-components';
import { FontSchema } from '../Uploads/FontSchema';

interface Props {
  fontSchema: FontSchema;
}

export const FontSchemaTabularView = ({ fontSchema }: Props): JSX.Element => {
  const fontSchemata = Object.entries(fontSchema.schemata).map(([k, v]) => ({
    fontCategory: k,
    fontFamily: v.fontFamily,
    fontStyle: v.fontStyle,
  }));
  const columnDefaultStyle = { overflow: 'hidden', textOverflow: 'ellipsis' };
  const columns = [
    {
      Header: 'Font Category',
      accessor: 'fontCategory',
      style: { maxHeight: '200px' },
    },
    {
      Header: 'Font Family',
      accessor: 'fontFamily',
      style: columnDefaultStyle,
    },
    {
      Header: 'Font Style',
      accessor: 'fontStyle',
      style: columnDefaultStyle,
    },
  ] as Column[];

  return (
    <div style={{ marginTop: '10px' }}>
      <ReactTablev6
        sortable={false}
        condensed={false}
        showPagination={false}
        minRows={0}
        columns={columns}
        data={fontSchemata}
        collapseOnDataChange={false}
        pageSize={fontSchemata.length}
      />
    </div>
  );
};
