import { Asset } from '../Asset';
import { ColorPaletteManagementAssetVersion } from './ColorPaletteManagementAssetVersion';

export interface ColorPaletteManagementAsset extends Asset<ColorPaletteManagementAssetVersion> {
  assetKind: 'colorPalette';
  assetType: 'management';
  isDeletable: boolean;
  designConceptId?: string;
  discoverableVersionNumber?: number;
}

export const isColorPaletteManagementAsset = (x: Asset<any>): x is ColorPaletteManagementAsset => {
  return x.assetType === 'management' && x.assetKind === 'colorPalette';
};
