import React, { useEffect, useState } from 'react';
import { isSuccessfulResponse } from '../../../Tools/ResponseHelper';
import { Accordion } from '@cimpress/react-components';
import ClearFilterButton from '../Components/ClearFilterButton';
import { localeCompare } from '../../../Tools/StringExt';
import { FacetValue } from '../../../api/Cams/model/FacetValue';
import * as CamsApi from '../../../api/Cams/cams-api';
import { defaultPageNumber, maxPageSize } from '../../Model/Constants';
import { AssetKind } from '../../../api/Cams/model/AssetKind';
import { Tenant } from '../../../api/model/Tenant';
import auth from '../../../auth';
import { AssetType } from '../../../api/Cams/model/AssetType';
import { SearchInput } from '../Components/SearchInput';
import { CheckboxList } from '../Components/CheckboxList';
import { getEmailId } from '../../../Tools/AuthHelper';

interface Props {
  selectedItems: string[];
  assetKinds?: AssetKind[];
  assetType: AssetType;
  tenant: Tenant;
  onFilterChanged: (items: string[]) => void;
}

export const CreatedByFilterPanel = ({
  selectedItems,
  assetKinds,
  assetType,
  tenant,
  onFilterChanged,
}: Props): JSX.Element => {
  // users are shuffled according to currently selected items and other values
  const [allItems, setAllItems] = useState<FacetValue[]>([]);
  // raw data
  const [allUsers, setAllUsers] = useState<FacetValue[]>([]);
  const [filteredItems, setFilteredItems] = useState<FacetValue[]>([]);
  const [searchResults, setSearchResults] = useState<FacetValue[]>([]);

  const currentUserEmailId = getEmailId(auth.getProfile());
  const currentUserName = auth.getProfile().name;

  useEffect(() => {
    const loadAllFilters = async () => {
      const users = await CamsApi.getFacetValues(
        tenant,
        'CreatedBy',
        maxPageSize,
        defaultPageNumber,
        undefined,
        assetKinds,
        assetType,
        undefined,
      );
      if (isSuccessfulResponse(users)) {
        setSearchResults([]);
        setAllUsers(users.data);
      }
    };

    loadAllFilters();
  }, [tenant, assetKinds, assetType]);

  useEffect(() => {
    const processUsers = (allUsers: FacetValue[]) => {
      let checkBoxItems = allUsers.filter((user) => localeCompare(user.value, currentUserEmailId) !== 0);

      selectedItems.forEach((element) => {
        if (checkBoxItems.find((x) => x.value == element) === undefined && currentUserEmailId !== element) {
          checkBoxItems.unshift({ label: `${element}`, value: element, usesCount: 0 });
        }
      });

      // set current user as first item
      checkBoxItems.unshift({ label: `${currentUserName} (me)`, value: currentUserEmailId, usesCount: 0 });

      setAllItems(checkBoxItems);
      if (searchResults.length > 0) {
        checkBoxItems = checkBoxItems.filter((x) => searchResults.find((y) => x.value == y.value));
      }

      setFilteredItems(checkBoxItems);
    };
    processUsers(allUsers);
  }, [allUsers, currentUserEmailId, currentUserName, selectedItems, searchResults]);

  const handleFilter = (filteredItems: FacetValue[]) => {
    setSearchResults(filteredItems);
    setFilteredItems(filteredItems);
  };

  const handleCleared = () => {
    setSearchResults([]);
  };

  return (
    <Accordion
      data-testid="designers-filters-panel"
      title={
        <>
          <span>Designer</span>
          <ClearFilterButton selectedItems={selectedItems} onFilterChanged={onFilterChanged} />
        </>
      }
      className="cams-accordion-card"
      defaultOpen
    >
      {allItems.length > 0 && (
        <SearchInput allItems={allItems} onFiltered={handleFilter} searchType="in-results" onCleared={handleCleared} />
      )}

      <CheckboxList
        allItems={filteredItems}
        selectedItems={selectedItems}
        onItemSelected={onFilterChanged}
        showUses={false}
        titleExtractor={(x) => x.value}
      />
    </Accordion>
  );
};
