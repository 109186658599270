import React from 'react';
import { shapes } from '@cimpress/react-components';

const Loading = (): JSX.Element => {
  return (
    <div className="container" data-testid="loading-spinner">
      <div className="row">
        <div className="col-md-12 text-center">
          <shapes.Spinner />
        </div>
      </div>
    </div>
  );
};

export default Loading;
