import { TenantDescription } from '../api/model/TenantDescription';
import { AssetCategory } from '../AssetsListPage/AssetCategory';

// Returns whether a tenant has access to a category in a broad sense
/*There a multiple ways to denote that a tenant has access to a category
Either
    the specfic category(ies) is(are) explicitly mentioned in `allowedAssetCategories`
OR
`allowedAssetCategories` is null or empty. In this specific case, it implicitly implies
that all existent categories are allowed (somewhat unintuitively)*/

export const hasAccessToCategory = (tenantDescription: TenantDescription, category: AssetCategory): boolean => {
  return (
    tenantDescription.settings.features.allowedAssetCategories === null ||
    tenantDescription.settings.features.allowedAssetCategories.length === 0 ||
    tenantDescription.settings.features.allowedAssetCategories.includes(category)
  );
};
