import { AssetKind } from '../api/Cams/model/AssetKind';
import { AssetType } from '../api/Cams/model/AssetType';

export const AssetCategories = [
  'design',
  'pattern',
  'clipart',
  'creative asset',
  'background',
  'font schema',
  'color palette',
] as const;
export type AssetCategory = typeof AssetCategories[number];

export const ToAssetCategory = (assetType: AssetType, assetKind: AssetKind): AssetCategory => {
  if (assetType === 'creative') {
    return 'creative asset';
  }

  switch (assetKind) {
    case '':
    case null:
    case undefined:
      return 'creative asset';
    case 'fontSchema':
      return 'font schema';
    case 'colorPalette':
      return 'color palette';
    default:
      return assetKind;
  }
};
