import './DesignProperties.scss';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import { AppContext } from '../../App';
import { DesignManagementAsset } from '../../api/Cams/model/Design/DesignManagementAsset';
import {
  getDesignManagementAssetDetailsPageRoute,
  getDesignManagementAssetsPageRoute,
} from '../../Routes/DesignManagementAssetRoutes';
import { serializeQueryParams } from '../../AssetsListPage/QueryString/QueryStringUtils';
import { DesignManagementAssetVersion } from '../../api/Cams/model/Design/DesignManagementAssetVersion';
import { CopyButton, Tooltip } from '@cimpress/react-components';

interface Props {
  design: DesignManagementAsset;
  designVersion: DesignManagementAssetVersion;
}

export const DesignProperties = ({ design, designVersion }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  return (
    <>
      <h3>Design properties</h3>
      <div className="document-properties-list" data-testid="design-management-asset-properties-block">
        {design.designConceptId && (
          <span>
            DesignConceptId:&nbsp;
            <Link
              to={getDesignManagementAssetsPageRoute(
                tenant.contentAuthoringAccountId,
                tenant.contentAuthoringAreaId,
                serializeQueryParams({ filters: { designConceptId: [design.designConceptId] } }),
              )}
              data-testid="design-concept-id"
            >
              {design.designConceptId}
            </Link>
            <Tooltip direction={'right'} contents="Copy Design Concept">
              <CopyButton variant="anchor" size="sm" value={design.designConceptId}>
                <i className="fa">&#xf0c5;</i> {/* the unicode for copy icon */}
              </CopyButton>
            </Tooltip>
          </span>
        )}

        {design.designUseCaseId && <span>DesignUseCaseId: {design.designUseCaseId}</span>}
        {design.designUseCaseName && (
          <span>
            DesignUseCaseName:&nbsp;
            <Link
              to={getDesignManagementAssetsPageRoute(
                tenant.contentAuthoringAccountId,
                tenant.contentAuthoringAreaId,
                serializeQueryParams({ filters: { designUseCaseNames: [design.designUseCaseName] } }),
              )}
              data-testid="design-use-case-name"
            >
              {design.designUseCaseName}
            </Link>
          </span>
        )}
        {design.designPhysicalSpecId && <span>DPSId: {design.designPhysicalSpecId}</span>}
        {design.externalCorrelationId && <span>ExternalCorrelationId: {design.externalCorrelationId}</span>}
        {design.mcpSku && <span>MCP SKU: {design.mcpSku}</span>}
        {design.metadataServiceId && <span>MetadataServiceId: {design.metadataServiceId}</span>}
        {design.productId && <span>Product ID: {design.productId}</span>}
        {design.productName && (
          <span>
            Product Name:&nbsp;
            <Link
              to={getDesignManagementAssetsPageRoute(
                tenant.contentAuthoringAccountId,
                tenant.contentAuthoringAreaId,
                serializeQueryParams({ filters: { productNames: [design.productName] } }),
              )}
              data-testid="design-product-name"
            >
              {design.productName}
            </Link>
          </span>
        )}
        {design.strategist && <span>Strategist: {design.strategist}</span>}
        {design.clonedFromDesignId && designVersion.versionNumber == 1 && (
          <span>
            Cloned from:&nbsp;
            <Link
              to={getDesignManagementAssetDetailsPageRoute(
                tenant.contentAuthoringAccountId,
                tenant.contentAuthoringAreaId,
                design.clonedFromDesignId,
              )}
              target="_blank"
              data-testid="design-cloned-from"
            >
              {design.clonedFromDesignId}
            </Link>
          </span>
        )}
      </div>
    </>
  );
};
