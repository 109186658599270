import { CopyButton, InlineEdit, Tooltip } from '@cimpress/react-components';
import React, { useContext, useEffect, useState } from 'react';
import { CreativeAsset, isCreativeAsset } from '../../api/Cams/model/CreativeAsset';
import { AppContext } from '../../App';
import { ApiErrorToast } from '../../components/ApiErrorToast';
import * as CamsCreativeApi from '../../api/Cams/cams-api-creative';
import * as CamsBackgroundsApi from '../../api/Cams/cams-api-backgrounds';
import * as CamsFontApi from '../../api/Cams/cams-api-fonts';
import * as CamsColorPaletteApi from '../../api/Cams/cams-api-colorPalettes';
import * as CamsClipartApi from '../../api/Cams/cams-api-cliparts';
import './DesignConceptIdEdit.scss';

import { ApiResponseOrError } from '../../api/ApiResponseOrError';
import { isSuccessfulResponse } from '../../Tools/ResponseHelper';
import {
  BackgroundManagementAsset,
  isBackgroundManagementAsset,
} from '../../api/Cams/model/Background/BackgroundManagementAsset';
import {
  FontSchemaManagementAsset,
  isFontSchemaManagementAsset,
} from '../../api/Cams/model/FontSchema/FontSchemaManagementAsset';
import {
  ColorPaletteManagementAsset,
  isColorPaletteManagementAsset,
} from '../../api/Cams/model/ColorPalette/ColorPaletteManagementAsset';
import { ClipartManagementAsset, isClipartManagementAsset } from '../../api/Cams/model/Clipart/ClipartManagementAsset';
import { Link } from 'react-router-dom';
import { getDesignConceptManagementAssetsPageRoute } from '../../Routes/DesignConceptManagementAssetRoutes';
import { serializeQueryParams } from '../../AssetsListPage/QueryString/QueryStringUtils';

type AssetWithDesignConcept =
  | CreativeAsset
  | BackgroundManagementAsset
  | FontSchemaManagementAsset
  | ColorPaletteManagementAsset
  | ClipartManagementAsset;

interface Props {
  asset: AssetWithDesignConcept;
}

export const DesignConceptIdEdit = ({ asset }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [designConceptId, setDesignConceptId] = useState(asset.designConceptId || '');
  const [inlineEditClassName, setInlineEditClassName] = useState('');
  const [saveResponse, setSaveResponse] = useState<ApiResponseOrError<AssetWithDesignConcept>>();

  useEffect(() => {
    setDesignConceptId(asset.designConceptId || '');
  }, [asset]);

  const updateAsset = async (asset: AssetWithDesignConcept, updateCreativeAssetResponse: string) => {
    if (isCreativeAsset(asset)) {
      return await CamsCreativeApi.updateCreativeAsset(asset.id, tenant, {
        designConceptId: updateCreativeAssetResponse,
      });
    } else if (isBackgroundManagementAsset(asset)) {
      return await CamsBackgroundsApi.updateBackgroundAsset(asset.id, tenant, {
        designConceptId: updateCreativeAssetResponse,
      });
    } else if (isFontSchemaManagementAsset(asset)) {
      return await CamsFontApi.updateFontSchemaAsset(asset.id, tenant, {
        designConceptId: updateCreativeAssetResponse,
      });
    } else if (isColorPaletteManagementAsset(asset)) {
      return await CamsColorPaletteApi.updateColorPaletteAsset(asset.id, tenant, {
        designConceptId: updateCreativeAssetResponse,
      });
    } else if (isClipartManagementAsset(asset)) {
      return await CamsClipartApi.updateClipartAsset(asset.id, tenant, {
        designConceptId: updateCreativeAssetResponse,
      });
    } else {
      throw new Error('Unsupported asset kind');
    }
  };

  const onDesignConceptSave = async (originalDesignConceptId?: string, newDesignConceptId?: string) => {
    if (originalDesignConceptId === newDesignConceptId) {
      return;
    }

    const updatedDesignConceptId: string = newDesignConceptId || '';
    const updateCreativeAssetResponse = (await updateAsset(
      asset,
      updatedDesignConceptId,
    )) as ApiResponseOrError<AssetWithDesignConcept>;

    setSaveResponse(updateCreativeAssetResponse);
    if (isSuccessfulResponse(updateCreativeAssetResponse)) {
      setDesignConceptId(updatedDesignConceptId);
      setInlineEditClassName('');
    } else {
      setInlineEditClassName('inline-edit-error');
    }
  };

  const onEdit = () => {
    setInlineEditClassName('');
  };

  return (
    <>
      <div className="design-concept-outer">
        <div className="inline-edit-wrapper">
          <span data-testid="design-concept-id-header">Design Concept Id:&nbsp;</span>
          <InlineEdit
            className={inlineEditClassName}
            placeholder="<empty>"
            name="DesignConceptId"
            value={designConceptId}
            disabled={!tenant.userPermissions.canWrite}
            onSave={(inputTarget) => onDesignConceptSave(designConceptId, inputTarget.value)}
            onEditStateChange={() => onEdit()}
            style={{ minWidth: '10px' }}
          />
          {designConceptId && (
            <Tooltip direction={'right'} contents="Copy Design Concept">
              <CopyButton variant="anchor" size="sm" data-testid="dc-copy-button" value={designConceptId}>
                <i className="fa">&#xf0c5;</i> {/* the unicode for copy icon */}
              </CopyButton>
            </Tooltip>
          )}
        </div>
        {designConceptId && (
          <Link
            to={getDesignConceptManagementAssetsPageRoute(
              tenant.contentAuthoringAccountId,
              tenant.contentAuthoringAreaId,
              serializeQueryParams({ filters: { designConceptId: [designConceptId] } }),
            )}
            data-testid="design-concept-id-link"
          >
            Show all assets for this design concept
          </Link>
        )}
      </div>
      <ApiErrorToast response={saveResponse} />
    </>
  );
};
