import { AxiosResponse, AxiosError } from 'axios';
export type ApiResponseOrError<T> = ApiResponse<T> | ApiError;
import * as Logger from '../Tools/Logging';

export interface ApiResponse<T> {
  responseType: 'success';
  data: T;
  statusCode: number;
}

export interface ApiError {
  responseType: 'error' | 'cancelled';
  content: string;
  statusCode: number;
}

export function createResponse<T>(response: AxiosResponse<T>): ApiResponseOrError<T> {
  if (response.status > 299) {
    return {
      responseType: 'error',
      statusCode: response.status,
      content: JSON.stringify(response.data),
    };
  }

  return {
    responseType: 'success',
    statusCode: response.status,
    data: response.data as T,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createErrorResponse<T>(error: AxiosError, url: URL): ApiResponseOrError<T> {
  const hostname = url.hostname;
  console.log(`Bad response from ${url}`, error);
  if (error.response && error.response.status >= 400 && hostname !== 'cam.contentauthoring.cimpress.io') {
    Logger.error(`Error occured while sending request to ${hostname}`, {
      error: error,
    });
  }
  return {
    responseType: error.code === 'ERR_CANCELED' ? 'cancelled' : 'error',
    statusCode: 500,
    content: `Request to ${hostname} API failed with: '${error?.message ?? error}', see console for details.`,
  };
}
