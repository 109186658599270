import React, { useContext, useState } from 'react';
import { Button, Modal } from '@cimpress/react-components';
import { ApiResponseOrError } from '../api/ApiResponseOrError';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiErrorToast } from './ApiErrorToast';
import { AppContext } from '../App';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { deleteAsset } from '../api/Cams/cams-api';
import { AnyAsset } from '../api/Cams/model/AnyAsset';

interface Props {
  assetId: string;
  name: string;
  assetCategory: AssetCategory;
  onDelete: () => void;
  showButton: boolean;
  disabled?: boolean;
}

export const DeleteAsset = ({ onDelete, assetId, name, assetCategory, showButton, disabled }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState<ApiResponseOrError<AnyAsset>>();

  const closeModal = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      const response = await deleteAsset(tenant, assetId);
      setDeleteResponse(response);

      if (isSuccessfulResponse(response)) {
        setModalIsOpen(false);
        onDelete();
      }
    } else {
      setModalIsOpen(false);
    }
  };

  const kindFriendlyName = assetCategory.format();

  return (
    <>
      {showButton && (
        <>
          <Button
            variant="secondary"
            size="sm"
            style={{ backgroundColor: 'rgb(240, 85, 58)' }}
            onClick={() => setModalIsOpen(true)}
            data-testid="delete-button"
            disabled={disabled}
          >
            Delete this {kindFriendlyName}
          </Button>
          <Modal
            status="danger"
            show={modalIsOpen}
            onRequestHide={() => setModalIsOpen(false)}
            title={`Delete ${name}`}
            closeButton={true}
            footer={
              <>
                <button className="btn btn-default" onClick={() => closeModal(false)}>
                  Cancel
                </button>
                <button data-testid="confirm-delete-button" className="btn btn-danger" onClick={() => closeModal(true)}>
                  Delete
                </button>
              </>
            }
          >
            The {kindFriendlyName} will disappear from the list. Delete?
            <ApiErrorToast response={deleteResponse} />
          </Modal>
        </>
      )}
    </>
  );
};
