import { LocationDescriptorObject } from 'history';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../App';
import { CamNavigationState } from '../DesignPage/Model/CamNavigationState';
import { getDesignConceptsTablePageRoute } from '../Routes/DesignConceptsTableRoute';

export const DesignConceptsTableLink = (): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const buildUrl = (): LocationDescriptorObject<CamNavigationState> => {
    if (tenant === undefined) {
      return {
        pathname: '/',
      };
    }

    const routeString = getRoute();

    return {
      pathname: routeString,
      state: {
        isTitleClicked: true,
      },
    };
  };

  const getRoute = (): string => {
    // get asset category of the currently open page
    return getDesignConceptsTablePageRoute(tenant.contentAuthoringAccountId, tenant.contentAuthoringAreaId);
  };

  const url = buildUrl();
  return (
    <>
      {tenant.settings.features.showDesignConceptsTableLink && (
        <Link to={url} data-testid="design-concepts-table-button">
          {` Design concepts`}
        </Link>
      )}
    </>
  );
};
