import React from 'react';
import { Card, CopyButton } from '@cimpress/react-components';
import { LocalizationUIButton } from '../../components/LocalizationUIButton';
import { TemplatePreview } from '../../components/TemplatePreview';
import { toTemplateToken } from '../../Tools/TemplateToken';
import './AssetCard.scss';

interface Props {
  panelName: string;
  designTemplateId: string;
  templateVersion?: number;
  physicalSpecId: string;
  showLocalizationButton: boolean;
  assetCardStyle?: React.CSSProperties;
}

export const TemplateCard = ({
  panelName,
  designTemplateId,
  templateVersion,
  physicalSpecId,
  showLocalizationButton,
  assetCardStyle,
}: Props): JSX.Element => {
  const templateToken = toTemplateToken(designTemplateId, physicalSpecId, templateVersion);

  return (
    <Card className="asset-card-style" style={assetCardStyle}>
      <div className="asset-preview">
        <TemplatePreview
          showAsLink={true}
          designTemplateId={designTemplateId}
          templateVersion={templateVersion}
          physicalSpecId={physicalSpecId}
        />
      </div>
      <div className="asset-detail">
        <div>
          <div className="asset-additional-info">
            <p className="card-text">
              <span className="asset-additional-info-property">Panel: </span>
              {panelName} {templateVersion && <i>(version {templateVersion})</i>}
              <br />
              <span className="asset-additional-info-property">Template Id:</span>
              <br />
              {designTemplateId}
              <br />
              <span className="asset-additional-info-property">Template Token:</span>
              <br />
              {templateToken}&nbsp;
              <CopyButton variant="anchor" value={templateToken}>
                Copy
              </CopyButton>
            </p>
          </div>
        </div>
        {showLocalizationButton && <LocalizationUIButton designTemplateIds={[designTemplateId]} />}
      </div>
    </Card>
  );
};
