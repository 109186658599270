import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import auth from './auth';
import Loading from './components/Loading';
import PageNotFound from './PageNotFound/PageNotFound';
import { CssLoader } from '@cimpress/react-components';
import * as Logger from './Tools/Logging';
import ErrorBoundary from './components/ErrorBoundary';
import DesignDetailPage from './DesignDetailPage/DesignDetailPage';
import { TenantContext } from './Tenants/TenantContext';
import { TenantContextProvider } from './Tenants/TenantContextProvider';
import * as Routes from './Tools/Routes';
import * as GenericRoutes from './Routes/GenericRoutes';
import * as DesignRoutes from './Routes/DesignManagementAssetRoutes';
import * as CreativeAssetRoutes from './Routes/CreativeAssetRoutes';
import * as PatternRoutes from './Routes/PatternManagementAssetRoutes';
import * as ClipartRoutes from './Routes/ClipartManagementAssetRoutes';
import * as BackgroundRoutes from './Routes/BackgroundManagementAssetRoutes';
import * as FontSchemaRoutes from './Routes/FontSchemaManagementAssetRoutes';
import * as ColorPaletteRoutes from './Routes/ColorPaletteManagementAssetRoutes';
import * as DesignConceptRoutes from './Routes/DesignConceptManagementAssetRoutes';
import { getDesignConceptsTablePageRoute } from './Routes/DesignConceptsTableRoute';
import { History } from 'history';
import { DesignCulturePreviewsPage as DesignCulturePreviewsPage } from './DesignCulturePreviewsPage/DesignCulturePreviewsPage';
import DesignClonePage from './DesignClonePage/DesignClonePage';
import DesignPropertiesPage from './DesignPropertiesPage/DesignPropertiesPage';
import { AssetsListPage } from './AssetsListPage/AssetsListPage';
import * as AssetsContextState from './AssetsContextState';
import * as QueryStringState from './AssetsListPage/QueryString/QueryStringState';
import PatternDetailPage from './PatternDetailPage/PatternDetailPage';
import AssetVersionsPage from './AssetVersionsPage/AssetVersionsPage';
import CreativeAssetDetailPage from './CreativeAssetDetailPage/CreativeAssetDetailPage';
import { AssetCategory } from './AssetsListPage/AssetCategory';
import { RedirectToHomePage } from './components/RedirectToHomePage';
import ConfigurationPage, { ConfigurationTab } from './Configuration/ConfigurationPage';
import ClipartDetailPage from './ClipartDetailPage/ClipartDetailPage';
import { getActiveTenant, getAssetCategory } from './Tools/LocalStorage';
import BackgroundDetailPage from './BackgroundDetailPage/BackgroundDetailPage';
import FontSchemaDetailPage from './FontSchemaDetailPage/FontSchemaDetailPage';
import ColorPaletteDetailPage from './ColorPaletteDetailPage/ColorPaletteDetailPage';
import { DesignConceptAssetsListPage } from './DesignConceptAssetListPage/DesignConceptAssetsListPage';
import { ParamsContextProvider } from './paramsContext/ParamsContextProvider';
import { DesignConceptsTablePage } from './DesignConceptsTablePage/DesignConceptsTablePage';

export const AppContext = TenantContext;

export const AssetsContext = React.createContext<AssetsContextState.AssetsContextState>({});

interface Props {
  history: History<any>;
}

const App = ({ history }: Props): JSX.Element => {
  const [authenticating, setAuthenticating] = useState(false);
  const [authenticationError, setAuthenticationError] = useState({} as Error);
  const tenantStorage = getActiveTenant();
  const assetCategoryStorage = getAssetCategory();
  const isLoggedIn = auth.isLoggedIn();

  const onSetAssetCategory = (assetKind: AssetCategory): void => {
    if (assetKind === designsContextState.previouslyViewedAssetKind) {
      return;
    }

    setDesignsContextState({ ...designsContextState, previouslyViewedAssetKind: assetKind });
  };

  const onSetQueryState = (queryState: QueryStringState.QueryStringState): void => {
    if (queryState === designsContextState.queryStringState) {
      return;
    }

    setDesignsContextState({ ...designsContextState, queryStringState: queryState });
  };

  const [designsContextState, setDesignsContextState] = useState<AssetsContextState.AssetsContextState>({});

  const ensureLoggedIn = () => {
    if (!isLoggedIn) {
      setAuthenticating(true);
      auth
        .ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
        .then((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            setAuthenticating(false);
            auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));
            Logger.info('CAM application started. User has been authenticated');
          } else {
            /**
             * If `isAuthenticated` is false, the library takes care of
             * redirecting to the login page, so we need not do anything
             * on our side.
             * The only case when `isAuthenticated` is false when you land
             * on the page and are either logged out or not authenticated.
             */
            Logger.info('User was not authenticated, trying to initiate authentication...');
          }
        })
        .catch((error: Error) => {
          Logger.error('Could not authenticate', error);
          // eslint-disable-next-line no-console
          console.log(error);
          setAuthenticating(false);
          setAuthenticationError(error);
        });
    }
  };

  /**
   * When you're logged out, it could trigger `ensureAuthentication` twice
   * And hence we need `isLoggedIn` in the dependency array
   */
  useEffect(ensureLoggedIn, [isLoggedIn]);

  useEffect(() => {
    const onUnload = Logger.flushLogs;

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  return (
    <div>
      {authenticating ? (
        <Loading />
      ) : isLoggedIn ? (
        <Router history={history}>
          <CssLoader>
            <Switch>
              <Route path="/">
                <TenantContextProvider>
                  <AssetsContext.Provider value={designsContextState}>
                    <ErrorBoundary>
                      <Switch>
                        <Route exact path="(index.html)?">
                          <Redirect
                            to={GenericRoutes.getVariableAssetPageRoute(
                              tenantStorage?.contentAuthoringAccountId,
                              tenantStorage?.contentAuthoringAreaId,
                              assetCategoryStorage,
                            )}
                          />
                        </Route>
                        <Route exact path="/designs">
                          <Redirect
                            to={DesignRoutes.getDesignManagementAssetsPageRoute(
                              tenantStorage?.contentAuthoringAccountId,
                              tenantStorage?.contentAuthoringAreaId,
                            )}
                          />
                        </Route>
                        <Route exact path="/patterns">
                          <Redirect
                            to={PatternRoutes.getPatternManagementAssetsPageRoute(
                              tenantStorage?.contentAuthoringAccountId,
                              tenantStorage?.contentAuthoringAreaId,
                            )}
                          />
                        </Route>
                        <Route exact path="/fonts">
                          <Redirect
                            to={FontSchemaRoutes.getFontSchemaManagementAssetsPageRoute(
                              tenantStorage?.contentAuthoringAccountId,
                              tenantStorage?.contentAuthoringAreaId,
                            )}
                          />
                        </Route>
                        <Route exact path="/colors">
                          <Redirect
                            to={ColorPaletteRoutes.getColorPaletteManagementAssetsPageRoute(
                              tenantStorage?.contentAuthoringAccountId,
                              tenantStorage?.contentAuthoringAreaId,
                            )}
                          />
                        </Route>
                        <Route exact path="/cliparts">
                          <Redirect
                            to={ClipartRoutes.getClipartManagementAssetsPageRoute(
                              tenantStorage?.contentAuthoringAccountId,
                              tenantStorage?.contentAuthoringAreaId,
                            )}
                          />
                        </Route>
                        <Route exact path="/creative">
                          <Redirect
                            to={CreativeAssetRoutes.getCreativeAssetsPageRoute(
                              tenantStorage?.contentAuthoringAccountId,
                              tenantStorage?.contentAuthoringAreaId,
                            )}
                          />
                        </Route>
                        <Route path={Routes.tenantRoutePrefix}>
                          <Switch>
                            <Route exact path={PatternRoutes.getPatternManagementAssetsPageRoute()}>
                              <ParamsContextProvider>
                                <AssetsListPage
                                  setPreviouslyViewedContentType={onSetAssetCategory}
                                  setQueryStringState={onSetQueryState}
                                />
                              </ParamsContextProvider>
                            </Route>
                            <Route exact path={PatternRoutes.getPatternDetailsPageRoute()}>
                              <PatternDetailPage />
                            </Route>

                            <Route exact path={PatternRoutes.getPatternVersionsPageRoute()}>
                              <AssetVersionsPage />
                            </Route>

                            <Route exact path={PatternRoutes.getPatternDetailsForSpecificVersionPageRoute()}>
                              <PatternDetailPage />
                            </Route>

                            <Route exact path={CreativeAssetRoutes.getCreativeVersionsPageRoute()}>
                              <AssetVersionsPage />
                            </Route>

                            <Route exact path={CreativeAssetRoutes.getCreativeAssetsPageRoute()}>
                              <ParamsContextProvider>
                                <AssetsListPage
                                  setPreviouslyViewedContentType={onSetAssetCategory}
                                  setQueryStringState={onSetQueryState}
                                />
                              </ParamsContextProvider>
                            </Route>

                            <Route exact path={CreativeAssetRoutes.getCreativeAssetDetailsPageRoute()}>
                              <CreativeAssetDetailPage />
                            </Route>

                            <Route exact path={DesignRoutes.getDesignManagementAssetDetailsPageRoute()}>
                              <DesignDetailPage />
                            </Route>

                            <Route exact path={DesignRoutes.getDesignManagementAssetsPageRoute()}>
                              <ParamsContextProvider>
                                <AssetsListPage
                                  setPreviouslyViewedContentType={onSetAssetCategory}
                                  setQueryStringState={onSetQueryState}
                                />
                              </ParamsContextProvider>
                            </Route>

                            <Route exact path={DesignRoutes.getEditDesignManagementAssetPropertiesPageRoute()}>
                              <DesignPropertiesPage />
                            </Route>

                            <Route exact path={DesignRoutes.getDesignManagementAssetVersionsPageRoute()}>
                              <AssetVersionsPage />
                            </Route>

                            <Route
                              exact
                              path={DesignRoutes.getDesignManagementAssetDetailsForSpecificVersionPageRoute()}
                            >
                              <DesignDetailPage />
                            </Route>

                            <Route exact path={DesignRoutes.getDesignManagementAssetCulturePreviewsPageRoute()}>
                              <DesignCulturePreviewsPage />
                            </Route>

                            <Route exact path={DesignRoutes.getDesignManagementAssetClonePageRoute()}>
                              <DesignClonePage />
                            </Route>

                            <Route exact path={ClipartRoutes.getClipartManagementAssetsPageRoute()}>
                              <ParamsContextProvider>
                                <AssetsListPage
                                  setPreviouslyViewedContentType={onSetAssetCategory}
                                  setQueryStringState={onSetQueryState}
                                />
                              </ParamsContextProvider>
                            </Route>

                            <Route exact path={BackgroundRoutes.getBackgroundManagementAssetsPageRoute()}>
                              <ParamsContextProvider>
                                <AssetsListPage
                                  setPreviouslyViewedContentType={onSetAssetCategory}
                                  setQueryStringState={onSetQueryState}
                                />
                              </ParamsContextProvider>
                            </Route>

                            <Route exact path={ClipartRoutes.getClipartDetailsPageRoute()}>
                              <ClipartDetailPage />
                            </Route>

                            <Route exact path={ClipartRoutes.getClipartVersionsPageRoute()}>
                              <AssetVersionsPage />
                            </Route>

                            <Route exact path={BackgroundRoutes.getBackgroundDetailsPageRoute()}>
                              <BackgroundDetailPage />
                            </Route>

                            <Route exact path={BackgroundRoutes.getBackgroundVersionsPageRoute()}>
                              <AssetVersionsPage />
                            </Route>

                            <Route exact path={ClipartRoutes.getClipartDetailsForSpecificVersionPageRoute()}>
                              <ClipartDetailPage />
                            </Route>

                            <Route exact path={BackgroundRoutes.getBackgroundDetailsForSpecificVersionPageRoute()}>
                              <BackgroundDetailPage />
                            </Route>

                            <Route exact path={GenericRoutes.getConfigurationPageRoute()}>
                              <ConfigurationPage setPreviouslyViewedContentType={onSetAssetCategory} />
                            </Route>

                            <Route exact path={GenericRoutes.getCategoryLocalizationRoute()}>
                              <ConfigurationPage
                                tab={ConfigurationTab.CategoryLocalization}
                                setPreviouslyViewedContentType={onSetAssetCategory}
                              />
                            </Route>

                            <Route exact path={FontSchemaRoutes.getFontSchemaManagementAssetsPageRoute()}>
                              <ParamsContextProvider>
                                <AssetsListPage
                                  setPreviouslyViewedContentType={onSetAssetCategory}
                                  setQueryStringState={onSetQueryState}
                                />
                              </ParamsContextProvider>
                            </Route>

                            <Route exact path={FontSchemaRoutes.getFontSchemaDetailsPageRoute()}>
                              <FontSchemaDetailPage />
                            </Route>

                            <Route exact path={FontSchemaRoutes.getFontSchemaVersionsPageRoute()}>
                              <AssetVersionsPage />
                            </Route>

                            <Route exact path={FontSchemaRoutes.getFontSchemaDetailsForSpecificVersionPageRoute()}>
                              <FontSchemaDetailPage />
                            </Route>

                            <Route exact path={ColorPaletteRoutes.getColorPaletteManagementAssetsPageRoute()}>
                              <ParamsContextProvider>
                                <AssetsListPage
                                  setPreviouslyViewedContentType={onSetAssetCategory}
                                  setQueryStringState={onSetQueryState}
                                />
                              </ParamsContextProvider>
                            </Route>

                            <Route exact path={DesignConceptRoutes.getDesignConceptManagementAssetsPageRoute()}>
                              <DesignConceptAssetsListPage />
                            </Route>

                            <Route exact path={getDesignConceptsTablePageRoute()}>
                              <ParamsContextProvider>
                                <DesignConceptsTablePage setQueryStringState={onSetQueryState} />
                              </ParamsContextProvider>
                            </Route>

                            <Route exact path={ColorPaletteRoutes.getColorPaletteDetailsPageRoute()}>
                              <ColorPaletteDetailPage />
                            </Route>

                            <Route exact path={GenericRoutes.getVariableAssetVersionsPageRoute()}>
                              <AssetVersionsPage />
                            </Route>

                            <Route exact path={ColorPaletteRoutes.getColorPaletteDetailsForSpecificVersionPageRoute()}>
                              <ColorPaletteDetailPage />
                            </Route>

                            <Route exact path="*">
                              <RedirectToHomePage />
                            </Route>
                          </Switch>
                        </Route>
                        <Route>
                          <PageNotFound />
                        </Route>
                      </Switch>
                    </ErrorBoundary>
                  </AssetsContext.Provider>
                </TenantContextProvider>
              </Route>
            </Switch>
          </CssLoader>
        </Router>
      ) : authenticationError ? (
        <div>Unexpected error encountered. {authenticationError.message}</div>
      ) : null}
    </div>
  );
};

export default App;
