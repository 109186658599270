import React, { useContext, useEffect, useRef, useState } from 'react';
import Loading from '../components/Loading';
import './DesignConceptAssetsListPage.scss';
import './DesignConceptTilesView.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import * as CamsApi from '../api/Cams/cams-api';
import { TenantDescription } from '../api/model/TenantDescription';
import { DesignConceptAssetsList } from './DesignConceptAssetsList';
import { AssetSearch } from '../AssetsListPage/Components/AssetSearch';
import { AppContext } from '../App';
import { defaultPageNumber, defaultPageSize } from '../AssetsListPage/Model/Constants';
import { SearchTerm } from '../AssetsListPage/Model/SearchTerm';
import { ComposedSampleList } from './ComposedSampleList';
import { AnyManagementAsset } from '../api/Cams/model/AnyManagementAsset';

export const DesignConceptAssetsListPage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { tenant } = useContext(AppContext);

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(history.location.search);
  const [designConceptId, setDesignConceptId] = useState<string>(searchParams.get('designConceptId') || '');

  const [assets, setAssets] = useState<AnyManagementAsset[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const controller = useRef<AbortController | null>(null);

  const loadData = async (
    tenant: TenantDescription,
    pageNumber: number,
    pageSize: number,
    designConceptId?: string,
  ) => {
    setLoading(true);
    setTotalCount(0);
    try {
      if (controller !== null) {
        controller.current?.abort();
      }

      controller.current = new AbortController();

      if (designConceptId) {
        const result = await CamsApi.getAssets(
          tenant,
          pageSize,
          pageNumber,
          ['background', 'colorPalette', 'fontSchema', 'clipart'],
          'management',
          {
            createdBy: [],
            tags: [],
            collections: [],
            designConceptId: [designConceptId],
            published: [],
            productNames: [],
            designUseCaseNames: [],
            accessibleTo: [],
          },
          undefined,
          controller.current,
        );

        if (isSuccessfulResponse(result)) {
          setTotalCount(result.data.totalCount);
          const assets = result.data.data as AnyManagementAsset[];
          assets.sort((a, b) => (a.assetKind || '').localeCompare(b.assetKind || ''));
          setAssets(assets);
          setLoading(false);
        } else if (result.responseType !== 'cancelled') {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setTotalCount(0);
        setAssets([]);
      }
    } catch {
      setLoading(false);
    }
  };

  const setQueryStringDesignConceptId = (designConceptId: string | undefined) => {
    history.push({
      pathname: history.location.pathname,
      search: designConceptId ? `?designConceptId=${designConceptId}` : '',
      state: {
        designConceptId: designConceptId,
      },
    });
  };

  const onDesignConceptIdChangedFromInput = (searchTerm: SearchTerm): void => {
    onDesignConceptIdChanged(searchTerm.term || '', true);
  };

  const onDesignConceptIdChangedFromQueryString = (newDesignConceptId: string): void => {
    onDesignConceptIdChanged(newDesignConceptId, false);
  };

  const onDesignConceptIdChanged = (newDesignConceptId: string, updateQueryString: boolean): void => {
    if (designConceptId !== newDesignConceptId) {
      setDesignConceptId(newDesignConceptId);
      if (updateQueryString) {
        setQueryStringDesignConceptId(newDesignConceptId);
      }
      loadData(tenant, defaultPageNumber, defaultPageSize, newDesignConceptId);
    }
  };

  useEffect(() => {
    loadData(tenant, defaultPageNumber, defaultPageSize, designConceptId);
    history.replace({
      ...location,
      state: {
        designConceptId: designConceptId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state && 'designConceptId' in (location.state as any)) {
      onDesignConceptIdChangedFromQueryString((location.state as any)?.designConceptId || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="container-fluid container-full-width assets-list-page">
      <div className="row cams-page-title">
        <div className="col-sm-12" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <AssetSearch
              assetsNewSearch={onDesignConceptIdChangedFromInput}
              search={{ term: designConceptId || '', exact: false }}
              allowExactSearch={false}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {loading && <Loading />}

          {!loading && assets.length === 0 && !designConceptId && <div className="cams-no-items">No assets found</div>}

          {!loading && designConceptId && (
            <>
              <DesignConceptAssetsList assets={assets} designConceptId={designConceptId} />
              <div className="assets-page-bar">
                {!loading && (
                  <span>
                    <span data-testid="total-count">{totalCount}</span> asset(s) found
                  </span>
                )}
              </div>
              <ComposedSampleList assets={assets} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
