import React, { useContext } from 'react';
import { Column, SortingRule } from 'react-table';
import { ReactTablev6 } from '@cimpress/react-components';
import { Link } from 'react-router-dom';
import { AppContext } from '../App';
import { BackgroundManagementAsset } from '../api/Cams/model/Background/BackgroundManagementAsset';
import { serializeQueryParams } from '../AssetsListPage/QueryString/QueryStringUtils';
import { getDesignConceptManagementAssetsPageRoute } from '../Routes/DesignConceptManagementAssetRoutes';
import Loading from '../components/Loading';
import { DesignConceptAssetTile } from '../DesignConceptAssetListPage/DesignConceptAssetTile';
import { MissingDesignConceptAssetTile } from '../DesignConceptAssetListPage/MissingDesignConceptAssetTile';

interface Props {
  data: any[];
  isLoading: boolean;
  onSortChange: (rule: SortingRule[]) => void;
  sortingRule: SortingRule;
}

export const DesignConceptsTable = ({ data, isLoading, onSortChange, sortingRule }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const columnDefaultStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px',
  };

  const columns = [
    {
      Header: 'Design Concept ID',
      accessor: 'designConceptId',
      style: columnDefaultStyle,
      // eslint-disable-next-line
      Cell: (tableProps) => (
        <Link
          to={getDesignConceptManagementAssetsPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            serializeQueryParams({ filters: { designConceptId: [tableProps.original.designConceptId] } }),
          )}
        >
          {tableProps.original.designConceptId}
        </Link>
      ),
    },
    {
      Header: 'Asset Count',
      accessor: 'assetCount',
      style: columnDefaultStyle,
    },
    {
      Header: 'Background',
      accessor: 'background',
      // eslint-disable-next-line react/display-name
      Cell: (tableProps) =>
        tableProps.original.background.length > 0 ? (
          tableProps.original.background.map((background: BackgroundManagementAsset) => (
            // eslint-disable-next-line
              <DesignConceptAssetTile
              asset={background}
              assetCategory={'background'}
              showCategoryLabel={false}
              showInCard={false}
            />
          ))
        ) : (
          <MissingDesignConceptAssetTile
            assetCategory={'background'}
            designConceptId={tableProps.original.designConceptId}
            showCategoryLabel={false}
            showInCard={false}
          />
        ),
      style: columnDefaultStyle,

      sortable: false,
    },
    {
      Header: 'Clipart',
      accessor: 'clipart',
      // eslint-disable-next-line
      Cell: (tableProps) =>
        tableProps.original.clipart ? (
          <DesignConceptAssetTile
            asset={tableProps.original.clipart}
            assetCategory={'clipart'}
            showCategoryLabel={false}
            showInCard={false}
          />
        ) : (
          <MissingDesignConceptAssetTile
            assetCategory={'clipart'}
            designConceptId={tableProps.original.designConceptId}
            showCategoryLabel={false}
            showInCard={false}
          />
        ),
      sortable: false,
      style: columnDefaultStyle,
    },
    {
      Header: 'Color Palette',
      accessor: 'colorPalette',
      // eslint-disable-next-line
      Cell: (tableProps) =>
        tableProps.original.colorPalette ? (
          <DesignConceptAssetTile
            asset={tableProps.original.colorPalette}
            assetCategory={'color palette'}
            showCategoryLabel={false}
            showInCard={false}
          />
        ) : (
          <MissingDesignConceptAssetTile
            assetCategory={'color palette'}
            designConceptId={tableProps.original.designConceptId}
            showCategoryLabel={false}
            showInCard={false}
          />
        ),
      sortable: false,
      style: columnDefaultStyle,
    },
    {
      Header: 'Font Schema',
      accessor: 'fontSchema',
      // eslint-disable-next-line
      Cell: (tableProps) =>
        tableProps.original.fontSchema ? (
          <DesignConceptAssetTile
            asset={tableProps.original.fontSchema}
            assetCategory={'font schema'}
            showCategoryLabel={false}
            showInCard={false}
          />
        ) : (
          <MissingDesignConceptAssetTile
            assetCategory={'font schema'}
            designConceptId={tableProps.original.designConceptId}
            showCategoryLabel={false}
            showInCard={false}
          />
        ),
      sortable: false,
      style: columnDefaultStyle,
    },
  ] as Column[];

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <ReactTablev6
          sorted={[sortingRule]}
          data={data}
          columns={columns}
          showPagination={false}
          pageSize={data.length}
          minRows={0}
          manual={true}
          onSortedChange={onSortChange}
          noDataText="No items found"
        ></ReactTablev6>
      )}
    </>
  );
};
