import { Button, shapes } from '@cimpress/react-components';
import React from 'react';

interface Props {
  isFormChanged: boolean;
  isInProgress: boolean;
  initialLabel: string;
  inProgressLabel: string;
  onUpload: () => void;
}

export const NonFileUploadButtonControl = ({
  isFormChanged,
  isInProgress,
  initialLabel,
  inProgressLabel,
  onUpload,
}: Props): JSX.Element => {
  return (
    <Button
      disabled={!isFormChanged || isInProgress}
      onClick={() => onUpload()}
      variant="primary"
      data-testid="non-file-upload-button"
    >
      <div className="flexbox-container">
        {isInProgress && <shapes.Spinner size="small" className="spinner" />}
        {isInProgress ? `${inProgressLabel}` : `${initialLabel}`}
      </div>
    </Button>
  );
};
