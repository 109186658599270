export const deepEqual = <T>(itemA: T, itemB: T): boolean => {
  const areObjects = isObject(itemA) && isObject(itemB);
  if (!areObjects) {
    return itemA === itemB;
  }

  const areArrays = Array.isArray(itemA) && Array.isArray(itemB);
  if (areArrays) {
    const arrayA = itemA as unknown as any[];
    const arrayB = itemB as unknown as any[];
    if (arrayA.length !== arrayB.length) {
      return false;
    }
    for (let index = 0; index < arrayA.length; index++) {
      const elementA = arrayA[index];
      const elementB = arrayB[index];
      if (elementA !== elementB) {
        return false;
      }
    }
    return true;
  }

  const keys = Object.keys(itemA);
  for (const key of keys) {
    const val1 = (itemA as any)[key];
    const val2 = (itemB as any)[key];
    if (!deepEqual(val1, val2)) {
      return false;
    }
  }
  return true;
};

const isObject = (object: any): boolean => {
  return object != null && typeof object === 'object';
};
