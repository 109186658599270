import React, { useEffect, useState, useContext } from 'react';
import { Modal, Toggle } from '@cimpress/react-components';
import { Tenant } from '../api/model/Tenant';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { ApiErrorToast } from './ApiErrorToast';
import { AppContext } from '../App';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import './PublishedToggle.scss';

interface Props {
  publishedInit: boolean;
  showConfirmation: boolean;
  enabledInit?: boolean;
  updateMethod: (tenant: Tenant, published: boolean) => Promise<ApiResponseOrError<any>>;
  getterFromResponse: (response: ApiResponse<any>) => boolean;
}

export const PublishedToggle = ({
  publishedInit,
  showConfirmation,
  enabledInit = true,
  updateMethod,
  getterFromResponse,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [published, setPublished] = useState(publishedInit);
  const [enabled, setEnabled] = useState(enabledInit);
  const [saveResponse, setSaveResponse] = useState<ApiResponseOrError<any>>();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    setEnabled(enabledInit);
  }, [enabledInit]);

  useEffect(() => {
    setPublished(publishedInit);
  }, [publishedInit]);

  const update = async (newValue: boolean) => {
    const response = (await updateMethod(tenant, newValue)) as ApiResponse<any>;

    setSaveResponse(response);

    if (isSuccessfulResponse(response)) {
      return getterFromResponse(response);
    }

    return undefined;
  };

  const toggle = async () => {
    const returnedVal = await update(!published);
    if (returnedVal !== undefined) {
      setPublished(returnedVal);
    }
  };

  const closeModal = async (isConfirmed: boolean) => {
    setModalIsOpen(false);

    if (isConfirmed) {
      toggle();
    }
  };

  const onToggleClick = async () => {
    if (showConfirmation) {
      setModalIsOpen(true);
    } else {
      toggle();
    }
  };

  return (
    <>
      <div className="publishedToggle">
        <Toggle
          data-testid="publish-switch"
          onText="Published"
          offText="Unpublished"
          on={published}
          onClick={onToggleClick}
          disabled={!enabled}
        />
      </div>
      <ApiErrorToast response={saveResponse} />
      <Modal
        status="warning"
        show={modalIsOpen}
        onRequestHide={() => setModalIsOpen(false)}
        title={`Publish this version?`}
        closeButton={true}
        footer={
          <>
            <button className="btn btn-default" onClick={() => closeModal(false)}>
              Cancel
            </button>
            <button className="btn btn-warning" onClick={() => closeModal(true)}>
              Publish
            </button>
          </>
        }
      >
        Publishing this version will automatically unpublish the currently published version.
      </Modal>
    </>
  );
};
