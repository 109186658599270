const TagStringSeparator = ';';

const deduplicateTrimTags = (tags: string[] | undefined): string[] => {
  if (tags === undefined || tags.length === 0) {
    return [];
  }

  return tags
    .filter((w) => w) // filter out empty entries
    .map((tag) => tag.trim())
    .filter((tag, index, tagArray) => tagArray.indexOf(tag) === index);
};

export const splitBySemicolon = (tagString: string | undefined): string[] => {
  if (tagString === undefined) {
    return [];
  }

  const resultTags = tagString
    .trim()
    .replace(/;+/g, ';') // replace multiples of ';' with a single ';'
    .replace(/  +/g, ' ') // replace multiples of ' ' with a single ' '
    .split(TagStringSeparator);
  return deduplicateTrimTags(resultTags);
};
