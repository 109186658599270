import React from 'react';
import { Button, Dropdown } from '@cimpress/react-components';
import * as LocalStorage from '../../Tools/LocalStorage';
import './AssetListViewSwitch.scss';
import { DesignsListViewType } from '../../DesignPage/DesignsListViewType';

interface Props {
  listViewType: DesignsListViewType;
  onListViewChange: (listViewType: DesignsListViewType) => void;
}

export const AssetListViewSwitch = ({ listViewType, onListViewChange }: Props): JSX.Element => {
  const getButtonVariant = (buttonType: DesignsListViewType) => {
    return buttonType === listViewType ? 'primary' : 'default';
  };

  const onButtonClick = (viewType: DesignsListViewType) => {
    LocalStorage.saveDesignsViewType(viewType);
    onListViewChange(viewType);
  };

  const buttons: { type: DesignsListViewType; caption: string; icon: string }[] = [
    { type: 'LargeTiles', caption: 'Large Tiles', icon: 'fa-th-large' },
    { type: 'Tiles', caption: 'Tiles', icon: 'fa-th' },
    { type: 'Table', caption: 'Table', icon: 'fa-align-justify' },
  ];

  const getCurrentButton = () => {
    return buttons.find((b) => b.type === listViewType);
  };

  return (
    <div className="list-view-switch">
      <Dropdown title={'View: ' + getCurrentButton()?.caption}>
        {buttons.map((button) => {
          return (
            <Button
              key={button.type}
              variant={getButtonVariant(button.type)}
              onClick={() => onButtonClick(button.type)}
            >
              <i className={`fa button-icon ` + button.icon} />
              <span>{button.caption}</span>
            </Button>
          );
        })}
      </Dropdown>
    </div>
  );
};
