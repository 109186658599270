import { AssetKind } from '../api/Cams/model/AssetKind';
import { CategoryLocalizationData } from '../CategoryLocalization/CategoryLocalization';
import { MissingLocalizationRecord } from './MissingLocalizationRecord';

export const getMissingLocalizations = (data?: CategoryLocalizationData): MissingLocalizationRecord[] => {
  if (data === undefined) {
    return [];
  }
  const missingLocalizations = Object.keys(data.localizations).flatMap((assetKind) => {
    const localizationByCategory = data.localizations[assetKind];
    const missingCategoryLocalizations = Object.keys(localizationByCategory).flatMap((localizationKey) => {
      const localizationByLocale = localizationByCategory[localizationKey];
      const missingLocalizations = data.locales
        .filter((locale) => {
          return localizationByLocale[locale] === undefined;
        })
        .map((locale) => {
          const record: MissingLocalizationRecord = {
            assetKind,
            localizationKey,
            locale,
          };
          return record;
        });
      return missingLocalizations;
    });
    return missingCategoryLocalizations;
  });
  return missingLocalizations;
};

export const getUnlocalizedCategories = (
  assetKind: AssetKind,
  categories: string[],
  categoryLocalizationData?: CategoryLocalizationData,
): string[] => {
  const tenantMissingLocalizations = getMissingLocalizations(categoryLocalizationData);
  const unlocalizedCategories = tenantMissingLocalizations
    .filter((l) => categories.some((c) => c === l.localizationKey))
    .filter((l) => l.assetKind === assetKind)
    .map((l) => l.localizationKey);
  const uniqueUnlocalizedCategories = [...new Set(unlocalizedCategories)];
  return uniqueUnlocalizedCategories;
};

export const getCategoryListMessage = (categories: string[], maxCategoriesInMessage?: number): string => {
  const count = maxCategoriesInMessage ?? 3;
  const categoryListMessage =
    categories.length > count
      ? `${categories.slice(0, count).join(', ')} and ${categories.length - count} more`
      : categories.join(', ');
  return categoryListMessage;
};

export function arrayEqual(a1: any[], a2: any[]): boolean {
  if (a1.length !== a2.length) return false;
  for (let i = 0; i < a1.length; i++) {
    if (a1[i] !== a2[i]) {
      return false;
    }
  }
  return true;
}
