import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { doGetRequest } from '../RequestUtils';

const localizationApiHost = config.localizationApiUrl;

export async function getCultureCoverage(localizationKeys: string[]): Promise<ApiResponseOrError<string[]>> {
  const url = new URL(`${localizationApiHost}/api/v2/localizations/cultureCoverage`);
  for (const key of localizationKeys) {
    url.searchParams.append('id', key);
  }

  return await doGetRequest<string[]>(url);
}
