import React from 'react';
import { Link } from 'react-router-dom';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { Tenant } from '../api/model/Tenant';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { getVariableAssetDetailPageRoute, getVariableAssetVersionsPageRoute } from '../Routes/GenericRoutes';

interface Props {
  assetVersion: AnyAssetVersion;
  tenant: Tenant;
  assetCategory: AssetCategory;
  isLatestVersion: boolean;
}

export const AssetVersion = ({ assetVersion, tenant, assetCategory, isLatestVersion }: Props): JSX.Element => {
  return (
    <>
      <p>
        Version: {assetVersion.versionNumber}&nbsp;
        <Link
          className="simple-link see-history-link"
          to={getVariableAssetVersionsPageRoute(
            assetVersion.assetId,
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            assetCategory,
          )}
        >
          see history
        </Link>
      </p>
      {!isLatestVersion && (
        <p>
          <span style={{ color: 'red' }}>This is not the latest version.&nbsp;</span>
          <Link
            to={getVariableAssetDetailPageRoute(
              tenant.contentAuthoringAccountId,
              tenant.contentAuthoringAreaId,
              assetCategory,
              assetVersion.assetId,
            )}
            className="simple-link"
          >
            Show the latest version
          </Link>
        </p>
      )}
    </>
  );
};
