import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { doGetRequest } from '../RequestUtils';

const dtsApiHost = config.dtsApiUrl;

export async function getLocalizationKeys(templateId: string, version?: string): Promise<ApiResponseOrError<string[]>> {
  const url = new URL(`${dtsApiHost}/api/v3/designs/${templateId}/versions/${version}/localizationKeys`);

  return await doGetRequest<string[]>(url);
}
