import config from '../../config';

const dterApiHost = config.dterApiUrl;
export const getRenderDocumentUrl = (
  templateToken: string,
  culture: string,
  backgroundColor?: string,
  useAdditionalQueryStringParameter?: boolean,
): string => {
  const url = new URL(`${dterApiHost}/api/v2/templates/${templateToken}/cultures/${culture}/renderDocument`);

  if (backgroundColor !== undefined) {
    url.searchParams.append('backgroundColor', backgroundColor);
  }

  if (useAdditionalQueryStringParameter === true) {
    url.searchParams.append('camLocalization', 'true');
  }

  return url.toString();
};
