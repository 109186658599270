import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import { FontSchemaAssetRequestBody } from './model/FontSchema/FontSchemaAssetRequestBody';
import { FontSchemaAssetVersionRequestBody } from './model/FontSchema/FontSchemaAssetVersionRequestBody';
import { FontSchemaManagementAsset } from './model/FontSchema/FontSchemaManagementAsset';
import { FontSchemaManagementAssetVersion } from './model/FontSchema/FontSchemaManagementAssetVersion';
import { UpdateFontSchemaAssetRequestBody } from './model/FontSchema/UpdateFontSchemaAssetRequestBody';
import * as RequestUtils from '../RequestUtils';
const camsApiHost = config.camsApiUrl;

export async function createFontSchemaAsset(
  tenant: Tenant,
  requestBody: FontSchemaAssetRequestBody,
): Promise<ApiResponseOrError<FontSchemaManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/fonts`,
  );
  const config = RequestUtils.axiosDefaultConfig();

  return await RequestUtils.doPostRequest<FontSchemaManagementAsset>(url, requestBody, config);
}

export async function createFontSchemaAssetVersion(
  tenant: Tenant,
  assetId: string,
  requestBody: FontSchemaAssetRequestBody,
): Promise<ApiResponseOrError<FontSchemaManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/fonts/${assetId}/versions`,
  );
  const config = RequestUtils.axiosDefaultConfig();
  return await RequestUtils.doPostRequest<FontSchemaManagementAsset>(url, requestBody, config);
}

export async function updateFontSchemaAsset(
  id: string,
  tenant: Tenant,
  requestBody: UpdateFontSchemaAssetRequestBody,
): Promise<ApiResponseOrError<FontSchemaManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/fonts/${id}`,
  );

  return await RequestUtils.doPatchRequest<FontSchemaManagementAsset>(url, requestBody);
}

export async function updateFontSchemaVersionAsset(
  id: string,
  version: number,
  tenant: Tenant,
  requestBody: FontSchemaAssetVersionRequestBody,
): Promise<ApiResponseOrError<FontSchemaManagementAssetVersion>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/fonts/${id}/versions/${version}:publish`,
  );

  return await RequestUtils.doPatchRequest<FontSchemaManagementAssetVersion>(url, requestBody);
}
