import { Asset } from '../Asset';
import { BackgroundManagementAssetVersion } from './BackgroundManagementAssetVersion';

export interface BackgroundManagementAsset extends Asset<BackgroundManagementAssetVersion> {
  assetKind: 'background';
  assetType: 'management';
  isDeletable: boolean;
  designConceptId?: string;
  discoverableVersionNumber?: number;
}

export const isBackgroundManagementAsset = (x: Asset<any>): x is BackgroundManagementAsset => {
  return x.assetType === 'management' && x.assetKind === 'background';
};
