import React from 'react';
import { CopyButton, Tooltip } from '@cimpress/react-components';
import { CMYK } from 'color-convert/conversions';
import { cmykToRgbCss, formatRgbArrayToCss } from '../../Tools/ColorHelpers';

interface Props {
  rgbArray?: number[];
  cmykArray: number[];
}

export const MonolithComboColorSwatch = ({ rgbArray, cmykArray }: Props): JSX.Element => {
  const cmyk: CMYK | undefined =
    cmykArray.length === 4
      ? [cmykArray[0] * 100, cmykArray[1] * 100, cmykArray[2] * 100, cmykArray[3] * 100]
      : undefined;

  const rgbCss = rgbArray && rgbArray.length === 3 ? formatRgbArrayToCss(rgbArray) : cmyk ? cmykToRgbCss(cmyk) : '';

  const stringifiedCmyk = JSON.stringify(cmyk);

  return (
    <Tooltip direction={'top'} contents={stringifiedCmyk}>
      <CopyButton
        variant="primary"
        size="sm"
        className="combo-color-swatch"
        value={stringifiedCmyk}
        successMessage={`Copied ${stringifiedCmyk} to clipboard.`}
        style={{
          backgroundColor: rgbCss,
        }}
      >
        {' '}
      </CopyButton>
    </Tooltip>
  );
};
