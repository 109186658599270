import React, { useEffect, useState } from 'react';
import { Button } from '@cimpress/react-components';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { FileToUpload } from './FileToUpload';

interface Props {
  upload: FileToUpload;
  onPreviewAdded: (uploadWithPreview: FileToUpload) => void;
  disabled: boolean;
}

export const PreviewUploadControl = ({ upload, onPreviewAdded, disabled }: Props): JSX.Element => {
  const [filePreviewUrl, setFilePreviewUrl] = useState<string>();
  const [canPreview, setCanPreview] = useState(false);

  const readPreviewImage = (blob: Blob) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setFilePreviewUrl(reader.result as unknown as string);
      },
      false,
    );
    reader.readAsDataURL(blob);
  };

  useEffect(() => {
    const imageExtensions = ['.jpg', '.jpeg', '.jfif', '.gif', '.bmp', '.png', '.webp', '.svg'];
    const isImageFile = (contentFile: File) => {
      const fileName = contentFile.name.toLowerCase();
      return imageExtensions.some((ext) => fileName?.endsWith(ext));
    };
    if (upload.previewFile) {
      setCanPreview(true);
      readPreviewImage(upload.previewFile);
    } else if (isImageFile(upload.contentFile)) {
      setCanPreview(true);
      readPreviewImage(upload.contentFile);
    }
  }, [canPreview, upload.contentFile, upload.previewFile]);

  const handlePreviewDrop = (upload: FileToUpload, preview: File, e: DropEvent) => {
    e.stopPropagation();
    upload.previewFile = preview;

    readPreviewImage(preview);

    onPreviewAdded({ ...upload, previewFile: preview });
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropzone
        onDrop={(selectedPreviewImage: File[], _rejections: FileRejection[], e: DropEvent) =>
          handlePreviewDrop(upload, selectedPreviewImage[0], e)
        }
        multiple={false}
        accept="image/*"
        noDragEventsBubbling={true}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: 'dropzone upload-preview-content' })}>
            <input {...getInputProps()} data-testid="dropzone-input" />
            {!canPreview ? (
              <>
                <Button variant="link" disabled={disabled}>
                  No Preview selected
                </Button>
                <i
                  className="fa fa-5x fa-image cursor-pointer"
                  style={{ opacity: '50%', display: 'flex', alignItems: 'center' }}
                />
              </>
            ) : (
              <>
                <Button variant="link" disabled={disabled}>
                  Preview selected
                </Button>
                <img src={filePreviewUrl} alt="Upload review" className="cursor-pointer" />
              </>
            )}
          </div>
        )}
      </Dropzone>
    </div>
  );
};
