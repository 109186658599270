import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import './CreativeAssetDetailPage.scss';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { CreativeAssetHeader } from './components/CreativeAssetHeader';
import { Button } from '@cimpress/react-components';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import { Toast } from '../components/Toast';
import { CreativeAssetVersion } from '../api/Cams/model/CreativeAssetVersion';
import { DeleteAsset } from '../components/DeleteAsset';
import { CreateOrUpdateCreativeAssetControl } from '../Uploads/CreateOrUpdateCreativeAssetControl';
import { UsedInManagementAssets } from './components/UsedInManagementAssets';
import { DetailedCreativeAsset } from '../api/Cams/model/DetailedCreativeAsset';
import { MonolithAssetColorVariants } from './MonolithColorVariants/MonolithAssetColorVariants';
import { AssetCategory, ToAssetCategory } from '../AssetsListPage/AssetCategory';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';
import { CreativeAsset } from '../api/Cams/model/CreativeAsset';
import { ManagementAssetVersion } from '../api/Cams/model/ManagementAssetVersion';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreativeAssetDetailPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();
  const history = useHistory();

  const query = useQuery();
  const version = query.get('version') || undefined;
  const message = query.get('message') || undefined;

  const assetCategory: AssetCategory = 'creative asset';

  const [creativeAsset, setCreativeAsset] = useState<DetailedCreativeAsset>();
  const [linkedManagementAssetVersions, setLinkedManagementAssetVersions] = useState<ManagementAssetVersion[]>([]);
  const [creativeAssetVersion, setCreativeAssetVersion] = useState<CreativeAssetVersion>();
  const [firstVersion, setFirstVersion] = useState<CreativeAssetVersion>();
  const [, setAssetError] = useState<string>();
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState(true);

  const loadAsset = async (id: string, version?: string | number) => {
    setIsLoading(true);
    setIsDeleteButtonShown(tenant.userPermissions.canWrite);
    const response = await CamsApi.getAsset<DetailedCreativeAsset>(tenant, id);
    setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      if (response.data.assetType !== 'creative') {
        history.push(
          getVariableAssetDetailPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            ToAssetCategory(response.data.assetType, response.data.assetKind || ''),
            id,
          ),
        );
        return;
      }
      const creativeAsset = response.data;
      setCreativeAsset(creativeAsset);

      const usedCreativeAssetVersion = await getUsedCreativeVersion(creativeAsset, version);

      const firstVersion =
        usedCreativeAssetVersion.versionNumber === 1
          ? usedCreativeAssetVersion
          : await getUsedCreativeVersion(creativeAsset, 1);
      setFirstVersion(firstVersion);

      setCreativeAssetVersion(usedCreativeAssetVersion);
      if (usedCreativeAssetVersion !== undefined) {
        setLinkedManagementAssetVersions(await getLinkedManagementAssets(usedCreativeAssetVersion));
      }
    }
  };

  const getUsedCreativeVersion = async (asset: CreativeAsset, version: string | number | undefined) => {
    let usedCreativeAssetVersion = asset.latestVersion;

    const versionNumber = parseInt(version?.toString() || '');
    if (versionNumber) {
      const versionResponse = await CamsApi.getAssetVersion(tenant, id, versionNumber);
      if (isSuccessfulResponse(versionResponse)) {
        usedCreativeAssetVersion = versionResponse.data as CreativeAssetVersion;
      }
    }
    return usedCreativeAssetVersion;
  };

  const getLinkedManagementAssets = async (assetVersion: CreativeAssetVersion) => {
    const result = await CamsApi.getUsedByCreativeAssetVersion(tenant, assetVersion);
    if (isSuccessfulResponse(result)) {
      return result.data;
    } else return [];
  };

  const handleUpdate = async () => {
    await loadAsset(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAsset(id, version);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, version]);

  const onAssetVersionCreated = async (assetId: string, version: number) => {
    setIsCreatingNewVersion(false);
    await loadAsset(assetId, version);
  };

  const isLatestVersion = creativeAsset?.latestVersion.versionNumber === creativeAssetVersion?.versionNumber;

  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="creative-details-title">Creative asset detail</h2>
          {creativeAsset && isLatestVersion && (
            <div className="detail-header-buttons">
              {isCreatingNewVersion ? (
                <Button variant="primary" className="btn-danger" onClick={() => setIsCreatingNewVersion(false)}>
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="upload-new-version-button"
                  data-testid="upload-new-version-button"
                  onClick={() => setIsCreatingNewVersion(true)}
                  disabled={!tenant.userPermissions.canWrite}
                >
                  Upload new version
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      {creativeAsset && isCreatingNewVersion && (
        <CreateOrUpdateCreativeAssetControl assetId={creativeAsset.id} onUpload={onAssetVersionCreated} />
      )}

      {isLoading && <Loading />}

      {!creativeAsset && !isLoading && <h4 data-testid="detail-not-found">Creative asset not found.</h4>}

      {creativeAsset && creativeAssetVersion && firstVersion && (
        <div className="row section">
          <div className="col-sm-12">
            <CreativeAssetHeader
              asset={creativeAsset}
              assetVersion={creativeAssetVersion}
              firstVersion={firstVersion}
              isLatestVersion={isLatestVersion}
            />
          </div>
        </div>
      )}
      {(linkedManagementAssetVersions?.length ?? 0) > 0 && (
        <div className="row section">
          <div className="col-sm-24 col-md-12">
            <UsedInManagementAssets assetVersions={linkedManagementAssetVersions} />
          </div>
        </div>
      )}
      {creativeAsset && tenant.contentAuthoringAreaId === 'fiNrCCvLd02lXTrJyKCh5Q' && (
        <div className="row section">
          <div className="col-sm-24 col-md-12">
            <MonolithAssetColorVariants assetId={id} />
          </div>
        </div>
      )}

      {creativeAsset && !creativeAsset.isDeleted && (
        <div className="row section">
          <div className="col-sm-6">
            <DeleteAsset
              assetId={creativeAsset.id}
              assetCategory={assetCategory}
              name={`${creativeAssetVersion?.uploadInfo?.fileName ?? 'this'} ${'creative asset'.format()}`}
              onDelete={handleUpdate}
              showButton={isDeleteButtonShown}
              disabled={!creativeAsset.isDeletable || isLoading}
            />
          </div>
        </div>
      )}
      {message && <Toast message={message} />}
    </div>
  );
};

export default CreativeAssetDetailPage;
