import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import * as CamsDesignsApi from '../api/Cams/cams-api-designs';
import './DesignDetailPage.scss';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { Button } from '@cimpress/react-components';
import Loading from '../components/Loading';
import { Toast } from '../components/Toast';
import { DesignManagementAsset } from '../api/Cams/model/Design/DesignManagementAsset';
import { AppContext } from '../App';
import { DesignManagementAssetVersion } from '../api/Cams/model/Design/DesignManagementAssetVersion';
import { CreateOrUpdateDesignAssetControl } from '../Uploads/CreateOrUpdateDesignAssetControl';
import { DesignHeader } from './components/DesignHeader';
import { DesignAssets } from './components/DesignAssets';
import { DeleteAsset } from '../components/DeleteAsset';
import { DesignCompiledTemplates } from './components/DesignCompiledTemplates';
import { CreativeAssetVersion } from '../api/Cams/model/CreativeAssetVersion';
import { getDesignCompiledTemplatesByVersion, getDesignName } from '../Tools/DesignHelpers';
import { getDesignManagementAssetCulturePreviewsPageRoute } from '../Routes/DesignManagementAssetRoutes';
import { enrichEnsemblesForProminentColor } from '../Tools/DesignTemplateHelpers';
import { EnsembleWithProminentColor } from '../api/Cams/model/EnsembleLineInfo/EnsembleWithProminentColor';
import { ToAssetCategory } from '../AssetsListPage/AssetCategory';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DesignDetailPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();
  const history = useHistory();

  const query = useQuery();
  const version = query.get('version') || undefined;
  const message = query.get('message') || undefined;

  const [design, setDesign] = useState<DesignManagementAsset>();
  const [designVersion, setDesignVersion] = useState<DesignManagementAssetVersion>();
  const [firstVersion, setFirstVersion] = useState<DesignManagementAssetVersion>();

  const [linkedAssets, setLinkedAssets] = useState<CreativeAssetVersion[]>([]);
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState(true);
  const [enrichedEnsembles, setEnrichedEnsembles] = useState<EnsembleWithProminentColor[]>([]);

  const loadAsset = async (id: string, version?: string | number) => {
    setIsLoading(true);
    setIsDeleteButtonShown(tenant.userPermissions.canWrite);
    const response = await CamsApi.getAsset<DesignManagementAsset>(tenant, id);
    // setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      if (response.data.assetKind !== 'design') {
        history.push(
          getVariableAssetDetailPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            ToAssetCategory(response.data.assetType, response.data.assetKind),
            id,
          ),
        );
        return;
      }

      const design = response.data;
      setDesign(design);

      const usedDesignVersion = await getUsedDesignVersion(design, version);

      const firstVersion =
        usedDesignVersion.versionNumber === 1 ? usedDesignVersion : await getUsedDesignVersion(design, 1);
      setFirstVersion(firstVersion);

      const ensembles = await enrichEnsemblesForProminentColor(usedDesignVersion.ensembleLine?.ensembles ?? []);
      setEnrichedEnsembles(ensembles);

      setDesignVersion(usedDesignVersion);
      setLinkedAssets(usedDesignVersion.linkedAssets);
    }
  };

  const getUsedDesignVersion = async (design: DesignManagementAsset, version: string | number | undefined) => {
    let usedDesignVersion = design.latestVersion;

    const versionNumber = parseInt(version?.toString() || '');
    if (versionNumber) {
      const versionResponse = await CamsApi.getAssetVersion(tenant, id, versionNumber);
      if (isSuccessfulResponse(versionResponse)) {
        usedDesignVersion = versionResponse.data as DesignManagementAssetVersion;
      }
    }
    return usedDesignVersion;
  };

  const handleUpdate = async () => {
    await loadAsset(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAsset(id, version);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, version]);

  const lockDesign = async (isLocked: boolean) => {
    setIsLoading(true);
    const response = await CamsDesignsApi.updateDesignAsset(design?.id || '', tenant, {
      isLocked,
    });
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      setDesign(response.data);
    }
  };

  const onDesignVersionCreated = async (designId: string) => {
    setIsCreatingNewVersion(false);
    await loadAsset(designId);
  };

  const isLatestVersion = design?.latestVersion.versionNumber === designVersion?.versionNumber;

  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="design-details-title">Design detail</h2>
          {design && isLatestVersion && (
            <div className="detail-header-buttons">
              {tenant.userPermissions.canWrite && (
                <i
                  data-testid="design-details-page-lock-button"
                  className={`fa fa-2x ${design.isLocked ? 'fa-lock' : 'fa-unlock'} text-primary design-button-lock`}
                  onClick={() => lockDesign(!design.isLocked)}
                  title={design.isLocked ? `Unlock the design for everybody.` : `Lock the design.`}
                />
              )}
              {isCreatingNewVersion ? (
                <Button variant="primary" className="btn-danger" onClick={() => setIsCreatingNewVersion(false)}>
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="upload-new-version-button"
                  data-testid="upload-new-version-button"
                  onClick={() => setIsCreatingNewVersion(true)}
                  disabled={design.isLocked || !tenant.userPermissions.canWrite}
                >
                  Upload new version
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      {design && isCreatingNewVersion && (
        <CreateOrUpdateDesignAssetControl
          designId={design.id}
          onUpload={onDesignVersionCreated}
          creativeAssetId={designVersion?.masterFile.assetId}
        />
      )}

      {isLoading && <Loading />}

      {!design && !isLoading && <h4 data-testid="detail-not-found">Design not found.</h4>}

      {design && designVersion && firstVersion && (
        <div className="row section">
          <div className="col-sm-12">
            <DesignHeader
              design={design}
              designVersion={designVersion}
              firstVersion={firstVersion}
              isLatestVersion={isLatestVersion}
            />
          </div>
          {linkedAssets.length > 0 && (
            <div className="col-sm-12 col-md-6">
              <DesignAssets assets={linkedAssets} />
            </div>
          )}
          {getDesignCompiledTemplatesByVersion(designVersion).length > 0 && (
            <div className="col-sm-12 col-md-6">
              <DesignCompiledTemplates ensembles={enrichedEnsembles} />
              <Link
                to={getDesignManagementAssetCulturePreviewsPageRoute(
                  tenant.contentAuthoringAccountId,
                  tenant.contentAuthoringAreaId,
                  design.id,
                )}
              >
                <Button variant="secondary">Show Previews in all cultures</Button>
              </Link>
            </div>
          )}
        </div>
      )}
      {/* For designs, we do not care about discoverable/undiscoverable status. In other words, even published designs can be deleted from CAM. */}
      {design && !design.isDeleted && (
        <div className="row section">
          <div className="col-sm-6">
            <DeleteAsset
              assetId={design.id}
              name={`${getDesignName(design, designVersion, 'this')} ${'design'.format()}`}
              onDelete={handleUpdate}
              assetCategory="design"
              showButton={isDeleteButtonShown}
              disabled={design.isLocked}
            />
          </div>
        </div>
      )}
      {message && <Toast message={message} />}
    </div>
  );
};

export default DesignDetailPage;
