import { AssetVersion } from './AssetVersion';
import { UploadInfo } from './UploadInfo';

export interface CreativeAssetVersion extends AssetVersion {
  assetKind: undefined;
  assetType: 'creative';

  uploadInfo: UploadInfo;
  previewInfo: UploadInfo | undefined;
}

export const isCreativeAssetVersion = (x: AssetVersion): x is CreativeAssetVersion => {
  return x.assetType === 'creative';
};
