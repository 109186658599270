import React, { useEffect, useState } from 'react';
import { Color, ColorPalette } from '../Uploads/ColorPalette';
import { CMYKColorSelectionComponent } from './CMYKColorSelectionComponent';

interface Props {
  onColorPaletteChange: (color: ColorPalette) => void;
  colorPalette?: ColorPalette;
}

export const ColorPaletteSelectionComponent = ({ onColorPaletteChange, colorPalette }: Props): JSX.Element => {
  const [colorPaletteState, setColorPalette] = useState<ColorPalette>({});

  useEffect(() => {
    onColorPaletteChange(colorPaletteState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorPaletteState]);

  const primaryColorChanged = (value: Color) => {
    setColorPalette((prev) => ({ ...prev, Primary: value }));
  };
  const secondaryColorChanged = (value: Color) => {
    setColorPalette((prev) => ({ ...prev, Secondary: value }));
  };
  const neutralColorChanged = (value: Color) => {
    setColorPalette((prev) => ({ ...prev, Neutral: value }));
  };
  const accentColorChanged = (value: Color) => {
    setColorPalette((prev) => ({ ...prev, Accent: value }));
  };
  const backgroundColorChanged = (value: Color) => {
    setColorPalette((prev) => ({ ...prev, Background: value }));
  };

  return (
    <div>
      <CMYKColorSelectionComponent
        colorName={'Primary'}
        onColorChange={primaryColorChanged}
        initialValue={colorPalette?.Primary?.cmyk ?? ''}
      />
      <CMYKColorSelectionComponent
        colorName={'Secondary'}
        onColorChange={secondaryColorChanged}
        initialValue={colorPalette?.Secondary?.cmyk ?? ''}
      />
      <CMYKColorSelectionComponent
        colorName={'Neutral'}
        onColorChange={neutralColorChanged}
        initialValue={colorPalette?.Neutral?.cmyk ?? ''}
      />
      <CMYKColorSelectionComponent
        colorName={'Accent'}
        onColorChange={accentColorChanged}
        initialValue={colorPalette?.Accent?.cmyk ?? ''}
      />
      <CMYKColorSelectionComponent
        colorName={'Background'}
        onColorChange={backgroundColorChanged}
        initialValue={colorPalette?.Background?.cmyk ?? ''}
      />
    </div>
  );
};
