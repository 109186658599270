import { Tooltip } from '@cimpress/react-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { Tenant } from '../api/model/Tenant';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { serializeQueryParams } from '../AssetsListPage/QueryString/QueryStringUtils';
import { getVariableAssetPageRoute } from '../Routes/GenericRoutes';
import { formatDateTime } from '../Tools/DateTimeHelpers';

interface Props {
  assetVersion: AnyAssetVersion;
  tenant: Tenant;
  assetCategory: AssetCategory;
  isLatestVersion: boolean;
  firstVersion: AnyAssetVersion;
}

export const AssetAuditInfo = ({
  assetVersion,
  tenant,
  assetCategory,
  isLatestVersion,
  firstVersion,
}: Props): JSX.Element => {
  return (
    <>
      <span>
        {isLatestVersion && <span>Last modified by: </span>}
        {!isLatestVersion && <span>Modified by: </span>}
        <Link
          to={getVariableAssetPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            assetCategory,
            serializeQueryParams({ filters: { createdBy: [assetVersion.createdBy] } }),
          )}
          data-testid="last-modified-by"
        >
          {assetVersion.createdByFullname}
        </Link>
      </span>
      <br />
      <Tooltip direction={'right'} contents={formatDateTime(assetVersion.created)}>
        {isLatestVersion && <span>Last modified: {formatDateTime(assetVersion.created, true)}</span>}
        {!isLatestVersion && <span>Modified: {formatDateTime(assetVersion.created, true)}</span>}
      </Tooltip>
      <p></p>
      {firstVersion && (
        <span>
          First version created by:&nbsp;
          <Link
            to={getVariableAssetPageRoute(
              tenant.contentAuthoringAccountId,
              tenant.contentAuthoringAreaId,
              assetCategory,
              serializeQueryParams({ filters: { createdBy: [firstVersion.createdBy] } }),
            )}
          >
            {firstVersion.createdByFullname}
          </Link>
          <br />
          <Tooltip direction={'right'} contents={formatDateTime(firstVersion.created)}>
            First version created: {formatDateTime(firstVersion.created, true)}
          </Tooltip>
        </span>
      )}
    </>
  );
};
