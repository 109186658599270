import React, { useContext, useEffect, useRef, useState } from 'react';
import Loading from '../components/Loading';
import './AssetsListPage.scss';
import { parseQueryStringState, serializeQueryParams, serializeToQueryString } from './QueryString/QueryStringUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { QueryStringState } from './QueryString/QueryStringState';
import * as CamsApi from '../api/Cams/cams-api';
import { useNavigationButtons, useQueryChange, useRouteChange } from '../customHooks/useNavigationButtons';
import { AssetsFilters } from './Model/AssetsFilters';
import { defaultPageNumber } from './Model/Constants';
import { AnyAsset } from '../api/Cams/model/AnyAsset';
import { AssetKind } from '../api/Cams/model/AssetKind';
import { NoAssetsFound } from './Components/NoAssetsFound';
import { AssetsList } from './AssetsListViews/AssetsList';
import { AssetsListFilterSidebar } from './Filters/AssetsListFilterSidebar';
import { AssetCategorySelector } from './Components/AssetCategorySelector';
import { CreateNewAssetButton } from '../components/CreateNewAssetButton';
import { DesignsListViewType } from '../DesignPage/DesignsListViewType';
import { AssetsListFooter } from './AssetsListFooter/AssetsListFooter';
import { AssetType } from '../api/Cams/model/AssetType';
import { AssetCategory } from './AssetCategory';
import { AssetSearch } from './Components/AssetSearch';
import * as LocalStorage from '../Tools/LocalStorage';
import { CamNavigationState } from '../DesignPage/Model/CamNavigationState';
import { PreviewInDexButton } from '../Preview/PreviewInDexButton';
import { isPatternManagementAssetVersion } from '../api/Cams/model/Pattern/PatternManagementAssetVersion';
import { PatternContentAssetVersion } from '../api/Cams/model/Pattern/PatternContentAssetVersion';
import { EmptyTenant, TenantDescription } from '../api/model/TenantDescription';
import { AppContext } from '../App';
import { Tenant } from '../api/model/Tenant';
import { ActiveFiltersStrip } from './Filters/Components/ActiveFiltersStrip';
import { SearchTerm } from './Model/SearchTerm';
import { formatAssetsToZip } from '../DownloadAsset/ZipUtility';
import { FilePathsToUrls } from '../api/Cams/model/FilePathsToUrls';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { calculatePagesCount, updatePageNumber } from '../Tools/PagingUtility';
import { deepEqual } from '../Tools/DeepEqual';
import { TenantMissingLocalizationNotification } from '../MissingLocalizationNotification/TenantMissingLocalizationNotification';
import { getVariableAssetPageRoute } from '../Routes/GenericRoutes';
import { getAssetCategorySetting } from '../AssetCategoryConfig';
import { hasAccessToCategory } from '../Tools/AssetCategoryAccessUtil';

interface Props {
  // pass these directly to avoid designs page re-rendering on app context change
  setQueryStringState: (queryStringState: QueryStringState) => void;
  setPreviouslyViewedContentType: (previouslyViewedAssetCategory: AssetCategory) => void;
}

export const AssetsListPage = ({ setQueryStringState, setPreviouslyViewedContentType }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const [assets, setAssets] = useState<AnyAsset[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<AnyAsset[]>([]);
  const [patternContentAssets, setPatternContentAssets] = useState<PatternContentAssetVersion[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { availableTenants } = useContext(AppContext);
  const [totalAssetsToDownload, setTotalAssetsToDownload] = useState(0);
  const [isTagReload, setIsTagReload] = useState(false);
  const [isCollectionReload, setIsCollectionReload] = useState(false);

  const location = useLocation();
  const queryStringState = parseQueryStringState(location, false, availableTenants);
  const [pageState, setPageState] = useState(queryStringState);

  const controller = useRef<AbortController | null>(null);
  const assetCategorySetting = getAssetCategorySetting(pageState.assetCategory);

  // Page state is initialized twice - when initializing the component (see above)
  // and during useRouteChange() call.
  // To avoid initializing filter sidebar twice, ignore the component init above.
  // Removing the component init above would result in having to handle "undefined" value in every code bit, which is nasty.
  const [initialLoadPassed, setInitialLoadPassed] = useState(false);

  const history = useHistory<CamNavigationState>();

  const onAssetSelected = (asset: AnyAsset, isSelected: boolean) => {
    if (isSelected) {
      setSelectedAssets((x) => [...x, asset]);
    } else {
      setSelectedAssets(selectedAssets.filter((x) => x.id !== asset.id));
    }
  };

  useEffect(() => {
    setTotalAssetsToDownload(selectedAssets.length > 0 ? selectedAssets.length : totalCount);
  }, [selectedAssets, totalCount]);

  const loadDataToDownload = async (): Promise<FilePathsToUrls | undefined> => {
    //Page size: 100
    //Max 10 pages i.e. max supported downloadable designs will be 1000
    const queryState = parseQueryStringState(history.location, true, availableTenants);
    if (selectedAssets.length > 0) {
      return formatAssetsToZip(selectedAssets.map((x) => x.latestVersion));
    } else {
      const numberOfPages = calculatePagesCount(totalCount, 100);
      let assetVersions: AnyAssetVersion[] = [];
      for (let i = 0; i < numberOfPages; i++) {
        const result = await CamsApi.getAssets(
          queryState.tenant,
          100,
          i,
          queryState.assetKinds,
          queryState.assetType,
          queryState.filters,
          queryState.search,
        );
        if (isSuccessfulResponse(result)) {
          assetVersions = assetVersions.concat(result.data.data.map((x) => x.latestVersion));
        }
      }
      return formatAssetsToZip(assetVersions);
    }
  };

  const loadData = async (
    tenant: TenantDescription,
    pageNumber: number,
    pageSize: number,
    filters: AssetsFilters,
    assetTypes: AssetType,
    assetKinds: AssetKind[],
    search: SearchTerm,
  ) => {
    setLoading(true);
    setTotalCount(0);
    setTotalAssetsToDownload(0);
    setSelectedAssets([]);
    try {
      if (controller !== null) {
        controller.current?.abort();
      }

      controller.current = new AbortController();

      const result = await CamsApi.getAssets(
        tenant,
        pageSize,
        pageNumber,
        assetKinds,
        assetTypes,
        filters,
        search,
        controller.current,
      );

      if (isSuccessfulResponse(result)) {
        setTotalCount(result.data.totalCount);
        setTotalAssetsToDownload(result.data.totalCount);
        setAssets(result.data.data);
        extractAndSetContentAssets(result.data.data);
        setLoading(false);
      } else if (result.responseType !== 'cancelled') {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const extractAndSetContentAssets = (data: AnyAsset[]) => {
    const contentAssetList = new Array<PatternContentAssetVersion>();
    const parsedQueryString = parseQueryStringState(history.location, false, availableTenants);
    if (parsedQueryString.assetKinds[0] === 'pattern') {
      data.forEach((x) => {
        if (isPatternManagementAssetVersion(x.latestVersion) && x.latestVersion.contentAsset) {
          contentAssetList.push(x.latestVersion.contentAsset);
        }
      });
    }
    setPatternContentAssets(contentAssetList);
  };

  const loadDataByQueryStringState = async (queryState: QueryStringState) => {
    loadData(
      getTenantDescription(queryState.tenant),
      queryState.paging.pageNumber,
      queryState.paging.pageSize,
      queryState.filters,
      queryState.assetType,
      queryState.assetKinds,
      queryState.search,
    );
  };

  const getTenantDescription = (tenant: Tenant): TenantDescription => {
    return (
      availableTenants.find(
        (t) =>
          t.contentAuthoringAccountId === tenant.contentAuthoringAccountId &&
          t.contentAuthoringAreaId === tenant.contentAuthoringAreaId,
      ) ?? EmptyTenant
    );
  };

  function mergeQueryState(originalState: QueryStringState, newState: QueryStringState) {
    if (deepEqual(originalState?.filters, newState.filters)) {
      newState.filters = originalState.filters;
    }
    return newState;
  }

  useNavigationButtons(() => {
    const queryState = parseQueryStringState(history.location, true, availableTenants);
    const mergedState = mergeQueryState(pageState, queryState);
    setPageState(mergedState);
    loadDataByQueryStringState(mergedState);
  }, []);

  const onRouteChange = () => {
    const queryState = parseQueryStringState(history.location, true, availableTenants);
    const mergedState = mergeQueryState(pageState, queryState);
    setPreviouslyViewedContentType(queryState.assetCategory);
    setInitialLoadPassed(true);
    setPageState(mergedState);
    loadDataByQueryStringState(mergedState);
  };

  useRouteChange(onRouteChange, []);

  /**
   * Any increment in query
   */
  useQueryChange(() => {
    const queryState = parseQueryStringState(history.location, false, availableTenants);
    loadDataByQueryStringState(queryState);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageState.paging.pageNumber, pageState.paging.pageSize]);

  const synchronizeQueryStringState = (queryStringState: QueryStringState): void => {
    serializeToQueryString(history, queryStringState);
    setQueryStringState(queryStringState);
  };

  const onPageChanged = (page: number): void => {
    // because Pagination calls onPageChange upon creation
    if (page !== pageState.paging.pageNumber) {
      setPageState({
        ...pageState,
        paging: { ...pageState.paging, pageNumber: page },
        isNavigationAction: false,
      });
    }
  };

  const onPageSizeChanged = (pageSize: number): void => {
    localStorage.setItem('pageSize', pageSize.toString());
    const newState = {
      ...pageState,
      paging: {
        ...pageState.paging,
        pageSize: pageSize,
        pageNumber: updatePageNumber(totalCount, pageSize, pageState.paging.pageNumber),
      },
      isNavigationAction: false,
    };
    setPageState(newState);
  };

  const onFiltersSearchCleared = (): void => {
    setPageState({
      ...pageState,
      filters: {
        createdBy: [],
        tags: [],
        collections: [],
        designConceptId: [],
        designUseCaseNames: [],
        productNames: [],
        published: [],
        accessibleTo: [],
      },
      search: {
        term: '',
        exact: false,
      },
      paging: { ...pageState.paging, pageNumber: defaultPageNumber },
      isNavigationAction: false,
    });
  };

  const onFiltersChanged = (filters: AssetsFilters): void => {
    setPageState({
      ...pageState,
      filters,
      paging: { ...pageState.paging, pageNumber: defaultPageNumber },
      isNavigationAction: false,
    });
  };

  const onListViewChange = (listViewType: DesignsListViewType): void => {
    setSelectedAssets([]);
    setPageState({
      ...pageState,
      listViewType: listViewType,
      paging: { ...pageState.paging, pageNumber: defaultPageNumber },
      isNavigationAction: false,
    });
  };

  const onSearch = (term: SearchTerm): void => {
    setPageState({
      ...pageState,
      paging: { pageNumber: defaultPageNumber, pageSize: pageState.paging.pageSize },
      search: term,
      isNavigationAction: false,
    });
  };

  /**
   * This method takes in all changes to a page state in a single step and prompts increment in query
   */
  useEffect(() => {
    if (!pageState.isNavigationAction && initialLoadPassed) {
      synchronizeQueryStringState(pageState);
    }

    if (!initialLoadPassed) {
      onRouteChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, initialLoadPassed]);

  useEffect(() => {
    const parsedQueryString = parseQueryStringState(history.location, false, availableTenants);

    if (!hasAccessToCategory(getTenantDescription(parsedQueryString.tenant), parsedQueryString.assetCategory)) {
      history.push(
        `${getVariableAssetPageRoute(
          LocalStorage.getActiveTenant()?.contentAuthoringAccountId,
          LocalStorage.getActiveTenant()?.contentAuthoringAreaId,
          LocalStorage.getAssetCategory(),
          serializeQueryParams(parsedQueryString),
        )}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAssetCategoryChanged = (assetCategory: AssetCategory): void => {
    const queryState = parseQueryStringState(history.location, false, availableTenants);
    queryState.paging.pageNumber = defaultPageNumber;
    const queryString = serializeQueryParams(queryState);
    history.push(
      `${getVariableAssetPageRoute(
        LocalStorage.getActiveTenant()?.contentAuthoringAccountId,
        LocalStorage.getActiveTenant()?.contentAuthoringAreaId,
        assetCategory,
        queryString,
      )}`,
    );
  };

  if (!initialLoadPassed) {
    return <Loading />;
  }

  return (
    <div className="container-fluid container-full-width assets-list-page">
      <div className="row cams-page-title">
        {assetCategorySetting.hasLocalization && <TenantMissingLocalizationNotification />}
        <div
          className="col-sm-12 col-md-3"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}
        >
          <AssetCategorySelector
            assetCategory={pageState.assetCategory}
            onAssetCategoryChanged={handleAssetCategoryChanged}
            tenantDescription={getTenantDescription(pageState.tenant)}
          />
        </div>
        <div className="col-sm-12 col-md-9" style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div style={{ flex: 1, marginRight: '40px' }}>
            <AssetSearch assetsNewSearch={onSearch} search={pageState.search} allowExactSearch={true} />
          </div>
          <div>
            <CreateNewAssetButton
              assetCategory={pageState.assetCategory}
              reloadIndexPageContents={() =>
                loadDataByQueryStringState(parseQueryStringState(history.location, false, availableTenants))
              }
              onTagReload={setIsTagReload}
              onCollectionReload={setIsCollectionReload}
              designConceptId={undefined}
            />
            {patternContentAssets.length > 0 && (
              <PreviewInDexButton tenant={pageState.tenant} assets={patternContentAssets} />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3">
          <AssetsListFilterSidebar
            pageState={pageState}
            assetKinds={pageState.assetKinds}
            assetType={pageState.assetType}
            assetCategory={pageState.assetCategory}
            initialFilters={pageState.filters}
            onFiltersChanged={onFiltersChanged}
            tenant={getTenantDescription(pageState.tenant)}
            isTagReload={isTagReload}
            onTagReload={setIsTagReload}
            isCollectionReload={isCollectionReload}
            onCollectionReload={setIsCollectionReload}
          />
        </div>

        <div className="col-sm-6 col-md-9">
          <ActiveFiltersStrip
            filters={pageState.filters}
            search={pageState.search}
            onSearchChanged={onSearch}
            onFilterChanged={onFiltersChanged}
            onFiltersSearchCleared={onFiltersSearchCleared}
          />

          {loading && <Loading />}

          {!loading && assets.length === 0 && <NoAssetsFound assetCategory={pageState.assetCategory} />}

          {!loading && assets.length > 0 && (
            <>
              <AssetsList
                assets={assets}
                viewType={pageState.listViewType}
                onAssetSelected={onAssetSelected}
                assetCategory={pageState.assetCategory}
              />
              <div className="assets-page-bar">
                {!loading && (
                  <span>
                    <span data-testid="total-count">{totalCount}</span>{' '}
                    {pageState.assetCategory.format({ plurality: 'plural' })} found
                  </span>
                )}
              </div>
              <AssetsListFooter
                paging={pageState.paging}
                totalCount={totalCount}
                totalAssetsToDownload={totalAssetsToDownload}
                onPageChanged={onPageChanged}
                onPageSizeChanged={onPageSizeChanged}
                listViewType={pageState.listViewType}
                onListViewChange={onListViewChange}
                loadDataToDownload={loadDataToDownload}
                assetCategory={pageState.assetCategory}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
