import React, { useContext, useEffect, useRef, useState } from 'react';
import { SortingRule } from 'react-table';
import * as CamsApi from '../api/Cams/cams-api';
import { AppContext } from '../App';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { BackgroundManagementAsset } from '../api/Cams/model/Background/BackgroundManagementAsset';
import { ClipartManagementAsset } from '../api/Cams/model/Clipart/ClipartManagementAsset';
import { ColorPaletteManagementAsset } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAsset';
import { PatternManagementAsset } from '../api/Cams/model/Pattern/PatternManagementAsset';
import { FontSchemaManagementAsset } from '../api/Cams/model/FontSchema/FontSchemaManagementAsset';
import { DesignConceptData } from '../api/Cams/model/DesignConceptData';
import './DesignConceptsTablePage.scss';
import { DesignConceptsTable } from './DesignConceptsTable';
import { QueryStringState } from '../AssetsListPage/QueryString/QueryStringState';
import { useHistory, useLocation } from 'react-router-dom';
import { parseQueryStringState, serializeToQueryString } from '../AssetsListPage/QueryString/QueryStringUtils';
import { useNavigationButtons, useQueryChange, useRouteChange } from '../customHooks/useNavigationButtons';
import { CamNavigationState } from '../DesignPage/Model/CamNavigationState';
import { DesignConceptFooter } from './DesignConceptFooter';
import { updatePageNumber } from '../Tools/PagingUtility';
import { deepEqual } from '../Tools/DeepEqual';
import { AssetSearch } from '../AssetsListPage/Components/AssetSearch';
import { SearchTerm } from '../AssetsListPage/Model/SearchTerm';
import { HasAssetCategoryFilter } from './HasAssetCategoryFilter';
import { DesignConceptsFilters } from './DesignConceptsFilters';
import { DesignConceptSort } from './DesignConceptSort';
import { CopyButton, Tooltip } from '@cimpress/react-components';
import { defaultPageNumber } from '../AssetsListPage/Model/Constants';

interface Props {
  setQueryStringState: (queryStringState: QueryStringState) => void;
}

export const DesignConceptsTablePage = ({ setQueryStringState }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { availableTenants } = useContext(AppContext);

  const [data, setData] = useState<DesignConceptData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const queryStringState = parseQueryStringState(location, false, availableTenants);
  const [pageState, setPageState] = useState(queryStringState);
  const [totalCount, setTotalCount] = useState(0);
  const controller = useRef<AbortController | null>(null);

  const [initialLoadPassed, setInitialLoadPassed] = useState(false);
  const [sortingRule, setSortingRule] = useState<SortingRule>({
    id: pageState.designConceptSort.columnName,
    desc: pageState.designConceptSort.sortOrder === 'asc' ? false : true,
  });

  const history = useHistory<CamNavigationState>();

  type AllowedAssetKinds =
    | BackgroundManagementAsset
    | ClipartManagementAsset
    | ColorPaletteManagementAsset
    | FontSchemaManagementAsset
    | PatternManagementAsset;

  const loadDesignConceptsData = async (
    pageNumber: number,
    pageSize: number,
    dcId: string,
    filters: DesignConceptsFilters,
    sort: DesignConceptSort,
  ) => {
    setLoading(true);
    setTotalCount(0);
    if (controller !== null) {
      controller.current?.abort();
    }

    controller.current = new AbortController();
    const designConcepts = await CamsApi.getDesignConcepts(
      tenant,
      pageNumber,
      pageSize,
      dcId,
      filters,
      sort,
      controller.current,
    );
    if (isSuccessfulResponse(designConcepts)) {
      setData(designConcepts.data.data);
      setTotalCount(designConcepts.data.totalCount);
      setLoading(false);
    }
  };

  const loadDataByQueryStringState = async (queryState: QueryStringState) => {
    loadDesignConceptsData(
      queryState.paging.pageNumber,
      queryState.paging.pageSize,
      queryState.search.term || '',
      queryState.designConceptFilters,
      queryState.designConceptSort,
    );
  };

  function mergeQueryState(originalState: QueryStringState, newState: QueryStringState) {
    if (deepEqual(originalState?.filters, newState.filters)) {
      newState.filters = originalState.filters;
    }
    return newState;
  }

  useNavigationButtons(() => {
    const queryState = parseQueryStringState(history.location, true, availableTenants);
    const mergedState = mergeQueryState(pageState, queryState);
    setPageState(mergedState);
    loadDataByQueryStringState(mergedState);
  }, []);

  const onRouteChange = () => {
    const queryState = parseQueryStringState(history.location, true, availableTenants);
    const mergedState = mergeQueryState(pageState, queryState);
    // setPreviouslyViewedContentType(queryState.assetCategory);
    setInitialLoadPassed(true);
    setPageState(mergedState);
    loadDataByQueryStringState(mergedState);
  };

  useRouteChange(onRouteChange, []);

  /**
   * Any increment in query
   */
  useQueryChange(() => {
    const queryState = parseQueryStringState(history.location, false, availableTenants);
    loadDataByQueryStringState(queryState);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageState.paging.pageNumber, pageState.paging.pageSize]);

  const synchronizeQueryStringState = (queryStringState: QueryStringState): void => {
    serializeToQueryString(history, queryStringState);
    setQueryStringState(queryStringState);
  };

  const onPageChanged = (page: number): void => {
    // because Pagination calls onPageChange upon creation
    if (page !== pageState.paging.pageNumber) {
      setPageState({
        ...pageState,
        paging: { ...pageState.paging, pageNumber: page },
        isNavigationAction: false,
      });
    }
  };

  const onFiltersChanged = (designConceptFilters: DesignConceptsFilters): void => {
    setPageState({
      ...pageState,
      paging: { ...pageState.paging, pageNumber: defaultPageNumber },
      designConceptFilters,
      isNavigationAction: false,
    });
  };

  const onPageSizeChanged = (pageSize: number): void => {
    localStorage.setItem('pageSize', pageSize.toString());
    const newState = {
      ...pageState,
      paging: {
        ...pageState.paging,
        pageSize: pageSize,
        pageNumber: updatePageNumber(100, pageSize, pageState.paging.pageNumber),
      },
      isNavigationAction: false,
    };
    setPageState(newState);
  };

  useEffect(() => {
    if (!pageState.isNavigationAction && initialLoadPassed) {
      synchronizeQueryStringState(pageState);
    }

    if (!initialLoadPassed) {
      onRouteChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, initialLoadPassed]);

  const tableData = data.map((dc) => ({
    designConceptId: dc.designConceptId,
    assetCount: dc.assetDtos.length,
    background: dc.assetDtos.filter((asset: AllowedAssetKinds) => asset.assetKind === 'background'),
    clipart: dc.assetDtos.find((asset: AllowedAssetKinds) => asset.assetKind === 'clipart'),
    colorPalette: dc.assetDtos.find((asset: AllowedAssetKinds) => asset.assetKind === 'colorPalette'),
    fontSchema: dc.assetDtos.find((asset: AllowedAssetKinds) => asset.assetKind === 'fontSchema'),
  }));

  const onDesignConceptIdChangedFromInput = (searchTerm: SearchTerm): void => {
    setPageState({
      ...pageState,
      paging: { ...pageState.paging, pageNumber: defaultPageNumber },
      search: searchTerm,
      isNavigationAction: false,
    });
  };

  const onFilterChanged = (value: boolean | undefined, key: keyof DesignConceptsFilters): void => {
    const updatedfilters = { ...pageState.designConceptFilters, [key]: value };
    onFiltersChanged(updatedfilters);
  };

  const onSortChange = (rules: SortingRule[]): void => {
    const rule = rules[0];

    if (rule) {
      setSortingRule(rule);

      setPageState({
        ...pageState,
        designConceptSort: {
          columnName: rule.id === 'assetCount' ? 'assetCount' : 'designConceptId',
          sortOrder: rule.desc ? 'desc' : 'asc',
        },
        isNavigationAction: false,
      });
    }
  };

  return (
    <div className="design-concepts-table" data-testid="design-concepts-table">
      <div className="design-concepts-filters">
        <AssetSearch search={pageState.search} assetsNewSearch={onDesignConceptIdChangedFromInput} />

        <HasAssetCategoryFilter
          assetCategory="background"
          value={pageState.designConceptFilters.hasBackground}
          onValueChange={(value) => onFilterChanged(value, 'hasBackground')}
          dataTestId={'background-filter'}
        />
        <HasAssetCategoryFilter
          assetCategory="clipart"
          value={pageState.designConceptFilters.hasClipart}
          onValueChange={(value) => onFilterChanged(value, 'hasClipart')}
          dataTestId={'clipart-filter'}
        />
        <HasAssetCategoryFilter
          assetCategory="color palette"
          value={pageState.designConceptFilters.hasColorPalette}
          onValueChange={(value) => onFilterChanged(value, 'hasColorPalette')}
          dataTestId={'colorpalette-filter'}
        />
        <HasAssetCategoryFilter
          assetCategory="font schema"
          value={pageState.designConceptFilters.hasFontSchema}
          onValueChange={(value) => onFilterChanged(value, 'hasFontSchema')}
          dataTestId={'fontschema-filter'}
        />
        {data.length > 0 && (
          <Tooltip
            direction={'right'}
            contents="Copy filtered Design Concept IDs from current page"
            style={{ marginBottom: '10px', marginLeft: 'auto' }}
          >
            <CopyButton
              variant="secondary"
              size="lg"
              data-testid="dc-copy-button"
              value={data.map((x) => x.designConceptId).join('\r\n')}
            >
              Copy IDs to clipboard
            </CopyButton>
          </Tooltip>
        )}
      </div>

      <DesignConceptsTable data={tableData} onSortChange={onSortChange} isLoading={loading} sortingRule={sortingRule} />
      <DesignConceptFooter
        paging={pageState.paging}
        totalCount={totalCount}
        onPageChanged={onPageChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    </div>
  );
};
