import React from 'react';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../App';
import { getAssetCategory } from '../Tools/LocalStorage';
import { pageToRedirect } from '../Tools/PageRedirectionUtility';

export const RedirectToHomePage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const url = pageToRedirect(tenant, getAssetCategory());
  return <>{<Redirect to={url} />}</>;
};
