import React, { useContext, useEffect, useState } from 'react';
import * as CamsApi from '../../api/Cams/cams-api';
import { MonolithAssetsMetadata, MonolithComboColorGroup } from '../../api/Cams/model/MonolithAssetsMetadata';
import { AppContext } from '../../App';
import Loading from '../../components/Loading';
import { MonolithComboColorVariantTile } from './MonolithComboColorVariantTile';
import { getResponseError, isSuccessfulResponse } from '../../Tools/ResponseHelper';
import { Alert } from '@cimpress/react-components';
import { MonolithComboMissingPreviewsTile } from './MonolithComboMissingPreviewsTile';
import './MonolithColorVariants.scss';
import { MonolithCopyAllColorSwatch } from './MonolithCopyAllColorSwatch';

interface Props {
  assetId: string;
}

export const MonolithAssetColorVariants = ({ assetId }: Props): JSX.Element | null => {
  const { tenant } = useContext(AppContext);

  const [metadata, setMetadata] = useState<MonolithAssetsMetadata>();
  const [, setAssetError] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const loadMetadata = async (id: string) => {
    setIsLoading(true);
    const response = await CamsApi.getMonolithAssetMetadata(tenant, id);
    setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      setMetadata(response.data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadMetadata(assetId);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const getComboColors = (comboId: number) => {
    return metadata?.colorGroups?.find((x) => x.comboId === comboId);
  };

  const hasAnyPreviews = (metadata: MonolithAssetsMetadata): boolean => {
    return metadata.previews.length > 0;
  };

  const hasAnyColorGroups = (metadata: MonolithAssetsMetadata): boolean => {
    return metadata.colorGroups.length > 0;
  };

  return (
    <>
      {isLoading && <Loading />}
      {metadata && (
        <>
          <h2 className="section-title">Color group swatches</h2>
          <Alert
            status={'info'}
            message={`Click on the color swatch to copy the color value. Click on 'Copy All' to copy all color group swatches`}
            dismissible={true}
          />
          <div className="copy-color-swatch">
            <MonolithCopyAllColorSwatch
              colorGroups={metadata.previews
                .map((x) => getComboColors(x.comboId))
                .filter((y): y is MonolithComboColorGroup => y !== undefined)}
            />
          </div>
          <div className="assets-list-grid">
            {hasAnyPreviews(metadata) &&
              metadata.previews.map((combo) => {
                return (
                  <MonolithComboColorVariantTile
                    key={`${combo.comboId}`}
                    combo={combo}
                    colorGroup={getComboColors(combo.comboId)}
                  />
                );
              })}
            {!hasAnyPreviews(metadata) && hasAnyColorGroups(metadata) && (
              <MonolithComboMissingPreviewsTile colorGroups={metadata.colorGroups} />
            )}
          </div>
        </>
      )}
    </>
  );
};
