import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { defaultPageSize } from '../AssetsListPage/Model/Constants';
import { ParamsContext } from './ParamsContext';

interface Props {
  children: JSX.Element;
}

export const ParamsContextProvider = ({ children }: Props): JSX.Element => {
  const history = useHistory();
  const location = history.location;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.get('pageSize')) {
      searchParams.set('pageSize', localStorage.getItem('pageSize') ?? defaultPageSize.toString());
    }

    history.replace({ search: searchParams.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <ParamsContext.Provider value={undefined}>{children}</ParamsContext.Provider>;
};
