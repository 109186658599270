import { tenantRoutePrefix } from '../Tools/Routes';

export function getDesignConceptsTablePageRoute(
  accountId?: string,
  contentAreaId?: string,
  queryString?: string,
): string {
  const path = `${tenantRoutePrefix}design-concepts`;
  if (accountId && contentAreaId) {
    const updatedPath = path.replace(':accountId', accountId).replace(':areaId', contentAreaId);
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
}
