import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Modal, Tag } from '@cimpress/react-components';
import { FileToUploadWithProgress } from './FileToUploadWithProgress';
import { UploadStatus } from './UploadStatus';
import { DropzoneWrapper } from './DropzoneWrapper';
import { ActionResult } from '../Tools/ActionResult';
import { ErrorToast } from '../components/ErrorToast';
import { FilesToUploadList } from './FilesToUploadList';
import { CamsAutocomplete } from '../components/CamsAutocomplete';
import * as CamsApi from '../api/Cams/cams-api';
import { splitBySemicolon } from '../Tools/TagsUtil';
interface Props {
  handleUpload: (
    uploads: FileToUploadWithProgress[],
    tags: string[],
    collections: string[],
    onProgressUpdated: (updatedFiles: FileToUploadWithProgress) => void,
  ) => Promise<ActionResult<any>>;
  onCloseClick: () => void;
  allowMultipleUploads: boolean;
}

export const UploadsControlBatch = ({ handleUpload, onCloseClick, allowMultipleUploads }: Props): JSX.Element => {
  const [batchUploadStatus, setBatchUploadStatus] = useState<UploadStatus>('None');
  const [uploads, setUploads] = useState([] as FileToUploadWithProgress[]);
  const [uploadsError, setUploadsError] = useState<Error[]>();
  const [collections, setCollections] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [isEditable, setIsEditable] = useState(true);
  const [newTagEntityValue, setNewTagEntityValue] = useState('');
  const [newCollectionEntityValue, setNewCollectionEntityValue] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    const newUploads = uploads.concat(
      acceptedFiles
        .filter((file) => !uploads.some((u) => u.contentFile.name === file.name))
        .map((file) => {
          return { contentFile: file, isMainFile: false, status: 'None' };
        }),
    );

    setUploads(newUploads);
  };

  const onFileDeleteClick = (fileName: string) => {
    const newUploads = uploads.filter((upload) => upload.contentFile.name !== fileName);

    setUploads(newUploads);
  };

  useEffect(() => {
    const getUploadStatus = (uploads: FileToUploadWithProgress[]): UploadStatus => {
      // none have started
      if (uploads.every((x) => x.status === 'None')) {
        return 'None';
      }

      // all have succeeded
      if (uploads.every((x) => x.status === 'Success')) {
        return 'Success';
      }

      // some failed and none are running
      if (uploads.some((x) => x.status === 'Failed') && !uploads.some((x) => x.status === 'Running')) {
        return 'Failed';
      }

      // otherwise we are still waiting for some to finish
      return 'Running';
    };

    setBatchUploadStatus(getUploadStatus(uploads));
  }, [uploads]);

  const onProgressUpdated = (upload: FileToUploadWithProgress) => {
    const indexOfReplaced = uploads.findIndex((element) => element.contentFile === upload.contentFile);
    const newUploads = uploads.filter((element) => element.contentFile !== upload.contentFile);
    newUploads.splice(indexOfReplaced, 0, upload);

    if (upload.status === 'Failed') {
      const uploadError: Error[] = newUploads
        .filter((x) => x.status === 'Failed')
        .map((x) => ({
          name: 'UploadFailed',
          message: x.contentFile.name + ': ' + x.error?.message,
        }));
      setUploadsError(uploadError);
    }

    setUploads(newUploads);
  };

  const onUploadClick = async (uploads: FileToUploadWithProgress[]) => {
    setIsEditable(false);
    if (newTagEntityValue || newCollectionEntityValue) {
      setModalIsOpen(true);
    } else {
      setIsEditable(false);
      if (newTagEntityValue || newCollectionEntityValue) {
        setModalIsOpen(true);
      } else {
        await uploadItems(uploads);
      }
    }
  };

  const uploadItems = async (uploads: FileToUploadWithProgress[]) => {
    if (uploads.length > 0) {
      await handleUpload(uploads, tags, collections, onProgressUpdated);
    }
  };

  const onRetryClick = async (uploads: FileToUploadWithProgress[]) => {
    const failedUploads = uploads.filter((u) => u.status === 'Failed');
    await uploadItems(failedUploads);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const addCollection = async (newCollections: string) => {
    const newCollectionsArray = splitBySemicolon(newCollections);
    const updatedCollections = new Set(collections ? [...collections, ...newCollectionsArray] : newCollectionsArray);
    setCollections(Array.from(updatedCollections));
  };

  const addTag = async (newTags: string) => {
    const newTagsArray = splitBySemicolon(newTags);
    const updatedTags = new Set(tags ? [...tags, ...newTagsArray] : newTagsArray);
    setTags(Array.from(updatedTags));
  };

  const onTagRemoveClick = async (value: string) => {
    const updatedTags = [...tags];
    const removedItemIndex = updatedTags.findIndex((tag) => tag === value);

    if (removedItemIndex > -1) {
      updatedTags.splice(removedItemIndex, 1);
      setTags(updatedTags);
    }
  };

  const onCollectionRemoveClick = async (value: string) => {
    const updatedCollections = [...collections];
    const removedItemIndex = updatedCollections.findIndex((collection) => collection === value);

    if (removedItemIndex > -1) {
      updatedCollections.splice(removedItemIndex, 1);
      setCollections(updatedCollections);
    }
  };

  const closeModal = async (isConfirmed: boolean) => {
    setModalIsOpen(false);
    if (isConfirmed) {
      await uploadItems(uploads);
    } else {
      setIsEditable(true);
    }
  };
  return (
    <>
      <DropzoneWrapper
        onDrop={handleDrop}
        allowMultiple={allowMultipleUploads}
        FilesToUploadList={
          <FilesToUploadList
            onFileDeleteClick={onFileDeleteClick}
            setUploads={setUploads}
            uploads={uploads}
          ></FilesToUploadList>
        }
        disabled={batchUploadStatus !== 'None'}
      />
      <div data-testid="asset-tags">
        <h3>{`Tags`}</h3>
        <CamsAutocomplete
          minLength={2}
          onItemSelected={addTag}
          loadData={CamsApi.getFacetValues}
          onValueChange={setNewTagEntityValue}
          enableAutocomplete={true}
          facetName={'ContentTags'}
          labelName={'Tags'}
          displayLonger={true}
          disabled={!isEditable}
        />
        {tags.map((tag) => {
          return (
            <Tag
              style={{ marginTop: '10px' }}
              key={tag}
              value={tag}
              size="lg"
              removable={isEditable}
              onRemoveClick={(value?: ReactNode) => onTagRemoveClick(value?.toString() || '')}
            />
          );
        })}
      </div>
      <div data-testid="asset-collections">
        <h3>{`Collections`}</h3>
        <CamsAutocomplete
          minLength={2}
          onItemSelected={addCollection}
          loadData={CamsApi.getFacetValues}
          onValueChange={setNewCollectionEntityValue}
          enableAutocomplete={true}
          facetName={'ContentCollections'}
          labelName={'Collections'}
          displayLonger={true}
          disabled={!isEditable}
        />
        {collections.map((collection) => {
          return (
            <Tag
              style={{ marginTop: '10px' }}
              key={collection}
              value={collection}
              size="lg"
              removable={isEditable}
              onRemoveClick={(value?: ReactNode) => onCollectionRemoveClick(value?.toString() || '')}
            />
          );
        })}
      </div>
      <div className="upload-button-panel">
        {/* uploadbutton */}
        {batchUploadStatus === 'None' && (
          <Button
            variant="primary"
            disabled={!(uploads.length > 0)}
            onClick={() => onUploadClick(uploads)}
            data-testid="upload-button"
          >
            {'Create'}
          </Button>
        )}
        {/* inProgressButton */}
        {batchUploadStatus === 'Running' && (
          <Button disabled={true} variant="primary" data-testid="running-upload-button">
            {'Uploading...'}
          </Button>
        )}
        {/* retryButton */}
        {batchUploadStatus === 'Failed' && (
          <Button variant="primary" onClick={() => onRetryClick(uploads)} data-testid="retry-button">
            Retry
          </Button>
        )}
        {/* close button */}
        {batchUploadStatus === 'Success' && (
          <Button variant="primary" data-testid="close-button" onClick={onCloseClick}>
            Close
          </Button>
        )}
      </div>
      <ErrorToast error={uploadsError} />
      <Modal
        status="warning"
        show={modalIsOpen}
        onRequestHide={() => setModalIsOpen(false)}
        title={`Omitted tags/colections`}
        closeButton={true}
        footer={
          <>
            <button data-testid="confirm-cancel-button" className="btn btn-default" onClick={() => closeModal(false)}>
              Cancel
            </button>
            <button data-testid="confirm-proceed-button" className="btn btn-danger" onClick={() => closeModal(true)}>
              Proceed
            </button>
          </>
        }
      >
        Following tags and/or collections are not added:
        {[newTagEntityValue, newCollectionEntityValue].filter(Boolean).join(', ')}. If you would like to continue
        without adding them please click Proceed. If you would like to add them, please click Cancel and then the green
        check mark to approve addition of those values. Finally, please click the create button again.
      </Modal>
    </>
  );
};
