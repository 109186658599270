import { Card, Checkbox } from '@cimpress/react-components';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { DesignManagementAsset } from '../../../api/Cams/model/Design/DesignManagementAsset';
import { AppContext } from '../../../App';
import { DesignAssetPreview } from '../../../components/DesignAssetPreview';
import { EnsembleLinePreview } from '../../../components/EnsembleLinePreview';
import { getDesignManagementAssetDetailsPageRoute } from '../../../Routes/DesignManagementAssetRoutes';

interface DesignLargeTileProps {
  design: DesignManagementAsset;
  onAssetSelected: (asset: DesignManagementAsset, isSelected: boolean) => void;
}

export const DesignLargeTile = ({ design, onAssetSelected }: DesignLargeTileProps): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [isSelected, setIsSelected] = useState(false);
  const designEnsembles = design.latestVersion.ensembleLine?.ensembles;
  const onSelected = (isSelected: boolean) => {
    setIsSelected(isSelected);
    onAssetSelected(design, isSelected);
  };
  return (
    <Card className="asset-large-tile">
      <Checkbox
        className={`checkbox ${isSelected ? 'checkbox-checked' : ''}`}
        checked={isSelected}
        label={!isSelected ? `Select to download` : `Selected to download`}
        onChange={() => {
          onSelected(!isSelected);
        }}
      />
      <Link
        to={getDesignManagementAssetDetailsPageRoute(
          tenant.contentAuthoringAccountId,
          tenant.contentAuthoringAreaId,
          design.id,
        )}
      >
        {designEnsembles && designEnsembles.length > 0 ? (
          <EnsembleLinePreview ensembles={designEnsembles} />
        ) : (
          <div className="preview-wrapper preview-wrapper-larger">
            <DesignAssetPreview
              designVersion={design.latestVersion}
              physicalSpecId={design.designPhysicalSpecId}
              width={500}
            />
          </div>
        )}
      </Link>
    </Card>
  );
};
