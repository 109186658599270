import { Snackbar } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';

interface Props {
  error?: Error[];
  keepOpen?: boolean;
  onDismiss?: () => void;
}

export const ErrorToast = ({ error, keepOpen, onDismiss }: Props): JSX.Element => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (!error) {
      return;
    }

    setErrorMessages(error?.map((x) => x?.message || error?.toString() || 'Unexpected error occured.'));
    setShowSnackbar(true);
  }, [error]);

  return (
    <Snackbar
      show={showSnackbar}
      status={'danger'}
      delay={keepOpen ? undefined : 5000}
      onHideSnackbar={() => {
        if (onDismiss) {
          onDismiss();
        }
        setShowSnackbar(false);
      }}
    >
      {errorMessages.map((x) => (
        <div key={x} data-testid="error-toast-span">
          {x}
        </div>
      ))}
    </Snackbar>
  );
};
