import React from 'react';
import { Button } from '@cimpress/react-components';
import { AssetsFilters } from '../Model/AssetsFilters';
import { isEmptySearchTerm, SearchTerm } from '../Model/SearchTerm';

interface Props {
  filters: AssetsFilters;
  search: SearchTerm;
  onFiltersSearchCleared: () => void;
}

export const ClearAllFiltersSearchButton = ({ filters, search, onFiltersSearchCleared }: Props): JSX.Element => {
  let isAnyFilterActive = false;

  for (const value of Object.values(filters)) {
    if (value.length > 0) {
      isAnyFilterActive = true;
    }
  }
  if (isEmptySearchTerm(search)) {
    isAnyFilterActive = true;
  }

  return (
    <>
      {isAnyFilterActive && (
        <Button variant="anchor" onClick={onFiltersSearchCleared} data-testid="clear-all-filters-button">
          Clear all filters
        </Button>
      )}
    </>
  );
};
