import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import { PatternAssetRequestBody } from './model/Pattern/PatternAssetRequestBody';
import { PatternManagementAsset } from './model/Pattern/PatternManagementAsset';
import * as RequestUtils from '../RequestUtils';
import { UpdatePatternAssetRequestBody } from './model/Pattern/UpdatePatternAssetRequestBody';
import { PatternAssetVersionRequestBody } from './model/Pattern/PatternAssetVersionRequestBody';
import { PatternManagementAssetVersion } from './model/Pattern/PatternManagementAssetVersion';
const camsApiHost = config.camsApiUrl;

export async function createPatternAsset(
  tenant: Tenant,
  requestBody: PatternAssetRequestBody,
): Promise<ApiResponseOrError<PatternManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/patterns`,
  );
  const config = RequestUtils.axiosDefaultConfig();

  return await RequestUtils.doPostRequest<PatternManagementAsset>(url, requestBody, config);
}

export async function createPatternAssetVersion(
  tenant: Tenant,
  patternId: string,
  requestBody: PatternAssetRequestBody,
): Promise<ApiResponseOrError<PatternManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/patterns/${patternId}/versions`,
  );
  const config = RequestUtils.axiosDefaultConfig();
  return await RequestUtils.doPostRequest<PatternManagementAsset>(url, requestBody, config);
}

export async function updatePatternAsset(
  id: string,
  tenant: Tenant,
  requestBody: UpdatePatternAssetRequestBody,
): Promise<ApiResponseOrError<PatternManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/patterns/${id}`,
  );

  return await RequestUtils.doPatchRequest<PatternManagementAsset>(url, requestBody);
}

export async function updatePatternVersionAsset(
  id: string,
  version: number,
  tenant: Tenant,
  requestBody: PatternAssetVersionRequestBody,
): Promise<ApiResponseOrError<PatternManagementAssetVersion>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/patterns/${id}/versions/${version}:publish`,
  );

  return await RequestUtils.doPatchRequest<PatternManagementAssetVersion>(url, requestBody);
}
