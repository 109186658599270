import { Card, Tooltip } from '@cimpress/react-components';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './LinkedAssetTile.scss';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { AssetPreview } from './AssetPreview';
import { formatDateTime } from '../Tools/DateTimeHelpers';
import { getAssetVersionName } from '../Tools/AssetHelpers';
import { DesignManagementAsset } from '../api/Cams/model/Design/DesignManagementAsset';
import { Tenant } from '../api/model/Tenant';
import { AppContext } from '../App';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { DesignAssetPreview } from './DesignAssetPreview';
import * as CamsApi from '../api/Cams/cams-api';
import { isDesignManagementAssetVersion } from '../api/Cams/model/Design/DesignManagementAssetVersion';

interface Props {
  assetVersion: AnyAssetVersion;
  toLink: string;
}

export const LinkedAssetTile = ({ assetVersion, toLink }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const width = 200;
  const [physicalSpecId, setPhysicalSpecId] = useState<string | undefined>(undefined);

  const getDPS = async (id: string, tenant: Tenant): Promise<void> => {
    if (!isDesignManagementAssetVersion(assetVersion)) return;
    const designAsset = await CamsApi.getAsset<DesignManagementAsset>(tenant, id);
    if (isSuccessfulResponse(designAsset)) {
      setPhysicalSpecId((designAsset.data as DesignManagementAsset).designPhysicalSpecId);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDPS(assetVersion.assetId, tenant);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="asset-card-style">
      <Link to={toLink}>
        <div className="asset-preview">
          {isDesignManagementAssetVersion(assetVersion) ? (
            <DesignAssetPreview
              designVersion={assetVersion}
              physicalSpecId={physicalSpecId}
              width={width}
              showAsLink={true}
            />
          ) : (
            <AssetPreview assetVersion={assetVersion} width={width} />
          )}
        </div>
        <div className="asset-detail">
          <div>
            <div className="asset-header">
              <h3 className="text-primary card-title">
                <p>{getAssetVersionName(assetVersion)}</p>
              </h3>
            </div>

            <div className="asset-additional-info">
              <p className="card-text">
                Last modified by {assetVersion.createdByFullname}
                <br />
                <Tooltip direction={'right'} contents={formatDateTime(assetVersion.created)}>
                  {formatDateTime(assetVersion.created, true)}
                </Tooltip>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
};
