import React from 'react';
import { Tag } from '@cimpress/react-components';
import './TagsList.scss';
import { FacetValue } from '../../../api/Cams/model/FacetValue';

interface Props {
  allItems: FacetValue[];
  selectedItems: string[];
  onItemSelected: (items: string[]) => void;
}

export const TagsList = ({ allItems, selectedItems, onItemSelected }: Props): JSX.Element => {
  const onSelect = (item: string, isSelected: boolean) => {
    const selectedValues = isSelected
      ? [...selectedItems, item]
      : selectedItems.filter((selected) => selected !== item);

    onItemSelected(selectedValues);
  };

  const isSelected = (item: FacetValue) => {
    return selectedItems.find((i) => i === item.value) !== undefined;
  };

  return (
    <>
      <form className="tags-form">
        {selectedItems.map((item) => {
          return (
            <span data-testid="tag" key={item} className="selected" onClick={() => onSelect(item, false)}>
              <Tag label={`${item}`} size="lg" removable />
            </span>
          );
        })}
        {allItems.map((item) => {
          const tagLabel = item.usesCount >= 0 ? `${item.label} ${item.usesCount}x` : item.label;
          return isSelected(item) ? (
            <span key={item.label} data-testid="tag" />
          ) : (
            <span key={item.value} data-testid="tag" onClick={() => onSelect(item.value, true)}>
              <Tag label={tagLabel} size="lg" />
            </span>
          );
        })}
      </form>
    </>
  );
};
