import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import * as CamsBackgroundsApi from '../api/Cams/cams-api-backgrounds';
import './BackgroundDetailPage.scss';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { BackgroundHeader } from './BackgroundHeader';
import { Button } from '@cimpress/react-components';
import { UpdateBackgroundAssetControl } from '../Uploads/UpdateBackgroundAssetControl';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import { Toast } from '../components/Toast';
import { BackgroundManagementAsset } from '../api/Cams/model/Background/BackgroundManagementAsset';
import { BackgroundManagementAssetVersion } from '../api/Cams/model/Background/BackgroundManagementAssetVersion';
import { DeleteAsset } from '../components/DeleteAsset';
import { Tenant } from '../api/model/Tenant';
import { ToAssetCategory } from '../AssetsListPage/AssetCategory';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BackgroundDetailPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();
  const history = useHistory();

  const query = useQuery();
  const version = query.get('version') || undefined;
  const message = query.get('message') || undefined;

  const [background, setBackground] = useState<BackgroundManagementAsset>();
  const [backgroundVersion, setBackgroundVersion] = useState<BackgroundManagementAssetVersion>();
  const [firstVersion, setFirstVersion] = useState<BackgroundManagementAssetVersion>();
  const [, setAssetError] = useState<string>();
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState(true);
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(true);

  const loadAsset = async (id: string, version?: string | number) => {
    setIsLoading(true);
    setIsDeleteButtonShown(tenant.userPermissions.canWrite);
    setIsPublishButtonEnabled(tenant.userPermissions.canPublish);
    const response = await CamsApi.getAsset<BackgroundManagementAsset>(tenant, id);
    setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      if (response.data.assetKind !== 'background') {
        history.push(
          getVariableAssetDetailPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            ToAssetCategory(response.data.assetType, response.data.assetKind),
            id,
          ),
        );
        return;
      }
      const background = response.data as BackgroundManagementAsset;
      setBackground(background);

      const usedBackgroundVersion = await getUsedBackgroundVersion(background, version);

      const firstVersion =
        usedBackgroundVersion.versionNumber === 1
          ? usedBackgroundVersion
          : await getUsedBackgroundVersion(background, 1);
      setFirstVersion(firstVersion);

      setIsPublishButtonEnabled((isPublishButtonEnabled) => isPublishButtonEnabled && !background.isDeleted);
      setBackgroundVersion(usedBackgroundVersion);
    }
  };

  const getUsedBackgroundVersion = async (
    background: BackgroundManagementAsset,
    version: string | number | undefined,
  ) => {
    let usedBackgroundVersion = background.latestVersion;

    const versionNumber = parseInt(version?.toString() || '');
    if (versionNumber) {
      const versionResponse = await CamsApi.getAssetVersion(tenant, id, versionNumber);
      if (isSuccessfulResponse(versionResponse)) {
        usedBackgroundVersion = versionResponse.data as BackgroundManagementAssetVersion;
      }
    }
    return usedBackgroundVersion;
  };

  const handleUpdate = async () => {
    await loadAsset(id);
  };

  const handlePublish = async (tenant: Tenant, id: string, version: number, published: boolean) => {
    setIsLoading(true);
    const publishResponse = await CamsBackgroundsApi.updateBackgroundVersionAsset(id, version, tenant, {
      isDiscoverable: published,
    });
    if (!isSuccessfulResponse(publishResponse)) {
      setIsLoading(false);
      return publishResponse;
    }

    const getAssetResponse = await CamsApi.getAsset<BackgroundManagementAsset>(tenant, id);
    if (isSuccessfulResponse(getAssetResponse)) {
      setBackground(getAssetResponse.data);
      setBackgroundVersion(publishResponse.data);
    }
    setIsLoading(false);

    return publishResponse;
  };

  const handleSaveProperty = async (tenant: Tenant, id: string, property: { [key: string]: string[] }) => {
    const response = await CamsBackgroundsApi.updateBackgroundAsset(id, tenant, property);
    if (response.responseType === 'success') {
      setBackground(response.data);
      const usedBackgroundVersion = await getUsedBackgroundVersion(response.data, version);
      setBackgroundVersion(usedBackgroundVersion);
    }
    return response;
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAsset(id, version);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, version]);

  const onAssetVersionCreated = async (assetId: string, version: number) => {
    setIsCreatingNewVersion(false);
    await loadAsset(assetId, version);
  };

  const isLatestVersion = background?.latestVersion.versionNumber === backgroundVersion?.versionNumber;

  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="background-details-title">Background detail</h2>
          {background && isLatestVersion && (
            <div className="detail-header-buttons">
              {isCreatingNewVersion ? (
                <Button variant="primary" className="btn-danger" onClick={() => setIsCreatingNewVersion(false)}>
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="background-upload-new-version-button"
                  data-testid="upload-new-version-button"
                  onClick={() => setIsCreatingNewVersion(true)}
                  disabled={!tenant.userPermissions.canWrite}
                >
                  Upload new version
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      {background && isCreatingNewVersion && (
        <UpdateBackgroundAssetControl
          backgroundAssetId={background.id}
          masterAssetId={background.latestVersion?.masterFile?.assetId}
          onUpload={onAssetVersionCreated}
        />
      )}

      {isLoading && <Loading />}

      {!background && !isLoading && <h4 data-testid="detail-not-found">Background not found.</h4>}

      {background && backgroundVersion && firstVersion && (
        <div className="row section">
          <div className="col-sm-12">
            <BackgroundHeader
              background={background}
              backgroundVersion={backgroundVersion}
              firstVersion={firstVersion}
              isLatestVersion={isLatestVersion}
              isPublishButtonEnabled={isPublishButtonEnabled}
              onPublish={(tenant, published) =>
                handlePublish(tenant, background.id, backgroundVersion.versionNumber, published)
              }
              onSaveTags={(tenant, keywords) => handleSaveProperty(tenant, background.id, { contentTags: keywords })}
              onSaveCollections={(tenant, collections) =>
                handleSaveProperty(tenant, background.id, { contentCollections: collections })
              }
              onSaveDestinations={(tenant, destinations) =>
                handleSaveProperty(tenant, background.id, { accessibleTo: destinations })
              }
            />
          </div>
        </div>
      )}

      {background && !background.isDeleted && (
        <div className="row section">
          <div className="col-sm-6">
            <DeleteAsset
              assetId={background.id}
              assetCategory={'background'}
              name={`${backgroundVersion?.masterFile?.uploadInfo?.fileName ?? 'this'} ${'background'.format()}`}
              onDelete={handleUpdate}
              showButton={isDeleteButtonShown}
              disabled={!background.isDeletable || isLoading}
            />
          </div>
        </div>
      )}
      {message && <Toast message={message} />}
    </div>
  );
};

export default BackgroundDetailPage;
