import { tenantRoutePrefix } from '../Tools/Routes';

export function getClipartVersionsPageRoute(accountId?: string, contentAreaId?: string, clipartId?: string): string {
  const path = `${tenantRoutePrefix}cliparts/:id/versions`;
  if (accountId && contentAreaId && clipartId) {
    return path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', clipartId);
  } else {
    return path;
  }
}

export function getClipartDetailsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  clipartId?: string,
  clipartVersion?: number,
  message?: string,
): string {
  let path = `${tenantRoutePrefix}cliparts/:id`;
  const messageSuffix = message ? `message=${message}` : '';

  if (!(accountId && contentAreaId && clipartId)) return path;

  path = path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', clipartId);
  path = path.concat('?');
  clipartVersion && (path = path.concat('version=', clipartVersion.toString()));
  return path + messageSuffix;
}

export const getClipartDetailsForSpecificVersionPageRoute = (): string =>
  `${tenantRoutePrefix}cliparts/:id?version=:version`;

export function getClipartManagementAssetsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  queryString?: string,
): string {
  const path = `${tenantRoutePrefix}cliparts`;
  if (accountId && contentAreaId) {
    const updatedPath = path.replace(':accountId', accountId).replace(':areaId', contentAreaId);
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
}
