import { TenantDescription } from '../api/model/TenantDescription';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { getVariableAssetPageRoute } from '../Routes/GenericRoutes';
import { hasAccessToCategory } from './AssetCategoryAccessUtil';

export const pageToRedirect = (tenant: TenantDescription, assetCategory: AssetCategory | undefined): string => {
  const allowedAssetCategories = tenant.settings.features.allowedAssetCategories;

  const handleAssetCategory = (assetCategory: AssetCategory, tenant: TenantDescription) => {
    return getVariableAssetPageRoute(tenant.contentAuthoringAccountId, tenant.contentAuthoringAreaId, assetCategory);
  };

  let category;
  if (assetCategory === undefined || assetCategory === null) {
    // Asset category is unavailable
    // We return the 1st explicitly allowed category or `design`, if none exist (all are implicitly allowed)
    category =
      allowedAssetCategories === null || allowedAssetCategories.length === 0 ? `design` : allowedAssetCategories[0];
  } else if (hasAccessToCategory(tenant, assetCategory)) {
    // Current tenant has access to the category
    // We just find and return it or assetCategory, if none exist (again, all are implicitly allowed and Asset category is available)
    category =
      allowedAssetCategories === null || allowedAssetCategories.length === 0
        ? assetCategory
        : allowedAssetCategories.find((x) => x.toLowerCase() === assetCategory.toLowerCase());
  } else {
    // Asset category is available but tenant does not have access to it
    // Assign the 1st allowed category
    category = allowedAssetCategories[0];
  }

  return handleAssetCategory(category as AssetCategory, tenant);
};
