import { DesignManagementAsset } from '../api/Cams/model/Design/DesignManagementAsset';
import { DesignManagementAssetVersion } from '../api/Cams/model/Design/DesignManagementAssetVersion';
import { Ensemble } from '../api/Cams/model/EnsembleLineInfo/Ensemble';
import { PanelInfoWithVersion } from '../api/Cams/model/EnsembleLineInfo/PanelInfo';

export function getDesignCompiledTemplatesByVersion(
  designVersion?: DesignManagementAssetVersion,
): PanelInfoWithVersion[] {
  if (!designVersion || !designVersion.ensembleLine) {
    return [];
  }

  if (designVersion.ensembleLine.ensembles.length > 0) {
    const panels: PanelInfoWithVersion[] = [];

    designVersion.ensembleLine.ensembles.map((e) => {
      e.panels.map((p) => {
        panels.push({
          templateId: p.templateId,
          panelName: p.panelName,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          templateVersion: e.version,
          dpsId: p.dpsId,
        });
      });
    });

    return panels;
  }

  return [];
}

export function getProminentEnsemble(ensembles?: Ensemble[]): Ensemble | undefined {
  if (ensembles === undefined || ensembles.length === 0) {
    return undefined;
  }

  return ensembles.find((e) => e.isProminentVariant) || ensembles[0];
}

// Ordering ensembles:
// First take prominent ensemble
// Then all the rest ordered by color ordinal
export function orderEnsembles(ensembles?: Ensemble[]): Ensemble[] {
  if (ensembles === undefined || ensembles.length === 0) {
    return [];
  }

  const getColorOrdinal = (ensemble: Ensemble) =>
    ensemble.variantInfos.find((vi) => vi.variantType === 'color')?.ordinal;

  const ordered = ensembles.sort((a, b) => {
    {
      if (a.isProminentVariant) return -1;
      if (b.isProminentVariant) return 1;
      return (getColorOrdinal(a) || 0) - (getColorOrdinal(b) || 0);
    }
  });

  return ordered;
}

export function getProminentPanelInfo(designVersion?: DesignManagementAssetVersion): PanelInfoWithVersion | undefined {
  const prominentEnsemble = getProminentEnsemble(designVersion?.ensembleLine?.ensembles);
  if (prominentEnsemble === undefined) {
    return undefined;
  }

  const firstPanel = prominentEnsemble.panels[0];

  return {
    templateId: firstPanel.templateId,
    panelName: firstPanel.panelName,
    templateVersion: prominentEnsemble.version,
    dpsId: firstPanel.dpsId,
  };
}

export function getProminentPanelInfos(designVersion?: DesignManagementAssetVersion): PanelInfoWithVersion[] {
  const prominentEnsemble = getProminentEnsemble(designVersion?.ensembleLine?.ensembles);
  if (prominentEnsemble === undefined) {
    return [];
  }

  const panels = prominentEnsemble.panels.map((panel) => {
    return {
      templateId: panel.templateId,
      panelName: panel.panelName,
      templateVersion: prominentEnsemble.version,
      dpsId: panel.dpsId,
    };
  });

  return panels;
}

export function getDesignName(
  design: DesignManagementAsset,
  designVersion?: DesignManagementAssetVersion,
  defaultValue = '',
): string {
  return design.designName || designVersion?.masterFile?.uploadInfo?.fileName || defaultValue;
}
