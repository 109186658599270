import React, { useContext } from 'react';
import { AnyAssetVersion } from '../../api/Cams/model/AnyAssetVersion';
import { ManagementAssetVersion } from '../../api/Cams/model/ManagementAssetVersion';
import { Tenant } from '../../api/model/Tenant';
import { AppContext } from '../../App';
import { AssetCategory, ToAssetCategory as toAssetCategory } from '../../AssetsListPage/AssetCategory';
import { LinkedAssetTile } from '../../components/LinkedAssetTile';
import { getVariableAssetDetailPageRoute } from '../../Routes/GenericRoutes';

interface Props {
  assetVersions: ManagementAssetVersion[];
}

const getToLink = (assetVersion: ManagementAssetVersion, tenant: Tenant): string => {
  const assetCategory: AssetCategory = toAssetCategory(assetVersion.assetType, assetVersion.assetKind || '');
  return getVariableAssetDetailPageRoute(
    tenant.contentAuthoringAccountId,
    tenant.contentAuthoringAreaId,
    assetCategory,
    assetVersion.assetId,
  );
};

export const UsedInManagementAssets = ({ assetVersions }: Props): JSX.Element | null => {
  const { tenant } = useContext(AppContext);
  return (
    <>
      <h2 className="section-title">Content using this asset</h2>
      <div className="designs-grid" data-testid="used-in-management-assets-grid">
        {assetVersions.map((version) => {
          return (
            <LinkedAssetTile
              key={`${version.assetId}-${version.versionNumber}`}
              assetVersion={version as AnyAssetVersion}
              toLink={getToLink(version, tenant)}
            />
          );
        })}
      </div>
    </>
  );
};
