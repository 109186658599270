import { Button } from '@cimpress/react-components';
import React, { useContext, useEffect, useState } from 'react';
import './CamsAutocomplete.scss';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiResponseOrError } from '../api/ApiResponseOrError';
import Autosuggest from 'react-autosuggest';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Tenant } from '../api/model/Tenant';
import { AppContext } from '../App';
import { FacetName } from '../api/Cams/model/FacetName';
import { FacetValue } from '../api/Cams/model/FacetValue';
import { defaultAutocompletePageSize } from '../AssetsListPage/Model/Constants';
import { TagValidation } from '../Tools/TagValidation';
import { useDebounce } from '../customHooks/useDebounce';

interface Props {
  minLength: number;
  onItemSelected: (value: string) => Promise<void>;
  loadData: (
    tenant: Tenant,
    name: FacetName,
    pageSize: number,
    pageNumber: number,
    value?: string,
  ) => Promise<ApiResponseOrError<FacetValue[]>>;
  enableAutocomplete: boolean;
  validateNewValue?: (value: string) => TagValidation;
  onValueChange?: (value: string) => void;
  facetName: FacetName;
  labelName: string;
  displayLonger?: boolean;
  disabled?: boolean;
}

export const CamsAutocomplete = ({
  minLength,
  onItemSelected,
  loadData,
  validateNewValue,
  onValueChange,
  enableAutocomplete,
  facetName,
  labelName,
  displayLonger,
  disabled,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [suggestions, setSuggestions] = useState<FacetValue[]>([]);
  const [newEntityValue, setNewEntityValue] = useState('');
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchInput = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debouncedSearchInput && enableAutocomplete) {
      loadSuggestions(debouncedSearchInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput]);

  const isSearchTermValid = (searchTerm: string | undefined): boolean => {
    if (searchTerm === undefined) {
      return false;
    }

    const searchTermTrimmed = searchTerm.trim();
    return searchTermTrimmed.length < minLength ? false : true;
  };

  const loadSuggestions = async (searchValue: string): Promise<void> => {
    const suggestions = await getSuggestions(searchValue);
    setSuggestions(suggestions);
  };

  const getSuggestions = async (searchTerm: string | undefined): Promise<FacetValue[]> => {
    if (!isSearchTermValid(searchTerm)) {
      return [];
    }

    const response = await loadData(tenant, facetName, defaultAutocompletePageSize, 0, (searchTerm as string).trim());
    if (isSuccessfulResponse(response)) {
      return response.data;
    }

    return [];
  };

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setSearchValue(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion: FacetValue) => {
    return suggestion.value;
  };

  const renderSuggestion = (suggestion: FacetValue) => {
    const matches = match(suggestion.label, newEntityValue);
    const parts = parse(suggestion.label, matches);

    const result = parts.map((part) => (part.highlight ? <strong>{part.text}</strong> : <span>{part.text}</span>));

    return <span>{result}</span>;
  };

  const onSuggestionSelected = async (_event: any, { suggestionValue }: { suggestionValue: string }) => {
    setIsLoading(true);
    await onItemSelected(suggestionValue);
    onSetNewValue('');
    setIsLoading(false);
  };

  const inputProps = {
    placeholder: `Add ${labelName.toLowerCase()}...`,
    value: newEntityValue,
    onChange: (_event: any, { newValue }: any) => {
      onSetNewValue(newValue);
    },
    className:
      newEntityValue && isSearchTermValid(newEntityValue)
        ? `react-autosuggest__input valid`
        : `react-autosuggest__input invalid`,
    disabled: (disabled || isLoading) ?? false,
  };

  const formSubmitted = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newEntityValue && isSearchTermValid(newEntityValue)) {
      setIsLoading(true);
      await onItemSelected(newEntityValue);
      setIsLoading(false);
      validateNewValue
        ? validateNewValue(newEntityValue).isValid === true
          ? onSetNewValue('')
          : onSetNewValue(newEntityValue)
        : onSetNewValue('');
    }
  };

  const onSetNewValue = (value: string) => {
    setNewEntityValue(value);
    if (onValueChange) onValueChange(value);
  };

  const formClassName = displayLonger ? 'autosuggest-wrapper autosuggest-longer' : 'autosuggest-wrapper';

  return (
    <>
      <form className={formClassName} onSubmit={formSubmitted}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {newEntityValue && isSearchTermValid(newEntityValue) && (
          <Button
            size="sm"
            variant="default"
            style={{ color: 'green' }}
            data-testid="autosuggest-submit-button"
            disabled={disabled || isLoading}
          >
            <i className={isLoading ? 'fa fa-hourglass-half button-icon' : 'fa fa-check button-icon'} />
          </Button>
        )}
      </form>
    </>
  );
};
