import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import { Link, useParams } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { AppContext } from '../App';
import { Tenant } from '../api/model/Tenant';
import { TemplatePreview } from '../components/TemplatePreview';
import Loading from '../components/Loading';
import { Checkbox, CopyButton, Table } from '@cimpress/react-components';
import { TableColumnProps } from '@cimpress/react-components/lib/Table/definitions';
import { AvailableCultures } from '../Tools/Cultures';
import './DesignCulturePreviewsPage.scss';
import { getProminentPanelInfos } from '../Tools/DesignHelpers';
import * as DtsApi from '../api/Dts/dts-api';
import * as LocalizationApi from '../api/Localization/localization-api';
import { toTemplateToken } from '../Tools/TemplateToken';
import { getDesignManagementAssetsPageRoute } from '../Routes/DesignManagementAssetRoutes';
import { DesignManagementAsset } from '../api/Cams/model/Design/DesignManagementAsset';
import { PanelInfoWithVersion } from '../api/Cams/model/EnsembleLineInfo/PanelInfo';
import { serializeQueryParams } from '../AssetsListPage/QueryString/QueryStringUtils';

interface QueryStringParams {
  id: string;
}

interface CultureCoverageCheckResult {
  panelInfo: PanelInfoWithVersion;
  error?: string | undefined;
  cultures: string[];
}

export const DesignCulturePreviewsPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();

  const [designLoadError, setDesignLoadError] = useState<string>();
  const [previewErrors, setPreviewErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<TableColumnProps[]>([]);
  const [cultures, setCultures] = useState<string[]>(AvailableCultures);
  const [renderableCultures, setRenderableCultures] = useState<string[]>(AvailableCultures);
  const [hideErrorPreviews, setHideErrorPreviews] = useState<boolean>(true);
  const [designConceptId, setDesignConceptId] = useState<string>();

  const onHideErrorPreviews = (hide: boolean): void => {
    setHideErrorPreviews(hide);
    if (hide) {
      setCultures(renderableCultures);
    } else {
      setCultures(AvailableCultures);
    }
  };

  useEffect(() => {
    const generateColumns = (panelInfos: PanelInfoWithVersion[]): TableColumnProps[] => {
      type tableCellType = { original: string };
      const columns: TableColumnProps[] = [
        {
          Header: 'Culture',
          accessor: 'culture',
          Cell: ({ original: culture }: tableCellType) => {
            return culture;
          },
        },
      ];

      for (const panel of panelInfos) {
        const templateToken = toTemplateToken(panel.templateId, panel.dpsId, panel.templateVersion);
        const panelColumn: TableColumnProps = {
          Header: (
            <>
              {panel.panelName}
              <div>
                <CopyButton variant="anchor" value={templateToken}>
                  Copy Template Token
                </CopyButton>
              </div>
            </>
          ),
          accessor: panel.panelName,
          Cell: ({ original: culture }: tableCellType) => {
            const preview = (
              <div className="preview-column">
                {cultures.includes(culture) && (
                  <TemplatePreview
                    templateVersion={panel.templateVersion}
                    designTemplateId={panel.templateId}
                    physicalSpecId={panel.dpsId}
                    showAsLink={true}
                    culture={culture}
                    useAdditionalQueryStringParameter={true}
                  />
                )}
                {!cultures.includes(culture) && <span>Localization Not Available</span>}
              </div>
            );
            return preview;
          },
        };

        columns.push(panelColumn);
      }

      return columns;
    };

    const identifyRenderableTemplateCultures = async (
      panelInfo: PanelInfoWithVersion,
    ): Promise<CultureCoverageCheckResult> => {
      const localizationKeysResponse = await DtsApi.getLocalizationKeys(
        panelInfo.templateId,
        panelInfo.templateVersion.toString(),
      );
      if (!isSuccessfulResponse(localizationKeysResponse)) {
        return {
          cultures: [],
          panelInfo: panelInfo,
          error: `Error loading localization keys for template '${panelInfo.templateId}'`,
        };
      }

      if (localizationKeysResponse.data.length === 0) {
        return {
          cultures: AvailableCultures,
          panelInfo: panelInfo,
        };
      }

      const culturesResponse = await LocalizationApi.getCultureCoverage(localizationKeysResponse.data);
      if (!isSuccessfulResponse(culturesResponse)) {
        return {
          cultures: [],
          panelInfo: panelInfo,
          error: `Error identifying culture coverage for template '${panelInfo.templateId}'`,
        };
      }

      return {
        cultures: culturesResponse.data,
        panelInfo: panelInfo,
      };
    };

    const intersect = (left: string[], right: string[]): string[] => {
      return left.filter((item) => right.includes(item));
    };

    const identifyRenderableCultures = async (panelInfos: PanelInfoWithVersion[]): Promise<string[]> => {
      const promises: Promise<CultureCoverageCheckResult>[] = [];
      for (const panelInfo of panelInfos) {
        promises.push(identifyRenderableTemplateCultures(panelInfo));
      }

      const results = await Promise.all(promises);

      const errors = results.map((result) => result.error).filter((error): error is string => error !== undefined);
      setPreviewErrors(errors);

      const panelCultures = results.map((result) => result.cultures);
      let cultureCoverageResult = panelCultures[0];
      for (let i = 1; i < panelCultures.length; i++) {
        cultureCoverageResult = intersect(cultureCoverageResult, panelCultures[i]);
      }

      return cultureCoverageResult;
    };

    const loadDesignCultures = async (tenant: Tenant, id: string) => {
      setIsLoading(true);

      const response = await CamsApi.getAsset<DesignManagementAsset>(tenant, id);
      setDesignLoadError(getResponseError(response));
      if (isSuccessfulResponse(response)) {
        const design = response.data;
        setDesignConceptId(design?.designConceptId);
        const panelInfos = getProminentPanelInfos(design.latestVersion);
        if (panelInfos === undefined || panelInfos.length === 0) {
          setDesignLoadError('CAM does not have published templates on record for this design.');
          return;
        }

        const renderableCultures = await identifyRenderableCultures(panelInfos);
        setRenderableCultures(renderableCultures);
        setCultures(renderableCultures);

        const columns = generateColumns(panelInfos);
        setColumns(columns);
      }

      setIsLoading(false);
    };

    const fetchData = async () => {
      await loadDesignCultures(tenant, id);
    };

    if (columns.length === 0) {
      fetchData();
    }
  }, [tenant, id, renderableCultures, hideErrorPreviews, columns.length, cultures]);

  return (
    <div className="container-fluid">
      <div className="row cams-page-title">
        <div className="col-sm-12">
          <h2 style={{ marginBottom: '15px' }}>Design Culture Previews</h2>
          {designConceptId && (
            <span key="DesignConceptId">
              DesignConceptId:&nbsp;
              <Link
                to={getDesignManagementAssetsPageRoute(
                  tenant.contentAuthoringAccountId,
                  tenant.contentAuthoringAreaId,
                  serializeQueryParams({ filters: { designConceptId: [designConceptId] } }),
                )}
              >
                {designConceptId}
              </Link>
            </span>
          )}
        </div>
      </div>

      {isLoading && <Loading />}

      {!isLoading && (
        <Checkbox
          label="Hide failed previews"
          checked={hideErrorPreviews}
          onChange={() => onHideErrorPreviews(!hideErrorPreviews)}
        />
      )}

      {!isLoading && designLoadError && <h4>{designLoadError}</h4>}
      {!isLoading && previewErrors && previewErrors.length > 0 && (
        <div>
          <h4>Error identifying available cultures for previews:</h4>
          {previewErrors.map((error) => (
            <p key={error}>{error}</p>
          ))}
        </div>
      )}

      {!isLoading && cultures.length > 0 && (
        <div className={`flex-column-align-center`}>
          <Table
            columns={columns}
            data={cultures}
            pageSize={cultures.length}
            className={`simple-table max-width-auto`}
          />
        </div>
      )}

      {!isLoading && cultures.length === 0 && <span>Design is not renderable in any of the cultures. </span>}
    </div>
  );
};
