import { AssetVersion } from '../AssetVersion';
import { CreativeAssetVersion } from '../CreativeAssetVersion';
import { ColorPaletteContentAssetVersion } from './ColorPaletteContentAssetVersion';

export interface ColorPaletteManagementAssetVersion extends AssetVersion {
  assetKind: 'colorPalette';
  assetType: 'management';

  colorPaletteJson: CreativeAssetVersion;
  contentAsset: ColorPaletteContentAssetVersion;
}

export const isColorPaletteManagementAssetVersion = (x: AssetVersion): x is ColorPaletteManagementAssetVersion => {
  return x.assetType === 'management' && x.assetKind === 'colorPalette';
};
