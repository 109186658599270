import React from 'react';
import { Column } from 'react-table';
import { ReactTablev6 } from '@cimpress/react-components';
import { generatePreviewUrlForColor } from '../Tools/CimDocUtil';
import { ColorPalette } from '../Uploads/ColorPalette';

interface Props {
  colorPalette: ColorPalette;
}

export const ColorPaletteTabularView = ({ colorPalette }: Props): JSX.Element => {
  const colorPaletteLocal = Object.entries(colorPalette).map(([k, v]) => ({
    name: k,
    cmyk: v.cmyk,
    preview: (
      <img className="color-table-preview" src={generatePreviewUrlForColor(v.cmyk, 500, '30mm')} alt="Preview" />
    ),
  }));
  const columnDefaultStyle = { overflow: 'hidden', textOverflow: 'ellipsis' };
  const columns = [
    {
      Header: 'Preview',
      accessor: 'preview',
      style: { maxHeight: '200px' },
      width: 100,
    },
    {
      Header: 'Color Category',
      accessor: 'name',
      style: { maxHeight: '200px' },
    },
    {
      Header: 'Color',
      accessor: 'cmyk',
      style: columnDefaultStyle,
    },
  ] as Column[];

  return (
    <div style={{ marginTop: '10px' }}>
      <ReactTablev6
        sortable={false}
        condensed={false}
        showPagination={false}
        minRows={0}
        columns={columns}
        data={colorPaletteLocal}
        collapseOnDataChange={false}
        pageSize={colorPaletteLocal.length}
      />
    </div>
  );
};
