import React, { useState } from 'react';
import { Button, Checkbox } from '@cimpress/react-components';
import { FileToUpload } from './FileToUpload';
import { PreviewUploadControl } from './PreviewUploadControl';
import { DropzoneWrapper } from './DropzoneWrapper';
import { ActionResult } from '../Tools/ActionResult';
import { ErrorToast } from '../components/ErrorToast';
import './UploadsControl.scss';

interface Props {
  allowMultiple: boolean;
  handleUpload: (uploads: FileToUpload[]) => Promise<ActionResult<never>>;
  enablePreviews?: boolean;
  uploadButtonText?: string;
  disabled?: boolean;
}

export const UploadsControl = ({
  allowMultiple,
  handleUpload,
  enablePreviews = true,
  uploadButtonText,
  disabled,
}: Props): JSX.Element => {
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploads, setUploads] = useState([] as FileToUpload[]);
  const [uploadsError, setUploadsError] = useState<Error>();
  const [showMainFilePicker, setShowMainFilePicker] = useState(false);

  const isMainFileCandidate = (contentFile: File) => {
    const fileName = contentFile.name.toLowerCase();
    return fileName.endsWith('.ai') || fileName.endsWith('.indd');
  };

  const checkUploadsForMainFiles = (uploads: FileToUpload[]) => {
    let mainFileCandidatesCount = 0;
    const uploadsCopy = [...uploads];
    uploadsCopy.forEach((fileToUpload) => {
      if (isMainFileCandidate(fileToUpload?.contentFile)) {
        mainFileCandidatesCount++;
        fileToUpload.isMainFile = mainFileCandidatesCount === 1; // set main file only to the very first found
      }
    });

    setShowMainFilePicker(uploads.length > 1);

    if (uploads.length === 1) {
      uploads[0].isMainFile = true;
    }

    return uploadsCopy;
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    let newUploads = allowMultiple
      ? uploads.concat(
          acceptedFiles
            .filter((file) => !uploads.some((u) => u.contentFile.name === file.name))
            .map((file) => {
              return { contentFile: file, isMainFile: false };
            }),
        )
      : [
          {
            contentFile: acceptedFiles[0],
            isMainFile: true,
          },
        ];

    newUploads = checkUploadsForMainFiles(newUploads);
    setUploads(newUploads);
  };

  const onFileDeleteClick = (fileName: string) => {
    let newUploads = uploads.filter((upload) => upload.contentFile.name !== fileName);
    newUploads = checkUploadsForMainFiles(newUploads);
    setUploads(newUploads);
  };

  const onMainCheckboxFileClick = (e: any, payload: FileToUpload) => {
    const newUploads = uploads.map((upload) => {
      return { ...upload, isMainFile: upload === payload };
    });
    setUploads(newUploads);
  };

  const onUploadClick = async (uploads: FileToUpload[]) => {
    if (uploads.length > 0) {
      setUploadInProgress(true);

      const uploadResult = await handleUpload(uploads);
      setUploadInProgress(false);

      switch (uploadResult.actionResultType) {
        case 'Cancel':
          return;
        case 'Error':
          setUploadsError(uploadResult.error);
          return;
        case 'Success':
        case 'EmptySuccess':
          setUploads([]);
      }
    }
  };

  const FilesToUploadList = (
    <div className={allowMultiple ? 'file-list' : 'file-list file-list-single'}>
      {uploads.map((upload: FileToUpload) => (
        <div className="list-group-item file-panel" key={upload.contentFile.name} onClick={(e) => e.stopPropagation()}>
          {!uploadInProgress && (
            <i
              className="fa fa-remove remove-icon cursor-pointer"
              onClick={() => onFileDeleteClick(upload.contentFile.name)}
            ></i>
          )}
          <span>{upload.contentFile.name}</span>
          {enablePreviews && (
            <PreviewUploadControl
              upload={upload}
              onPreviewAdded={() => setUploads(uploads.splice(0))}
              disabled={uploadInProgress}
            />
          )}
          {showMainFilePicker && (
            <Checkbox
              label="Primary"
              checked={upload.isMainFile}
              payload={upload}
              onChange={onMainCheckboxFileClick}
              disabled={uploadInProgress}
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <DropzoneWrapper
        onDrop={handleDrop}
        allowMultiple={allowMultiple}
        FilesToUploadList={FilesToUploadList}
        disabled={uploadInProgress}
      />
      <div className="upload-button-panel">
        <Button
          variant="primary"
          onClick={() => onUploadClick(uploads)}
          disabled={uploads.length === 0 || uploadInProgress || disabled}
          data-testid="upload-button"
        >
          {uploadInProgress ? 'Uploading...' : uploadButtonText}
        </Button>
      </div>
      <ErrorToast error={uploadsError ? [uploadsError] : undefined} />
    </>
  );
};
