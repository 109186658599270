import React, { useState } from 'react';
import { useEffect } from 'react';
import { getRenderDocumentUrl } from '../api/DTeR/dter-api';
import { createPreviewUrl } from '../Tools/Preview';
import { toTemplateToken } from '../Tools/TemplateToken';
import { NoPreview } from './NoPreview';
import * as dpsApi from '../api/Dps/dps-api';
import './TemplatePreview.scss';
import { shapes } from '@cimpress/react-components';

interface Props {
  designTemplateId?: string;
  templateVersion?: number;
  physicalSpecId?: string;
  showAsLink: boolean;
  culture?: string;
  previewWidth?: number;
  useAdditionalQueryStringParameter?: boolean;
}

export const TemplatePreview = ({
  designTemplateId,
  templateVersion,
  physicalSpecId,
  showAsLink,
  culture,
  previewWidth,
  useAdditionalQueryStringParameter,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [noPreview, setNoPreview] = useState(false);
  const [previewUri, setPreviewUri] = useState('');

  const selectedCulture = culture ?? 'en-US';

  useEffect(() => {
    const generatePreviewUrl = async () => {
      if (!designTemplateId || !physicalSpecId) {
        setNoPreview(true);
        return;
      }

      setIsLoading(true);
      const whiteColor = `rgb(255,255,255)`;

      const dterUrl = getRenderDocumentUrl(
        toTemplateToken(designTemplateId, physicalSpecId, templateVersion),
        selectedCulture,
        whiteColor,
        useAdditionalQueryStringParameter,
      );
      const width = previewWidth || 800;
      const initialPreview = createPreviewUrl(dterUrl, width);
      setPreviewUri(initialPreview);

      try {
        const backgroundColor = await dpsApi.getBackgroundRgba(physicalSpecId, dpsApi.Rgba.WhiteColor);

        const dterUrl = getRenderDocumentUrl(
          toTemplateToken(designTemplateId, physicalSpecId, templateVersion),
          selectedCulture,
          backgroundColor.toDterRgba(),
          useAdditionalQueryStringParameter,
        );

        const previewImageUri = createPreviewUrl(dterUrl, width);
        setPreviewUri(previewImageUri);
      } catch {
        setNoPreview(true);
      } finally {
        setIsLoading(false);
      }
    };

    generatePreviewUrl();
  }, [
    designTemplateId,
    physicalSpecId,
    previewWidth,
    selectedCulture,
    templateVersion,
    useAdditionalQueryStringParameter,
  ]);

  return (
    <>
      {noPreview && <NoPreview />}

      {isLoading && (
        <div className="spinner-container">
          <shapes.Spinner size="medium" />
        </div>
      )}

      {!noPreview && showAsLink && (
        <a href={previewUri} style={{ display: 'contents' }} target="_blank" rel="noopener noreferrer">
          <img src={previewUri} className="template-preview-image" alt="Design template preview" />
        </a>
      )}

      {!noPreview && !showAsLink && (
        <img src={previewUri} className="template-preview-image" alt="Design template preview" />
      )}
    </>
  );
};
