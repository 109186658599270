import { DependencyList, useEffect, useRef } from 'react';

/*
This useEffect will not trigger initially, but only on subsequent changes.
*/
export const useUpdatedEffect = (handler: () => void, dependencies: DependencyList): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return handler();
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
