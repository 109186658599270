import { tenantRoutePrefix } from '../Tools/Routes';

export function getPatternVersionsPageRoute(accountId?: string, contentAreaId?: string, patternId?: string): string {
  const path = `${tenantRoutePrefix}patterns/:id/versions`;
  if (accountId && contentAreaId && patternId) {
    return path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', patternId);
  } else {
    return path;
  }
}

export function getPatternDetailsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  patternId?: string,
  patternVersion?: number,
  message?: string,
): string {
  let path = `${tenantRoutePrefix}patterns/:id`;
  const messageSuffix = message ? `message=${message}` : '';

  if (!(accountId && contentAreaId && patternId)) return path;

  path = path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', patternId);
  path = path.concat('?');
  patternVersion && (path = path.concat('version=', patternVersion.toString()));
  return path + messageSuffix;
}

export const getPatternDetailsForSpecificVersionPageRoute = (): string =>
  `${tenantRoutePrefix}patterns/:id?version=:version`;

export function getPatternManagementAssetsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  queryString?: string,
): string {
  const path = `${tenantRoutePrefix}patterns`;
  if (accountId && contentAreaId) {
    const updatedPath = path.replace(':accountId', accountId).replace(':areaId', contentAreaId);
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
}
