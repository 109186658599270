import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import * as RequestUtils from '../RequestUtils';
import { ColorPaletteAssetRequestBody } from './model/ColorPalette/ColorPaletteAssetRequestBody';
import { ColorPaletteManagementAsset } from './model/ColorPalette/ColorPaletteManagementAsset';
import { UpdateColorPaletteAssetRequestBody } from './model/ColorPalette/UpdateColorPaletteAssetRequestBody';
import { ColorPaletteManagementAssetVersion } from './model/ColorPalette/ColorPaletteManagementAssetVersion';
import { ColorPaletteAssetVersionRequestBody } from './model/ColorPalette/ColorPaletteAssetVersionRequestBody';
const camsApiHost = config.camsApiUrl;

export async function createColorPaletteAsset(
  tenant: Tenant,
  requestBody: ColorPaletteAssetRequestBody,
): Promise<ApiResponseOrError<ColorPaletteManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/colors`,
  );
  const config = RequestUtils.axiosDefaultConfig();

  return await RequestUtils.doPostRequest<ColorPaletteManagementAsset>(url, requestBody, config);
}

export async function createColorPaletteAssetVersion(
  tenant: Tenant,
  assetId: string,
  requestBody: ColorPaletteAssetRequestBody,
): Promise<ApiResponseOrError<ColorPaletteManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/colors/${assetId}/versions`,
  );
  const config = RequestUtils.axiosDefaultConfig();
  return await RequestUtils.doPostRequest<ColorPaletteManagementAsset>(url, requestBody, config);
}

export async function updateColorPaletteAsset(
  id: string,
  tenant: Tenant,
  requestBody: UpdateColorPaletteAssetRequestBody,
): Promise<ApiResponseOrError<ColorPaletteManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/colors/${id}`,
  );

  return await RequestUtils.doPatchRequest<ColorPaletteManagementAsset>(url, requestBody);
}

export async function updateColorPaletteVersionAsset(
  id: string,
  version: number,
  tenant: Tenant,
  requestBody: ColorPaletteAssetVersionRequestBody,
): Promise<ApiResponseOrError<ColorPaletteManagementAssetVersion>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/colors/${id}/versions/${version}:publish`,
  );

  return await RequestUtils.doPatchRequest<ColorPaletteManagementAssetVersion>(url, requestBody);
}
