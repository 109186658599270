import config from '../../config';
import { throwOnApiError } from '../../Tools/ResponseHelper';
import * as RequestUtils from '../RequestUtils';

const sherbertApiHost = config.sherbertApiUrl;
const axiosConfig = RequestUtils.axiosDefaultConfig();

interface ImageInfo {
  format?: string;
  width: number;
  height: number;
}
export const getImageInfo = async (id: string, signature: string): Promise<ImageInfo | undefined> => {
  const url = new URL(`${sherbertApiHost}/v2/assets/${id}/info?signature=${signature}`);

  try {
    const imageInfo = await throwOnApiError(RequestUtils.doGetRequest<any>(url, axiosConfig));
    return { format: imageInfo.image.format, width: imageInfo.image.width, height: imageInfo.image.height };
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getPdfInfo = async (id: string, pageNum: number, signature: string): Promise<ImageInfo | undefined> => {
  const url = new URL(`${sherbertApiHost}/v2/assets/${id}/info/image/${pageNum}?signature=${signature}`);
  try {
    const result = await throwOnApiError(RequestUtils.doGetRequest<any>(url, axiosConfig));
    return { width: result.width, height: result.height } as ImageInfo;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
