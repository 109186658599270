import React, { useContext, useState } from 'react';
import { Button } from '@cimpress/react-components';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../App';
import { CreateOrUpdateDesignAssetControl } from '../Uploads/CreateOrUpdateDesignAssetControl';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { CreateOrUpdateCreativeAssetControl } from '../Uploads/CreateOrUpdateCreativeAssetControl';
import { getDesignManagementAssetDetailsPageRoute } from '../Routes/DesignManagementAssetRoutes';
import { getCreativeAssetDetailsPageRoute } from '../Routes/CreativeAssetRoutes';
import { CreateBatchAssetControl } from '../Uploads/CreateBatchAssetControl';
import { Plurality } from '../Tools/Plurality';
import { CreateOrUpdateFontAssetControl } from '../Uploads/CreateOrUpdateFontAssetControl';
import { CreateOrUpdateColorPaletteAssetControl } from '../Uploads/CreateOrUpdateColorPaletteAssetControl';
import { getFontSchemaDetailsPageRoute } from '../Routes/FontSchemaManagementAssetRoutes';
import { getColorPaletteDetailsPageRoute } from '../Routes/ColorPaletteManagementAssetRoutes';
import { getAssetCategorySetting } from '../AssetCategoryConfig';
import './CreateNewAssetButton.scss';
import { CreateOrUpdateAssetModal } from './CreateOrUpdateAssetModal';

interface Props {
  assetCategory: AssetCategory;
  reloadIndexPageContents: () => void;
  onTagReload: (value: boolean) => void;
  onCollectionReload: (value: boolean) => void;
  designConceptId?: string;
}

export const CreateNewAssetButton = ({
  assetCategory,
  reloadIndexPageContents,
  onTagReload,
  onCollectionReload,
  designConceptId,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const assetCategorySetting = getAssetCategorySetting(assetCategory);
  const plurality: Plurality =
    assetCategorySetting.isCreateNewButtonLabelPlural &&
    // allow plural only if category does not have DC uniqueness constraint (on DC page)
    (!designConceptId || !getAssetCategorySetting(assetCategory).hasUniqueDesignConceptId)
      ? 'plural'
      : 'singular';

  let className = '';
  const handleDesignCreated = (designId: string): void => {
    history.push(
      getDesignManagementAssetDetailsPageRoute(
        tenant.contentAuthoringAccountId,
        tenant.contentAuthoringAreaId,
        designId,
      ),
    );
    setIsModalOpen(false);
  };

  const handleBatchCreated = (): void => {
    reloadIndexPageContents();
    onTagReload(true);
    onCollectionReload(true);
    setIsModalOpen(false);
  };

  const handleCreativeAssetCreated = (assetId: string, version: number): void => {
    history.push(
      getCreativeAssetDetailsPageRoute(
        tenant.contentAuthoringAccountId,
        tenant.contentAuthoringAreaId,
        assetId,
        version,
      ),
    );
    setIsModalOpen(false);
  };

  const handleFontSchemaAssetCreated = (assetId: string, version: number): void => {
    history.push(
      getFontSchemaDetailsPageRoute(tenant.contentAuthoringAccountId, tenant.contentAuthoringAreaId, assetId, version),
    );
    setIsModalOpen(false);
  };

  const handleColorPaletteAssetCreated = (assetId: string, version: number): void => {
    history.push(
      getColorPaletteDetailsPageRoute(
        tenant.contentAuthoringAccountId,
        tenant.contentAuthoringAreaId,
        assetId,
        version,
      ),
    );
    setIsModalOpen(false);
  };

  const friendlyAssetCategoryName = assetCategory.format();
  let createOrUpdateControl = <CreateOrUpdateDesignAssetControl onUpload={handleDesignCreated} />;
  if (assetCategorySetting.isBulkCreateAllowed) {
    createOrUpdateControl = (
      <CreateBatchAssetControl
        assetCategory={assetCategory}
        onCloseClick={handleBatchCreated}
        designConceptId={designConceptId}
      />
    );
  } else {
    if (assetCategory === 'creative asset') {
      createOrUpdateControl = <CreateOrUpdateCreativeAssetControl onUpload={handleCreativeAssetCreated} />;
    } else if (assetCategory === 'font schema') {
      className = 'create-update-asset';
      createOrUpdateControl = (
        <CreateOrUpdateFontAssetControl onUpload={handleFontSchemaAssetCreated} designConceptId={designConceptId} />
      );
    } else if (assetCategory === 'color palette') {
      createOrUpdateControl = (
        <CreateOrUpdateColorPaletteAssetControl
          onUpload={handleColorPaletteAssetCreated}
          designConceptId={designConceptId}
        />
      );
    }
  }

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        disabled={!tenant.userPermissions.canWrite}
        data-testid="create-new-asset-button"
      >
        Create New {friendlyAssetCategoryName.format({ capitalLetter: true, plurality: plurality })}
      </Button>

      <CreateOrUpdateAssetModal
        isModalOpen={isModalOpen}
        onSetModelOpen={setIsModalOpen}
        title={`Create New ${friendlyAssetCategoryName.format({ capitalLetter: true, plurality: plurality })}`}
        closeButton={true}
        className={className}
        component={createOrUpdateControl}
      />
    </>
  );
};
