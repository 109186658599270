import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import * as RequestUtils from '../RequestUtils';
import { BackgroundAssetRequestBody } from './model/Background/BackgroundAssetRequestBody';
import { BackgroundAssetVersionRequestBody } from './model/Background/BackgroundAssetVersionRequestBody';
import { BackgroundManagementAsset } from './model/Background/BackgroundManagementAsset';
import { BackgroundManagementAssetVersion } from './model/Background/BackgroundManagementAssetVersion';
import { UpdateBackgroundAssetRequestBody } from './model/Background/UpdateBackgroundAssetRequestBody';
const camsApiHost = config.camsApiUrl;

export async function createBackgroundAsset(
  tenant: Tenant,
  requestBody: BackgroundAssetRequestBody,
): Promise<ApiResponseOrError<BackgroundManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/backgrounds`,
  );
  const config = RequestUtils.axiosDefaultConfig();

  return await RequestUtils.doPostRequest<BackgroundManagementAsset>(url, requestBody, config);
}

export async function createBackgroundAssetVersion(
  tenant: Tenant,
  backgroundId: string,
  requestBody: BackgroundAssetRequestBody,
): Promise<ApiResponseOrError<BackgroundManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/backgrounds/${backgroundId}/versions`,
  );
  const config = RequestUtils.axiosDefaultConfig();
  return await RequestUtils.doPostRequest<BackgroundManagementAsset>(url, requestBody, config);
}

export async function updateBackgroundAsset(
  id: string,
  tenant: Tenant,
  requestBody: UpdateBackgroundAssetRequestBody,
): Promise<ApiResponseOrError<BackgroundManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/backgrounds/${id}`,
  );

  return await RequestUtils.doPatchRequest<BackgroundManagementAsset>(url, requestBody);
}

export async function updateBackgroundVersionAsset(
  id: string,
  version: number,
  tenant: Tenant,
  requestBody: BackgroundAssetVersionRequestBody,
): Promise<ApiResponseOrError<BackgroundManagementAssetVersion>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/backgrounds/${id}/versions/${version}:publish`,
  );

  return await RequestUtils.doPatchRequest<BackgroundManagementAssetVersion>(url, requestBody);
}
