/**
 * Taken from https://github.com/parshap/truncate-utf8-bytes/blob/master/lib/truncate.js
 */
const isHighSurrogate = (codePoint: number) => {
  return codePoint >= 0xd800 && codePoint <= 0xdbff;
};

const isLowSurrogate = (codePoint: number) => {
  return codePoint >= 0xdc00 && codePoint <= 0xdfff;
};

const getLength = Buffer.byteLength.bind(Buffer);

export const truncate = (stringToSanitize: string, byteLength: number): string => {
  if (typeof stringToSanitize !== 'string') {
    throw new Error('Input must be string');
  }

  const charLength = stringToSanitize.length;
  let curByteLength = 0;
  let codePoint;
  let segment;

  for (let i = 0; i < charLength; i += 1) {
    codePoint = stringToSanitize.charCodeAt(i);
    segment = stringToSanitize[i];

    if (isHighSurrogate(codePoint) && isLowSurrogate(stringToSanitize.charCodeAt(i + 1))) {
      i += 1;
      segment += stringToSanitize[i];
    }

    curByteLength += getLength(segment);

    if (curByteLength === byteLength) {
      return stringToSanitize.slice(0, i + 1);
    } else if (curByteLength > byteLength) {
      return stringToSanitize.slice(0, i - segment.length + 1);
    }
  }

  return stringToSanitize;
};
