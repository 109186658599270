import Header from '@cimpress-technology/react-platform-header';
import React, { useEffect, useState } from 'react';
import auth from '../auth';
import { TenantContext } from './TenantContext';
import { EmptyTenant, TenantDescription } from '../api/model/TenantDescription';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import * as CamsApi from '../api/Cams/cams-api';
import Loading from '../components/Loading';
import { TenantPicker } from './TenantPicker';
import { TitlePageLink } from '../DesignPage/TitlePageLink';
import NoTenantsModal from './NoTenantsModal';
import { ConfigurationLink } from '../Configuration/ConfigurationLink';
import {
  canPrincipalPublish,
  canPrincipalWrite,
  getUserPermissons,
  PrincipalPermissionsResponse,
} from '../Tools/PermissionHelpers';
import { DesignConceptsTableLink } from '../DesignConceptsTablePage/DesignConceptsTableLink';

interface Props {
  children: JSX.Element;
}

export const TenantContextProvider = (props: Props): JSX.Element => {
  const [availableTenants, setAvailableTenants] = useState<TenantDescription[]>([]);
  const [loadingTenants, setLoadingTenants] = useState(true);
  const [tenant, setTenant] = useState<TenantDescription>(EmptyTenant);
  const [tenantSelected, setTenantSelected] = useState(false);

  const enrichTenantsWithUserPermissions = (
    tenants: TenantDescription[],
    permissions: PrincipalPermissionsResponse,
  ) => {
    tenants.forEach((tenant) => {
      tenant.userPermissions = {
        canPublish: canPrincipalPublish(permissions, tenant),
        canWrite: canPrincipalWrite(permissions, tenant),
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const tenantsPromise = CamsApi.getTenants();
      const userPermissionsPromise = getUserPermissons();
      const [tenantsResponse, permissionsResponse] = await Promise.all([tenantsPromise, userPermissionsPromise]);

      if (isSuccessfulResponse(tenantsResponse) && isSuccessfulResponse(permissionsResponse)) {
        const tenants = tenantsResponse.data;
        const userPermissions = permissionsResponse.data;

        enrichTenantsWithUserPermissions(tenants, userPermissions);
        setAvailableTenants(tenants);
        setLoadingTenants(false);
      }
    };

    fetchData();
  }, []);

  const onTenantSelected = (tenantDescription: TenantDescription) => {
    if (!tenantDescription) {
      return;
    }

    setLoadingTenants(false);
    setTenant(tenantDescription);
    setTenantSelected(true);
  };

  const appLinks = [
    {
      id: 'tenant-picker',
      content: <TenantPicker availableTenants={availableTenants} onTenantSelected={onTenantSelected} />,
    },
    {
      id: 'design-concepts-table',
      content: <DesignConceptsTableLink />,
    },
    {
      id: 'configuration',
      content: <ConfigurationLink />,
    },
  ];

  return (
    <TenantContext.Provider value={{ tenant, availableTenants }}>
      <Header
        accessToken={auth.getAccessToken()}
        appTitle={<TitlePageLink caption="Content Asset Manager" />}
        appLinks={appLinks}
        profile={auth.getProfile()}
        onLogInClicked={() => auth.login()}
        onLogOutClicked={() => auth.logout('/')}
        isLoggedIn={auth.isLoggedIn()}
      />
      <div style={{ minHeight: 'calc(100vh - 225px)' }}>
        {loadingTenants && <Loading />}

        {!loadingTenants && tenantSelected ? props.children : <></>}

        {!loadingTenants && availableTenants.length === 0 && <NoTenantsModal />}
      </div>
    </TenantContext.Provider>
  );
};
