import React, { useContext, useState } from 'react';
import { AppContext } from '../App';
import { ColorPaletteSelectionComponent } from '../components/ColorPaletteSelectionComponent';
import { ColorPalette } from './ColorPalette';
import { NonFileUploadButtonControl } from '../components/NonFileUploadButtonControl';
import { validateColorPalette } from '../Tools/ColorPaletteUtils';
import { ErrorToast } from '../components/ErrorToast';
import { FileToUpload } from './FileToUpload';
import { createOrUpdateColorPaletteAsset } from '../api/Cams/CamsApiFacade';
import { ColorPaletteManagementAsset } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAsset';
import { useFormik } from 'formik';

interface Props {
  assetId?: string;
  masterAssetId?: string;
  colorPaletteInitial?: ColorPalette;
  onUpload: (assetId: string, version: number) => void;
  designConceptId?: string;
}

export const CreateOrUpdateColorPaletteAssetControl = ({
  assetId,
  masterAssetId,
  colorPaletteInitial,
  onUpload,
  designConceptId,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [colorPalette, setColorPalette] = useState<ColorPalette>({});
  const [uploadsError, setUploadsError] = useState<Error>();
  const [isInProgress, setIsInProgress] = useState(false);
  const handleFileUpload = async (fileToUpload: FileToUpload): Promise<ColorPaletteManagementAsset> => {
    //use creative asset to link to patternasset
    return await createOrUpdateColorPaletteAsset(
      tenant,
      fileToUpload,
      undefined,
      undefined,
      designConceptId,
      assetId,
      masterAssetId,
    );
  };

  const formik = useFormik({
    initialValues: {
      ColorPalette: colorPaletteInitial,
    },
    onSubmit: async () => {
      handleUpload();
    },
  });

  const sanitizeColorPalette = (colorPalette: ColorPalette): ColorPalette => {
    return {
      Primary: colorPalette.Primary?.cmyk !== 'cmyk(,,,)' ? colorPalette.Primary : undefined,
      Secondary: colorPalette.Secondary?.cmyk !== 'cmyk(,,,)' ? colorPalette.Secondary : undefined,
      Neutral: colorPalette.Neutral?.cmyk !== 'cmyk(,,,)' ? colorPalette.Neutral : undefined,
      Accent: colorPalette.Accent?.cmyk !== 'cmyk(,,,)' ? colorPalette.Accent : undefined,
      Background: colorPalette.Background?.cmyk !== 'cmyk(,,,)' ? colorPalette.Background : undefined,
    };
  };

  const onColorPaletteChange = (colorPalette: ColorPalette) => {
    formik.setValues({ ColorPalette: colorPalette });
    setColorPalette(colorPalette);
  };

  const handleUpload = async () => {
    try {
      setIsInProgress(true);
      const isValidColorPalette = validateColorPalette(colorPalette);
      if (!isValidColorPalette.isValid) {
        setUploadsError(new Error(isValidColorPalette.error));
        return;
      }
      const sanitizedColorPalette = JSON.stringify(sanitizeColorPalette(colorPalette));
      const asset = await handleFileUpload({
        contentFile: new File([sanitizedColorPalette], getFileName(sanitizedColorPalette), {
          type: 'application/json',
        }),
        isMainFile: true,
      });
      onUpload(asset.id, asset.latestVersion.versionNumber);
    } catch (e) {
      setUploadsError(e);
    } finally {
      setIsInProgress(false);
    }
  };

  const getFileName = (colorPalette: any) => {
    return `${colorPalette
      .replace(/[^\w\s]/gi, '')
      .replaceAll('cmyk', '')
      .replace('Primary', 'P')
      .replace('Secondary', 'S')
      .replace('Neutral', 'N')
      .replace('Background', 'B')
      .replace('Accent', 'A')}.json`;
  };

  return (
    <div className="row">
      <form onSubmit={formik.handleSubmit} data-testid="create-or-update-colorpalette-form">
        <div className="col-sm-12">
          <ColorPaletteSelectionComponent
            onColorPaletteChange={onColorPaletteChange}
            colorPalette={colorPaletteInitial}
          />
        </div>
        <div className="col-sm-12">
          <NonFileUploadButtonControl
            isInProgress={isInProgress}
            isFormChanged={formik.dirty}
            initialLabel={'Save Changes'}
            inProgressLabel={'Saving Changes...'}
            onUpload={handleUpload}
          />
          <ErrorToast error={uploadsError ? [uploadsError] : undefined} onDismiss={() => setUploadsError(undefined)} />
        </div>
      </form>
    </div>
  );
};
