import React from 'react';
import { shapes } from '@cimpress/react-components';
import { FileToUploadWithProgress } from './FileToUploadWithProgress';
import { PreviewUploadControl } from './PreviewUploadControl';

interface Props {
  setUploads: (uploads: FileToUploadWithProgress[]) => void;
  uploads: FileToUploadWithProgress[];
  onFileDeleteClick: (fileName: string) => void;
}

export const FilesToUploadList = ({ setUploads, uploads, onFileDeleteClick }: Props): JSX.Element => {
  const successStyle = {
    color: 'green',
    fontSize: '32px',
  };
  const failedStyle = {
    color: 'red',
    fontSize: '32px',
  };

  return (
    <div className="file-list">
      {uploads.map((upload: FileToUploadWithProgress) => (
        <div
          className={upload.status === 'Success' ? 'list-group-item file-panel success' : 'list-group-item file-panel'}
          key={upload.contentFile.name}
          onClick={(e) => e.stopPropagation()}
        >
          {!(upload.status === 'Running' || upload.status === 'Success') && (
            <i
              className="fa fa-remove remove-icon cursor-pointer"
              onClick={() => {
                onFileDeleteClick(upload.contentFile.name);
              }}
            ></i>
          )}
          <span>{upload.contentFile.name}</span>
          <PreviewUploadControl
            upload={upload}
            onPreviewAdded={() => setUploads(uploads.splice(0))}
            disabled={upload.status === 'Running' || upload.status === 'Success'}
          />
          {upload.status === 'Running' && <shapes.Spinner />}
          {upload.status === 'Success' && <i style={successStyle} className="fa fa-solid fa-check"></i>}
          {upload.status === 'Failed' && <i style={failedStyle} className="fa fa-solid fa-remove"></i>}
        </div>
      ))}
    </div>
  );
};
