import React from 'react';
import { Card } from '@cimpress/react-components';
import { MonolithComboColorGroup } from '../../api/Cams/model/MonolithAssetsMetadata';
import { MonolithComboColorSwatch } from './MonolithComboColorSwatch';

interface Props {
  colorGroups: MonolithComboColorGroup[];
}

export const MonolithComboMissingPreviewsTile = ({ colorGroups }: Props): JSX.Element => {
  return (
    <Card className="card-combo">
      <div className="asset-header">
        <div className="card-title">
          <h3 style={{ marginTop: '4px' }}>No combos were found. Showing existing color groups only.</h3>
          {colorGroups.map((colorGroup, i) => {
            return (
              <div key={i}>
                <span style={{ flex: 1 }}></span>
                {colorGroup.colors.map((color, j) => {
                  return <MonolithComboColorSwatch key={j} rgbArray={color.rgb} cmykArray={color.cmyk} />;
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};
