import React, { useEffect, useState } from 'react';
import './CollectionsTreeView.scss';
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';
import { FacetValue } from '../../../api/Cams/model/FacetValue';

interface Props {
  allItems: FacetValue[];
  selectedItems: string[];
  label: string;
  onItemSelected: (items: string[]) => void;
}

const CollectionsTreeView = ({ allItems, selectedItems, label, onItemSelected }: Props): JSX.Element => {
  const [treeData, setTreeData] = useState<TreeData>();
  const nonExistingCollectionName = '901a3185-1eda-4f2a-8e26-35bc301cccc6'; // a random GUID

  type TreeItem = { key: string; label: string; nodes?: TreeItem[] };
  type TreeData = { items: TreeItem[]; initialOpenNodes: string[]; initialActiveKey: string | undefined };

  useEffect(() => {
    const transformToTree = (facetValues: FacetValue[]): TreeItem[] => {
      const result: TreeItem[] = [];

      selectedItems.forEach((element) => {
        if (facetValues.find((x) => x.value == element) === undefined) {
          facetValues.unshift({ label: `${element}`, value: element, usesCount: 0 });
        }
      });

      // root is a starting point to build a tree
      // we'll use root nodes as the "tree" itself
      const treeRoot: TreeItem = { key: '', label: '', nodes: result };

      if (facetValues.length > 0) {
        // iterate through all tree path strings ('a/b/c') to build a tree
        facetValues.reduce((tree, currentPath) => {
          // split tree path string 'a/b/c' into individual branches
          const pathBranches = currentPath.value.split('/');

          // iterate through branches one by one from root-most
          // adding branches that do not exist yet to the tree
          pathBranches.reduce((tree, branchName) => {
            tree.nodes = tree.nodes || [];

            // check whether this particular branch was already added
            // e.g. 'a/b/c' and 'a/d' are sharing branch 'a'
            const existingBranch = tree.nodes.find((q) => q.key === branchName);

            if (existingBranch === undefined) {
              // create a branch if it does not exist
              const branchToAdd = { key: branchName, label: branchName };
              tree.nodes.push(branchToAdd);
              return branchToAdd;
            }

            return existingBranch;
          }, tree);
          return tree;
        }, treeRoot);
      }

      return result;
    };

    const treeItems = transformToTree(allItems);
    const initialOpenNodes = allItems.map((i) => i.value);
    const initialActiveKey = selectedItems && selectedItems.length > 0 ? selectedItems[0] : nonExistingCollectionName;
    // passing an empty string or undefined will not update the currently active item in the tree menu
    // resetting can be done either like this or by calling the inner resetOpenNodes method,
    // which is not easily accessible

    setTreeData({
      items: treeItems,
      initialOpenNodes,
      initialActiveKey,
    });
  }, [allItems, selectedItems]);

  const onSelect = (item: string) => {
    const selected = [item];
    onItemSelected(selected);
  };

  const resetSelection = (resetOpenNodes?: (openNodes?: string[]) => void) => {
    if (resetOpenNodes) resetOpenNodes();
    onItemSelected([]);
  };

  const openedIcon = <i className="fa fa-lg fa-angle-down"></i>;
  const closedIcon = <i className="fa fa-lg fa-angle-right"></i>;

  return allItems.length > 0 ? (
    <>
      <form className="collections-form">
        {treeData && treeData.items && treeData.items.length > 0 && (
          <TreeMenu
            data={treeData.items}
            initialOpenNodes={treeData.initialOpenNodes}
            hasSearch={false}
            onClickItem={({ key }) => onSelect(key)}
            activeKey={treeData.initialActiveKey}
            initialActiveKey={treeData.initialActiveKey}
          >
            {({ items, resetOpenNodes }) => (
              <div className="rstm-tree-item-group">
                <div className="rstm-tree-item-all" onClick={() => resetSelection(resetOpenNodes)}>
                  All
                </div>
                <ul>
                  {items.map(({ key, ...props }) => (
                    <ItemComponent key={key} {...props} openedIcon={openedIcon} closedIcon={closedIcon} />
                  ))}
                </ul>
              </div>
            )}
          </TreeMenu>
        )}
      </form>
    </>
  ) : (
    <> {label} are not found. </>
  );
};

export default CollectionsTreeView;
