import React from 'react';
import { AssetsListPaging } from '../AssetsListPage/AssetsListFooter/AssetsListPaging';
import { AssetsPaging } from '../AssetsListPage/Model/AssetsPaging';
import './DesignConceptFooter.scss';

interface Props {
  paging: AssetsPaging;
  totalCount: number;
  onPageChanged: (page: number) => void;
  onPageSizeChanged: (pageSize: number) => void;
}

export const DesignConceptFooter = ({ paging, totalCount, onPageChanged, onPageSizeChanged }: Props): JSX.Element => {
  return (
    <div className={`design-concept-table-footer`}>
      <AssetsListPaging
        paging={paging}
        totalItemsCount={totalCount}
        performPageChange={onPageChanged}
        performPageSizeChange={onPageSizeChanged}
      />
    </div>
  );
};
