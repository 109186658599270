import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getConfigurationPageRoute } from '../Routes/GenericRoutes';
import { AppContext } from '../App';

export const ConfigurationLink = (): JSX.Element => {
  const { tenant } = useContext(AppContext);

  return (
    <>
      <Link
        to={getConfigurationPageRoute(tenant.contentAuthoringAccountId, tenant.contentAuthoringAreaId)}
        data-testid="configuration-button"
      >
        <i className="fa fa-gear button-icon"></i>
      </Link>
    </>
  );
};
