import { ContentAssetVersion } from '../api/Cams/model/ContentAssetVersion';
import { DexAsset } from '../Preview/DexAsset';
import { Metadata } from '../Preview/Metadata';
import { isString, isStringArray } from './Guards';

type PatternsDictionary = { [id: string]: DexAsset };
export const MapAssetsToDexAssets = (assets: ContentAssetVersion[]): PatternsDictionary => {
  const result: PatternsDictionary = {};

  assets.forEach((asset) => {
    const contentAssetuploadInfo = asset.uploadInfo;
    const metadata: Metadata = JSON.parse(contentAssetuploadInfo.metadata);

    // TODO: remove additional parsing step, once data migration is over
    let collections: string[] = [];
    if (isStringArray(metadata.collections)) {
      collections = metadata.collections;
    }
    if (isString(metadata.collections)) {
      const parsedCollections = JSON.parse(metadata.collections);
      if (isStringArray(parsedCollections)) {
        collections = parsedCollections;
      }
    }

    const categories = collections.filter((c) => c.startsWith('Category/')).map((c) => c.replace('Category/', ''));
    const firstCategory = categories.length > 0 ? categories[0] : 'Uncategorized';

    result[contentAssetuploadInfo.id] = {
      id: contentAssetuploadInfo.id,
      tags: contentAssetuploadInfo.tags ?? [],
      fileUrl: contentAssetuploadInfo.fileUrl ?? '',
      category: firstCategory,
      categories: categories.length > 0 ? categories : ['Uncategorized'],
      collections: collections,
    };
  });

  return result;
};
