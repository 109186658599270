import { tenantRoutePrefix } from '../Tools/Routes';

export function getCreativeAssetsPageRoute(accountId?: string, contentAreaId?: string, queryString?: string): string {
  const path = `${tenantRoutePrefix}creative`;
  if (accountId && contentAreaId) {
    const updatedPath = path.replace(':accountId', accountId).replace(':areaId', contentAreaId);
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
}

export function getCreativeAssetDetailsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  assetId?: string,
  assetVersion?: number,
  message?: string,
): string {
  let path = `${tenantRoutePrefix}creative/:id`;
  const messageSuffix = message ? `message=${message}` : '';

  if (!(accountId && contentAreaId && assetId)) return path;

  path = path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', assetId);
  path = path.concat('?');
  assetVersion && (path = path.concat('version=', assetVersion.toString()));
  return path + messageSuffix;
}

export function getCreativeVersionsPageRoute(accountId?: string, contentAreaId?: string, designId?: string): string {
  const path = `${tenantRoutePrefix}creative/:id/versions`;
  if (accountId && contentAreaId && designId) {
    return path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', designId);
  } else {
    return path;
  }
}
