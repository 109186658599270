import React from 'react';
import { MonolithComboColorGroup } from '../../api/Cams/model/MonolithAssetsMetadata';
import { Tooltip, CopyButton } from '@cimpress/react-components';

interface Props {
  colorGroups: MonolithComboColorGroup[];
}

export const MonolithCopyAllColorSwatch = ({ colorGroups }: Props): JSX.Element => {
  const cmykCollection = colorGroups[0]?.colors
    ? colorGroups.map((x) => x.colors.map((y) => [y.cmyk[0] * 100, y.cmyk[1] * 100, y.cmyk[2] * 100, y.cmyk[3] * 100]))
    : [[[]]];
  const stringifiedCmykCollection = JSON.stringify(cmykCollection);

  return cmykCollection[0][0].length > 0 ? (
    <Tooltip direction={'top'} contents="Copy All">
      <CopyButton size="sm" value={stringifiedCmykCollection} successMessage={`Copied to clipboard.`}>
        Copy All
      </CopyButton>
    </Tooltip>
  ) : (
    <></>
  );
};
