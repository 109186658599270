import { Card, Tooltip } from '@cimpress/react-components';
import React, { useContext } from 'react';
import { formatDateTime } from '../../Tools/DateTimeHelpers';
import './DesignHeader.scss';
import * as CamsDesignsApi from '../../api/Cams/cams-api-designs';
import { Link } from 'react-router-dom';
import { DesignProperties } from './DesignProperties';
import { DesignManagementAsset } from '../../api/Cams/model/Design/DesignManagementAsset';
import { DesignManagementAssetVersion } from '../../api/Cams/model/Design/DesignManagementAssetVersion';
import { AppContext } from '../../App';
import { DesignAssetPreview } from '../../components/DesignAssetPreview';
import {
  getDesignManagementAssetDetailsPageRoute,
  getDesignManagementAssetsPageRoute,
  getDesignManagementAssetVersionsPageRoute,
  getEditDesignManagementAssetPropertiesPageRoute,
} from '../../Routes/DesignManagementAssetRoutes';
import { AssetTags } from '../../components/AssetTags';
import { ApiResponse } from '../../api/ApiResponseOrError';
import { NoPreview } from '../../components/NoPreview';
import { AssetCollections } from '../../components/AssetCollections';
import { localeCompare } from '../../Tools/StringExt';
import auth from '../../auth';
import { getDesignName } from '../../Tools/DesignHelpers';
import { DownloadButton } from '../../components/DownloadButton';
import { serializeQueryParams } from '../../AssetsListPage/QueryString/QueryStringUtils';
import { DownloadWithLinkedAssetsButton } from '../../DownloadAsset/DownloadWithLinkedAssetsButton';
import { getEmailId } from '../../Tools/AuthHelper';
import { formatAssetsToZip } from '../../DownloadAsset/ZipUtility';

interface Props {
  design: DesignManagementAsset;
  designVersion: DesignManagementAssetVersion;
  firstVersion: DesignManagementAssetVersion;
  isLatestVersion: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DesignHeader = ({ design, designVersion, firstVersion, isLatestVersion }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const downloadLink = designVersion.masterFile?.uploadInfo?.fileUrl;
  const downloadFileName = designVersion.masterFile?.uploadInfo?.fileName;

  const currentUserEmail = getEmailId(auth.getProfile());
  const isCorrupted = designVersion.masterFile?.uploadInfo?.hash === '0';

  if (!designVersion.masterFile || !firstVersion) {
    return <NoPreview />;
  }

  const isDownloadable = !design.isLocked || localeCompare(firstVersion.createdBy, currentUserEmail) === 0;
  const isTagPanelCollapsed = !design.isLocked || (design.contentTags?.length ?? 0) > 0;
  const isCollectionPanelCollapsed = !design.isLocked || (design.contentCollections?.length ?? 0) > 0;

  return (
    <Card>
      <div className="row design-header-wrapper">
        <div className="col-sm-4">
          <div className="preview-wrapper">
            <DesignAssetPreview
              designVersion={designVersion}
              showAsLink={true}
              physicalSpecId={design.designPhysicalSpecId}
              width={800}
            />
          </div>
        </div>
        <div className="col-sm-8 design-detail-wrapper">
          <h1 className="text-primary design-name-header" data-testid="design-name-header">
            {design.isDeleted ? (
              <span className="text-danger">{getDesignName(design, designVersion)} (Design was deleted)</span>
            ) : (
              <>{getDesignName(design, designVersion)}</>
            )}
          </h1>
          <div className="card-text design-description">
            {design.isLocked && (
              <p className="text-danger" data-testid="design-locked-message">
                Design is locked, modification is disabled
              </p>
            )}
            <p>
              Version: {designVersion.versionNumber}&nbsp;
              <Link
                className="simple-link see-history-link"
                to={getDesignManagementAssetVersionsPageRoute(
                  tenant.contentAuthoringAccountId,
                  tenant.contentAuthoringAreaId,
                  design.id,
                )}
              >
                see history
              </Link>
            </p>
            {!isLatestVersion && (
              <p>
                <span className="text-danger">This is not the latest version.&nbsp;</span>
                <Link
                  to={getDesignManagementAssetDetailsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    design.id,
                  )}
                  className="simple-link"
                >
                  Show the latest version
                </Link>
              </p>
            )}
            <p>
              {isLatestVersion && (
                <>
                  <span>
                    Last modified by:&nbsp;
                    <Link
                      to={getDesignManagementAssetsPageRoute(
                        tenant.contentAuthoringAccountId,
                        tenant.contentAuthoringAreaId,
                        serializeQueryParams({ filters: { createdBy: [designVersion.createdBy] } }),
                      )}
                      data-testid="last-modified-by"
                    >
                      {designVersion.createdByFullname}
                    </Link>
                  </span>
                  <br />
                  <Tooltip direction={'right'} contents={formatDateTime(designVersion.created)}>
                    <span>Last modified: {formatDateTime(designVersion.created, true)}</span>
                  </Tooltip>
                </>
              )}
              {!isLatestVersion && (
                <>
                  <span>
                    Modified by:&nbsp;
                    <Link
                      to={getDesignManagementAssetsPageRoute(
                        tenant.contentAuthoringAccountId,
                        tenant.contentAuthoringAreaId,
                        serializeQueryParams({ filters: { createdBy: [designVersion.createdBy] } }),
                      )}
                    >
                      {designVersion.createdByFullname}
                    </Link>
                  </span>
                  <br />
                  <Tooltip direction={'right'} contents={formatDateTime(designVersion.created)}>
                    <span>Modified: {formatDateTime(designVersion.created, true)}</span>
                  </Tooltip>
                </>
              )}
            </p>
            <p>
              First version created by:&nbsp;
              <Link
                to={getDesignManagementAssetsPageRoute(
                  tenant.contentAuthoringAccountId,
                  tenant.contentAuthoringAreaId,
                  serializeQueryParams({ filters: { createdBy: [designVersion.createdBy] } }),
                )}
              >
                {firstVersion.createdByFullname}
              </Link>
              <br />
              <Tooltip direction={'right'} contents={formatDateTime(firstVersion.created)}>
                First version created: {formatDateTime(firstVersion.created, true)}
              </Tooltip>
            </p>
            {isTagPanelCollapsed && (
              <div style={{ marginBottom: '20px' }}>
                <AssetTags
                  entityTags={design.contentTags}
                  facetName="ContentTags"
                  componentLabel="Tags"
                  enableAutocomplete={tenant.settings.features.hideTagsFilter !== true}
                  updateMethod={(tenant, tags) =>
                    CamsDesignsApi.updateDesignAsset(design.id, tenant, { contentTags: tags })
                  }
                  getterFromResponse={(resp: ApiResponse<DesignManagementAsset>) => resp.data.contentTags ?? []}
                  disabled={design.isLocked || !tenant.userPermissions.canWrite}
                  getAssetsListWithTagFilterRoute={(tag) =>
                    getDesignManagementAssetsPageRoute(
                      tenant.contentAuthoringAccountId,
                      tenant.contentAuthoringAreaId,
                      serializeQueryParams({ filters: { tags: [tag] } }),
                    )
                  }
                />
              </div>
            )}
            <div data-testid="design-properties-panel" style={{ marginBottom: '20px' }}>
              <DesignProperties design={design} designVersion={designVersion} />
              {!design.isLocked && tenant.userPermissions.canWrite && (
                <Link
                  to={getEditDesignManagementAssetPropertiesPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    design.id,
                  )}
                  className="nav-link-edit-properties"
                >
                  Edit properties
                </Link>
              )}
            </div>
            {isCollectionPanelCollapsed && (
              <div style={{ marginBottom: '20px' }}>
                <AssetCollections
                  entityCollections={design.contentCollections}
                  facetName="ContentCollections"
                  componentLabel="Collections"
                  updateMethod={(tenant, collections) =>
                    CamsDesignsApi.updateDesignAsset(design.id, tenant, { contentCollections: collections })
                  }
                  getterFromResponse={(resp: ApiResponse<DesignManagementAsset>) => resp.data.contentCollections ?? []}
                  disabled={design.isLocked || !tenant.userPermissions.canWrite}
                  getAssetsListWithCollectionFilterRoute={(collection) =>
                    getDesignManagementAssetsPageRoute(
                      tenant.contentAuthoringAccountId,
                      tenant.contentAuthoringAreaId,
                      serializeQueryParams({ filters: { collections: [collection] } }),
                    )
                  }
                />
              </div>
            )}
          </div>
          <div className="design-buttons">
            {isCorrupted && (
              <div className="corrupted-design">
                <span>
                  <i className="fa fa-exclamation-triangle"></i>
                </span>
                This is a corrupted design which is not downloadable.
              </div>
            )}
            {!isCorrupted && (
              <Tooltip
                direction="right"
                contents={`Design was locked by the admin. Only the author (${firstVersion.createdBy}) is allowed to download.`}
                show={isDownloadable ? false : undefined}
              >
                <div className="buttons-wrapper">
                  <DownloadButton
                    isDownloadable={isDownloadable}
                    downloadFilename={downloadFileName}
                    downloadLink={downloadLink}
                    label="Download Design"
                  />
                  <div className="download-with-linked-assets">
                    {designVersion.linkedAssets.length > 0 && (
                      <DownloadWithLinkedAssetsButton
                        title={'Download With Linked Assets'}
                        filePathsToUrls={formatAssetsToZip([designVersion], getDesignName(design, designVersion))}
                        zipName={getDesignName(design, designVersion)}
                      />
                    )}
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
