import React from 'react';
import { AnyAsset } from '../api/Cams/model/AnyAsset';
import { DesignConceptAssetsListTilesView } from './DesignConceptAssetsListTilesView';

interface DesignConceptAssetsListProps {
  assets: AnyAsset[];
  designConceptId: string;
}
export const DesignConceptAssetsList = ({ assets, designConceptId }: DesignConceptAssetsListProps): JSX.Element => {
  // this is the only view type for the DC page
  return <DesignConceptAssetsListTilesView assets={assets} designConceptId={designConceptId} />;
};
