import * as ColorConvert from 'color-convert';
import { CMYK } from 'color-convert/conversions';
import { DTecRGB } from '../api/DTeC/DTecRGB';

const parseCmyk = (cmyk?: string): CMYK | undefined => {
  if (cmyk === undefined) return undefined;

  const cmykValue = cmyk.substring('cmyk('.length, cmyk.length - ')'.length); // '15, 100, 100, 0'
  const cmykNumbers = cmykValue.split(',').map((x) => parseInt(x.trim()));

  return cmykNumbers as CMYK;
};

export const cmykToRgbCss = (cmyk?: CMYK): string | undefined => {
  if (cmyk === undefined) return undefined;

  const rgbValues = ColorConvert.cmyk.rgb(cmyk);

  return `rgb(${rgbValues.join(',')})`;
};

export const parseCmykToRgbCss = (cmyk: string): string | undefined => {
  return cmykToRgbCss(parseCmyk(cmyk));
};

export const formatDTecRgbToCss = (rgb: DTecRGB): string => {
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

export const formatRgbArrayToCss = (rgb: number[]): string => {
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};
