import React from 'react';
import { DesignsListViewType } from '../../DesignPage/DesignsListViewType';
import { AssetsPaging } from '../Model/AssetsPaging';
import { AssetListViewSwitch } from './AssetListViewSwitch';
import { AssetsListPaging } from './AssetsListPaging';
import './AssetsListFooter.scss';
import { DownloadWithLinkedAssetsButton } from '../../DownloadAsset/DownloadWithLinkedAssetsButton';
import { FilePathsToUrls } from '../../api/Cams/model/FilePathsToUrls';
import { AssetCategory } from '../AssetCategory';
import { getAssetCategorySetting } from '../../AssetCategoryConfig';

interface Props {
  assetCategory: AssetCategory;
  paging: AssetsPaging;
  totalCount: number;
  totalAssetsToDownload: number;
  onPageChanged: (page: number) => void;
  onPageSizeChanged: (pageSize: number) => void;
  listViewType: DesignsListViewType;
  onListViewChange: (listViewType: DesignsListViewType) => void;
  loadDataToDownload?: () => Promise<FilePathsToUrls | undefined>;
}

export const AssetsListFooter = ({
  assetCategory,
  paging,
  totalCount,
  totalAssetsToDownload,
  onPageChanged,
  onPageSizeChanged,
  listViewType,
  onListViewChange,
  loadDataToDownload,
}: Props): JSX.Element => {
  const assetCategorySetting = getAssetCategorySetting(assetCategory);
  return (
    <div className={`assets-list-footer`}>
      <AssetsListPaging
        paging={paging}
        totalItemsCount={totalCount}
        performPageChange={onPageChanged}
        performPageSizeChange={onPageSizeChanged}
      />
      <AssetListViewSwitch listViewType={listViewType} onListViewChange={onListViewChange} />
      <div style={{ flex: 1 }}></div>
      {assetCategorySetting.downloadable && (
        <DownloadWithLinkedAssetsButton
          title={`Download ${totalAssetsToDownload} assets`}
          disableButton={totalAssetsToDownload > 1000}
          loadDataToDownload={loadDataToDownload}
        />
      )}
    </div>
  );
};
