import { FormatAssetKindOptions } from './FormatAssetKindOptions';

declare global {
  interface String {
    format(option?: FormatAssetKindOptions): string;
  }
}

String.prototype.format = function (options?: FormatAssetKindOptions) {
  let result = String(this);

  if (options?.plurality === 'plural') {
    result += 's';
  }

  if (options?.capitalLetter) {
    result = result[0].toUpperCase() + result.substring(1);
  }

  if (options?.suffix) {
    result += options.suffix;
  }

  return result;
};

export function localeCompare(string1: string, string2: string): number {
  return string1.localeCompare(string2, undefined, { sensitivity: 'accent' });
}
