import { AssetVersion } from '../AssetVersion';
import { CreativeAssetVersion } from '../CreativeAssetVersion';
import { EnsembleLine } from '../EnsembleLineInfo/EnsembleLine';
import { ManagementAssetVersion } from '../ManagementAssetVersion';

export interface DesignManagementAssetVersion extends ManagementAssetVersion {
  assetKind: 'design';

  linkedAssets: CreativeAssetVersion[];
  ensembleLine: EnsembleLine | undefined;
}

export const isDesignManagementAssetVersion = (x: AssetVersion): x is DesignManagementAssetVersion => {
  return x.assetType === 'management' && x.assetKind === 'design';
};
