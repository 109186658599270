import { ColorPaletteManagementAssetVersion } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAssetVersion';
import { readJson } from '../api/Uploads/uploads-api';
import { ColorPalette } from '../Uploads/ColorPalette';
import { Font, FontSchema } from '../Uploads/FontSchema';
import { isSuccessfulResponse } from './ResponseHelper';

export const getColorPalette = async (
  assetVersion: ColorPaletteManagementAssetVersion,
): Promise<ColorPalette | undefined> => {
  const colorPaletteInJson = await readJson<ColorPalette>(new URL(assetVersion.colorPaletteJson?.uploadInfo?.fileUrl));

  return isSuccessfulResponse(colorPaletteInJson) ? colorPaletteInJson.data : undefined;
};

export const validateColorPalette = (colorPalette: ColorPalette): { isValid: boolean; error: string } => {
  const colors = Object.fromEntries(Object.entries(colorPalette).filter(([, v]) => v.cmyk !== 'cmyk(,,,)'));
  const manditoryColors = ['Primary', 'Secondary', 'Neutral', 'Accent', 'Background'];
  const missingColors = [];
  for (const manditoryColor of manditoryColors) {
    if (!colors[manditoryColor]) {
      missingColors.push(manditoryColor);
    }
  }

  if (missingColors.length > 0) {
    return {
      isValid: false,
      error: `Please specify missing colors: ${missingColors.join(', ')}`,
    };
  }

  //CHECK FOR FORMAT CMYK(10,10,10,10) WHERE 10 MUST BE >=0 && <=100
  // eslint-disable-next-line prettier/prettier
  const regexp = new RegExp('cmyk\\((\\b([0-9]|[1-9][0-9]|100)\\b,){3}(\\b([0-9]|[1-9][0-9]|100)\\b)\\)');

  for (const index in colors) {
    const color = colors[index];
    const isValidReg = regexp.test(color.cmyk);
    if (!isValidReg) {
      return { isValid: false, error: `One or multiple values not between 0 and 100 for color: ${index}` };
    }
  }

  return {
    isValid: true,
    error: '',
  };
};

export const getParameterCaseInsensitive = (key: string, schema?: FontSchema): Font | undefined => {
  if (schema) {
    const asLowercase = key.toLowerCase();
    const font = Object.keys(schema.schemata).find((k) => k.toLowerCase() === asLowercase);
    return font ? schema.schemata[font] : undefined;
  }
  return undefined;
};
