import React, { useContext } from 'react';
import { ActionResult, createErrorResponse, createSuccessResponse } from '../Tools/ActionResult';
import { AppContext } from '../App';
import { UploadsControl } from '../Uploads/UploadsControl';
import { FileToUpload } from '../Uploads/FileToUpload';
import { uploadCategoryLocalization } from '../api/Cams/cams-api';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiResponseOrError } from '../api/ApiResponseOrError';

interface Props {
  onUpload: (response?: ApiResponseOrError<any>) => void;
}

export const UploadCategoryLocalizationControl = ({ onUpload }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const handleFileUpload = async (fileToUpload: FileToUpload): Promise<ApiResponseOrError<any> | undefined> => {
    const response = await uploadCategoryLocalization(tenant, fileToUpload.contentFile);
    if (isSuccessfulResponse(response)) {
      return undefined;
    } else {
      const message =
        JSON.parse(response.content).detail ||
        response.content ||
        'Something went wrong during Category localization upload.';

      return { ...response, content: message };
    }
  };

  const handleUpload = async (uploads: FileToUpload[]): Promise<ActionResult<never>> => {
    try {
      if (uploads.length !== 1) {
        return createErrorResponse(new Error('Please provide single file.'));
      }

      const fileToUpload = uploads[0];
      const response = await handleFileUpload(fileToUpload);

      onUpload(response);
      return createSuccessResponse();
    } catch (e) {
      return createErrorResponse(e);
    }
  };
  return (
    <UploadsControl
      allowMultiple={false}
      handleUpload={handleUpload}
      uploadButtonText={'Upload'}
      enablePreviews={false}
    />
  );
};
