import { Button } from '@cimpress/react-components';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as CamsApi from '../api/Cams/cams-api';
import * as CamsColorPaletteApi from '../api/Cams/cams-api-colorPalettes';
import { ColorPaletteManagementAsset } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAsset';
import { ColorPaletteManagementAssetVersion } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAssetVersion';
import { Tenant } from '../api/model/Tenant';
import { AppContext } from '../App';
import { ToAssetCategory } from '../AssetsListPage/AssetCategory';
import { CreateOrUpdateAssetModal } from '../components/CreateOrUpdateAssetModal';
import { DeleteAsset } from '../components/DeleteAsset';
import Loading from '../components/Loading';
import { Toast } from '../components/Toast';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';
import { getColorPalette } from '../Tools/ColorPaletteUtils';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ColorPalette } from '../Uploads/ColorPalette';
import { CreateOrUpdateColorPaletteAssetControl } from '../Uploads/CreateOrUpdateColorPaletteAssetControl';
import { ColorPaletteHeader } from './ColorPaletteHeader';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ColorPaletteDetailPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();
  const history = useHistory();
  const query = useQuery();
  const version = query.get('version') || undefined;
  const message = query.get('message') || undefined;
  const [asset, setAsset] = useState<ColorPaletteManagementAsset>();
  const [assetVersion, setAssetVersion] = useState<ColorPaletteManagementAssetVersion>();
  const [firstVersion, setFirstVersion] = useState<ColorPaletteManagementAssetVersion>();
  const [, setAssetError] = useState<string>();
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState(true);
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(true);
  const [colorPalette, setColorPalette] = useState<ColorPalette>();
  const loadAsset = async (id: string, version?: string | number) => {
    setIsLoading(true);
    setIsDeleteButtonShown(tenant.userPermissions.canWrite);
    setIsPublishButtonEnabled(tenant.userPermissions.canPublish);
    const response = await CamsApi.getAsset<ColorPaletteManagementAsset>(tenant, id);
    setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      if (response.data.assetKind !== 'colorPalette') {
        history.push(
          getVariableAssetDetailPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            ToAssetCategory(response.data.assetType, response.data.assetKind),
            id,
          ),
        );
        return;
      }
      const colorPalette = response.data as ColorPaletteManagementAsset;
      setAsset(colorPalette);

      const usedColorPaletteVersion = await getUsedColorPaletteVersion(colorPalette, version);

      const firstVersion =
        usedColorPaletteVersion.versionNumber === 1
          ? usedColorPaletteVersion
          : await getUsedColorPaletteVersion(colorPalette, 1);
      setFirstVersion(firstVersion);

      const parsedColorPalette = await getColorPalette(usedColorPaletteVersion);
      setColorPalette(parsedColorPalette);
      setIsPublishButtonEnabled((isPublishButtonEnabled) => isPublishButtonEnabled && !colorPalette.isDeleted);
      setAssetVersion(usedColorPaletteVersion);
    }
  };
  const getUsedColorPaletteVersion = async (
    colorPaletteAsset: ColorPaletteManagementAsset,
    version: string | number | undefined,
  ) => {
    let usedColorPaletteVersion = colorPaletteAsset.latestVersion;
    const versionNumber = parseInt(version?.toString() || '');
    if (versionNumber) {
      const versionResponse = await CamsApi.getAssetVersion(tenant, id, versionNumber);
      if (isSuccessfulResponse(versionResponse)) {
        usedColorPaletteVersion = versionResponse.data as ColorPaletteManagementAssetVersion;
      }
    }
    return usedColorPaletteVersion;
  };

  const handleUpdate = async () => {
    await loadAsset(id);
  };
  const handlePublish = async (tenant: Tenant, id: string, version: number, published: boolean) => {
    setIsLoading(true);
    const publishResponse = await CamsColorPaletteApi.updateColorPaletteVersionAsset(id, version, tenant, {
      isDiscoverable: published,
    });
    if (!isSuccessfulResponse(publishResponse)) {
      setIsLoading(false);
      return publishResponse;
    }
    const getAssetResponse = await CamsApi.getAsset<ColorPaletteManagementAsset>(tenant, id);
    if (isSuccessfulResponse(getAssetResponse)) {
      setAsset(getAssetResponse.data);
      setAssetVersion(publishResponse.data);
    }
    setIsLoading(false);
    return publishResponse;
  };
  const handleSaveProperty = async (tenant: Tenant, id: string, property: { [key: string]: string[] }) => {
    const response = await CamsColorPaletteApi.updateColorPaletteAsset(id, tenant, property);
    if (response.responseType === 'success') {
      setAsset(response.data);
      const usedColorPaletteVersion = await getUsedColorPaletteVersion(response.data, version);
      setAssetVersion(usedColorPaletteVersion);
    }
    return response;
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAsset(id, version);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, version]);

  const onAssetVersionCreated = async (assetId: string, version: number) => {
    setIsCreatingNewVersion(false);
    await loadAsset(assetId, version);
  };
  const isLatestVersion = asset?.latestVersion.versionNumber === assetVersion?.versionNumber;
  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="colorpalette-details-title">Color palette detail</h2>
          {asset && isLatestVersion && (
            <div className="detail-header-buttons">
              <Button
                variant="primary"
                className="upload-new-version-button"
                data-testid="upload-new-version-button"
                onClick={() => setIsCreatingNewVersion(true)}
                disabled={!tenant.userPermissions.canWrite}
              >
                Edit color palette
              </Button>
            </div>
          )}
        </div>
      </div>
      {isLoading && <Loading />}
      {!asset && !isLoading && <h4 data-testid="detail-not-found">Color palette not found.</h4>}
      {asset && assetVersion && firstVersion && (
        <div className="row section">
          <div className="col-sm-12">
            <ColorPaletteHeader
              asset={asset}
              assetVersion={assetVersion}
              firstVersion={firstVersion}
              isLatestVersion={isLatestVersion}
              isPublishButtonEnabled={isPublishButtonEnabled}
              colorPalette={colorPalette}
              onPublish={(tenant, published) => handlePublish(tenant, asset.id, assetVersion.versionNumber, published)}
              onSaveTags={(tenant, keywords) => handleSaveProperty(tenant, asset.id, { contentTags: keywords })}
              onSaveCollections={(tenant, collections) =>
                handleSaveProperty(tenant, asset.id, { contentCollections: collections })
              }
              onSaveDestinations={(tenant, destinations) =>
                handleSaveProperty(tenant, asset.id, { accessibleTo: destinations })
              }
            />
          </div>
        </div>
      )}
      {asset && !asset.isDeleted && (
        <div className="row section">
          <div className="col-sm-6">
            <DeleteAsset
              assetId={asset.id}
              assetCategory={'color palette'}
              name={`this ${'color palette'.format()}`}
              onDelete={handleUpdate}
              showButton={isDeleteButtonShown}
              disabled={!asset.isDeletable || isLoading}
            />
          </div>
        </div>
      )}

      {message && <Toast message={message} />}
      {asset && (
        <CreateOrUpdateAssetModal
          isModalOpen={isCreatingNewVersion}
          onSetModelOpen={setIsCreatingNewVersion}
          title={'Edit Color Palette'}
          closeButton={true}
          className="create-update-asset"
          component={
            <CreateOrUpdateColorPaletteAssetControl
              assetId={asset.id}
              masterAssetId={asset.latestVersion?.colorPaletteJson?.assetId}
              colorPaletteInitial={colorPalette}
              onUpload={onAssetVersionCreated}
            />
          }
        />
      )}
    </div>
  );
};

export default ColorPaletteDetailPage;
