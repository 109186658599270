export const AvailableCultures = [
  'en-us',
  'es-us',
  'en-ca',
  'fr-ca',
  'en-gb',
  'de-de',
  'fr-fr',
  'nl-be',
  'fr-be',
  'nl-nl',
  'de-ch',
  'it-ch',
  'de-at',
  'pt-pt',
  'es-es',
  'en-ie',
  'da-dk',
  'fi-fi',
  'nb-no',
  'en-au',
  'en-sg',
  'it-it',
  'sv-se',
];
