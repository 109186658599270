import React from 'react';
import { TemplateCard } from './TemplateCard';
import './DesignCompiledTemplates.scss';
import { parseCmykToRgbCss } from '../../Tools/ColorHelpers';
import { getProminentEnsemble, orderEnsembles } from '../../Tools/DesignHelpers';
import { hasColorVariants } from '../../api/Cams/model/EnsembleLineInfo/Ensemble';
import { EnsembleWithProminentColor } from '../../api/Cams/model/EnsembleLineInfo/EnsembleWithProminentColor';

interface Props {
  ensembles: EnsembleWithProminentColor[];
}

export const DesignCompiledTemplates = ({ ensembles }: Props): JSX.Element | null => {
  const orderedEnsembles = orderEnsembles(ensembles);
  const prominentEnsemble = getProminentEnsemble(orderedEnsembles);
  const otherColorVariants = orderedEnsembles.filter((e) => e !== prominentEnsemble);

  if (prominentEnsemble === undefined) {
    return null;
  }

  const getEnsembleCardStyle = (ensemble: EnsembleWithProminentColor): React.CSSProperties | undefined => {
    return hasColorVariants(ensemble)
      ? ({
          borderLeftColor: ensemble.prominentColorRgb || parseCmykToRgbCss(ensemble.variantInfos[0].variantData[0]),
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
        } as React.CSSProperties)
      : undefined;
  };

  return (
    <>
      <h2 className="section-title">Template Ensembles</h2>
      <div className="ensemble-group">
        {prominentEnsemble.panels.map((panel) => {
          return (
            <TemplateCard
              key={panel.templateId}
              showLocalizationButton={true}
              panelName={panel.panelName}
              designTemplateId={panel.templateId}
              templateVersion={prominentEnsemble.version}
              physicalSpecId={panel.dpsId}
              assetCardStyle={getEnsembleCardStyle(prominentEnsemble)}
            />
          );
        })}
      </div>

      <h3>Other color variants</h3>
      {otherColorVariants.map((ensemble, key) => {
        return (
          <div className="ensemble-group" key={key}>
            {ensemble.panels.map((panel) => {
              return (
                <TemplateCard
                  key={panel.templateId}
                  showLocalizationButton={false}
                  panelName={panel.panelName}
                  designTemplateId={panel.templateId}
                  templateVersion={ensemble.version}
                  physicalSpecId={panel.dpsId}
                  assetCardStyle={getEnsembleCardStyle(ensemble)}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};
