import React from 'react';
import { AnyAsset } from '../api/Cams/model/AnyAsset';
import { AssetCategory, ToAssetCategory } from '../AssetsListPage/AssetCategory';
import { DesignConceptAssetTile } from './DesignConceptAssetTile';
import { MissingDesignConceptAssetTile } from './MissingDesignConceptAssetTile';

interface Props {
  assets: AnyAsset[];
  designConceptId: string;
}

export const DesignConceptAssetsListTilesView = ({ assets, designConceptId }: Props): JSX.Element => {
  const getMissingAssetCategories = () => {
    const allowedAssetCategories: AssetCategory[] = ['background', 'color palette', 'font schema', 'clipart'];
    const missingAssetCategories: AssetCategory[] = [];
    for (const allowedAssetCategory of allowedAssetCategories) {
      const checkAllowedAssetCategory = (asset: AnyAsset) => {
        const assetCategory = ToAssetCategory(asset.assetType, asset.assetKind);
        return assetCategory === allowedAssetCategory;
      };
      if (assets.find(checkAllowedAssetCategory) === undefined) {
        missingAssetCategories.push(allowedAssetCategory);
      }
    }
    return missingAssetCategories;
  };

  const missingAssetCategories = getMissingAssetCategories();
  return (
    <div className={`assets-list-grid dc-assets-list-grid`} data-testid="assets-list-grid">
      {assets.map((asset) => {
        return (
          <DesignConceptAssetTile
            asset={asset}
            assetCategory={ToAssetCategory(asset.assetType, asset.assetKind)}
            key={`${asset.id}-${asset.latestVersion.versionNumber}`}
          />
        );
      })}
      {missingAssetCategories.map((missingAssetCategory) => {
        return (
          <MissingDesignConceptAssetTile
            assetCategory={missingAssetCategory}
            designConceptId={designConceptId}
            key={missingAssetCategory}
          />
        );
      })}
    </div>
  );
};
