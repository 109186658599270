import React, { useEffect, useState } from 'react';
import { TemplatePreview } from './TemplatePreview';
import './EnsembleLinePreview.scss';
import { parseCmykToRgbCss } from '../Tools/ColorHelpers';
import { enrichEnsemblesForProminentColor } from '../Tools/DesignTemplateHelpers';
import { orderEnsembles } from '../Tools/DesignHelpers';
import { Ensemble, hasColorVariants } from '../api/Cams/model/EnsembleLineInfo/Ensemble';
import { EnsembleWithProminentColor } from '../api/Cams/model/EnsembleLineInfo/EnsembleWithProminentColor';

interface Props {
  ensembles: Ensemble[];
}

export const EnsembleLinePreview = ({ ensembles }: Props): JSX.Element => {
  const [activeEnsemble, setActiveEnsemble] = useState(ensembles[0]);
  const [activePanel, setActivePanel] = useState(0);
  const [enrichedEnsembles, setEnrichedEnsembles] = useState<EnsembleWithProminentColor[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const enrichedEnsembles = await enrichEnsemblesForProminentColor(ensembles);
      const orderedEnsembles = orderEnsembles(enrichedEnsembles);

      setEnrichedEnsembles(orderedEnsembles);
      setActiveEnsemble(orderedEnsembles[0]);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ensembles]);

  const panels = activeEnsemble.panels;

  return (
    <>
      <div className="color-group-swatches">
        {enrichedEnsembles.map((ensemble, key) => {
          const className = activeEnsemble === ensemble ? 'color-swatch-dot active' : 'color-swatch-dot';

          return hasColorVariants(ensemble) ? (
            <span
              key={key}
              className={className}
              style={{
                backgroundColor:
                  ensemble.prominentColorRgb || parseCmykToRgbCss(ensemble.variantInfos[0].variantData[0]),
              }}
              onMouseEnter={() => setActiveEnsemble(ensemble)}
            ></span>
          ) : (
            <></>
          );
        })}
      </div>
      {panels.length > 1 &&
        panels.map((panel, key) => {
          const className =
            activePanel === key ? `panel-preview-picker panel-preview-picker-active` : `panel-preview-picker`;

          return (
            <div
              className={className}
              key={panel.templateId}
              style={{
                position: 'absolute',
                left: (key * (100 / panels.length)).toString() + '%',
                top: hasColorVariants(activeEnsemble) ? 99 : 55,
                bottom: '20px',
                width: `${100 / panels.length}%`,
                boxShadow: 'none',
                zIndex: 100,
              }}
              onMouseEnter={() => setActivePanel(key)}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: key === 0 ? 15 : 7,
                  right: key === 0 ? 7 : 15,
                }}
              ></div>
            </div>
          );
        })}
      <div className={`preview-wrapper preview-wrapper-larger`} style={{ paddingBottom: '25px' }}>
        <TemplatePreview
          designTemplateId={activeEnsemble.panels[activePanel].templateId}
          templateVersion={activeEnsemble.version}
          physicalSpecId={activeEnsemble.panels[activePanel].dpsId}
          showAsLink={false}
        />
      </div>
    </>
  );
};
