import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import * as CamsPatternsApi from '../api/Cams/cams-api-patterns';
import './PatternDetailPage.scss';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { PatternHeader } from './PatternHeader';
import { Button } from '@cimpress/react-components';
import { UpdatePatternAssetControl } from '../Uploads/UpdatePatternAssetControl';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import { Toast } from '../components/Toast';
import { PatternManagementAsset } from '../api/Cams/model/Pattern/PatternManagementAsset';
import { PatternManagementAssetVersion } from '../api/Cams/model/Pattern/PatternManagementAssetVersion';
import { DeleteAsset } from '../components/DeleteAsset';
import { Tenant } from '../api/model/Tenant';
import { AssetMissingLocalizationNotification } from '../MissingLocalizationNotification/AssetMissingLocalizationNotification';
import { parseCategories } from '../Tools/CategoryUtils';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';
import { ToAssetCategory } from '../AssetsListPage/AssetCategory';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PatternDetailPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();
  const history = useHistory();

  const query = useQuery();
  const version = query.get('version') || undefined;
  const message = query.get('message') || undefined;

  const assetCategory = 'pattern';

  const [pattern, setPattern] = useState<PatternManagementAsset>();
  const [patternVersion, setPatternVersion] = useState<PatternManagementAssetVersion>();
  const [firstVersion, setFirstVersion] = useState<PatternManagementAssetVersion>();
  const [, setAssetError] = useState<string>();
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState(true);
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(true);

  const loadAsset = async (id: string, version?: string | number) => {
    setIsLoading(true);
    setIsDeleteButtonShown(tenant.userPermissions.canWrite);
    setIsPublishButtonEnabled(tenant.userPermissions.canPublish);
    const response = await CamsApi.getAsset<PatternManagementAsset>(tenant, id);
    setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      if (response.data.assetKind !== 'pattern') {
        history.push(
          getVariableAssetDetailPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            ToAssetCategory(response.data.assetType, response.data.assetKind),
            id,
          ),
        );
        return;
      }

      const pattern = response.data as PatternManagementAsset;
      setPattern(pattern);

      const usedPatternVersion = await getUsedPatternVersion(pattern, version);

      const firstVersion =
        usedPatternVersion.versionNumber === 1 ? usedPatternVersion : await getUsedPatternVersion(pattern, 1);
      setFirstVersion(firstVersion);

      setIsPublishButtonEnabled((isPublishButtonEnabled) => isPublishButtonEnabled && !pattern.isDeleted);
      setPatternVersion(usedPatternVersion);
    }
  };

  const getUsedPatternVersion = async (pattern: PatternManagementAsset, version: string | number | undefined) => {
    let usedPatternVersion = pattern.latestVersion;

    const versionNumber = parseInt(version?.toString() || '');
    if (versionNumber) {
      const versionResponse = await CamsApi.getAssetVersion(tenant, id, versionNumber);
      if (isSuccessfulResponse(versionResponse)) {
        usedPatternVersion = versionResponse.data as PatternManagementAssetVersion;
      }
    }
    return usedPatternVersion;
  };

  const handleUpdate = async () => {
    await loadAsset(id);
  };

  const handlePublish = async (tenant: Tenant, id: string, version: number, published: boolean) => {
    setIsLoading(true);
    const publishResponse = await CamsPatternsApi.updatePatternVersionAsset(id, version, tenant, {
      isDiscoverable: published,
    });
    if (!isSuccessfulResponse(publishResponse)) {
      setIsLoading(false);
      return publishResponse;
    }

    const getAssetResponse = await CamsApi.getAsset<PatternManagementAsset>(tenant, id);
    if (isSuccessfulResponse(getAssetResponse)) {
      setPattern(getAssetResponse.data);
      setPatternVersion(publishResponse.data);
    }
    setIsLoading(false);

    return publishResponse;
  };

  const handleSaveProperty = async (tenant: Tenant, id: string, property: { [key: string]: string[] }) => {
    const response = await CamsPatternsApi.updatePatternAsset(id, tenant, property);
    if (response.responseType === 'success') {
      setPattern(response.data);
      const usedPatternVersion = await getUsedPatternVersion(response.data, version);
      setPatternVersion(usedPatternVersion);
    }
    return response;
  };

  useEffect(() => {
    loadAsset(id, version);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, version]);

  const onAssetVersionCreated = async (assetId: string, version: number) => {
    setIsCreatingNewVersion(false);
    await loadAsset(assetId, version);
  };

  const isLatestVersion = pattern?.latestVersion.versionNumber === patternVersion?.versionNumber;

  return (
    <div className="container-fluid container-stretch">
      <AssetMissingLocalizationNotification
        assetKind={assetCategory}
        categories={parseCategories(pattern?.contentCollections)}
      />
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="pattern-details-title">Pattern detail</h2>
          {pattern && isLatestVersion && (
            <div className="detail-header-buttons">
              {isCreatingNewVersion ? (
                <Button variant="primary" className="btn-danger" onClick={() => setIsCreatingNewVersion(false)}>
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className="upload-new-version-button"
                  data-testid="upload-new-version-button"
                  onClick={() => setIsCreatingNewVersion(true)}
                  disabled={!tenant.userPermissions.canWrite}
                >
                  Upload new version
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      {pattern && isCreatingNewVersion && (
        <UpdatePatternAssetControl
          patternAssetId={pattern.id}
          masterAssetId={pattern.latestVersion?.masterFile?.assetId}
          onUpload={onAssetVersionCreated}
        />
      )}

      {isLoading && <Loading />}

      {!pattern && !isLoading && <h4 data-testid="detail-not-found">Pattern not found.</h4>}

      {pattern && patternVersion && firstVersion && (
        <div className="row section">
          <div className="col-sm-12">
            <PatternHeader
              pattern={pattern}
              patternVersion={patternVersion}
              firstVersion={firstVersion}
              isLatestVersion={isLatestVersion}
              isPublishButtonEnabled={isPublishButtonEnabled}
              onPublish={(tenant, published) =>
                handlePublish(tenant, pattern.id, patternVersion.versionNumber, published)
              }
              onSaveTags={(tenant, keywords) => handleSaveProperty(tenant, pattern.id, { contentTags: keywords })}
              onSaveCollections={(tenant, collections) =>
                handleSaveProperty(tenant, pattern.id, { contentCollections: collections })
              }
              onSaveDestinations={(tenant, destinations) =>
                handleSaveProperty(tenant, pattern.id, { accessibleTo: destinations })
              }
            />
          </div>
        </div>
      )}

      {pattern && !pattern.isDeleted && (
        <div className="row section">
          <div className="col-sm-6">
            <DeleteAsset
              assetId={pattern.id}
              assetCategory={'pattern'}
              name={`${patternVersion?.masterFile?.uploadInfo?.fileName ?? 'this'} ${'pattern'.format()}`}
              onDelete={handleUpdate}
              showButton={isDeleteButtonShown}
              disabled={!pattern.isDeletable || isLoading}
            />
          </div>
        </div>
      )}
      {message && <Toast message={message} />}
    </div>
  );
};

export default PatternDetailPage;
