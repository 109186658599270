import { Card, Checkbox, Tooltip } from '@cimpress/react-components';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnyAsset } from '../../../api/Cams/model/AnyAsset';
import { AppContext } from '../../../App';
import { getAssetCategorySetting } from '../../../AssetCategoryConfig';
import { AssetPreview } from '../../../components/AssetPreview';
import { getAssetVersionName } from '../../../Tools/AssetHelpers';
import { formatDateTime } from '../../../Tools/DateTimeHelpers';
import { getRouteByAssetCategory } from '../../../Tools/NavigationRoutesUtility';
import { AssetCategory } from '../../AssetCategory';

interface Props {
  asset: AnyAsset;
  assetCategory: AssetCategory;
  onAssetSelected: (asset: AnyAsset, isSelected: boolean) => void;
}

export const Tile = ({ asset, assetCategory, onAssetSelected }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [isSelected, setIsSelected] = useState(false);
  const assetCategorySetting = getAssetCategorySetting(assetCategory);
  const latestVersion = asset.latestVersion;

  const onSelected = (isSelected: boolean) => {
    setIsSelected(isSelected);
    onAssetSelected(asset, isSelected);
  };
  return (
    <Card className="asset-tile">
      {assetCategorySetting.downloadable && (
        <Checkbox
          className={`checkbox ${isSelected ? 'checkbox-checked' : ''}`}
          checked={isSelected}
          label={!isSelected ? `Select to download` : `Selected to download`}
          onChange={() => {
            onSelected(!isSelected);
          }}
        />
      )}
      <Link to={`${getRouteByAssetCategory(tenant, assetCategory)}${asset.id}`}>
        <div className="preview-wrapper">
          <AssetPreview assetVersion={latestVersion} width={500} />
        </div>
        {assetCategorySetting.isFileNameVisible && <p>{getAssetVersionName(latestVersion)}</p>}
        <p>
          <Tooltip direction={'bottom'} contents={formatDateTime(latestVersion.created)}>
            Last Modified: {formatDateTime(latestVersion.created, true)} by {latestVersion.createdByFullname}
          </Tooltip>
        </p>
      </Link>
    </Card>
  );
};
