import React from 'react';
import { Checkbox } from '@cimpress/react-components';
import './CheckboxList.scss';
import { FacetValue } from '../../../api/Cams/model/FacetValue';

interface Props {
  allItems: FacetValue[];
  selectedItems: string[];
  onItemSelected: (items: string[]) => void;
  showUses: boolean;
  titleExtractor?: (item: FacetValue) => string;
}

export const CheckboxList = ({
  allItems,
  selectedItems,
  onItemSelected,
  showUses,
  titleExtractor = () => '',
}: Props): JSX.Element => {
  const onChange = (item: string, isSelected: boolean) => {
    const selectedValues = isSelected
      ? selectedItems.filter((selected) => selected !== item)
      : [...selectedItems, item];

    onItemSelected(selectedValues);
  };

  const isSelected = (item: FacetValue) => {
    return selectedItems.find((i) => i === item.value) !== undefined;
  };

  return (
    <>
      <form className="cams-search-form">
        {allItems.map((item) => {
          const checked = isSelected(item);

          const label = showUses ? (
            <>
              <span>{item.label}</span>
              <em className="text-muted"> ({item.usesCount})</em>
            </>
          ) : (
            <span>{item.label}</span>
          );

          return (
            <div key={item.value} title={titleExtractor === undefined ? '' : titleExtractor(item)}>
              <Checkbox
                data-testid={`designer-checkbox-${item.value}-testid`}
                label={label}
                checked={checked}
                payload={item.value}
                onChange={() => onChange(item.value, checked)}
              ></Checkbox>
            </div>
          );
        })}
      </form>
    </>
  );
};
