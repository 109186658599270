import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'Lg6wH8539FgZI5aYR7854YOxEq0vJLAc'; // https://developer.cimpress.io/clients/Lg6wH8539FgZI5aYR7854YOxEq0vJLAc/settings

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
  leeway: 300,
  emitInitialTokenExpired: false,
});

export default auth;
