import { DTecTemplate } from '../api/DTeC/DTecTemplate';
import { isSuccessfulResponse } from './ResponseHelper';
import { toTemplateToken } from './TemplateToken';
import * as DTeCApi from '../api/DTeC/dtec-api';
import { formatDTecRgbToCss } from './ColorHelpers';
import { getProminentEnsemble } from './DesignHelpers';
import { DTecEnsembleLine } from '../api/DTeC/DTecEnsembleLine';
import { Ensemble } from '../api/Cams/model/EnsembleLineInfo/Ensemble';
import { EnsembleWithProminentColor } from '../api/Cams/model/EnsembleLineInfo/EnsembleWithProminentColor';

export const enrichEnsemblesForProminentColor = async (
  ensembles: Ensemble[],
): Promise<EnsembleWithProminentColor[]> => {
  const loadEnsemble = async (ensemble: Ensemble) => {
    const panel = ensemble.panels[0];
    const templateToken = toTemplateToken(panel.templateId, panel.dpsId, ensemble.version);
    const designTemplateDetailsResponse = await DTeCApi.getEnsembleDetails(templateToken);
    if (isSuccessfulResponse(designTemplateDetailsResponse)) {
      const dtecResponseDetails = designTemplateDetailsResponse.data;
      return dtecResponseDetails.length > 0 ? dtecResponseDetails[0] : undefined;
    }
  };

  const loadEnsembleLine = async (ensembleLineId: string) => {
    const ensembleLineResponse = await DTeCApi.getEnsembleLine(ensembleLineId);
    if (isSuccessfulResponse(ensembleLineResponse)) {
      const dtecResponseDetails = ensembleLineResponse.data;
      return dtecResponseDetails;
    }
  };

  const getProminentColor = (template: DTecTemplate) => {
    const properties = template.designProperties;

    const rgb =
      properties.colorVariantDifferentialColors?.length > 0
        ? properties.colorVariantDifferentialColors[0].rgb
        : properties.colorComposition?.length > 0
        ? properties.colorComposition[0].rgb
        : undefined;

    if (!rgb) return undefined;

    return formatDTecRgbToCss(rgb);
  };

  const prominentEnsemble = getProminentEnsemble(ensembles);
  if (prominentEnsemble === undefined) {
    return ensembles;
  }

  const ensembleDetails = await loadEnsemble(prominentEnsemble);
  if (ensembleDetails === undefined) {
    return ensembles;
  }

  const ensembleLineDetails = await loadEnsembleLine(ensembleDetails.ensembleLineId);
  if (ensembleLineDetails === undefined || ensembleLineDetails.ensembles.length === 0) {
    return ensembles;
  }

  const getDTecTemplate = (ensembleLine: DTecEnsembleLine, templateToken: string): DTecTemplate | undefined => {
    for (const ensemble of ensembleLine.ensembles) {
      const dtecTemplate = ensemble.templates.find((template) => {
        return template.templateToken === templateToken;
      });

      if (dtecTemplate !== undefined) {
        return dtecTemplate;
      }
    }
    return undefined;
  };

  const enrichedEnsembles = ensembles.map((ensemble) => {
    const panel = ensemble.panels[0]; // take first template as a reference color
    const templateToken = toTemplateToken(panel.templateId, panel.dpsId, ensemble.version);

    const dtecTemplate = getDTecTemplate(ensembleLineDetails, templateToken);

    if (dtecTemplate === undefined) {
      return ensemble;
    }

    const prominentColorRgb = getProminentColor(dtecTemplate);
    const enrichedEnsemble: EnsembleWithProminentColor = { ...ensemble, prominentColorRgb };

    return enrichedEnsemble;
  });

  return enrichedEnsembles;
};
