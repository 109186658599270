import React from 'react';
import { Column } from 'react-table';
import { ReactTablev6 } from '@cimpress/react-components';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';

interface Props {
  assets: AnyAsset[];
}

export const UnknownTabularView = ({ assets }: Props): JSX.Element => {
  const columnDefaultStyle = { overflow: 'hidden', textOverflow: 'ellipsis' };
  const columns = [
    {
      Header: 'Name',
      id: 'name',
      Cell: () => {
        const span = <span>Unknown asset</span>;
        return span;
      },
      style: columnDefaultStyle,
    },
  ] as Column[];

  return (
    <ReactTablev6
      sortable={false}
      showPagination={false}
      minRows={0}
      columns={columns}
      data={assets}
      collapseOnDataChange={false}
      pageSize={assets.length}
    />
  );
};
