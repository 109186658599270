async function toDataURL(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();
  const dataUrl = URL.createObjectURL(blob);
  return dataUrl;
}

function getExtension(filename: string): string | undefined {
  const parts = filename.split('.');
  if (parts.length === 0) return undefined;
  return parts[parts.length - 1].toLowerCase();
}

const extensionsThatOpenNativelyInBrowser = [
  'apng',
  'avif',
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'webp',
  'bmp',
  'tif',
  'tiff',
  'pdf',
  'ico',
  'cur',
  'json',
];

export async function downloadAsFile(url: string, filename: string): Promise<void> {
  const a = document.createElement('a');
  const extension = getExtension(filename);
  a.href = extension && extensionsThatOpenNativelyInBrowser.includes(extension) ? await toDataURL(url) : url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function downloadBlobAsFile(fileContent: Blob, filename: string): void {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(fileContent);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
