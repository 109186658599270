import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import * as RequestUtils from '../RequestUtils';
import { CloneDesignRequestBody } from './model/Design/CloneDesignRequestBody';
import { DesignAssetRequestBody } from './model/Design/DesignAssetRequestBody';
import { DesignAssetVersionRequestBody } from './model/Design/DesignAssetVersionRequestBody';
import { DesignManagementAsset } from './model/Design/DesignManagementAsset';
import { UpdateDesignAssetRequestBody } from './model/Design/UpdateDesignAssetRequestBody';
const camsApiHost = config.camsApiUrl;

export async function createDesignAsset(
  tenant: Tenant,
  requestBody: DesignAssetRequestBody,
): Promise<ApiResponseOrError<DesignManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/designs`,
  );

  const config = RequestUtils.axiosDefaultConfig();
  return await RequestUtils.doPostRequest<DesignManagementAsset>(url, requestBody, config);
}

export async function createDesignAssetVersion(
  tenant: Tenant,
  designId: string,
  requestBody: DesignAssetVersionRequestBody,
): Promise<ApiResponseOrError<DesignManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/designs/${designId}/versions`,
  );
  const config = RequestUtils.axiosDefaultConfig();

  return await RequestUtils.doPostRequest<DesignManagementAsset>(url, requestBody, config);
}

export async function updateDesignAsset(
  id: string,
  tenant: Tenant,
  requestBody: UpdateDesignAssetRequestBody,
): Promise<ApiResponseOrError<DesignManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/designs/${id}`,
  );

  return await RequestUtils.doPatchRequest<DesignManagementAsset>(url, requestBody);
}

export async function cloneDesignAsset(
  tenant: Tenant,
  id: string,
  requestBody: CloneDesignRequestBody,
): Promise<ApiResponseOrError<DesignManagementAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/management/designs/${id}:clone`,
  );

  const config = RequestUtils.axiosDefaultConfig();
  return await RequestUtils.doPostRequest<DesignManagementAsset>(url, requestBody, config);
}
