import { Button, Tag } from '@cimpress/react-components';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import './AssetCollections.scss';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiErrorToast } from './ApiErrorToast';
import { AppContext } from '../App';
import * as CamsApi from '../api/Cams/cams-api';
import { Tenant } from '../api/model/Tenant';
import { FacetName } from '../api/Cams/model/FacetName';
import { CamsAutocomplete } from './CamsAutocomplete';
import { splitBySemicolon } from '../Tools/TagsUtil';
import { Link } from 'react-router-dom';

interface Props {
  entityCollections?: string[];
  facetName: FacetName;
  componentLabel: string;
  updateMethod: (tenant: Tenant, collections: string[]) => Promise<ApiResponseOrError<any>>;
  getterFromResponse: (response: ApiResponse<any>) => string[];
  disabled?: boolean;
  getAssetsListWithCollectionFilterRoute: (collection: string) => string;
}

export const AssetCollections = ({
  entityCollections,
  facetName,
  componentLabel,
  updateMethod,
  getterFromResponse,
  disabled,
  getAssetsListWithCollectionFilterRoute,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [collections, setCollections] = useState<string[]>(entityCollections || []);
  const [saveResponse, setSaveResponse] = useState<ApiResponseOrError<any>>();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCollections(entityCollections || []);
  }, [entityCollections]);

  useEffect(() => {
    if (disabled) {
      setIsEditing(false);
    }
  }, [disabled]);

  const save = async (updatedCollections: string[]) => {
    const response = (await updateMethod(tenant, updatedCollections)) as ApiResponse<any>;

    setSaveResponse(response);

    if (isSuccessfulResponse(response)) {
      return getterFromResponse(response);
    }

    return false;
  };

  const onRemoveClick = async (value: string) => {
    const updatedCollections = [...collections];
    const removedItemIndex = updatedCollections.findIndex((collection) => collection === value);

    if (removedItemIndex > -1) {
      updatedCollections.splice(removedItemIndex, 1);
      setCollections(updatedCollections);
      await save(updatedCollections);
    }
  };

  const addCollection = async (newCollections: string) => {
    const newCollectionsArray = splitBySemicolon(newCollections);

    const updatedCollections = collections ? [...collections, ...newCollectionsArray] : newCollectionsArray;

    const savedCollections = await save(updatedCollections);
    if (savedCollections) {
      setCollections(savedCollections);
    }
  };

  const onDoneClick = async () => {
    setIsEditing(false);
    setIsLoading(true);
    if (value) {
      await addCollection(value);
    }
    setIsLoading(false);
    setValue('');
  };

  return (
    <div data-testid="asset-collections">
      <h3>{componentLabel}</h3>
      {collections && (
        <div className="collections-list">
          {collections.map((collection) => {
            if (!isEditing) {
              return (
                <Link to={getAssetsListWithCollectionFilterRoute(collection)} className="tag-link">
                  <Tag key={collection} value={collection} size="lg" />
                </Link>
              );
            } else {
              return (
                <Tag
                  key={collection}
                  value={collection}
                  size="lg"
                  removable={isEditing}
                  onRemoveClick={(value?: ReactNode) => onRemoveClick(value?.toString() || '')}
                />
              );
            }
          })}
          {isEditing && (
            <CamsAutocomplete
              minLength={2}
              onItemSelected={addCollection}
              loadData={CamsApi.getFacetValues}
              enableAutocomplete={true}
              facetName={facetName}
              labelName={componentLabel}
              displayLonger={true}
              disabled={disabled}
              onValueChange={(v) => setValue(v)}
            />
          )}
          {!isEditing && (
            <Button
              disabled={disabled || isLoading}
              onClick={() => setIsEditing(true)}
              variant="link"
              className="edit-button"
            >
              {collections.length > 0 ? (
                <>
                  <i className="fa fa-pencil"></i>
                  <span>Edit collections</span>
                </>
              ) : (
                'Add collections'
              )}
            </Button>
          )}

          {isEditing && (
            <>
              <Button onClick={onDoneClick} className="inline-edit-button-action">
                <span>Done</span>
              </Button>
            </>
          )}
        </div>
      )}
      <ApiErrorToast response={saveResponse} />
    </div>
  );
};
