import { Card } from '@cimpress/react-components';
import React, { useContext } from 'react';
import './ColorPaletteHeader.scss';
import { AssetTags } from '../components/AssetTags';
import { AssetCollections } from '../components/AssetCollections';
import { AppContext } from '../App';
import { AssetPreview } from '../components/AssetPreview';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { Tenant } from '../api/model/Tenant';
import { serializeQueryParams } from '../AssetsListPage/QueryString/QueryStringUtils';
import { DesignConceptIdEdit } from '../CreativeAssetDetailPage/components/DesignConceptIdEdit';
import { PublishedToggle } from '../components/PublishedToggle';
import { ColorPaletteManagementAssetVersion } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAssetVersion';
import { ColorPaletteManagementAsset } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAsset';
import { ColorPalette } from '../Uploads/ColorPalette';
import { getColorPaletteManagementAssetsPageRoute } from '../Routes/ColorPaletteManagementAssetRoutes';
import { ColorPaletteTabularView } from './ColorPaletteTabularView';
import { AssetVersion } from '../components/AssetVersion';
import { AssetAuditInfo } from '../components/AssetAuditInfo';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { DifferentAssetVersionPublished } from '../components/DifferentAssetVersionPublished';
import { AssetDestinations } from '../components/AssetDestinations';

interface Props {
  asset: ColorPaletteManagementAsset;
  assetVersion: ColorPaletteManagementAssetVersion;
  firstVersion: ColorPaletteManagementAssetVersion;
  isLatestVersion: boolean;
  isPublishButtonEnabled: boolean;
  colorPalette?: ColorPalette;
  onPublish: (tenant: Tenant, published: boolean) => Promise<ApiResponseOrError<any>>;
  onSaveTags: (tenant: Tenant, keywords: string[]) => Promise<ApiResponseOrError<ColorPaletteManagementAsset>>;
  onSaveCollections: (
    tenant: Tenant,
    collections: string[],
  ) => Promise<ApiResponseOrError<ColorPaletteManagementAsset>>;
  onSaveDestinations: (
    tenant: Tenant,
    destinations: string[],
  ) => Promise<ApiResponseOrError<ColorPaletteManagementAsset>>;
}

export const ColorPaletteHeader = ({
  asset,
  assetVersion,
  firstVersion,
  isLatestVersion,
  isPublishButtonEnabled,
  colorPalette,
  onPublish,
  onSaveTags,
  onSaveCollections,
  onSaveDestinations,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const isDifferentVersionPublished =
    asset.discoverableVersionNumber !== null && asset.discoverableVersionNumber !== assetVersion.versionNumber;

  return (
    <Card>
      <div className="row header-wrapper">
        <div className="col-sm-4">
          <div className="color-palette-preview-wrapper">
            <AssetPreview assetVersion={assetVersion} />
          </div>
          <div className="card-text detail-palette-description">
            <AssetVersion
              assetVersion={assetVersion}
              tenant={tenant}
              assetCategory={'color palette'}
              isLatestVersion={isLatestVersion}
            />

            <AssetAuditInfo
              assetVersion={assetVersion}
              tenant={tenant}
              assetCategory={'color palette'}
              isLatestVersion={isLatestVersion}
              firstVersion={firstVersion as AnyAssetVersion}
            ></AssetAuditInfo>
          </div>
        </div>
        <div className="col-sm-8 detail-header-wrapper">
          <div className="detail-header-title-row" data-testid="color-palette-name-header">
            {asset.isDeleted && <span style={{ color: 'red' }}>This color palette is deleted</span>}
            <PublishedToggle
              publishedInit={assetVersion.isDiscoverable}
              updateMethod={onPublish}
              getterFromResponse={(resp) => resp.data.isDiscoverable}
              enabledInit={isPublishButtonEnabled}
              showConfirmation={isDifferentVersionPublished}
            />
          </div>
          <div className="card-text">
            {isDifferentVersionPublished && (
              <DifferentAssetVersionPublished
                assetId={asset.id}
                versionNumber={asset.discoverableVersionNumber}
                tenant={tenant}
                assetCategory={'color palette'}
              />
            )}
            {colorPalette && <ColorPaletteTabularView colorPalette={colorPalette} />}
            <DesignConceptIdEdit asset={asset} />
            <div className="detail-header-tags-wrapper">
              <AssetTags
                entityTags={asset.contentTags}
                facetName="ContentTags"
                componentLabel="Tags"
                updateMethod={onSaveTags}
                disabled={!tenant.userPermissions.canWrite}
                enableAutocomplete={tenant.settings.features.hideTagsFilter !== true}
                getterFromResponse={(resp: ApiResponse<ColorPaletteManagementAsset>) => resp.data.contentTags ?? []}
                getAssetsListWithTagFilterRoute={(tag) =>
                  getColorPaletteManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { tags: [tag] } }),
                  )
                }
              />
            </div>
            <div className="detail-header-tags-wrapper">
              <AssetCollections
                entityCollections={asset.contentCollections}
                facetName="ContentCollections"
                componentLabel="Collections"
                updateMethod={onSaveCollections}
                disabled={!tenant.userPermissions.canWrite}
                getterFromResponse={(resp) => resp.data.contentCollections}
                getAssetsListWithCollectionFilterRoute={(collection) =>
                  getColorPaletteManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { collections: [collection] } }),
                  )
                }
              />
            </div>
            {tenant.settings.features.accessibleTo.length > 0 && (
              <div className="detail-header-tags-wrapper">
                <AssetDestinations
                  entityDestinations={asset.accessibleTo}
                  componentLabel="Accessible to"
                  disabled={!tenant.userPermissions.canPublish}
                  updateMethod={onSaveDestinations}
                  getterFromResponse={(resp: ApiResponse<ColorPaletteManagementAsset>) => resp.data.accessibleTo ?? []}
                  getAssetsListWithDestinationFilterRoute={(accessibleTo) =>
                    getColorPaletteManagementAssetsPageRoute(
                      tenant.contentAuthoringAccountId,
                      tenant.contentAuthoringAreaId,
                      serializeQueryParams({ filters: { accessibleTo: [accessibleTo] } }),
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
