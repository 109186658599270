import React from 'react';
import { CategoryKinds, CategoryLocalizationData } from './CategoryLocalization';
import './CategoryLocalization.scss';

interface Props {
  categoryKind: CategoryKinds;
  categoryLocalizationData: CategoryLocalizationData;
}

const CategoryLocalizationTable = ({ categoryKind, categoryLocalizationData }: Props): JSX.Element => {
  const { locales, localizations } = categoryLocalizationData;
  const categories = Object.keys(localizations[categoryKind] ?? {});

  return (
    <table className="category-localization-table">
      <tr>
        <th>Localization Key</th>
        {locales.map((locale) => (
          <th key={locale}>{locale}</th>
        ))}
      </tr>
      {categories.map((category) => (
        <tr key={category}>
          <td>{category}</td>
          {locales.map((locale) => (
            <td key={locale}>
              {localizations[categoryKind][category][locale] && (
                <span>{localizations[categoryKind][category][locale]}</span>
              )}
            </td>
          ))}
        </tr>
      ))}
    </table>
  );
};

export default CategoryLocalizationTable;
