export const imageExtensions = ['.jpg', '.jpeg', '.jfif', '.gif', '.bmp', '.png', '.webp', '.svg'];
export const sherbertImageExtensions = [
  '.jpg',
  '.jpeg',
  '.jfif',
  '.gif',
  '.bmp',
  '.png',
  '.webp',
  '.pdf',
  '.ai',
  '.tga',
  '.tiff',
  '.tif',
  '.psd',
  '.eps',
  '.svg',
];
