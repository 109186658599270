import React, { useEffect, useState } from 'react';
import { getComposedTemplateUrl } from '../api/Cams/ags-api';
import Loading from '../components/Loading';
import config from '../config';
import pako from 'pako';

export const getSceneUrl = (designPhysicalSpecId: string, width: number): string => {
  const surfaceUrl = `${config.dpsApiUrl}/api/v2/DesignPhysicalSpecs/${designPhysicalSpecId}/surfaceSet?requestor=DTeC_UI_Composition`;

  const sceneInstructions = {
    width,
    page: 1,
    showFullBleed: true,
    product: {
      calculatedSurfaceSetUrl: surfaceUrl,
    },
    layers: [
      {
        type: 'overlay',
        source: 'safe',
        stroke: {
          color: 'rgb(100,100,100)',
          dotted: true,
        },
      },
      {
        type: 'overlay',
        source: 'trim',
        stroke: {
          color: 'rgb(100,100,100)',
          dotted: false,
        },
      },
      {
        type: 'overlay',
        source: 'bleed',
        fillColor: 'rgb(255,255,255)',
        stroke: {
          color: 'rgb(100,100,100)',
          dotted: false,
        },
      },
      {
        type: 'overlay',
        source: 'fold',
        stroke: {
          color: 'rgb(255,0,0)',
          dotted: true,
        },
      },
      {
        type: 'overlay',
        source: 'cut',
        stroke: {
          color: 'rgb(0,0,255)',
          dotted: false,
        },
      },
    ],
  };

  const deflatedSceneInstructions = btoa(JSON.stringify(sceneInstructions));

  const sceneUrl = `https://scenes.documents.cimpress.io/v3/transient?data=${deflatedSceneInstructions}`;

  return sceneUrl;
};

export const getPreviewUrlUsingDocument = (encodedDocument: string, dpsId: string, width: number): string => {
  const instructionsDocumentsUrl = `${config.instructionsDocumentsUrl}/v3/instructions:preview?documentUri=${encodedDocument}`;
  const renderingUrl = `${
    config.renderingUrl
  }/v1/cse/preview?width=${width}&height=${width}&format=png&instructions_uri=${encodeURIComponent(
    instructionsDocumentsUrl,
  )}`;
  const sceneUrl = getSceneUrl(dpsId, width);

  return `${renderingUrl}&scene=${encodeURIComponent(sceneUrl)}`;
};

export const encodeDocument = (document: string): string => {
  const deflated = pako.deflateRaw(document);

  return encodeURIComponent(bufferToBase64(deflated));
};

const bufferToBase64 = (buffer: Uint8Array): string => {
  const binstr = Array.prototype.map.call(buffer, (ch) => String.fromCharCode(ch)).join('');

  return btoa(binstr);
};

interface ComposedSampleProps {
  templateToken: string;
  components: any;
}

export const ComposedSample = ({ templateToken, components }: ComposedSampleProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [renderingUrl, setRenderingUrl] = useState<string>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');

      try {
        // Here, we cannot use config and must use this hardcoded PROD URL because
        // the sample templates are only created on PROD
        const targetDocumentUrl = `https://prod.templaterealization.content.cimpress.io/api/v2/templates/${templateToken}/cultures/en-us/editDocument`;
        const composedTemplateUrl = await getComposedTemplateUrl(targetDocumentUrl, components);

        const dpsId = templateToken.slice(42);
        const renderingUrl = getPreviewUrlUsingDocument(composedTemplateUrl, dpsId, 400);
        setRenderingUrl(renderingUrl);
      } catch (e) {
        console.log(e);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="preview-wrapper">
      {error && <div className="alert alert-warning">ERROR: {error.message}</div>}
      {isLoading && <Loading />}
      {renderingUrl && <img src={renderingUrl} alt="composed-sample" />}
    </div>
  );
};
