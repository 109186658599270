import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { doGetRequest } from '../RequestUtils';
import { DTecEnsemble } from './DTecEnsemble';
import { DTecEnsembleLine } from './DTecEnsembleLine';

const dterApiHost = config.dtecApiUrl;

const getEnsembleDetailsUrl = (templateToken: string, numberOfPanels?: number, culture?: string): string => {
  const url = new URL(`${dterApiHost}/api/v4/templates/${templateToken}/ensembles:filterBySameDPS`);

  if (numberOfPanels !== undefined) {
    url.searchParams.append('numPanels', numberOfPanels.toString());
  }

  if (culture !== undefined) {
    url.searchParams.append('culture', culture);
  }

  return url.toString();
};

export async function getEnsembleDetails(
  templateToken: string,
  numberOfPanels?: number,
  culture?: string,
): Promise<ApiResponseOrError<DTecEnsemble[]>> {
  const url = new URL(getEnsembleDetailsUrl(templateToken, numberOfPanels, culture));

  return await doGetRequest<DTecEnsemble[]>(url);
}

export async function getEnsembleLine(ensembleLineId: string): Promise<ApiResponseOrError<DTecEnsembleLine>> {
  const url = new URL(`${dterApiHost}/api/v4/ensembleLines/${ensembleLineId}`);

  return await doGetRequest<DTecEnsembleLine>(url);
}
