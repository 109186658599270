import React, { useState } from 'react';
import { Button } from '@cimpress/react-components';
import config from '../config';
import { ContentAssetVersion } from '../api/Cams/model/ContentAssetVersion';
import { MapAssetsToDexAssets } from '../Tools/AssetToDexAssetMapperUtility';
import * as uploadsApi from '../api/Uploads/uploads-api';
import * as camsApi from '../api/Cams/cams-api';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiErrorToast } from '../components/ApiErrorToast';
import { ApiResponseOrError } from '../api/ApiResponseOrError';
import { shapes } from '@cimpress/react-components';
import './PreviewInDexButton.scss';
import { Tenant } from '../api/model/Tenant';
import { splitAssetsWithMultipleCategories } from './assetSplitterUtility';
import { sortAssetsCustomOrder, sortAssetsLexicographically } from './assetSorterUtility';

interface Props {
  assets: ContentAssetVersion[];
  uploadsTenant?: string;
  tenant: Tenant;
  isDisabled?: boolean;
}

export const PreviewInDexButton = ({
  assets,
  uploadsTenant = 'default',
  tenant,
  isDisabled = false,
}: Props): JSX.Element => {
  const [uploadsResponse, setUploadsResponse] = useState<ApiResponseOrError<any>>();
  const [isLoading, setIsLoading] = useState(false);
  const dexAssetsDictionary = MapAssetsToDexAssets(assets);
  const dexAssetsArray = Object.values(dexAssetsDictionary);
  const uploadsInternal = async () => {
    setIsLoading(true);
    const categoryOrder = await camsApi.getCategoryOrdering(tenant, 'pattern');
    const sortedDexAssets = isSuccessfulResponse(categoryOrder)
      ? sortAssetsCustomOrder(splitAssetsWithMultipleCategories(dexAssetsArray), categoryOrder.data)
      : sortAssetsLexicographically(dexAssetsArray);
    const blob = new Blob([JSON.stringify(sortedDexAssets)], { type: 'application/json' });
    const uploads = await uploadsApi.uploads(blob, `blob-${Date.now()}.json`, 'default', 1);
    if (isSuccessfulResponse(uploads)) {
      const patternsUrl = `patternsUrl=${config.uploadsApiUrl}/v1/uploads/${uploads.data[0].uploadId}?tenant=${uploadsTenant}`;
      const newWindow = window.open(`${config.dexPageUrl}?${patternsUrl}`, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      setUploadsResponse(uploads);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Button
        disabled={isDisabled}
        style={{ marginLeft: '10px', width: 'auto' }}
        onClick={() => uploadsInternal()}
        target="_blank"
      >
        <div className="flexbox-container">
          {isLoading && <shapes.Spinner size="small" className="spinner" />}
          {`Test In Dex`}
        </div>
      </Button>
      <ApiErrorToast response={uploadsResponse} />
    </>
  );
};
