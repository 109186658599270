import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext, AssetsContext } from '../App';
import * as queryStringUtil from '../AssetsListPage/QueryString/QueryStringUtils';
import { LocationDescriptorObject } from 'history';
import { CamNavigationState } from './Model/CamNavigationState';
import { getVariableAssetPageRoute } from '../Routes/GenericRoutes';
interface Props {
  caption: string;
}

export const TitlePageLink = ({ caption }: Props): JSX.Element => {
  const { tenant, availableTenants } = useContext(AppContext);
  const { queryStringState: contextQueryStringState } = useContext(AssetsContext);

  const location = useLocation();
  const urlQueryStringState = queryStringUtil.parseQueryStringState(location, false, availableTenants);

  const buildUrl = (): LocationDescriptorObject<CamNavigationState> => {
    if (tenant === undefined) {
      return {
        pathname: '/',
      };
    }

    const routeString = getRoute();
    const queryString = getQueryString();

    return {
      pathname: routeString,
      search: queryString,
      state: {
        isTitleClicked: true,
      },
    };
  };

  const getRoute = (): string => {
    // get asset category of the currently open page
    return getVariableAssetPageRoute(
      tenant.contentAuthoringAccountId,
      tenant.contentAuthoringAreaId,
      urlQueryStringState.assetCategory,
    );
  };

  const getQueryString = (): string | undefined => {
    return contextQueryStringState === undefined
      ? undefined
      : queryStringUtil.serializeQueryParams(contextQueryStringState);
  };

  const url = buildUrl();

  return (
    <Link data-testid="title-page-link" to={url}>
      {caption}
    </Link>
  );
};
