export type ActionResult<T> = ActionSuccess<T> | EmptySuccess | ActionError | ActionCancel;

export interface EmptySuccess {
  actionResultType: 'EmptySuccess';
}

export interface ActionSuccess<T> {
  actionResultType: 'Success';
  data: T;
}

export interface ActionError {
  actionResultType: 'Error';
  error: Error;
}

export interface ActionCancel {
  actionResultType: 'Cancel';
}

export function createCancelResponse(): ActionCancel {
  return {
    actionResultType: 'Cancel',
  };
}

export function createErrorResponse(error: Error): ActionError {
  return {
    actionResultType: 'Error',
    error: error,
  };
}

export function createSuccessResponse<T>(data?: T): ActionSuccess<T> | EmptySuccess {
  if (!data) {
    return {
      actionResultType: 'EmptySuccess',
    };
  }

  return {
    actionResultType: 'Success',
    data: data,
  };
}

export function isSuccessfulResult<T>(result: ActionResult<T>): result is ActionSuccess<T> {
  return result.actionResultType === 'Success';
}

export function isErrorResult(result: ActionResult<any>): result is ActionError {
  return result.actionResultType === 'Error';
}

export async function throwOnErrorAndUnwrap<T>(resultPromise: Promise<ActionResult<T>>): Promise<T> {
  const result = await resultPromise;
  if (isErrorResult(result)) {
    throw result.error;
  }

  if (!isSuccessfulResult(result)) {
    throw new Error('Cannot unwrap value');
  }

  return result.data;
}

export async function throwOnError(resultPromise: Promise<ActionResult<any>>): Promise<unknown> {
  const result = await resultPromise;
  if (isErrorResult(result)) {
    throw result.error;
  }

  return isSuccessfulResult(result) ? result.data : undefined;
}

export function mergeActionResults(actionResults: ActionResult<any>[]): ActionResult<never> {
  const failures = actionResults.filter(isErrorResult);
  if (failures.length > 0) {
    // TODO: aggregate errors
    createErrorResponse(failures[0].error);
  }

  return createSuccessResponse();
}
