import { ColorPaletteComponent, FontSchemaComponent } from '../api/Cams/model/Ags/models';
import { ColorPalette } from '../Uploads/ColorPalette';
import { FontSchema } from '../Uploads/FontSchema';

const toFontStyle = (fontStyle: string) => {
  const style = fontStyle.toLowerCase();
  switch (style) {
    case 'bolditalic':
      return 'bold,italic';
    default:
      return style;
  }
};

export const transformFontSchemaForAGS = (fontSchema: FontSchema): FontSchemaComponent => {
  const primary = fontSchema.schemata.Primary;
  const secondary = fontSchema.schemata.Secondary;
  const tertiary = fontSchema.schemata.Tertiary;

  return {
    primary: {
      family: primary.fontFamily,
      style: toFontStyle(primary.fontStyle),
    },
    secondary: secondary
      ? {
          family: secondary.fontFamily,
          style: toFontStyle(secondary.fontStyle),
        }
      : undefined,
    tertiary: tertiary
      ? {
          family: tertiary.fontFamily,
          style: toFontStyle(tertiary.fontStyle),
        }
      : undefined,
    type: 'fontSchema',
  };
};

export const transformColorPaletteForAGS = (colorPalette: ColorPalette): ColorPaletteComponent => {
  return {
    Primary: colorPalette.Primary?.cmyk ?? '',
    Secondary: colorPalette.Secondary?.cmyk,
    Neutral: colorPalette.Neutral?.cmyk,
    Accent: colorPalette.Accent?.cmyk,
    Background: colorPalette.Background?.cmyk,
    type: 'colorPalette',
  };
};

export const transformImageAssetForAGS = (
  assetKind: 'clipart' | 'background',
  printUrl: string,
  previewUrl: string,
  originalSourceUrl: string,
  width: number,
  height: number,
): any => {
  const assetInfo = {
    printUrl,
    previewUrl,
    originalSourceUrl,
    width: `${width}px`,
    height: `${height}px`,
  };

  const type = assetKind === 'clipart' ? 'logo' : 'backgroundImage';

  return {
    type,
    ...assetInfo,
  };
};
