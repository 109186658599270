import React, { useContext } from 'react';
import { Column } from 'react-table';
import { ReactTablev6 } from '@cimpress/react-components';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../Tools/DateTimeHelpers';
import { AppContext } from '../../../App';
import { DesignManagementAsset } from '../../../api/Cams/model/Design/DesignManagementAsset';
import { DesignManagementAssetVersion } from '../../../api/Cams/model/Design/DesignManagementAssetVersion';
import { getDesignManagementAssetDetailsPageRoute } from '../../../Routes/DesignManagementAssetRoutes';
import { DesignAssetPreview } from '../../../components/DesignAssetPreview';
import { getDesignName } from '../../../Tools/DesignHelpers';

interface Props {
  designs: DesignManagementAsset[];
}

export const DesignsTabularView = ({ designs }: Props): JSX.Element => {
  const assetVersions = designs.map((design) => design.latestVersion);
  const { tenant } = useContext(AppContext);

  type tableCellType = { original: DesignManagementAssetVersion };
  const columnDefaultStyle = { overflow: 'hidden', textOverflow: 'ellipsis' };
  const columns = [
    {
      Header: 'Name',
      id: 'name',
      Cell: ({ original: version }: tableCellType) => {
        const design = designs.find((d) => d.latestVersion === version);
        const link = (
          <Link
            to={getDesignManagementAssetDetailsPageRoute(
              tenant.contentAuthoringAccountId,
              tenant.contentAuthoringAreaId,
              version.assetId,
            )}
          >
            {design ? getDesignName(design, version, '<No name>') : '<No name>'}
          </Link>
        );
        return link;
      },
      style: columnDefaultStyle,
    },
    {
      Header: 'Asset Preview',
      id: 'assetPreview',
      Cell: ({ original: version }: tableCellType) => {
        const accessor = <DesignAssetPreview designVersion={version} width={300} />;
        return accessor;
      },
      style: { maxHeight: '200px' },
    },
    {
      Header: 'Last Modified',
      id: 'modified',
      Cell: ({ original: version }: tableCellType) => {
        return formatDateTime(version.created);
      },
      style: columnDefaultStyle,
    },
    {
      Header: 'Last Modified By',
      accessor: 'createdByFullname',
      style: columnDefaultStyle,
    },
  ] as Column[];

  return (
    <ReactTablev6
      sortable={false}
      showPagination={false}
      minRows={0}
      columns={columns}
      data={assetVersions}
      collapseOnDataChange={false}
      pageSize={assetVersions.length}
    />
  );
};
