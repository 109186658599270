export const appendQueryStringValue = (
  params: URLSearchParams,
  paramName: string,
  value: string | undefined | null,
): void => {
  if (value === null || value === undefined || value.length === 0) {
    return;
  }

  params.append(paramName, value);
};

export const appendQueryStringArray = (
  params: URLSearchParams,
  paramName: string,
  array: (string | undefined | null)[] | undefined,
): void => {
  if (array === null || array === undefined || array.length === 0) {
    return;
  }

  for (const value of array.filter((v): v is string => !!v)) {
    params.append(paramName, value);
  }
};
