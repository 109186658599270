import { DexAsset } from './DexAsset';

export const splitAssetsWithMultipleCategories = (assets: DexAsset[]): DexAsset[] => {
  const patternsWithSingleCategory = assets.filter((x) => x.categories.length < 2);
  const patternsWithMultipleCategories = assets.filter((x) => x.categories.length > 1);

  const splitPatterns = patternsWithMultipleCategories.map((x) => splitAsset(x)).flat();

  return patternsWithSingleCategory.concat(splitPatterns);
};

const splitAsset = (asset: DexAsset): DexAsset[] => {
  return asset.categories.map((x) => ({
    id: asset.id,
    fileUrl: asset.fileUrl,
    tags: asset.tags,
    collections: asset.collections,
    category: x,
    categories: [x],
  }));
};
