import React, { useContext } from 'react';
import { ActionResult, createErrorResponse, createSuccessResponse } from '../Tools/ActionResult';
import { FileToUpload } from './FileToUpload';
import { UploadsControl } from './UploadsControl';
import { createOrUpdateDesignAsset } from '../api/Cams/CamsApiFacade';
import { AppContext } from '../App';

interface Props {
  designId?: string;
  creativeAssetId?: string;
  onUpload: (designId: string) => void;
}

export const CreateOrUpdateDesignAssetControl = ({ designId, creativeAssetId, onUpload }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const isNewDesign = designId === undefined;

  const handleUpload = async (uploads: FileToUpload[]): Promise<ActionResult<never>> => {
    try {
      const masterFile = uploads.find((fileToUpload) => fileToUpload.isMainFile);
      if (!masterFile) {
        return createErrorResponse(new Error('Please provide main design artwork.'));
      }

      const linkedFiles = uploads.filter((file) => file !== masterFile);

      const design = await createOrUpdateDesignAsset(tenant, masterFile, {}, linkedFiles, designId, creativeAssetId);

      onUpload(design.id);
      return createSuccessResponse();
    } catch (e) {
      return createErrorResponse(e);
    }
  };
  return (
    <>
      <UploadsControl
        allowMultiple={true}
        handleUpload={handleUpload}
        uploadButtonText={isNewDesign ? 'Create' : 'Create new version'}
      />
    </>
  );
};
