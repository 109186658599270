import { Card, Tooltip } from '@cimpress/react-components';
import React, { useContext } from 'react';
import { formatDateTime } from '../../Tools/DateTimeHelpers';
import './CreativeAssetHeader.scss';
import { Link } from 'react-router-dom';
import { AssetTags } from '../../components/AssetTags';
import { AppContext } from '../../App';
import * as CamsCreativeApi from '../../api/Cams/cams-api-creative';
import { AssetPreview } from '../../components/AssetPreview';
import { ApiResponse, ApiResponseOrError } from '../../api/ApiResponseOrError';
import { CreativeAsset } from '../../api/Cams/model/CreativeAsset';
import { CreativeAssetVersion } from '../../api/Cams/model/CreativeAssetVersion';
import { AssetCollections } from '../../components/AssetCollections';
import {
  getCreativeAssetDetailsPageRoute,
  getCreativeAssetsPageRoute,
  getCreativeVersionsPageRoute,
} from '../../Routes/CreativeAssetRoutes';
import { DesignConceptIdEdit } from './DesignConceptIdEdit';
import { DownloadButton } from '../../components/DownloadButton';
import { serializeQueryParams } from '../../AssetsListPage/QueryString/QueryStringUtils';

interface Props {
  asset: CreativeAsset;
  assetVersion: CreativeAssetVersion;
  firstVersion: CreativeAssetVersion;
  isLatestVersion: boolean;
}

export const CreativeAssetHeader = ({ asset, assetVersion, firstVersion, isLatestVersion }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const downloadLink = assetVersion.uploadInfo?.fileUrl;
  const downloadFilename = assetVersion.uploadInfo?.fileName;
  const isCorrupted = assetVersion.uploadInfo?.hash === '0';

  return (
    <Card>
      <div className="row pattern-header-wrapper" data-testid="creative-asset-header-container">
        <div className="col-sm-4">
          <div className="preview-wrapper" style={{ minHeight: '200px' }}>
            <AssetPreview assetVersion={assetVersion} />
          </div>
          <div className="card-text pattern-description">
            <p>
              Version: {assetVersion.versionNumber}&nbsp;
              <Link
                className="simple-link see-history-link"
                to={getCreativeVersionsPageRoute(
                  tenant.contentAuthoringAccountId,
                  tenant.contentAuthoringAreaId,
                  asset.id,
                )}
              >
                see history
              </Link>
            </p>
            {!isLatestVersion && (
              <p>
                <span style={{ color: 'red' }}>This is not the latest version.&nbsp;</span>
                <Link
                  to={getCreativeAssetDetailsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    asset.id,
                  )}
                  className="simple-link"
                >
                  Show the latest version
                </Link>
              </p>
            )}
            <p>
              {isLatestVersion && (
                <>
                  <span>
                    Last modified by:&nbsp;
                    <Link
                      to={getCreativeAssetsPageRoute(
                        tenant.contentAuthoringAccountId,
                        tenant.contentAuthoringAreaId,
                        serializeQueryParams({ filters: { createdBy: [assetVersion.createdBy] } }),
                      )}
                      data-testid="last-modified-by"
                    >
                      {assetVersion.createdByFullname}
                    </Link>
                  </span>
                  <br />
                  <Tooltip direction={'right'} contents={formatDateTime(assetVersion.created)}>
                    <span>Last modified: {formatDateTime(assetVersion.created, true)}</span>
                  </Tooltip>
                </>
              )}
              {!isLatestVersion && (
                <>
                  <span>
                    Modified by:&nbsp;
                    <Link
                      to={getCreativeAssetsPageRoute(
                        tenant.contentAuthoringAccountId,
                        tenant.contentAuthoringAreaId,
                        serializeQueryParams({ filters: { createdBy: [assetVersion.createdBy] } }),
                      )}
                    >
                      {assetVersion.createdByFullname}
                    </Link>
                  </span>
                  <br />
                  <Tooltip direction={'right'} contents={formatDateTime(assetVersion.created)}>
                    <span>Modified: {formatDateTime(assetVersion.created, true)}</span>
                  </Tooltip>
                </>
              )}
            </p>
            {firstVersion && (
              <p>
                First version created by:&nbsp;
                <Link
                  to={getCreativeAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { createdBy: [assetVersion.createdBy] } }),
                  )}
                >
                  {firstVersion.createdByFullname}
                </Link>
                <br />
                <Tooltip direction={'right'} contents={formatDateTime(firstVersion.created)}>
                  First version created: {formatDateTime(firstVersion.created, true)}
                </Tooltip>
              </p>
            )}
          </div>
        </div>
        <div className="col-sm-8 pattern-detail-wrapper">
          <div className="detail-header-title-row">
            <h1 className="text-primary pattern-name-header" data-testid="creative-asset-name-header">
              {asset.isDeleted ? (
                <span style={{ color: 'red' }}>{assetVersion.uploadInfo?.fileName} (Asset was deleted)</span>
              ) : (
                <>{assetVersion.uploadInfo?.fileName}</>
              )}
            </h1>
          </div>
          <div className="card-text">
            {tenant.settings.features.showDesignConceptFilter && (
              <div className="pattern-tags-wrapper">
                <DesignConceptIdEdit asset={asset} />
              </div>
            )}
            <div className="pattern-tags-wrapper">
              <AssetTags
                entityTags={asset.tags}
                facetName="Tags"
                componentLabel="Tags"
                enableAutocomplete={tenant.settings.features.hideTagsFilter !== true}
                updateMethod={(tenant, tags): Promise<ApiResponseOrError<CreativeAsset>> =>
                  CamsCreativeApi.updateCreativeAsset(asset.id, tenant, { tags: tags })
                }
                disabled={!tenant.userPermissions.canWrite}
                getterFromResponse={(resp: ApiResponse<CreativeAsset>) => resp.data.tags ?? []}
                getAssetsListWithTagFilterRoute={(tag) =>
                  getCreativeAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { tags: [tag] } }),
                  )
                }
              />
            </div>
            <div className="pattern-tags-wrapper">
              <AssetCollections
                entityCollections={asset.collections}
                facetName="Collections"
                componentLabel="Collections"
                updateMethod={(tenant, collections) =>
                  CamsCreativeApi.updateCreativeAsset(asset.id, tenant, {
                    collections: collections,
                  })
                }
                disabled={!tenant.userPermissions.canWrite}
                getterFromResponse={(resp) => resp.data.collections}
                getAssetsListWithCollectionFilterRoute={(collection) =>
                  getCreativeAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { collections: [collection] } }),
                  )
                }
              />
            </div>
          </div>
          <div className="card-text">
            <div className="pattern-tags-wrapper">
              <div className="pattern-buttons">
                {isCorrupted && (
                  <div className="corrupted-creative">
                    <span>
                      <i className="fa fa-exclamation-triangle"></i>
                    </span>
                    This is a corrupted creative asset which is not downloadable.
                  </div>
                )}
                {!isCorrupted && (
                  <DownloadButton
                    downloadLink={downloadLink}
                    downloadFilename={downloadFilename}
                    isDownloadable={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
