import { Tenant } from '../api/model/Tenant';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { DesignsListViewType } from '../DesignPage/DesignsListViewType';

const envName = process.env.REACT_APP_STAGE;
const tenantKeyName = `CAMS_${envName}_tenant`;
const designsViewTypeName = `CAMS_${envName}_designsViewType`;
const assetCategory = `CAMS_${envName}_assetCategory`;

export const saveActiveTenant = (tenant: Tenant): void => {
  localStorage.setItem(tenantKeyName, JSON.stringify(tenant));
};

export const getActiveTenant = (): Tenant | undefined => {
  const tenant = localStorage.getItem(tenantKeyName);

  return tenant ? JSON.parse(tenant) : undefined;
};

export const saveDesignsViewType = (viewType: DesignsListViewType): void => {
  localStorage.setItem(designsViewTypeName, viewType);
};

export const getDesignsViewType = (): DesignsListViewType | undefined => {
  const viewType = localStorage.getItem(designsViewTypeName) as DesignsListViewType;

  return viewType || undefined;
};

export const saveAssetCategory = (assetKind: AssetCategory): void => {
  localStorage.setItem(assetCategory, assetKind);
};

export const getAssetCategory = (): AssetCategory | undefined => {
  const contentType = localStorage.getItem(assetCategory) as AssetCategory;

  return contentType || undefined;
};
