import axios from 'axios';
import axiosRetry from 'axios-retry';
import { AxiosRequestConfig } from 'axios';
import auth from '../auth';
import config from '../config';
import { ApiResponseOrError, createErrorResponse, createResponse } from './ApiResponseOrError';

const camRequestor = config.camRequestor;

axiosRetry(axios, {
  retries: 2,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    return (
      (error.response?.status ?? 500) >= 500 &&
      (error.config.method === 'patch' || error.config.method === 'post') &&
      (!!error.config.url?.includes('localhost') || !!error.config.url?.includes('cam.'))
    );
  },
});

export const axiosDefaultConfig = (abortController?: AbortController): AxiosRequestConfig => {
  return {
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    params: {
      requestor: camRequestor,
    },
    validateStatus: (status) => status < 400,
    signal: abortController?.signal,
  } as AxiosRequestConfig;
};

export async function doGetRequest<T>(
  url: URL,
  config?: AxiosRequestConfig,
  abortController?: AbortController,
): Promise<ApiResponseOrError<T>> {
  try {
    const response = await axios.get<T>(url.toString(), config ?? axiosDefaultConfig(abortController));
    return createResponse(response);
  } catch (error) {
    return createErrorResponse(error, url);
  }
}

export async function doPatchRequest<T>(
  url: URL,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  body: any,
  config?: AxiosRequestConfig,
): Promise<ApiResponseOrError<T>> {
  try {
    const response = await axios.patch<T>(url.toString(), body, config ?? axiosDefaultConfig());
    return createResponse(response);
  } catch (error) {
    return createErrorResponse(error, url);
  }
}

export async function doPostRequest<T>(
  url: URL,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  body: any,
  config?: AxiosRequestConfig,
): Promise<ApiResponseOrError<T>> {
  try {
    const response = await axios.post<T>(url.toString(), body, config ?? axiosDefaultConfig());
    return createResponse(response);
  } catch (error) {
    return createErrorResponse(error, url);
  }
}

export async function doDeleteRequest<T>(url: URL, config?: AxiosRequestConfig): Promise<ApiResponseOrError<T>> {
  try {
    const response = await axios.delete<T>(url.toString(), config ?? axiosDefaultConfig());
    return createResponse(response);
  } catch (error) {
    return createErrorResponse(error, url);
  }
}

export async function doGetRequestAsBlob<T>(
  url: URL,
  config?: AxiosRequestConfig,
  abortController?: AbortController,
): Promise<ApiResponseOrError<T>> {
  const requestConfig = config ?? axiosDefaultConfig(abortController);
  requestConfig.responseType = 'blob';

  return await doGetRequest(url, requestConfig, abortController);
}

export function createFileUploadRequestConfig(): AxiosRequestConfig {
  const config = axiosDefaultConfig();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  config.headers!['content-type'] = 'multipart/form-data';

  return config;
}

export function createFormData<T>(requestBody?: T): FormData {
  const formData = new FormData();
  if (requestBody !== undefined) {
    formData.append('requestBody', JSON.stringify(requestBody));
  }
  return formData;
}
