import React, { useContext } from 'react';
import { Column } from 'react-table';
import { ReactTablev6 } from '@cimpress/react-components';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../Tools/DateTimeHelpers';
import { AppContext } from '../../../App';
import { AssetPreview } from '../../../components/AssetPreview';
import { AnyAsset } from '../../../api/Cams/model/AnyAsset';
import { AnyAssetVersion } from '../../../api/Cams/model/AnyAssetVersion';
import { getAssetVersionName } from '../../../Tools/AssetHelpers';
import { AssetCategory } from '../../AssetCategory';
import { getAssetCategorySetting } from '../../../AssetCategoryConfig';
import { getRouteByAssetCategory } from '../../../Tools/NavigationRoutesUtility';

interface Props {
  assets: AnyAsset[];
  assetCategory: AssetCategory;
}

export const TabularView = ({ assets, assetCategory }: Props): JSX.Element => {
  const assetVersions = assets.map((asset) => asset.latestVersion);
  const { tenant } = useContext(AppContext);
  const assetCategorySetting = getAssetCategorySetting(assetCategory);

  type tableCellType = { original: AnyAssetVersion };
  const columnDefaultStyle = { overflow: 'hidden', textOverflow: 'ellipsis' };
  const columns = [
    {
      Header: 'Asset Preview',
      id: 'assetPreview',
      Cell: ({ original: version }: tableCellType) => {
        const link = !assetCategorySetting.isFileNameVisible ? (
          <Link to={`${getRouteByAssetCategory(tenant, assetCategory)}${version.assetId}`}>
            <AssetPreview assetVersion={version} width={300} />
          </Link>
        ) : (
          <AssetPreview assetVersion={version} width={300} />
        );
        return link;
      },
      style: { maxHeight: '200px' },
    },
    {
      Header: 'Last Modified',
      id: 'modified',
      Cell: ({ original: version }: tableCellType) => {
        return formatDateTime(version.created);
      },
      style: columnDefaultStyle,
    },
    {
      Header: 'Last Modified By',
      accessor: 'createdByFullname',
      style: columnDefaultStyle,
    },
  ] as Column[];
  if (assetCategorySetting.isFileNameVisible) {
    columns.unshift({
      Header: 'Name',
      id: 'name',
      Cell: ({ original: version }: tableCellType) => {
        const link = (
          <Link to={`${getRouteByAssetCategory(tenant, assetCategory)}${version.assetId}`}>
            {getAssetVersionName(version)}
          </Link>
        );
        return link;
      },
      style: columnDefaultStyle,
    });
  }
  return (
    <ReactTablev6
      sortable={false}
      showPagination={false}
      minRows={0}
      columns={columns}
      data={assetVersions}
      collapseOnDataChange={false}
      pageSize={assetVersions.length}
    />
  );
};
