import React from 'react';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';
import { AssetCategory } from '../AssetCategory';
import { AssetTile } from '../AssetViews/AssetTile';
import './AssetsListTilesView.scss';

interface Props {
  assets: AnyAsset[];
  assetCategory: AssetCategory;
  onAssetSelected: (asset: AnyAsset, isSelected: boolean) => void;
}

export const AssetsListTilesView = ({ assets, assetCategory, onAssetSelected }: Props): JSX.Element => {
  return (
    <div className="assets-list-grid" data-testid="assets-list-grid">
      {assets.map((asset) => {
        return (
          <AssetTile
            asset={asset}
            key={`${asset.id}-${asset.latestVersion.versionNumber}`}
            onAssetSelected={onAssetSelected}
            assetCategory={assetCategory}
          />
        );
      })}
    </div>
  );
};
