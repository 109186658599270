import { tenantRoutePrefix } from '../Tools/Routes';

export function getDesignManagementAssetsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  queryString?: string,
): string {
  const path = `${tenantRoutePrefix}designs`;
  if (accountId && contentAreaId) {
    const updatedPath = path.replace(':accountId', accountId).replace(':areaId', contentAreaId);
    return !!queryString ? `${updatedPath}?${queryString}` : updatedPath;
  } else {
    return path;
  }
}

export function getDesignManagementAssetDetailsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  designId?: string,
  designVersion?: number,
  message?: string,
): string {
  let path = `${tenantRoutePrefix}designs/:id`;
  const messageSuffix = message ? `message=${message}` : '';

  if (!(accountId && contentAreaId && designId)) return path;

  path = path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', designId);
  path = path.concat('?');
  designVersion && (path = path.concat('version=', designVersion.toString()));
  return path + messageSuffix;
}

export function getDesignManagementAssetVersionsPageRoute(
  accountId?: string,
  contentAreaId?: string,
  designId?: string,
): string {
  const path = `${tenantRoutePrefix}designs/:id/versions`;
  if (accountId && contentAreaId && designId) {
    return path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', designId);
  } else {
    return path;
  }
}

export const getDesignManagementAssetDetailsForSpecificVersionPageRoute = (): string =>
  `${tenantRoutePrefix}designs/:id?version=:version`;

export const getEditDesignManagementAssetPropertiesPageRoute = (
  accountId?: string,
  contentAreaId?: string,
  designId?: string,
): string => {
  const path = `${tenantRoutePrefix}designs/:id/metadata`;
  if (accountId && contentAreaId && designId) {
    return path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', designId);
  } else {
    return path;
  }
};

export const getDesignManagementAssetCulturePreviewsPageRoute = (
  accountId?: string,
  contentAreaId?: string,
  designId?: string,
): string => {
  const path = `${tenantRoutePrefix}designs/:id/culturePreviews`;
  return accountId && contentAreaId && designId
    ? path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', designId)
    : path;
};

export const getDesignManagementAssetClonePageRoute = (
  accountId?: string,
  contentAreaId?: string,
  designId?: string,
): string => {
  const path = `${tenantRoutePrefix}designs/:id/clone`;
  return accountId && contentAreaId && designId
    ? path.replace(':accountId', accountId).replace(':areaId', contentAreaId).replace(':id', designId)
    : path;
};
