import React from 'react';
import { Button, Dropdown } from '@cimpress/react-components';
import { AssetCategory } from '../AssetsListPage/AssetCategory';

const options = [
  {
    label: 'No filter',
    value: undefined,
  },
  {
    label: 'With',
    value: true,
  },
  {
    label: 'Without',
    value: false,
  },
];

interface Props {
  assetCategory: AssetCategory;
  value: boolean | undefined;
  onValueChange: (value: boolean | undefined) => void;
  dataTestId: string;
}

export const HasAssetCategoryFilter = ({ assetCategory, value, onValueChange, dataTestId }: Props): JSX.Element => {
  const getButtonVariant = (buttonType: boolean | undefined) => {
    return buttonType === value ? 'primary' : 'default';
  };

  const onButtonClick = (selected: boolean | undefined) => {
    onValueChange(selected);
  };

  const title = `${assetCategory.format({ plurality: 'plural', capitalLetter: true })}: ${
    options.find((b) => b.value === value)?.label || ''
  }`;

  return (
    <div className="design-concepts-filters-switch">
      <Dropdown title={title} data-testid={dataTestId}>
        {options.map((button) => {
          return (
            <Button
              key={button.label}
              variant={getButtonVariant(button.value)}
              onClick={() => onButtonClick(button.value)}
            >
              <span>{button.label}</span>
            </Button>
          );
        })}
      </Dropdown>
    </div>
  );
};
