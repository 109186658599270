/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Accordion, Card, CodeBlock } from '@cimpress/react-components';
import React from 'react';
import * as Logger from '../Tools/Logging';
import murzik from './murzik.jpg';
import './ErrorBoundary.scss';

interface ErrorInfo {
  componentStack: any;
}

interface State {
  error: any;
  errorInfo: ErrorInfo | null;
}

// https://reactjs.org/docs/error-boundaries.html
// The lifecycle method componentDidCatch() which is necessary for catching the child component errors does not work with hooks yet
// Use traditional component here instead details see https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    Logger.error('Unhandled error occured in CAM', {
      error: error,
      errorInfo: errorInfo,
    });
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  private refreshPage() {
    window.location.reload();
  }
  private goHome() {
    window.location.href = '/';
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return (
        <>
          <Card
            header={<h2>Oops! Something went wrong on our end 😞. The devs have been notified 👨‍💻👩‍💻🔔.</h2>}
            variant="layout"
            style={{
              border: '1px solid gray',
              padding: '50px',
              margin: '50px',
              backgroundColor: '#eff3f5',
              textAlign: 'center',
            }}
          >
            <div className="self-healing-buttons">
              <button onClick={this.refreshPage}>Try Refresh</button>
              <button onClick={this.goHome}>Go to Home Page</button>
            </div>

            <p>
              If the error persists, please contact{' '}
              <a href="https://cimpress.slack.com/archives/CKHTF34E5" target="_blank">
                #mcp-support-content-management
              </a>
              .
            </p>
            <img src={murzik} height="450px" alt="a black cat asleep on a gaming chair" />
          </Card>
          <Accordion
            title="Click to expand and view more technical details"
            style={{ border: '2px solid red', backgroundColor: '#eff3f5', margin: '50px', padding: '25px' }}
          >
            <p>
              <b>Error</b>:{' '}
              <CodeBlock code={this.state.error.toString() || 'An error text could not be found'}></CodeBlock>
            </p>
            <b>Component stack trace:</b>
            <CodeBlock code={this.state.errorInfo?.componentStack}></CodeBlock>
          </Accordion>
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
