import { Asset } from './Asset';
import { CreativeAssetVersion } from './CreativeAssetVersion';

export interface CreativeAsset extends Asset<CreativeAssetVersion> {
  assetKind: undefined;
  assetType: 'creative';

  designConceptId?: string | undefined;
}

export const isCreativeAsset = (x: Asset<any>): x is CreativeAsset => {
  return x.assetType === 'creative';
};
