import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import * as RequestUtils from '../RequestUtils';
import { createFormData } from '../RequestUtils';
import { CreativeAsset } from './model/CreativeAsset';
import { CreativeAssetRequestBody } from './model/CreativeAssetRequestBody';
import { CreativeAssetVersion } from './model/CreativeAssetVersion';
import { UpdateCreativeAssetRequestBody } from './model/UpdateCreativeAssetRequestBody';

const camsApiHost = config.camsApiUrl;

export async function getAssetVersionsByHash(
  tenant: Tenant,
  hash: string,
): Promise<ApiResponseOrError<CreativeAssetVersion[]>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/creative/hash/${hash}`,
  );
  return await RequestUtils.doGetRequest<CreativeAssetVersion[]>(url);
}

export async function createCreativeAsset(
  tenant: Tenant,
  creativeAsset: File,
  creativeAssetPreview: File | undefined,
  requestBody: CreativeAssetRequestBody = {} as CreativeAssetRequestBody,
): Promise<ApiResponseOrError<CreativeAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/creative`,
  );
  const formData = createFormDataWithUpload(creativeAsset, requestBody, creativeAssetPreview);
  const config = RequestUtils.createFileUploadRequestConfig();

  return await RequestUtils.doPostRequest<CreativeAsset>(url, formData, config);
}

export async function createCreativeAssetVersion(
  tenant: Tenant,
  creativeAsset: File,
  creativeAssetPreview: File | undefined,
  id?: string,
  requestBody: CreativeAssetRequestBody = {} as CreativeAssetRequestBody,
): Promise<ApiResponseOrError<CreativeAssetVersion>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/creative/${id}/versions`,
  );
  const formData = createFormDataWithUpload(creativeAsset, requestBody, creativeAssetPreview);
  const config = RequestUtils.createFileUploadRequestConfig();

  return await RequestUtils.doPostRequest<CreativeAssetVersion>(url, formData, config);
}

export async function updateCreativeAsset(
  id: string,
  tenant: Tenant,
  requestBody: UpdateCreativeAssetRequestBody,
): Promise<ApiResponseOrError<CreativeAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/creative/${id}`,
  );

  return await RequestUtils.doPatchRequest<CreativeAsset>(url, requestBody);
}

function createFormDataWithUpload<T>(asset: File, requestBody?: T, preview?: File): FormData {
  const formData = createFormData(requestBody);
  formData.append('asset', asset, asset.name);
  if (preview !== undefined) {
    formData.append('preview', preview, preview.name);
  }
  return formData;
}
