import { TextField, Pagination, Select } from '@cimpress/react-components';
import React, { useState } from 'react';
import './AssetsListPaging.scss';
import { AssetsPaging } from '../Model/AssetsPaging';
import { calculatePagesCount } from '../../Tools/PagingUtility';

interface Props {
  paging: AssetsPaging;
  totalItemsCount: number;
  performPageChange: (page: number) => void;
  performPageSizeChange: (pageSize: number) => void;
}

export const AssetsListPaging = ({
  paging,
  totalItemsCount,
  performPageChange,
  performPageSizeChange,
}: Props): JSX.Element => {
  const [goToPage, setGoToPage] = useState('');

  const totalPagesCount = calculatePagesCount(totalItemsCount, paging.pageSize);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGoToPage(e.target.value);
  };

  const goToPageSubmitted = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const goToPageInt = parseInt(goToPage);
    if (goToPageInt >= 1) {
      const goTo = goToPageInt >= totalPagesCount ? totalPagesCount : goToPageInt;
      performPageChange(goTo - 1);
    }
  };

  const onPageChange = ({ selected }: { selected: number }) => {
    performPageChange(selected);
  };

  const mapPageSizeToSelectItem = (pageSize: number) => {
    return { label: pageSize.toString(), value: pageSize.toString() };
  };

  const pageSizeOptions = [30, 60, 90].map((x) => {
    return { label: x.toString(), value: x.toString() };
  });

  const pageSizeOnChange = (selected: { label: string; value: string }) => {
    const pageSize = parseInt(selected.value);
    performPageSizeChange(pageSize);
  };

  return (
    <div className={`assets-list-paging`} data-testid="paging">
      {totalPagesCount > 1 && (
        <form onSubmit={goToPageSubmitted}>
          <TextField name="go-to-page" label="Go To" value={goToPage} onChange={onInputChange} />
        </form>
      )}
      <Pagination
        forcePage={paging.pageNumber}
        initialPage={paging.pageNumber}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={totalPagesCount}
        onPageChange={onPageChange}
        pagesOutsideElipses={1}
        pagesBetweenElipses={3}
      />
      <Select
        isClearable={false}
        label="Page Size"
        value={mapPageSizeToSelectItem(paging.pageSize)}
        options={pageSizeOptions}
        onChange={pageSizeOnChange as any}
      />
    </div>
  );
};
