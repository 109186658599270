import React from 'react';
import { DesignManagementAssetVersion } from '../api/Cams/model/Design/DesignManagementAssetVersion';
import { getProminentPanelInfo } from '../Tools/DesignHelpers';
import { AssetPreview } from './AssetPreview';
import { TemplatePreview } from './TemplatePreview';

interface Props {
  designVersion: DesignManagementAssetVersion;
  showAsLink?: boolean;
  navigationUri?: string;
  width?: number;
  physicalSpecId?: string;
}

export const DesignAssetPreview = ({
  designVersion,
  showAsLink,
  width,
  physicalSpecId,
  navigationUri,
}: Props): JSX.Element => {
  const prominentPanelInfo = getProminentPanelInfo(designVersion);

  return prominentPanelInfo?.templateId ? (
    <TemplatePreview
      designTemplateId={prominentPanelInfo.templateId}
      templateVersion={prominentPanelInfo.templateVersion}
      physicalSpecId={prominentPanelInfo.dpsId}
      showAsLink={showAsLink || false}
      previewWidth={width}
    />
  ) : (
    <AssetPreview
      assetVersion={designVersion}
      showAsLink={showAsLink}
      navigationUri={navigationUri}
      physicalSpecId={physicalSpecId}
      width={width}
    />
  );
};
