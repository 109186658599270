import { Action, Location } from 'history';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { DependencyList } from 'react-router/node_modules/@types/react';
import { CamNavigationState } from '../DesignPage/Model/CamNavigationState';

/**
 * The pop action gets triggered every time a back or forward navigation is done.
 * @param handler Method to be executed upon push action.
 * @param dep Dependency list for underlying useEffect.
 */
export function useNavigationButtons(handler: () => void, dep: DependencyList): void {
  useUponAction(handler, dep, 'POP');
}

/**
 * The push action gets triggered every time new item record is pushed into history.
 * @param handler Method to be executed upon push action.
 * @param dep Dependency list for underlying useEffect.
 */
export function usePushAction(handler: () => void, dep: DependencyList): void {
  useUponAction(handler, dep, 'PUSH');
}

export function useQueryChange(handler: () => void, dep: DependencyList): void {
  useUponTrigger(handler, dep, (prev, next, a) => {
    const state = next.state as CamNavigationState;
    if (state && state.isTitleClicked) {
      // this was deliberate route change, even if just query string has changed
      return false;
    }
    return a === 'PUSH' && prev.search !== next.search;
  });
}

export function useRouteChange(handler: () => void, dep: DependencyList): void {
  useUponTrigger(handler, dep, (prev, next, a) => {
    // wasn't a navigation
    if (a !== 'PUSH') {
      return false;
    }

    // route has changed
    if (prev.pathname !== next.pathname) {
      return true;
    }

    // route didn't change, but still was a navigation action, e.g. clicking title link
    const state = next.state as CamNavigationState;
    if (state && state.isTitleClicked) {
      return true;
    }

    return false;
  });
}

function useUponAction(handler: () => void, dep: DependencyList, action: Action): void {
  return useUponTrigger(handler, dep, (prev, next, a) => a === action);
}

function useUponTrigger(
  handler: () => void,
  dep: DependencyList,
  trigger: (previousLocation: Location<unknown>, newLocation: Location<unknown>, action: Action) => boolean,
) {
  const history = useHistory();
  const previousState = useRef<Location<unknown>>({
    hash: history.location.hash,
    pathname: history.location.pathname,
    search: history.location.search,
    state: undefined,
  });
  useEffect(() => {
    return history.listen((location: Location<unknown>, actionPassed: Action) => {
      if (trigger(previousState.current, location, actionPassed)) {
        handler();
      }
      previousState.current = location;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
}
