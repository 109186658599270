import { TabCard } from '@cimpress/react-components';
import { Tab } from '@cimpress/react-components/lib/TabCard';
import React, { useContext, useState } from 'react';
import { AppContext, AssetsContext } from '../App';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import CategoryLocalizationPage from '../CategoryLocalization/CategoryLocalizationPage';
import { getAssetCategory } from '../Tools/LocalStorage';
import { CategoryOrdering } from './components/CategoryOrdering';

export enum ConfigurationTab {
  CategoryOrdering = 0,
  CategoryLocalization = 1,
}

interface Props {
  // pass these directly to avoid designs page re-rendering on app context change
  tab?: ConfigurationTab;
  setPreviouslyViewedContentType: (previouslyViewedAssetCategory: AssetCategory) => void;
}

const ConfigurationPage = ({ tab, setPreviouslyViewedContentType }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { previouslyViewedAssetKind } = useContext(AssetsContext);
  const [tabIndex, setTabIndex] = useState(tab ?? ConfigurationTab.CategoryOrdering);
  const updateSelectedTab = (e: any, selectedKey: number) => setTabIndex(selectedKey);

  const categoryOrdering = (
    <CategoryOrdering
      tenant={tenant}
      initialAssetKind={previouslyViewedAssetKind ?? getAssetCategory() ?? 'pattern'}
      setPreviouslyViewedContentType={setPreviouslyViewedContentType}
    />
  );

  const categoryLocalization = <CategoryLocalizationPage />;

  const tabs: Tab[] = [];
  tabs[ConfigurationTab.CategoryOrdering] = {
    name: 'Category Ordering',
    block: categoryOrdering,
    href: 'Category Ordering',
  };
  tabs[ConfigurationTab.CategoryLocalization] = {
    name: 'Category Localization',
    block: categoryLocalization,
    href: 'Category Localization',
  };

  return (
    <div className="container-fluid">
      <div className="col-sm-12">
        <h1 data-testid="content-configuration-page-title">Content configuration</h1>
      </div>

      <div className="row section">
        <div className="col-sm-12">
          <TabCard tabs={tabs} selectedIndex={tabIndex} onSelect={updateSelectedTab} />
        </div>
      </div>
    </div>
  );
};

export default ConfigurationPage;
