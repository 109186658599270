import auth from '../auth';
import { doGetRequest } from '../api/RequestUtils';
import { Tenant } from '../api/model/Tenant';
import { ApiResponseOrError } from '../api/ApiResponseOrError';

export interface PrincipalPermissionsResponse {
  [resourceType: string]: Resource[];
}

interface Resource {
  identifier: string;
  permissions: string[];
}

export const getUserPermissons = async (): Promise<ApiResponseOrError<PrincipalPermissionsResponse>> => {
  const principal = auth.getProfile().sub;

  const urlEncodedSub = encodeURIComponent(principal);
  const url = new URL(`https://api.cimpress.io/auth/access-management/v1/principals/${urlEncodedSub}/permissions`);
  return await doGetRequest<PrincipalPermissionsResponse>(url);
};

const hasPrincipalPermission = (
  permissions: PrincipalPermissionsResponse,
  tenant: Tenant,
  permission: string,
): boolean => {
  const godPermissionExists =
    permissions['content-authoring-area']?.find((x) => x.identifier === '*')?.permissions.includes(permission) ?? false;

  const specificPermissionExists =
    permissions['content-authoring-area']
      ?.find((x) => x.identifier === tenant.contentAuthoringAreaId)
      ?.permissions.includes(permission) ?? false;

  const correctPermissionExists = godPermissionExists || specificPermissionExists;
  return correctPermissionExists;
};

export const canPrincipalWrite = (permissions: PrincipalPermissionsResponse, tenant: Tenant): boolean => {
  return hasPrincipalPermission(permissions, tenant, 'write:content');
};

export const canPrincipalPublish = (permissions: PrincipalPermissionsResponse, tenant: Tenant): boolean => {
  return hasPrincipalPermission(permissions, tenant, 'publish:content');
};
