import React from 'react';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';
import { DesignManagementAsset } from '../../api/Cams/model/Design/DesignManagementAsset';
import { AssetCategory } from '../AssetCategory';
import { DesignsTabularView } from '../AssetViews/Designs/DesignsTabularView';
import { TabularView } from '../AssetViews/GenericAssetViews/TabularView';
import { UnknownTabularView } from '../AssetViews/UnknownTabularView';
import './AssetsListTilesView.scss';

interface Props {
  assets: AnyAsset[];
  assetCategory: AssetCategory;
}

export const AssetsListTabularView = ({ assets, assetCategory }: Props): JSX.Element => {
  const assetType = assets.length > 0 ? assets[0].assetType : 'management';
  const assetKind = assets.length > 0 ? assets[0].assetKind : 'design';
  switch (assetType) {
    case 'management': {
      switch (assetKind) {
        case 'pattern':
        case 'clipart':
        case 'fontSchema':
        case 'colorPalette':
        case 'background':
          return <TabularView assets={assets} assetCategory={assetCategory} />;
        case 'design':
          return <DesignsTabularView designs={assets as DesignManagementAsset[]} />;
        default:
          return <UnknownTabularView assets={assets} />;
      }
    }
    case 'creative':
      return <TabularView assets={assets} assetCategory={assetCategory} />;
    default:
      return <UnknownTabularView assets={assets} />;
  }
};
