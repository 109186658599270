export interface TagValidation {
  isValid: boolean;
  message?: string;
}

const validateNewTag = (t: string): TagValidation => {
  if (t.length >= 128) {
    return {
      isValid: false,
      message: 'Unable to parse tags. Tags must be less than 128 characters in length.',
    };
  }

  if (t.length < 2) {
    return {
      isValid: false,
      message: 'Unable to parse tags. Tags must be at least 2 characters long.',
    };
  }

  return { isValid: true };
};

export const validateNewTags = (tags: string[]): TagValidation => {
  let result: TagValidation = { isValid: true };
  tags.forEach((t) => {
    const validation = validateNewTag(t);
    if (validation.isValid === false) {
      result = validation;
    }
  });

  return result;
};
