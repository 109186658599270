import React, { useContext, useState } from 'react';
import { Button, Tooltip } from '@cimpress/react-components';
import { shapes } from '@cimpress/react-components';
import './DownloadWithLinkedAssetsButton.scss';
import { AppContext } from '../App';
import { FilePathsToUrls } from '../api/Cams/model/FilePathsToUrls';
import * as CamsApi from '../api/Cams/cams-api';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiResponseOrError } from '../api/ApiResponseOrError';
import { ApiErrorToast } from '../components/ApiErrorToast';
import { downloadAsFile } from '../Tools/DownloadAsFile';
import { sanitize } from '../Tools/InputSanitizers/SanitizeFilenameUtil';

interface Props {
  title: string;
  filePathsToUrls?: FilePathsToUrls;
  zipName?: string;
  disableButton?: boolean;
  loadDataToDownload?: () => Promise<FilePathsToUrls | undefined>;
}

export const DownloadWithLinkedAssetsButton = ({
  title,
  filePathsToUrls,
  zipName,
  disableButton,
  loadDataToDownload,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const [zipDownloadResponse, setZipDownloadResponse] = useState<ApiResponseOrError<any>>();
  const [isDownloading, setIsDownloading] = useState(false);

  const zipFilename = sanitize(zipName ? `${zipName}.zip` : `Assets-${Date.now()}.zip`);

  const downloadAsZipInternal = async () => {
    setIsDownloading(true);
    if (loadDataToDownload) {
      const dataToBeZipped = await loadDataToDownload();
      if (!dataToBeZipped) {
        setIsDownloading(false);
        return;
      }
      filePathsToUrls = dataToBeZipped;
    }

    if (filePathsToUrls) {
      const result = await CamsApi.zipAndDownload(tenant, filePathsToUrls, zipFilename);
      if (isSuccessfulResponse(result)) {
        downloadAsFile(result.data, zipFilename);
      } else {
        setZipDownloadResponse(result);
      }
    }

    setIsDownloading(false);
  };
  return (
    <div>
      {disableButton ? (
        <Tooltip
          contents="Downloading more than 1000 assets is not supported, please reduce the number of assets to be downloaded by
        applying search terms and/or filters."
        >
          <Button variant="default" size="lg" onClick={() => downloadAsZipInternal()} disabled={true}>
            {title}
          </Button>
        </Tooltip>
      ) : (
        <Button variant="default" size="lg" onClick={() => downloadAsZipInternal()} disabled={isDownloading}>
          <div className="flexbox-container">
            {isDownloading && <shapes.Spinner size="small" className="spinner" />}
            {title}
          </div>
        </Button>
      )}
      <ApiErrorToast response={zipDownloadResponse} />
    </div>
  );
};
