import React from 'react';
import { Card } from '@cimpress/react-components';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';

interface Props {
  asset: AnyAsset;
}

export const UnknownTile = ({}: Props): JSX.Element => {
  return (
    <Card className="unknown-tile">
      <span>Unknown asset</span>
    </Card>
  );
};
