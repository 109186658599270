import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { ActionResult, createErrorResponse, createSuccessResponse } from './ActionResult';

export function isSuccessfulResponse<T>(response: ApiResponseOrError<T>): response is ApiResponse<T> {
  return response.responseType === 'success';
}

export function getResponseError(response: ApiResponseOrError<any>): string {
  if (response.responseType === 'error') {
    return response.content || 'Unknown error happened';
  }

  return '';
}

export async function throwOnApiError<T>(responsePromise: Promise<ApiResponseOrError<T>>): Promise<T> {
  const response = await responsePromise;
  if (!isSuccessfulResponse(response)) {
    throw new Error(getResponseError(response));
  } else {
    return response.data;
  }
}

export function toActionResult<T>(response: ApiResponseOrError<T>): ActionResult<T> {
  if (response.responseType === 'success') {
    return createSuccessResponse(response.data);
  } else {
    return createErrorResponse(new Error(response.content || 'Unexpected error happened'));
  }
}
