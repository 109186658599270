import { FontSchemaManagementAssetVersion } from '../api/Cams/model/FontSchema/FontSchemaManagementAssetVersion';
import { readJson } from '../api/Uploads/uploads-api';
import { Font, FontSchema } from '../Uploads/FontSchema';
import { isSuccessfulResponse } from './ResponseHelper';

export const getFontSchema = async (
  assetVersion: FontSchemaManagementAssetVersion,
): Promise<FontSchema | undefined> => {
  const fontSchemaInJson = await readJson<FontSchema>(new URL(assetVersion.masterFile.uploadInfo.fileUrl));
  return isSuccessfulResponse(fontSchemaInJson) ? fontSchemaInJson.data : undefined;
};

export const validateFontSchema = (schema: FontSchema): { isValid: boolean; error: string } => {
  let result = {
    isValid: false,
    error: 'Font schema is empty',
  };
  const fonts = Object.fromEntries(Object.entries(schema.schemata).filter(([, v]) => v.fontFamily || v.fontStyle));
  switch (Object.entries(fonts).length) {
    case 1:
      result = validateFontInternal(schema, ['Primary']);
      break;
    case 2:
      result = validateFontInternal(schema, ['Primary', 'Secondary']);
      break;
    case 3:
      result = validateFontInternal(schema, ['Primary', 'Secondary', 'Tertiary']);
      break;
  }
  return result;
};

const validateFontInternal = (schema: FontSchema, fontCatergory: string[]) => {
  for (const item in fontCatergory) {
    const font = getParameterCaseInsensitive(fontCatergory[item], schema);
    if (!font?.fontFamily || !font?.fontStyle) {
      return {
        isValid: false,
        error: `${fontCatergory[item]} font style is missing.`,
      };
    }
  }
  return {
    isValid: true,
    error: '',
  };
};

export const sanitizeFontSchema = (schema: FontSchema): FontSchema => {
  return {
    ...schema,
    schemata: Object.fromEntries(Object.entries(schema.schemata).filter(([, v]) => v.fontFamily && v.fontStyle)),
  };
};

export const getParameterCaseInsensitive = (key: string, schema?: FontSchema): Font | undefined => {
  if (schema) {
    const asLowercase = key.toLowerCase();
    const font = Object.keys(schema.schemata).find((k) => k.toLowerCase() === asLowercase);
    return font ? schema.schemata[font] : undefined;
  }
  return undefined;
};
