import React, { useEffect, useState } from 'react';
import { DragAndDrop, Droppable, withDraggable, FlexBox } from '@cimpress/react-components';
import { InjectedDraggableProps } from '@cimpress/react-components/lib/DragAndDrop/withDraggable';
import IconDragDrop from '@cimpress-technology/react-streamline-icons/lib/IconDragDrop';

interface DragAndDropItem {
  id: string;
  droppableId: string;
  index: number;
}

interface DragAndDropProps extends InjectedDraggableProps {
  item: DragAndDropItem;
}

// a little function to help us with reordering the result
const reorder = (list: DragAndDropItem[], startIndex: number, endIndex: number): DragAndDropItem[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableItem = withDraggable(({ item }: DragAndDropProps) => (
  <div data-testid="drag-and-drop-item">{item.id}</div>
));

interface Props {
  currentItemOrder: string[];
  orderChanged: (order: string[]) => void;
  disabled: boolean;
}

const DragAndDropList = ({ currentItemOrder, orderChanged, disabled }: Props): JSX.Element | null => {
  const [itemOrder, setItemOrder] = useState<DragAndDropItem[]>([]);

  useEffect(() => {
    setItemOrder(
      currentItemOrder.map((currentId, index) => ({
        id: currentId,
        droppableId: 'CategoryOrderingDroppable',
        index: index,
      })),
    );
  }, [currentItemOrder]);

  const onDragEnd = (result: { source: DragAndDropItem; destination: DragAndDropItem }) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(itemOrder, source.index, destination.index);
      setItemOrder(items);
      orderChanged(items.map((x) => x.id));
    }
  };

  return (
    <div data-testid="drag-and-drop">
      <DragAndDrop onDragEnd={onDragEnd}>
        <FlexBox>
          <div style={{ flexBasis: '100%' }}>
            <Droppable
              droppableId="CategoryOrderingDroppable"
              isEmpty={itemOrder.length === 0}
              emptyPlaceholder={<div> There are no categories for current tentant. </div>}
            >
              {itemOrder.map((item, index) => (
                <DraggableItem
                  isDragDisabled={disabled}
                  draggableId={item.id}
                  index={index}
                  key={item.id}
                  icon={IconDragDrop}
                  item={item}
                />
              ))}
            </Droppable>
          </div>
        </FlexBox>
      </DragAndDrop>
    </div>
  );
};

export default DragAndDropList;
