import React, { useState, useEffect, useContext } from 'react';
import * as CamsApi from '../api/Cams/cams-api';
import * as CamsFontApi from '../api/Cams/cams-api-fonts';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { FontSchemaHeader } from './FontSchemaHeader';
import { Button } from '@cimpress/react-components';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import { Toast } from '../components/Toast';
import { FontSchemaManagementAsset } from '../api/Cams/model/FontSchema/FontSchemaManagementAsset';
import { FontSchemaManagementAssetVersion } from '../api/Cams/model/FontSchema/FontSchemaManagementAssetVersion';
import { DeleteAsset } from '../components/DeleteAsset';
import { Tenant } from '../api/model/Tenant';
import { CreateOrUpdateFontAssetControl } from '../Uploads/CreateOrUpdateFontAssetControl';
import { FontSchema } from '../Uploads/FontSchema';
import { getFontSchema } from '../Tools/FontSchemaUtils';
import { CreateOrUpdateAssetModal } from '../components/CreateOrUpdateAssetModal';
import { getVariableAssetDetailPageRoute } from '../Routes/GenericRoutes';
import { ToAssetCategory } from '../AssetsListPage/AssetCategory';
import '../detail-header.scss';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const getUsedFontSchemaVersion = async (
  tenant: Tenant,
  id: string,
  fontSchemaAsset: FontSchemaManagementAsset,
  version: string | number | undefined,
): Promise<FontSchemaManagementAssetVersion> => {
  let usedFontSchemaVersion = fontSchemaAsset.latestVersion;

  const versionNumber = parseInt(version?.toString() || '');
  if (versionNumber) {
    const versionResponse = await CamsApi.getAssetVersion(tenant, id, versionNumber);
    if (isSuccessfulResponse(versionResponse)) {
      usedFontSchemaVersion = versionResponse.data as FontSchemaManagementAssetVersion;
    }
  }
  return usedFontSchemaVersion;
};

const FontSchemaDetailPage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const { id } = useParams<QueryStringParams>();

  const history = useHistory();

  const query = useQuery();
  const version = query.get('version') || undefined;
  const message = query.get('message') || undefined;

  const [asset, setAsset] = useState<FontSchemaManagementAsset>();
  const [assetVersion, setAssetVersion] = useState<FontSchemaManagementAssetVersion>();
  const [firstVersion, setFirstVersion] = useState<FontSchemaManagementAssetVersion>();

  const [, setAssetError] = useState<string>();
  const [isCreatingNewVersion, setIsCreatingNewVersion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState(true);
  const [isPublishButtonEnabled, setIsPublishButtonEnabled] = useState(true);
  const [fontSchema, setFontSchema] = useState<FontSchema>();

  const loadAsset = async (id: string, version?: string | number) => {
    setIsLoading(true);
    setIsDeleteButtonShown(tenant.userPermissions.canWrite);
    setIsPublishButtonEnabled(tenant.userPermissions.canPublish);
    const response = await CamsApi.getAsset<FontSchemaManagementAsset>(tenant, id);
    setAssetError(getResponseError(response));
    setIsLoading(false);
    if (isSuccessfulResponse(response)) {
      if (response.data.assetKind !== 'fontSchema') {
        history.push(
          getVariableAssetDetailPageRoute(
            tenant.contentAuthoringAccountId,
            tenant.contentAuthoringAreaId,
            ToAssetCategory(response.data.assetType, response.data.assetKind),
            id,
          ),
        );
        return;
      }
      const fontSchema = response.data as FontSchemaManagementAsset;
      setAsset(fontSchema);

      const usedFontSchemaVersion = await getUsedFontSchemaVersion(tenant, id, fontSchema, version);

      const firstVersion =
        usedFontSchemaVersion.versionNumber === 1
          ? usedFontSchemaVersion
          : await getUsedFontSchemaVersion(tenant, id, fontSchema, 1);
      setFirstVersion(firstVersion);

      const parsedFontSchema = await getFontSchema(usedFontSchemaVersion);
      setFontSchema(parsedFontSchema);

      setIsPublishButtonEnabled((isPublishButtonEnabled) => isPublishButtonEnabled && !fontSchema.isDeleted);
      setAssetVersion(usedFontSchemaVersion);
    }
  };

  const handleUpdate = async () => {
    await loadAsset(id);
  };

  const handlePublish = async (tenant: Tenant, id: string, version: number, published: boolean) => {
    setIsLoading(true);
    const publishResponse = await CamsFontApi.updateFontSchemaVersionAsset(id, version, tenant, {
      isDiscoverable: published,
    });
    if (!isSuccessfulResponse(publishResponse)) {
      setIsLoading(false);
      return publishResponse;
    }

    const getAssetResponse = await CamsApi.getAsset<FontSchemaManagementAsset>(tenant, id);
    if (isSuccessfulResponse(getAssetResponse)) {
      setAsset(getAssetResponse.data);
      setAssetVersion(publishResponse.data);
    }
    setIsLoading(false);

    return publishResponse;
  };

  const handleSaveProperty = async (tenant: Tenant, id: string, property: { [key: string]: string[] }) => {
    const response = await CamsFontApi.updateFontSchemaAsset(id, tenant, property);
    if (response.responseType === 'success') {
      setAsset(response.data);
      const usedFontSchemaVersion = await getUsedFontSchemaVersion(tenant, id, response.data, version);
      setAssetVersion(usedFontSchemaVersion);
    }
    return response;
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadAsset(id, version);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, version]);

  const onAssetVersionCreated = async (assetId: string, version: number) => {
    setIsCreatingNewVersion(false);
    await loadAsset(assetId, version);
  };

  const isLatestVersion = asset?.latestVersion.versionNumber === assetVersion?.versionNumber;

  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2 data-testid="fontschema-details-title">Font schema detail</h2>
          {asset && isLatestVersion && (
            <div className="detail-header-buttons">
              <Button
                variant="primary"
                className="upload-new-version-button"
                data-testid="upload-new-version-button"
                onClick={() => setIsCreatingNewVersion(true)}
                disabled={!tenant.userPermissions.canWrite}
              >
                Edit font schema
              </Button>
            </div>
          )}
        </div>
      </div>

      {isLoading && <Loading />}

      {!asset && !isLoading && <h4 data-testid="detail-not-found">Font schema not found.</h4>}

      {asset && assetVersion && firstVersion && (
        <div className="row section">
          <div className="col-sm-12">
            <FontSchemaHeader
              asset={asset}
              assetVersion={assetVersion}
              firstVersion={firstVersion}
              isLatestVersion={isLatestVersion}
              isPublishButtonEnabled={isPublishButtonEnabled}
              fontSchema={fontSchema}
              onPublish={(tenant, published) => handlePublish(tenant, asset.id, assetVersion.versionNumber, published)}
              onSaveTags={(tenant, keywords) => handleSaveProperty(tenant, asset.id, { contentTags: keywords })}
              onSaveCollections={(tenant, collections) =>
                handleSaveProperty(tenant, asset.id, { contentCollections: collections })
              }
              onSaveDestinations={(tenant, destinations) =>
                handleSaveProperty(tenant, asset.id, { accessibleTo: destinations })
              }
            />
          </div>
        </div>
      )}

      {asset && !asset.isDeleted && (
        <div className="row section">
          <div className="col-sm-6">
            <DeleteAsset
              assetId={asset.id}
              assetCategory={'font schema'}
              name={`this ${'font schema'.format()}`}
              onDelete={handleUpdate}
              showButton={isDeleteButtonShown}
              disabled={!asset.isDeletable || isLoading}
            />
          </div>
        </div>
      )}
      {message && <Toast message={message} />}
      {asset && (
        <CreateOrUpdateAssetModal
          isModalOpen={isCreatingNewVersion}
          onSetModelOpen={setIsCreatingNewVersion}
          title={'Edit Font Schema'}
          closeButton={true}
          className="create-update-asset"
          component={
            <CreateOrUpdateFontAssetControl
              assetId={asset.id}
              masterAssetId={asset.latestVersion?.masterFile?.assetId}
              fontSchemata={fontSchema}
              onUpload={onAssetVersionCreated}
            />
          }
        />
      )}
    </div>
  );
};

export default FontSchemaDetailPage;
