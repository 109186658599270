import React, { useEffect, useState } from 'react';
import { CollectionsFacetName } from '../../../api/Cams/model/CollectionsFacetName';
import { FacetValue } from '../../../api/Cams/model/FacetValue';
import { Tenant } from '../../../api/model/Tenant';
import * as CamsApi from '../../../api/Cams/cams-api';
import { defaultPageNumber, maxPageSize } from '../../Model/Constants';
import { AssetType } from '../../../api/Cams/model/AssetType';
import { isSuccessfulResponse } from '../../../Tools/ResponseHelper';
import { Accordion } from '@cimpress/react-components';
import ClearFilterButton from '../Components/ClearFilterButton';
import CollectionsTreeView from './CollectionsTreeView';

interface Props {
  selectedCollections: string[];
  tenant: Tenant;
  label: string;
  facetName: CollectionsFacetName;
  assetType: AssetType;
  onFilterChanged: (items: string[]) => void;
  isReload?: boolean;
  onIsReload?: (value: boolean) => void;
}

export const CollectionsFilterPanel = ({
  selectedCollections,
  tenant,
  label,
  facetName,
  assetType,
  onFilterChanged,
  isReload,
  onIsReload,
}: Props): JSX.Element => {
  const [allItems, setAllItems] = useState<FacetValue[]>([]);

  const loadAllFilters = async () => {
    const getCollectionsResponse = await CamsApi.getFacetValues(
      tenant,
      facetName,
      maxPageSize,
      defaultPageNumber,
      undefined,
      undefined,
      assetType,
      undefined,
    );

    if (isSuccessfulResponse(getCollectionsResponse)) {
      setAllItems(getCollectionsResponse.data as FacetValue[]);
    }
  };
  useEffect(() => {
    loadAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, facetName, assetType]);

  useEffect(() => {
    if (isReload && onIsReload) {
      loadAllFilters();
      onIsReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  return (
    <Accordion
      data-testid="collections-filters-panel"
      title={
        <>
          <span>{label}</span>
          <ClearFilterButton selectedItems={selectedCollections} onFilterChanged={onFilterChanged} />
        </>
      }
      className="cams-accordion-card"
      defaultOpen
    >
      <CollectionsTreeView
        label={label}
        allItems={allItems}
        selectedItems={selectedCollections}
        onItemSelected={onFilterChanged}
      />
    </Accordion>
  );
};
