import React from 'react';
import { Tag } from '@cimpress/react-components';
import { AssetsFilters } from '../../Model/AssetsFilters';
import './ActiveFiltersStrip.scss';
import { ClearAllFiltersSearchButton } from '../../Components/ClearAllFiltersSearchButton';
import { EMPTY_SEARCH_TERM, SearchTerm } from '../../Model/SearchTerm';

interface Props {
  filters: AssetsFilters;
  search: SearchTerm;
  onFilterChanged: (filters: AssetsFilters) => void;
  onSearchChanged: (searchTerm: SearchTerm) => void;
  onFiltersSearchCleared: () => void;
}

interface FilterStripItem {
  filterType: keyof AssetsFilters | 'search';
  value: string;
  label: string;
}

type FilterType = keyof AssetsFilters | 'search';

export const ActiveFiltersStrip = ({
  filters,
  search,
  onFilterChanged,
  onSearchChanged,
  onFiltersSearchCleared,
}: Props): JSX.Element | null => {
  const onClearSearch = () => {
    onSearchChanged(EMPTY_SEARCH_TERM);
  };

  const filterStripItems: FilterStripItem[] = [];

  const addFilterStripItem = (filterType: FilterType, label: string, value?: string) => {
    if (!value) {
      return;
    }
    filterStripItems.push({
      filterType,
      value,
      label,
    });
  };

  const addFilterStripArray = (filterType: FilterType, values: string[], labelPrefix: string) => {
    if (values.length === 0) {
      return;
    }

    for (const value of values) {
      addFilterStripItem(filterType, `${labelPrefix}: ${value}`, value);
    }
  };

  addFilterStripItem('search', `${search.exact ? 'Exact search' : 'Search'}: ${search.term}`, search.term);
  addFilterStripArray('collections', filters.collections, 'Collection');
  addFilterStripArray('createdBy', filters.createdBy, 'Designer');
  addFilterStripArray('designConceptId', filters.designConceptId, 'Design Concept Id');
  addFilterStripArray('designUseCaseNames', filters.designUseCaseNames, 'Design Use Case Name');
  addFilterStripArray('productNames', filters.productNames, 'Product Name');
  addFilterStripArray('published', filters.published, 'Published');
  addFilterStripArray('tags', filters.tags, 'Tag');
  addFilterStripArray('accessibleTo', filters.accessibleTo, 'Accessible To');

  const onRemoveItem = (removedItem: string, propertyName: FilterType): void => {
    if (propertyName === 'search') {
      onClearSearch();
      return;
    }

    const newValues = filters[propertyName].filter((item) => item !== removedItem);
    const newFilters: AssetsFilters = {
      ...filters,
    };
    newFilters[propertyName] = newValues;

    onFilterChanged(newFilters);
  };

  return (
    <>
      {filterStripItems.length > 0 && (
        <div data-testid="active-filters-strip" className="filters-strip">
          {filterStripItems.length >= 2 && (
            <ClearAllFiltersSearchButton
              filters={filters}
              search={search}
              onFiltersSearchCleared={onFiltersSearchCleared}
            />
          )}
          {filterStripItems.map((filterStripItem) => {
            return (
              <Tag
                key={filterStripItem.label}
                label={filterStripItem.label}
                removable={true}
                className="tag-with-space"
                onRemoveClick={() => onRemoveItem(filterStripItem.value, filterStripItem.filterType)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
