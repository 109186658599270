import React from 'react';
import { Card } from '@cimpress/react-components';
import { AssetCategory } from '../AssetsListPage/AssetCategory';
import { getAssetCategorySetting } from '../AssetCategoryConfig';
import { CreateNewAssetButton } from '../components/CreateNewAssetButton';

interface MissingDesignConceptAssetTileProps {
  assetCategory: AssetCategory;
  designConceptId: string;
  showCategoryLabel?: boolean;
  showInCard?: boolean;
}

interface MissingDesignConceptAssetTileInternalProps {
  assetCategory: AssetCategory;
  designConceptId: string;
  showCategoryLabel: boolean;
}

const MissingDesignConceptAssetTileInternal = ({
  assetCategory,
  designConceptId,
  showCategoryLabel,
}: MissingDesignConceptAssetTileInternalProps) => {
  return (
    <>
      {showCategoryLabel && (
        <>
          <h2>{assetCategory.format({ capitalLetter: true })}</h2>
          <h4>
            {`No ${assetCategory.format({
              plurality: getAssetCategorySetting(assetCategory).hasUniqueDesignConceptId ? 'singular' : 'plural',
            })} found for the Design Concept`}
          </h4>
        </>
      )}
      <CreateNewAssetButton
        assetCategory={assetCategory}
        reloadIndexPageContents={() => window.location.reload()}
        onTagReload={() => false}
        onCollectionReload={() => false}
        designConceptId={designConceptId}
      ></CreateNewAssetButton>
    </>
  );
};

export const MissingDesignConceptAssetTile = ({
  assetCategory,
  designConceptId,
  showCategoryLabel = true,
  showInCard = true,
}: MissingDesignConceptAssetTileProps): JSX.Element => {
  return showInCard ? (
    <Card className="asset-tile">
      <MissingDesignConceptAssetTileInternal
        assetCategory={assetCategory}
        designConceptId={designConceptId}
        showCategoryLabel={showCategoryLabel}
      />
    </Card>
  ) : (
    <div className="asset-tile">
      <MissingDesignConceptAssetTileInternal
        assetCategory={assetCategory}
        designConceptId={designConceptId}
        showCategoryLabel={showCategoryLabel}
      />
    </div>
  );
};
