import React, { useEffect, useState } from 'react';
import * as CamsApi from '../../api/Cams/cams-api';
import { Tenant } from '../../api/model/Tenant';
import Loading from '../../components/Loading';
import { isSuccessfulResponse } from '../../Tools/ResponseHelper';
import { Button } from '@cimpress/react-components';
import DragAndDropList from './DragAndDropList';
import './CategoryOrdering.scss';
import { AssetKind } from '../../api/Cams/model/AssetKind';
import { AssetCategorySelector } from '../../AssetsListPage/Components/AssetCategorySelector';
import { AssetCategory } from '../../AssetsListPage/AssetCategory';
import { TenantDescription } from '../../api/model/TenantDescription';

interface Props {
  tenant: TenantDescription;
  initialAssetKind: AssetCategory;
  setPreviouslyViewedContentType: (previouslyViewedAssetCategory: AssetCategory) => void;
}

export const CategoryOrdering = ({
  tenant,
  initialAssetKind,
  setPreviouslyViewedContentType,
}: Props): JSX.Element | null => {
  const supportedAssetKinds: AssetCategory[] = ['pattern', 'clipart'];

  const [isLoading, setIsLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [assetKind, setAssetKind] = useState<AssetKind>(
    (supportedAssetKinds.find((x) => x === initialAssetKind) as AssetKind) ?? 'pattern',
  );
  const [categoryOrder, setCategoryOrder] = useState<string[]>([]);
  const [modifiedCategoryOrder, setModifiedCategoryOrder] = useState<string[]>([]);

  const canPublish = tenant.userPermissions.canPublish;

  useEffect(() => {
    fetchAll(tenant, assetKind);
    setIsDirty(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, assetKind]);

  useEffect(() => {
    setModifiedCategoryOrder(categoryOrder);
  }, [categoryOrder]);

  const fetchAll = async (tenant: Tenant, assetKind: AssetKind) => {
    setIsLoading(true);
    try {
      await fetchCategoryOrder(tenant, assetKind);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategoryOrder = async (tenant: Tenant, assetKind: AssetKind) => {
    const getCategoryOrderingResponse = await CamsApi.getCategoryOrdering(tenant, assetKind);

    if (isSuccessfulResponse(getCategoryOrderingResponse)) {
      setCategoryOrder(getCategoryOrderingResponse.data);
    }
  };

  const saveCategoryOrder = async (tenant: Tenant, categoryOrderToSave: string[]) => {
    setIsLoading(true);
    const response = await CamsApi.setCategoryOrdering(tenant, assetKind, {
      categories: categoryOrderToSave,
    });

    if (isSuccessfulResponse(response)) {
      setIsDirty(false);
    }
    setIsLoading(false);
  };

  const handleBeforeAssetKindChanged = (): boolean => {
    if (isDirty && !confirm('You have unsaved changes. Do you want to continue?')) {
      return false;
    }
    return true;
  };

  const handleAssetKindChanged = async (assetKind: AssetCategory) => {
    setAssetKind(assetKind as AssetKind);
    setPreviouslyViewedContentType(assetKind);
  };

  return (
    <div className="category-ordering-section">
      <div className="col-sm-12 detail-header">
        <AssetCategorySelector
          assetCategory={assetKind as AssetCategory}
          onBeforeAssetCategoryChanged={handleBeforeAssetKindChanged}
          onAssetCategoryChanged={handleAssetKindChanged}
          tenantDescription={tenant}
          defaultAssetCategories={supportedAssetKinds}
          customSuffix={' category ordering'}
        />
        <div className="detail-header-buttons">
          {!canPublish && (
            <div className="unsaved-changes-warning">
              <h6 className="text-danger" data-testid="category-order-no-permissions">
                Read only: you do not have the permission to publish.
              </h6>
            </div>
          )}
        </div>
      </div>

      {isLoading && <Loading />}

      {!isLoading && (
        <div className="col-sm-12">
          <DragAndDropList
            disabled={!canPublish || !tenant.userPermissions.canPublish}
            currentItemOrder={modifiedCategoryOrder}
            orderChanged={(order) => {
              setModifiedCategoryOrder(order);
              setIsDirty(true);
            }}
          />
          <div className="save-footer">
            <Button
              disabled={!canPublish || !isDirty}
              variant="primary"
              onClick={() => saveCategoryOrder(tenant, modifiedCategoryOrder)}
            >
              Save category order
            </Button>
            {isDirty && (
              <div className="unsaved-changes-warning">
                <h6 className="text-warning" data-testid="category-order-unsaved-changes">
                  You have unsaved changes.
                </h6>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
