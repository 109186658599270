import { AssetVersion } from '../AssetVersion';
import { CreativeAssetVersion } from '../CreativeAssetVersion';
import { FontSchemaContentAssetVersion } from './FontSchemaContentAssetVersion';

export interface FontSchemaManagementAssetVersion extends AssetVersion {
  assetKind: 'fontSchema';
  assetType: 'management';

  masterFile: CreativeAssetVersion;
  contentAsset: FontSchemaContentAssetVersion;
}

export const isFontSchemaManagementAssetVersion = (x: AssetVersion): x is FontSchemaManagementAssetVersion => {
  return x.assetType === 'management' && x.assetKind === 'fontSchema';
};
