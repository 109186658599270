import React, { useEffect, useState } from 'react';
import { Checkbox, TextField } from '@cimpress/react-components';
import './AssetSearch.scss';
import {
  EMPTY_SEARCH_TERM,
  isEmptySearchTerm,
  isEmptySearchTermValue,
  isValidSearchTerm,
  SearchTerm,
} from '../Model/SearchTerm';

interface Props {
  search: SearchTerm;
  assetsNewSearch: (searchInput: SearchTerm) => void;
  allowExactSearch?: boolean;
}

export const AssetSearch = ({ search, assetsNewSearch, allowExactSearch }: Props): JSX.Element => {
  const [searchInput, setSearchInput] = useState(search);

  useEffect(() => {
    setSearchInput(search);
  }, [search]);

  const onSearchSubmit = () => {
    isValidSearchTerm(searchInput) ? assetsNewSearch(searchInput) : alert('Please provide at least 3 characters.');
  };

  const searchSubmitted = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearchSubmit();
  };

  const onTermChangedByInput = (newTerm: Partial<SearchTerm>) => {
    setSearchInput({ ...search, ...newTerm });

    // on clearing the text field notify search term is no longer there
    if (isEmptySearchTermValue(newTerm.term) && !isEmptySearchTerm(search)) {
      assetsNewSearch({ ...search, term: '' });
    }
  };

  const termChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onTermChangedByInput({ term: e.target.value || '' });
  };

  const clearSearch = () => {
    onTermChangedByInput(EMPTY_SEARCH_TERM);
  };

  const exactChanged = () => {
    setSearchInput((search) => {
      return { ...search, exact: !search.exact };
    });
  };

  return (
    <form className="asset-search-form" onSubmit={searchSubmitted}>
      <TextField
        className="asset-search-term-input"
        name="search"
        label="Search"
        value={searchInput.term || ''}
        type="search"
        onChange={termChanged}
        rightAddon={
          <>
            <a className="btn btn-default" data-testid="assets-search-button-clear" onClick={clearSearch}>
              <i className="fa fa-times fa-lg button-icon" style={{ paddingRight: '0' }}></i>
            </a>
            <button className="btn btn-default" data-testid="assets-search-button">
              <i className="fa fa-search button-icon"></i>
            </button>
          </>
        }
        data-testid="assets-search-field"
      />
      {allowExactSearch && (
        <div className="asset-search-exact-checkbox">
          <Checkbox label="Exact search" checked={searchInput.exact || false} onChange={exactChanged} />
        </div>
      )}
    </form>
  );
};
