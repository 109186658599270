import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { isDesignManagementAssetVersion } from '../api/Cams/model/Design/DesignManagementAssetVersion';
import { FilePathsToUrls } from '../api/Cams/model/FilePathsToUrls';
import { getAssetUploadInfo } from '../Tools/AssetHelpers';
import { sanitize } from '../Tools/InputSanitizers/SanitizeFilenameUtil';

export const formatAssetsToZip = (assetVersions: AnyAssetVersion[], assetName?: string): FilePathsToUrls => {
  const filePathsToUrls: FilePathsToUrls = {};
  assetVersions.forEach((av) => {
    const uploadInfo = getAssetUploadInfo(av);
    const folderName = sanitize(
      `${assetName ?? uploadInfo?.fileName.substring(0, uploadInfo?.fileName.lastIndexOf('.'))}-${av.assetId}`,
    );

    if (uploadInfo)
      filePathsToUrls[updateFileNameIfExists(filePathsToUrls, folderName, uploadInfo.fileName)] = uploadInfo.fileUrl;
    includeLinkedAsset(filePathsToUrls, av, folderName);
  });
  return filePathsToUrls;
};

const includeLinkedAsset = (filePathsToUrls: FilePathsToUrls, assetVersion: AnyAssetVersion, folderName: string) => {
  if (isDesignManagementAssetVersion(assetVersion)) {
    assetVersion.linkedAssets.forEach((linkedAsset) => {
      if (linkedAsset.uploadInfo.fileName)
        filePathsToUrls[updateFileNameIfExists(filePathsToUrls, folderName, linkedAsset.uploadInfo.fileName)] =
          linkedAsset.uploadInfo.fileUrl;
    });
  }
};

const updateFileNameIfExists = (filePathsToUrls: FilePathsToUrls, folderName: string, fileName: string) => {
  if (`${folderName}/${fileName}` in filePathsToUrls) {
    const name = fileName.substring(0, fileName.lastIndexOf('.'));
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    return `${folderName}/${name}_${randomNumber}.${extension}`;
  }
  return `${folderName}/${fileName}`;
};
