export interface SearchTerm {
  term: string | undefined;
  exact: boolean | undefined;
}

export const EMPTY_SEARCH_TERM: SearchTerm = {
  term: undefined,
  exact: undefined,
};

export const isEmptySearchTerm = (search: SearchTerm): boolean => !(search && !isEmptySearchTermValue(search.term));
export const isEmptySearchTermValue = (term: string | undefined): boolean => !(!!term && term.length > 0);

export const isValidSearchTerm = (search: SearchTerm): boolean => search && !!search.term && search.term.length >= 3;
