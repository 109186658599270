import config from '../../config';
import { ApiResponseOrError } from '../ApiResponseOrError';
import { Tenant } from '../model/Tenant';
import * as RequestUtils from '../RequestUtils';
import { AssetsFilters } from '../../AssetsListPage/Model/AssetsFilters';
import { appendQueryStringArray, appendQueryStringValue } from '../../Tools/UriHelpers';
import { GetAssetsResponse } from './dtos/GetAssetsResponse';
import { FacetName } from './model/FacetName';
import { FacetValue } from './model/FacetValue';
import { AssetKind } from './model/AssetKind';
import { AssetType } from './model/AssetType';
import { AnyAssetVersion } from './model/AnyAssetVersion';
import { TenantDescription } from '../model/TenantDescription';
import { CategoryOrderBody } from './model/CategoryOrderBody';
import { SearchTerm } from '../../AssetsListPage/Model/SearchTerm';
import { CategoryLocalizationData } from '../../CategoryLocalization/CategoryLocalization';
import { FilePathsToUrls } from './model/FilePathsToUrls';
import { JsonInputToZip } from './model/JsonInputToZip';
import { MonolithAssetsMetadata } from './model/MonolithAssetsMetadata';
import { createFormData } from '../RequestUtils';
import { AnyAsset } from './model/AnyAsset';
import { GetDesignConceptsResponse } from './dtos/GetDesignConceptsResponse';
import { DesignConceptsFilters } from '../../DesignConceptsTablePage/DesignConceptsFilters';
import { DesignConceptSort } from '../../DesignConceptsTablePage/DesignConceptSort';
import { CreativeAssetVersion } from './model/CreativeAssetVersion';
import { ManagementAssetVersion } from './model/ManagementAssetVersion';

const camsApiHost = config.camsApiUrl;

export const getAsset = async <T>(tenant: Tenant, id: string): Promise<ApiResponseOrError<T>> => {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/${id}`,
  );

  return await RequestUtils.doGetRequest<T>(url);
};

export async function deleteAsset(tenant: Tenant, id: string): Promise<ApiResponseOrError<AnyAsset>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/${id}`,
  );

  return await RequestUtils.doDeleteRequest<AnyAsset>(url);
}

export const zipAndDownload = async (
  tenant: Tenant,
  filePathsToUrls: FilePathsToUrls,
  fileName: string,
): Promise<ApiResponseOrError<string>> => {
  const url = new URL(`${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/zip`);
  const input: JsonInputToZip = {
    filePathsToUrls: filePathsToUrls,
    fileName: fileName,
  };
  return await RequestUtils.doPostRequest(url, input);
};

function addFilters(
  searchParams: URLSearchParams,
  filters: AssetsFilters | undefined,
  assetType: AssetType | undefined,
) {
  if (filters !== undefined) {
    appendQueryStringArray(searchParams, 'createdBy', filters.createdBy);
    appendQueryStringArray(searchParams, 'designConceptIds', filters.designConceptId);
    appendQueryStringArray(searchParams, 'designUseCaseNames', filters.designUseCaseNames);
    appendQueryStringArray(searchParams, 'published', filters.published);
    appendQueryStringArray(searchParams, 'productNames', filters.productNames);
    appendQueryStringArray(searchParams, 'accessibleTo', filters.accessibleTo);

    if (assetType === 'management') {
      appendQueryStringArray(searchParams, 'contentTags', filters.tags);
      appendQueryStringArray(searchParams, 'contentCollections', filters.collections);
    } else {
      appendQueryStringArray(searchParams, 'tags', filters.tags);
      appendQueryStringArray(searchParams, 'collections', filters.collections);
    }
  }
}

export const getAssets = async (
  tenant: Tenant,
  pageSize: number,
  pageNumber: number,
  assetKinds?: AssetKind[],
  assetType?: AssetType,
  filters?: AssetsFilters,
  search?: SearchTerm,
  abortController?: AbortController,
): Promise<ApiResponseOrError<GetAssetsResponse>> => {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets`,
  );

  addFilters(url.searchParams, filters, assetType);
  appendQueryStringValue(url.searchParams, 'assetType', assetType);
  if (assetType === 'management') {
    appendQueryStringArray(url.searchParams, 'assetKind', assetKinds);
  }
  appendQueryStringValue(url.searchParams, 'search', search?.term);
  appendQueryStringValue(url.searchParams, 'exact', search?.exact !== undefined ? `${search?.exact}` : undefined);
  url.searchParams.append('pageSize', `${pageSize}`);
  url.searchParams.append('pageNumber', `${pageNumber + 1}`); // UI index page numbers from 0, API from 1

  return await RequestUtils.doGetRequest<GetAssetsResponse>(url, undefined, abortController);
};

export const getAssetVersions = async (
  tenant: Tenant,
  assetId: string,
): Promise<ApiResponseOrError<AnyAssetVersion[]>> => {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/${assetId}/versions`,
  );

  return await RequestUtils.doGetRequest<AnyAssetVersion[]>(url);
};

export const getFacetValues = async (
  tenant: Tenant,
  name: FacetName,
  pageSize: number,
  pageNumber: number,
  value?: string,
  assetKinds?: AssetKind[],
  assetType?: AssetType,
  filters?: AssetsFilters,
  search?: SearchTerm,
): Promise<ApiResponseOrError<FacetValue[]>> => {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/facets/${name}`,
  );

  addFilters(url.searchParams, filters, assetType);
  if (assetType === 'management') {
    appendQueryStringArray(url.searchParams, 'assetKind', assetKinds);
  }
  appendQueryStringValue(url.searchParams, 'assetType', assetType);
  appendQueryStringValue(url.searchParams, 'value', value);
  appendQueryStringValue(url.searchParams, 'search', search?.term);
  appendQueryStringValue(url.searchParams, 'exact', search?.exact !== undefined ? `${search?.exact}` : undefined);
  url.searchParams.append('pageSize', `${pageSize}`);
  url.searchParams.append('pageNumber', `${pageNumber + 1}`); // UI index page numbers from 0, API from 1

  return await RequestUtils.doGetRequest<FacetValue[]>(url);
};

export async function getAssetVersion(
  tenant: Tenant,
  id: string,
  version: number,
): Promise<ApiResponseOrError<AnyAssetVersion>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/${id}/versions/${version}`,
  );

  return await RequestUtils.doGetRequest<AnyAssetVersion>(url);
}

export async function getTenants(): Promise<ApiResponseOrError<TenantDescription[]>> {
  const url = new URL(`${camsApiHost}/api/v4/tenants`);
  return await RequestUtils.doGetRequest<TenantDescription[]>(url);
}

export async function getCategoryOrdering(tenant: Tenant, assetKind: AssetKind): Promise<ApiResponseOrError<string[]>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/categoryOrder/${assetKind}`,
  );
  return await RequestUtils.doGetRequest<string[]>(url);
}

export async function setCategoryOrdering(
  tenant: Tenant,
  assetKind: AssetKind,
  categoryOrder: CategoryOrderBody,
): Promise<ApiResponseOrError<string[]>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/categoryOrder/${assetKind}`,
  );
  return await RequestUtils.doPostRequest<string[]>(url, categoryOrder);
}

export async function downloadCategoryLocalizationFile(tenant: Tenant): Promise<ApiResponseOrError<Blob>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/categoryLocalization/download`,
  );
  return await RequestUtils.doGetRequestAsBlob<Blob>(url);
}

export function getCategoryLocalization(
  tenant: Tenant,
): [Promise<ApiResponseOrError<CategoryLocalizationData>>, () => void] {
  const abortController = new AbortController();
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/categoryLocalization`,
  );
  return [RequestUtils.doGetRequest<CategoryLocalizationData>(url), () => abortController.abort()];
}

export async function uploadCategoryLocalization(
  tenant: Tenant,
  localizationDataFile: File,
): Promise<ApiResponseOrError<any>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/categoryLocalization`,
  );

  const formData = createFormData();
  formData.append('localizationDataFile', localizationDataFile, localizationDataFile.name);

  const config = RequestUtils.createFileUploadRequestConfig();

  return await RequestUtils.doPostRequest<CategoryLocalizationData>(url, formData, config);
}

export async function getMonolithAssetMetadata(
  tenant: Tenant,
  assetId: string,
): Promise<ApiResponseOrError<MonolithAssetsMetadata>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/monolithMetadata/${assetId}`,
  );
  return await RequestUtils.doGetRequest<MonolithAssetsMetadata>(url);
}

export async function getDesignConcepts(
  tenant: Tenant,
  pageNumber: number,
  pageSize: number,
  designConceptId: string,
  filters: DesignConceptsFilters,
  sort: DesignConceptSort,
  abortController?: AbortController,
): Promise<ApiResponseOrError<GetDesignConceptsResponse>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/designConcepts`,
  );

  url.searchParams.append('pageSize', `${pageSize}`);
  url.searchParams.append('pageNumber', `${pageNumber + 1}`);
  url.searchParams.append('search', `${designConceptId}`);

  if (filters.hasBackground !== undefined) url.searchParams.append('hasBackground', `${filters.hasBackground}`);
  if (filters.hasClipart !== undefined) url.searchParams.append('hasClipart', `${filters.hasClipart}`);
  if (filters.hasColorPalette !== undefined) url.searchParams.append('hasColorPalette', `${filters.hasColorPalette}`);
  if (filters.hasFontSchema !== undefined) url.searchParams.append('hasFontSchema', `${filters.hasFontSchema}`);

  url.searchParams.append('columnName', sort.columnName);
  url.searchParams.append('sortOrder', sort.sortOrder);

  return await RequestUtils.doGetRequest<GetDesignConceptsResponse>(url, undefined, abortController);
}

export async function getUsedByCreativeAssetVersion(
  tenant: Tenant,
  asset: CreativeAssetVersion,
  abortController?: AbortController,
): Promise<ApiResponseOrError<ManagementAssetVersion[]>> {
  const url = new URL(
    `${camsApiHost}/api/v4/${tenant.contentAuthoringAccountId}/${tenant.contentAuthoringAreaId}/assets/creative/${asset.assetId}/versions/${asset.versionNumber}/usedBy`,
  );
  return await RequestUtils.doGetRequest<any>(url, undefined, abortController);
}
