import { QueryStringState } from './QueryStringState';
import { Location } from 'history';
import { defaultPageNumber, defaultPageSize } from '../Model/Constants';
import { appendQueryStringArray, appendQueryStringValue } from '../../Tools/UriHelpers';
import { History } from 'history';
import { Tenant } from '../../api/model/Tenant';
import { DesignsListViewType } from '../../DesignPage/DesignsListViewType';
import * as LocalStorage from '../../Tools/LocalStorage';
import { AssetCategories, AssetCategory } from '../AssetCategory';
import { AssetKind } from '../../api/Cams/model/AssetKind';
import { CamNavigationState } from '../../DesignPage/Model/CamNavigationState';
import { DeepPartial } from '../../Tools/DeepPartial';
import { EmptyTenant, TenantDescription } from '../../api/model/TenantDescription';
import { getAssetCategoryPathName } from '../../Routes/GenericRoutes';
import {
  DesignConceptSort,
  isDesignConceptColumnNameToSort,
  isSortOrder,
} from '../../DesignConceptsTablePage/DesignConceptSort';

const parseNumber = (parameter: string | null | undefined, defaultValue: number): number => {
  if (parameter === null || parameter === undefined || parameter.length === 0) {
    return defaultValue;
  }

  const parsedNumber = Number(parameter);
  if (isNaN(parsedNumber) || parsedNumber <= 0) {
    return defaultValue;
  }

  return parsedNumber;
};

export const stringToBool = (value?: string | null): boolean | undefined => {
  if (value?.toLowerCase() === 'true') return true;
  if (value?.toLowerCase() === 'false') return false;

  return undefined;
};

export const parseDesignConceptSort = (columnName?: string | null, sortOrder?: string | null): DesignConceptSort => {
  const clName = isDesignConceptColumnNameToSort(columnName) ? columnName : 'assetCount';
  const order = isSortOrder(sortOrder) ? sortOrder : 'desc';
  {
    return {
      columnName: clName,
      sortOrder: order,
    };
  }
};

const parseListViewType = (
  parameter: string | null | undefined,
  defaultValue: DesignsListViewType,
): DesignsListViewType => {
  if (parameter === null || parameter === undefined || parameter.length === 0) {
    return defaultValue;
  }

  const possibleValues: DesignsListViewType[] = ['Tiles', 'LargeTiles', 'Table'];

  return (
    possibleValues.find(
      (possibleValue) => parameter.localeCompare(possibleValue, undefined, { sensitivity: 'accent' }) === 0,
    ) || defaultValue
  );
};

const getTenantDescription = (tenant: Tenant, availableTenants: TenantDescription[]): TenantDescription => {
  return (
    availableTenants.find(
      (t) =>
        t.contentAuthoringAccountId === tenant.contentAuthoringAccountId &&
        t.contentAuthoringAreaId === tenant.contentAuthoringAreaId,
    ) ?? EmptyTenant
  );
};

export const parseQueryStringState = (
  location: Location<unknown>,
  isNavigationAction: boolean,
  availableTenants: TenantDescription[],
): QueryStringState => {
  const searchParams = new URLSearchParams(location.search);
  const assetCategory =
    parseAssetCategoryFromPathname(location.pathname) || LocalStorage.getAssetCategory() || 'design';
  const assetKind = getAssetKind(assetCategory);
  const assetType = assetCategory == 'creative asset' ? 'creative' : 'management';
  const tenant = parseTenantFromPathname(location.pathname);

  const listViewType = parseListViewType(
    searchParams.get('listViewType'),
    LocalStorage.getDesignsViewType() || 'Tiles',
  );

  const searchTerm = searchParams.get('search') || '';
  const exactSearch = searchParams.get('exact')?.toLowerCase() === 'true';
  const search = {
    term: searchTerm,
    exact: exactSearch,
  };

  const pageNumber = parseNumber(searchParams.get('pageNumber'), defaultPageNumber);
  return {
    filters: {
      createdBy: searchParams.getAll('createdBy'),
      tags: searchParams.getAll('tags'),
      collections: searchParams.getAll('collections'),
      designConceptId: searchParams.getAll('designConceptId'),
      designUseCaseNames: searchParams.getAll('designUseCaseNames'),
      productNames: searchParams.getAll('productNames'),
      published: searchParams.getAll('published'),
      accessibleTo: searchParams.getAll('accessibleTo'),
    },
    listViewType,
    paging: {
      pageNumber: pageNumber === defaultPageNumber ? pageNumber : pageNumber - 1,
      pageSize: parseNumber(
        searchParams.get('pageSize'),
        parseInt(localStorage.getItem('pageSize') ?? defaultPageSize.toString()),
      ),
    },
    assetCategory: assetCategory,
    assetKinds: [assetKind],
    assetType: assetType,
    isNavigationAction,
    tenant: getTenantDescription(tenant, availableTenants),
    search: search,
    designConceptFilters: {
      hasBackground: stringToBool(searchParams.get('hasBackground')),
      hasClipart: stringToBool(searchParams.get('hasClipart')),
      hasColorPalette: stringToBool(searchParams.get('hasColorPalette')),
      hasFontSchema: stringToBool(searchParams.get('hasFontSchema')),
    },
    designConceptSort: parseDesignConceptSort(searchParams.get('columnName'), searchParams.get('sortOrder')),
  };
};

export const parseTenantFromPathname = (pathname: string): Tenant => {
  const tokens = pathname.split('/');
  const contentArea = tokens[tokens.indexOf('contentarea') + 1];
  const account = tokens[tokens.indexOf('accounts') + 1];
  return {
    contentAuthoringAccountId: account,
    contentAuthoringAreaId: contentArea,
  };
};

const getAssetKind = (assetCategory: AssetCategory): AssetKind => {
  if (assetCategory === 'creative asset') {
    return '';
  }

  if (assetCategory === 'design') {
    return 'design';
  }

  if (assetCategory === 'font schema') {
    return 'fontSchema';
  }

  if (assetCategory === 'color palette') {
    return 'colorPalette';
  }
  return assetCategory;
};

export const serializeQueryParams = (queryStringState: DeepPartial<QueryStringState>): string => {
  const queryParams = new URLSearchParams();
  appendQueryStringValue(
    queryParams,
    'pageNumber',
    queryStringState.paging?.pageNumber === undefined || queryStringState.paging.pageNumber === defaultPageNumber
      ? undefined
      : (queryStringState.paging.pageNumber + 1).toString(),
  );
  appendQueryStringValue(
    queryParams,
    'pageSize',
    queryStringState.paging?.pageSize === undefined ? undefined : queryStringState.paging.pageSize.toString(),
  );
  appendQueryStringArray(queryParams, 'createdBy', queryStringState.filters?.createdBy);
  appendQueryStringArray(queryParams, 'tags', queryStringState.filters?.tags);
  appendQueryStringArray(queryParams, 'collections', queryStringState.filters?.collections);
  appendQueryStringArray(queryParams, 'designConceptId', queryStringState.filters?.designConceptId);
  appendQueryStringArray(queryParams, 'designUseCaseNames', queryStringState.filters?.designUseCaseNames);
  appendQueryStringArray(queryParams, 'productNames', queryStringState.filters?.productNames);
  appendQueryStringArray(queryParams, 'published', queryStringState.filters?.published);
  appendQueryStringArray(queryParams, 'accessibleTo', queryStringState.filters?.accessibleTo);
  appendQueryStringValue(queryParams, 'search', queryStringState.search?.term);
  appendQueryStringValue(queryParams, 'exact', queryStringState.search?.exact ? 'true' : undefined);

  const dcFilters = queryStringState.designConceptFilters;

  appendQueryStringValue(queryParams, 'hasBackground', dcFilters?.hasBackground?.toString());
  appendQueryStringValue(queryParams, 'hasClipart', dcFilters?.hasClipart?.toString());
  appendQueryStringValue(queryParams, 'hasColorPalette', dcFilters?.hasColorPalette?.toString());
  appendQueryStringValue(queryParams, 'hasFontSchema', dcFilters?.hasFontSchema?.toString());

  const dcSort = queryStringState.designConceptSort;
  appendQueryStringValue(queryParams, 'columnName', dcSort?.columnName);
  appendQueryStringValue(queryParams, 'sortOrder', dcSort?.sortOrder);

  return queryParams.toString();
};

export const serializeToQueryString = (
  history: History<CamNavigationState>,
  queryStringState: QueryStringState,
): void => {
  const serializedQueryString = serializeQueryParams(queryStringState);
  history.push({
    search: serializedQueryString,
  });
};

export const parseAssetCategoryFromPathname = (pathname: string): AssetCategory | undefined => {
  let foundCategory: string | undefined = undefined;
  AssetCategories.forEach((category) => {
    const categoryFirstWord = getAssetCategoryPathName(category);
    if (pathname.indexOf(`/${categoryFirstWord}`) !== -1) {
      foundCategory = category;
    }
  });
  return foundCategory;
};
