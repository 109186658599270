import React, { useContext } from 'react';
import { ActionResult, createErrorResponse, createSuccessResponse } from '../Tools/ActionResult';
import { FileToUpload } from './FileToUpload';
import { UploadsControl } from './UploadsControl';
import { createOrUpdateCreativeAsset } from '../api/Cams/CamsApiFacade';
import { AppContext } from '../App';
import { CreativeAsset } from '../api/Cams/model/CreativeAsset';

interface Props {
  assetId?: string;
  onUpload: (assetId: string, version: number) => void;
}

export const CreateOrUpdateCreativeAssetControl = ({ assetId, onUpload }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const isNewAsset = assetId === undefined;

  const handleFileUpload = async (fileToUpload: FileToUpload): Promise<CreativeAsset> => {
    //use creative asset to link to patternasset
    return await createOrUpdateCreativeAsset(tenant, fileToUpload, assetId);
  };

  const handleUpload = async (uploads: FileToUpload[]): Promise<ActionResult<never>> => {
    try {
      if (uploads.length !== 1) {
        return createErrorResponse(new Error('Please provide single artwork file.'));
      }

      const fileToUpload = uploads[0];
      const asset = await handleFileUpload(fileToUpload);

      onUpload(asset.id, asset.latestVersion.versionNumber);
      return createSuccessResponse();
    } catch (e) {
      return createErrorResponse(e);
    }
  };
  return (
    <>
      <UploadsControl
        allowMultiple={false}
        handleUpload={handleUpload}
        uploadButtonText={isNewAsset ? 'Create' : 'Create new version'}
      />
    </>
  );
};
