import { Tag, Button, Modal } from '@cimpress/react-components';
import React, { useContext, useEffect, useState } from 'react';
import './AssetDestinations.scss';
import { Link } from 'react-router-dom';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { Tenant } from '../api/model/Tenant';
import { AppContext } from '../App';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { ApiErrorToast } from './ApiErrorToast';
import { CheckboxList } from '../AssetsListPage/Filters/Components/CheckboxList';
import { FacetValue } from '../api/Cams/model/FacetValue';

interface Props<T> {
  entityDestinations?: string[];
  componentLabel: string;
  disabled?: boolean;
  updateMethod: (tenant: Tenant, destinations: string[]) => Promise<ApiResponseOrError<T>>;
  getterFromResponse: (response: ApiResponse<T>) => string[];
  getAssetsListWithDestinationFilterRoute: (destination: string) => string;
}

export const AssetDestinations = <T,>({
  entityDestinations,
  componentLabel,
  disabled,
  updateMethod,
  getterFromResponse,
  getAssetsListWithDestinationFilterRoute,
}: Props<T>): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [currentlyAssignedDestinations, setCurrentlyAssignedDestinations] = useState<string[]>(
    entityDestinations || [],
  );
  const [saveResponse, setSaveResponse] = useState<ApiResponseOrError<T>>();
  const [isEditing, setIsEditing] = useState(false);
  const [currentlySelectedDestinations, setCurrentlySelectedDestinations] = useState<string[]>(
    entityDestinations || [],
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentlyAssignedDestinations(entityDestinations || []);
    setCurrentlySelectedDestinations(entityDestinations || []);
  }, [entityDestinations]);

  useEffect(() => {
    if (disabled) {
      setIsEditing(false);
    }
  }, [disabled]);

  const save = async (updatedDestinations: string[]) => {
    const response = (await updateMethod(tenant, updatedDestinations)) as ApiResponse<T>;

    setSaveResponse(response);

    if (isSuccessfulResponse(response)) {
      return getterFromResponse(response);
    }

    return false;
  };

  const updateDestinations = async (newDestinations: string[]) => {
    const savedDestinations = await save(newDestinations);
    if (savedDestinations) {
      setCurrentlyAssignedDestinations(savedDestinations);
    }
  };

  const onDoneClick = async () => {
    setIsEditing(false);
    setIsLoading(true);
    const isEqual = (a: string[], b: string[]) => a.sort() === b.sort();
    if (!isEqual([...currentlyAssignedDestinations], [...currentlySelectedDestinations])) {
      await updateDestinations(currentlySelectedDestinations);
    }
    setIsLoading(false);
  };

  const onDestinationsModalClose = () => {
    setCurrentlySelectedDestinations(currentlyAssignedDestinations);
    setIsEditing(false);
  };

  return (
    <div data-testid="asset-destinations">
      <h3>{componentLabel}</h3>
      {currentlyAssignedDestinations && (
        <div className="destinations-list">
          {currentlyAssignedDestinations.map((destination) => {
            return (
              <Link key={destination} to={getAssetsListWithDestinationFilterRoute(destination)} className="tag-link">
                <Tag key={destination} value={destination} size="lg" removable={false} />
              </Link>
            );
          })}
          {currentlyAssignedDestinations.length === 0 && (
            <Tag value={'No services'} size="lg" removable={false} className="no-services-tag" />
          )}
          <Button
            disabled={disabled || isLoading}
            onClick={() => setIsEditing(true)}
            variant="link"
            className="edit-button"
          >
            <>
              <i className="fa fa-pencil"></i>
              <span>Edit</span>
            </>
          </Button>
          {isEditing && (
            <Modal
              size="lg"
              closeButton={true}
              closeOnOutsideClick={true}
              onRequestHide={onDestinationsModalClose}
              show={isEditing}
              title="Asset accessible to"
              footer={
                <div>
                  <Button onClick={onDestinationsModalClose} className="cancel-button">
                    Cancel
                  </Button>
                  <Button onClick={onDoneClick} variant="primary" className="done-button">
                    Done
                  </Button>
                </div>
              }
            >
              Please indicate the services where you would like to enable the use of this asset. Enabling this will
              allow the published asset to be available for use.
              <CheckboxList
                allItems={tenant.settings.features.accessibleTo.map((destination) => {
                  const destinationFacetItem: FacetValue = {
                    label: destination,
                    value: destination,
                    usesCount: 0, // doesn't really matter because `showUses` is false
                  };
                  return destinationFacetItem;
                })}
                selectedItems={currentlySelectedDestinations}
                onItemSelected={(selectedDestinations) => setCurrentlySelectedDestinations(selectedDestinations)}
                showUses={false}
                titleExtractor={(x) => x.value}
              />
            </Modal>
          )}
        </div>
      )}
      <ApiErrorToast response={saveResponse} />
    </div>
  );
};
