import React, { useState, useEffect, useContext } from 'react';
import * as CamsDesignApi from '../api/Cams/cams-api-designs';
import './DesignClonePage.scss';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getResponseError, isSuccessfulResponse } from '../Tools/ResponseHelper';
import { AppContext } from '../App';
import Loading from '../components/Loading';
import { ErrorToast } from '../components/ErrorToast';
import { CloneDesignRequestBody } from '../api/Cams/model/Design/CloneDesignRequestBody';
import { getDesignManagementAssetDetailsPageRoute } from '../Routes/DesignManagementAssetRoutes';
import { downloadAsFile } from '../Tools/DownloadAsFile';

interface QueryStringParams {
  id: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface CloneDesignParams {
  strategist?: string;
  designConceptId?: string;
  designUseCaseId?: string;
  externalCorrelationId?: string;
  designName?: string;
  fileName?: string;
}

interface ValidatedDesignParams {
  strategist: string;
  designConceptId: string;
  designUseCaseId: string;
  externalCorrelationId: string;
  designName?: string;
  fileName: string;
}

const DesignClonePage = (): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const history = useHistory();
  const { id } = useParams<QueryStringParams>();

  const query = useQuery();

  const externalCorrelationId = query.get('externalCorrelationId') || undefined;
  const strategist = query.get('strategist') || undefined;
  const designConceptId = query.get('designConceptId') || undefined;
  const designUseCaseId = query.get('designUseCaseId') || undefined;
  const designName = query.get('designName') || undefined;
  const fileName = query.get('designFileName') || undefined;
  const autoDownload = query.get('autoDownload') || undefined;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const validateParams = (cloneDesignParams: CloneDesignParams): cloneDesignParams is ValidatedDesignParams => {
    if (!cloneDesignParams.externalCorrelationId) {
      setError(new Error('Missing externalCorrelationId parameter in query string.'));
      return false;
    }
    if (!cloneDesignParams.strategist) {
      setError(new Error('Missing strategist parameter in query string.'));
      return false;
    }
    if (!cloneDesignParams.designConceptId) {
      setError(new Error('Missing designConceptId parameter in query string.'));
      return false;
    }
    if (!cloneDesignParams.designUseCaseId) {
      setError(new Error('Missing designUseCaseId parameter in query string.'));
      return false;
    }
    return true;
  };

  const cloneDesign = async (id: string, params: CloneDesignRequestBody) => {
    setIsLoading(true);

    const response = await CamsDesignApi.cloneDesignAsset(tenant, id, params);

    if (isSuccessfulResponse(response)) {
      const cloningMessage =
        response.statusCode === 201 // Created
          ? 'Successfully cloned Design.'
          : 'The Design was already cloned in the past. Opening the design detail page.';

      if (autoDownload === 'true') {
        const uploadInfo = response.data.latestVersion.masterFile.uploadInfo;
        await downloadAsFile(uploadInfo.fileUrl, uploadInfo.fileName);
      }

      history.push(
        getDesignManagementAssetDetailsPageRoute(
          tenant.contentAuthoringAccountId,
          tenant.contentAuthoringAreaId,
          response.data.id,
          undefined,
          cloningMessage,
        ),
      );
    } else {
      const errorMessage = getResponseError(response) as unknown as any;
      console.log(errorMessage);
      setError(new Error(errorMessage.Detail || 'Cloning design failed. See console for more details.'));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const cloneDesignParams: CloneDesignParams = {
          strategist,
          designConceptId,
          designUseCaseId,
          externalCorrelationId,
          designName,
          fileName,
        };

        if (validateParams(cloneDesignParams)) {
          await cloneDesign(id, cloneDesignParams);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, id, externalCorrelationId, strategist, designConceptId, designUseCaseId]);

  return (
    <div className="container-fluid container-stretch">
      <div className="row cams-page-title">
        <div className="col-sm-12 detail-header">
          <h2>Cloning Design</h2>
        </div>
      </div>

      {isLoading && <Loading />}
      <ErrorToast error={error ? [error] : undefined} keepOpen={true} />
    </div>
  );
};

export default DesignClonePage;
