import { AssetVersion } from '../AssetVersion';
import { ManagementAssetVersion } from '../ManagementAssetVersion';
import { PatternContentAssetVersion } from './PatternContentAssetVersion';

export interface PatternManagementAssetVersion extends ManagementAssetVersion {
  assetKind: 'pattern';

  contentAsset: PatternContentAssetVersion;
}

export const isPatternManagementAssetVersion = (x: AssetVersion): x is PatternManagementAssetVersion => {
  return x.assetType === 'management' && x.assetKind === 'pattern';
};
