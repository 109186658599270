import { Button, Card, Modal } from '@cimpress/react-components';
import React, { useContext, useState } from 'react';
import './PatternHeader.scss';
import { AssetTags } from '../components/AssetTags';
import { AssetCollections } from '../components/AssetCollections';
import { AppContext } from '../App';
import { PatternManagementAsset } from '../api/Cams/model/Pattern/PatternManagementAsset';
import { PatternManagementAssetVersion } from '../api/Cams/model/Pattern/PatternManagementAssetVersion';
import { AssetPreview } from '../components/AssetPreview';
import { PublishedToggle } from '../components/PublishedToggle';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { generatePreviewUrl } from '../Tools/CimDocUtil';
import { getPatternManagementAssetsPageRoute } from '../Routes/PatternManagementAssetRoutes';
import { Tenant } from '../api/model/Tenant';
import { PreviewInDexButton } from '../Preview/PreviewInDexButton';
import { DownloadButton } from '../components/DownloadButton';
import { serializeQueryParams } from '../AssetsListPage/QueryString/QueryStringUtils';
import { AssetVersion } from '../components/AssetVersion';
import { AssetAuditInfo } from '../components/AssetAuditInfo';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { DifferentAssetVersionPublished } from '../components/DifferentAssetVersionPublished';
import { AssetDestinations } from '../components/AssetDestinations';

interface Props {
  pattern: PatternManagementAsset;
  patternVersion: PatternManagementAssetVersion;
  firstVersion: PatternManagementAssetVersion;
  isLatestVersion: boolean;
  isPublishButtonEnabled: boolean;
  onPublish: (tenant: Tenant, published: boolean) => Promise<ApiResponseOrError<any>>;
  onSaveTags: (tenant: Tenant, keywords: string[]) => Promise<ApiResponseOrError<PatternManagementAsset>>;
  onSaveCollections: (tenant: Tenant, collections: string[]) => Promise<ApiResponseOrError<PatternManagementAsset>>;
  onSaveDestinations: (tenant: Tenant, destinations: string[]) => Promise<ApiResponseOrError<PatternManagementAsset>>;
}

export const PatternHeader = ({
  pattern,
  patternVersion,
  firstVersion,
  isLatestVersion,
  isPublishButtonEnabled,
  onPublish,
  onSaveTags,
  onSaveCollections,
  onSaveDestinations,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const downloadLink = patternVersion.masterFile?.uploadInfo?.fileUrl;
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const patternContentVersion = patternVersion?.contentAsset;
  const isDifferentVersionPublished =
    pattern.discoverableVersionNumber !== null && pattern.discoverableVersionNumber !== patternVersion.versionNumber;

  const isCorrupted =
    patternVersion.masterFile?.uploadInfo == null || patternVersion.masterFile?.uploadInfo.hash == '0';

  return (
    <Card>
      <div className="row header-wrapper">
        <div className="col-sm-4">
          <div className="preview-wrapper" style={{ minHeight: '200px' }}>
            <AssetPreview assetVersion={patternVersion} />
          </div>
          <div className="card-text detail-header-description">
            <AssetVersion
              assetVersion={patternVersion}
              tenant={tenant}
              assetCategory={'pattern'}
              isLatestVersion={isLatestVersion}
            />

            <AssetAuditInfo
              assetVersion={patternVersion}
              tenant={tenant}
              assetCategory={'pattern'}
              isLatestVersion={isLatestVersion}
              firstVersion={firstVersion as AnyAssetVersion}
            />
            <p></p>
            {patternContentVersion?.uploadInfo !== undefined && (
              <p>Content asset id: {patternContentVersion.uploadInfo.id}</p>
            )}
          </div>
        </div>
        <div className="col-sm-8 detail-header-wrapper">
          <div className="detail-header-title-row">
            <h1 className="text-primary detail-header-name" data-testid="pattern-name-header">
              {pattern.isDeleted ? (
                <span style={{ color: 'red' }}>
                  {patternVersion.masterFile?.uploadInfo?.fileName} (Asset was deleted)
                </span>
              ) : (
                <>{patternVersion.masterFile?.uploadInfo?.fileName}</>
              )}
            </h1>
            <PublishedToggle
              publishedInit={patternVersion.isDiscoverable}
              updateMethod={onPublish}
              getterFromResponse={(resp) => resp.data.isDiscoverable}
              enabledInit={isPublishButtonEnabled && !isCorrupted}
              showConfirmation={isDifferentVersionPublished}
            />
          </div>
          <div className="card-text">
            {isDifferentVersionPublished && (
              <DifferentAssetVersionPublished
                assetId={pattern.id}
                versionNumber={pattern.discoverableVersionNumber}
                tenant={tenant}
                assetCategory={'pattern'}
              />
            )}
            <div className="detail-header-tags-wrapper">
              <AssetTags
                entityTags={pattern.contentTags}
                facetName="ContentTags"
                componentLabel="Tags"
                enableAutocomplete={tenant.settings.features.hideTagsFilter !== true}
                updateMethod={onSaveTags}
                disabled={!tenant.userPermissions.canWrite}
                getterFromResponse={(resp: ApiResponse<PatternManagementAsset>) => resp.data.contentTags ?? []}
                getAssetsListWithTagFilterRoute={(tag) =>
                  getPatternManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { tags: [tag] } }),
                  )
                }
              />
            </div>
            <div className="detail-header-tags-wrapper">
              <AssetCollections
                entityCollections={pattern.contentCollections}
                facetName="ContentCollections"
                componentLabel="Collections"
                updateMethod={onSaveCollections}
                disabled={!tenant.userPermissions.canWrite}
                getterFromResponse={(resp) => resp.data.contentCollections}
                getAssetsListWithCollectionFilterRoute={(collection) =>
                  getPatternManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { collections: [collection] } }),
                  )
                }
              />
            </div>
            {tenant.settings.features.accessibleTo.length > 0 && (
              <div className="detail-header-tags-wrapper">
                <AssetDestinations
                  entityDestinations={pattern.accessibleTo}
                  componentLabel="Accessible to"
                  disabled={!tenant.userPermissions.canPublish}
                  updateMethod={onSaveDestinations}
                  getterFromResponse={(resp: ApiResponse<PatternManagementAsset>) => resp.data.accessibleTo ?? []}
                  getAssetsListWithDestinationFilterRoute={(accessibleTo) =>
                    getPatternManagementAssetsPageRoute(
                      tenant.contentAuthoringAccountId,
                      tenant.contentAuthoringAreaId,
                      serializeQueryParams({ filters: { accessibleTo: [accessibleTo] } }),
                    )
                  }
                />
              </div>
            )}
          </div>
          <div className="card-text">
            <div className="detail-header-tags-wrapper">
              <div className="pattern-buttons">
                <DownloadButton
                  downloadFilename={patternVersion.masterFile.uploadInfo?.fileName}
                  downloadLink={downloadLink}
                  isDownloadable={!isCorrupted}
                />
              </div>
              <div className="preview-buttons">
                <Button
                  style={{ width: 'auto' }}
                  onClick={() => setPreviewModalOpen(true)}
                  target="_blank"
                  disabled={isCorrupted}
                >
                  {`Preview`}
                </Button>
                {patternVersion?.contentAsset && (
                  <PreviewInDexButton
                    isDisabled={isCorrupted}
                    tenant={tenant}
                    assets={[patternVersion?.contentAsset]}
                  />
                )}
              </div>
            </div>
            {isCorrupted && (
              <div className="corrupted-creative">
                <span>
                  <i className="fa fa-exclamation-triangle"></i>
                </span>
                This is a corrupted creative asset which is not downloadable.
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        status="info"
        show={previewModalOpen}
        onRequestHide={() => setPreviewModalOpen(false)}
        title={`Pattern preview`}
        closeButton={true}
      >
        <img
          src={generatePreviewUrl(downloadLink ?? '', 1200)}
          className="detail-preview-image"
          alt="Pattern preview"
        />
      </Modal>
    </Card>
  );
};
