import { Card, Modal } from '@cimpress/react-components';
import React, { useContext, useState } from 'react';
import './BackgroundHeader.scss';
import { AssetTags } from '../components/AssetTags';
import { AssetCollections } from '../components/AssetCollections';
import { AppContext } from '../App';
import { BackgroundManagementAsset } from '../api/Cams/model/Background/BackgroundManagementAsset';
import { BackgroundManagementAssetVersion } from '../api/Cams/model/Background/BackgroundManagementAssetVersion';
import { AssetPreview } from '../components/AssetPreview';
import { ApiResponse, ApiResponseOrError } from '../api/ApiResponseOrError';
import { generatePreviewUrl } from '../Tools/CimDocUtil';
import { getBackgroundManagementAssetsPageRoute } from '../Routes/BackgroundManagementAssetRoutes';
import { Tenant } from '../api/model/Tenant';
import { DownloadButton } from '../components/DownloadButton';
import { serializeQueryParams } from '../AssetsListPage/QueryString/QueryStringUtils';
import { DesignConceptIdEdit } from '../CreativeAssetDetailPage/components/DesignConceptIdEdit';
import { PublishedToggle } from '../components/PublishedToggle';
import { AssetAuditInfo } from '../components/AssetAuditInfo';
import { AssetVersion } from '../components/AssetVersion';
import { AnyAssetVersion } from '../api/Cams/model/AnyAssetVersion';
import { DifferentAssetVersionPublished } from '../components/DifferentAssetVersionPublished';
import { AssetDestinations } from '../components/AssetDestinations';

interface Props {
  background: BackgroundManagementAsset;
  backgroundVersion: BackgroundManagementAssetVersion;
  firstVersion: BackgroundManagementAssetVersion;
  isLatestVersion: boolean;
  isPublishButtonEnabled: boolean;
  onPublish: (tenant: Tenant, published: boolean) => Promise<ApiResponseOrError<any>>;
  onSaveTags: (tenant: Tenant, keywords: string[]) => Promise<ApiResponseOrError<BackgroundManagementAsset>>;
  onSaveCollections: (tenant: Tenant, collections: string[]) => Promise<ApiResponseOrError<BackgroundManagementAsset>>;
  onSaveDestinations: (
    tenant: Tenant,
    destinations: string[],
  ) => Promise<ApiResponseOrError<BackgroundManagementAsset>>;
}

export const BackgroundHeader = ({
  background,
  backgroundVersion,
  firstVersion,
  isLatestVersion,
  isPublishButtonEnabled,
  onPublish,
  onSaveTags,
  onSaveCollections,
  onSaveDestinations,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const downloadLink = backgroundVersion.masterFile.uploadInfo.fileUrl;
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const backgroundContentVersion = backgroundVersion?.contentAsset;
  const isDifferentVersionPublished =
    background.discoverableVersionNumber !== null &&
    background.discoverableVersionNumber !== backgroundVersion.versionNumber;

  return (
    <Card>
      <div className="row header-wrapper">
        <div className="col-sm-4">
          <div className="preview-wrapper" style={{ minHeight: '200px' }}>
            <AssetPreview assetVersion={backgroundVersion} />
          </div>
          <div className="card-text detail-header-description">
            <AssetVersion
              assetVersion={backgroundVersion}
              tenant={tenant}
              assetCategory={'background'}
              isLatestVersion={isLatestVersion}
            />

            <AssetAuditInfo
              assetVersion={backgroundVersion}
              tenant={tenant}
              assetCategory={'background'}
              isLatestVersion={isLatestVersion}
              firstVersion={firstVersion as AnyAssetVersion}
            />
          </div>
          <p></p>
          {backgroundContentVersion?.uploadInfo !== undefined && (
            <p>Content asset id: {backgroundContentVersion.uploadInfo.id}</p>
          )}
        </div>
        <div className="col-sm-8 detail-header-wrapper">
          <div className="detail-header-title-row">
            <h1 className="text-primary detail-header-name" data-testid="background-name-header">
              {background.isDeleted ? (
                <span style={{ color: 'red' }}>
                  {backgroundVersion.masterFile?.uploadInfo?.fileName} (Asset was deleted)
                </span>
              ) : (
                <>{backgroundVersion.masterFile?.uploadInfo?.fileName}</>
              )}
            </h1>
            <PublishedToggle
              publishedInit={backgroundVersion.isDiscoverable}
              updateMethod={onPublish}
              getterFromResponse={(resp) => resp.data.isDiscoverable}
              enabledInit={isPublishButtonEnabled}
              showConfirmation={isDifferentVersionPublished}
            />
          </div>
          <div className="card-text">
            {isDifferentVersionPublished && (
              <DifferentAssetVersionPublished
                assetId={background.id}
                versionNumber={background.discoverableVersionNumber}
                tenant={tenant}
                assetCategory={'background'}
              />
            )}
            <div className="detail-header-tags-wrapper">
              <DesignConceptIdEdit asset={background} />
            </div>
            <div className="detail-header-tags-wrapper">
              <AssetTags
                entityTags={background.contentTags}
                facetName="ContentTags"
                componentLabel="Tags"
                updateMethod={onSaveTags}
                disabled={!tenant.userPermissions.canWrite}
                enableAutocomplete={tenant.settings.features.hideTagsFilter !== true}
                getterFromResponse={(resp: ApiResponse<BackgroundManagementAsset>) => resp.data.contentTags ?? []}
                getAssetsListWithTagFilterRoute={(tag) =>
                  getBackgroundManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { tags: [tag] } }),
                  )
                }
              />
            </div>
            <div className="detail-header-tags-wrapper">
              <AssetCollections
                entityCollections={background.contentCollections}
                facetName="ContentCollections"
                componentLabel="Collections"
                updateMethod={onSaveCollections}
                disabled={!tenant.userPermissions.canWrite}
                getterFromResponse={(resp) => resp.data.contentCollections}
                getAssetsListWithCollectionFilterRoute={(collection) =>
                  getBackgroundManagementAssetsPageRoute(
                    tenant.contentAuthoringAccountId,
                    tenant.contentAuthoringAreaId,
                    serializeQueryParams({ filters: { collections: [collection] } }),
                  )
                }
              />
            </div>
            {tenant.settings.features.accessibleTo.length > 0 && (
              <div className="detail-header-tags-wrapper">
                <AssetDestinations
                  entityDestinations={background.accessibleTo}
                  componentLabel="Accessible to"
                  disabled={!tenant.userPermissions.canPublish}
                  updateMethod={onSaveDestinations}
                  getterFromResponse={(resp: ApiResponse<BackgroundManagementAsset>) => resp.data.accessibleTo ?? []}
                  getAssetsListWithDestinationFilterRoute={(accessibleTo) =>
                    getBackgroundManagementAssetsPageRoute(
                      tenant.contentAuthoringAccountId,
                      tenant.contentAuthoringAreaId,
                      serializeQueryParams({ filters: { accessibleTo: [accessibleTo] } }),
                    )
                  }
                />
              </div>
            )}
          </div>
          <div className="card-text">
            <div className="detail-header-tags-wrapper">
              <div className="detail-header-buttons">
                <DownloadButton
                  downloadFilename={backgroundVersion.masterFile.uploadInfo.fileName}
                  downloadLink={downloadLink}
                  isDownloadable={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        status="info"
        show={previewModalOpen}
        onRequestHide={() => setPreviewModalOpen(false)}
        title={`Background preview`}
        closeButton={true}
      >
        <img
          src={generatePreviewUrl(downloadLink ?? '', 1200)}
          className="detail-preview-image"
          alt="Background preview"
        />
      </Modal>
    </Card>
  );
};
