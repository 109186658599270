import { Button } from '@cimpress/react-components';
import React from 'react';
import './ClearFilterButton.scss';

interface Props {
  selectedItems: string[];
  onFilterChanged: (items: string[]) => void;
}

const ClearFilterButton = ({ selectedItems, onFilterChanged }: Props): JSX.Element => {
  const clearFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    onFilterChanged([]);
  };
  return (
    <>
      <span style={{ flex: 1 }}></span>
      {selectedItems?.length > 0 && (
        <Button
          className="clear-filter-btn"
          variant="anchor"
          onClick={(e) => clearFilter(e)}
          data-testid="clear-filter-button"
        >
          Clear filter
        </Button>
      )}
    </>
  );
};

export default ClearFilterButton;
