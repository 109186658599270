import { AssetVersion } from './AssetVersion';
import { CreativeAssetVersion } from './CreativeAssetVersion';

export interface ManagementAssetVersion extends AssetVersion {
  assetType: 'management';

  masterFile: CreativeAssetVersion;
}

export const isManagementAssetVersion = (x: AssetVersion): x is ManagementAssetVersion => {
  return x.assetType === 'management';
};
