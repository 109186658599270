import React, { useContext, useEffect, useState } from 'react';
import { AnyManagementAsset } from '../api/Cams/model/AnyManagementAsset';
import { ColorPaletteManagementAsset } from '../api/Cams/model/ColorPalette/ColorPaletteManagementAsset';
import { FontSchemaManagementAsset } from '../api/Cams/model/FontSchema/FontSchemaManagementAsset';
import config from '../config';
import { getFontSchema } from '../Tools/FontSchemaUtils';
import { FontSchema } from '../Uploads/FontSchema';
import { ComposedSample } from './ComposedSample';
import { ColorPalette } from '../Uploads/ColorPalette';
import { getColorPalette } from '../Tools/ColorPaletteUtils';
import { BackgroundManagementAsset } from '../api/Cams/model/Background/BackgroundManagementAsset';
import { ClipartManagementAsset } from '../api/Cams/model/Clipart/ClipartManagementAsset';
import {
  transformColorPaletteForAGS,
  transformFontSchemaForAGS,
  transformImageAssetForAGS,
} from '../Tools/AssetToAgsComponentMapperUtility';
import { targetTemplateTokensVistaprint, targetTemplateTokensContentSolutionTestTenant } from './ComposedSampleData';
import { getImageInfo, getPdfInfo } from '../api/Cams/sherbert-api';
import './ComposedSampleList.scss';
import { Card } from '@cimpress/react-components';
import { ComponentCompositionAssetKind } from './ComponentCompositionEnum';
import { AppContext } from '../App';

interface ComposedSampleListProps {
  assets: AnyManagementAsset[];
}

export const ComposedSampleList = ({ assets }: ComposedSampleListProps): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const [components, setComponents] = useState<any[]>();

  const targetTemplateTokens =
    tenant.accountId === 'ozoDdrmewShEcbUDWX8J3V' && tenant.contentAuthoringAreaId === 'BariCgbVOzbaYywM'
      ? targetTemplateTokensVistaprint
      : targetTemplateTokensContentSolutionTestTenant;
  const sherbertAssetLink = (assetId: string) => `${config.sherbertApiUrl}/v2/assets/${assetId}`;

  const handleFontSchema = async (asset: FontSchemaManagementAsset) => {
    const parsedFontSchema = await getFontSchema(asset.latestVersion);

    return {
      ...transformFontSchemaForAGS(parsedFontSchema as FontSchema),
    };
  };

  const handleColorPalette = async (asset: ColorPaletteManagementAsset) => {
    const parsedColorPalette = await getColorPalette(asset.latestVersion);

    return {
      ...transformColorPaletteForAGS(parsedColorPalette as ColorPalette),
    };
  };

  const handleImageAsset = async (asset: BackgroundManagementAsset | ClipartManagementAsset) => {
    const contentAsset = asset.latestVersion.contentAsset;
    const sherbertId = contentAsset.uploadInfo.id;
    const signature = asset.latestVersion.contentAsset.uploadInfo.fileUrl.split('signature=')[1];

    const printUrl = `${sherbertAssetLink(sherbertId)}/print?signature=${signature}`;
    const previewUrl = `${sherbertAssetLink(sherbertId)}/webPreview?signature=${signature}`;
    const originalSourceUrl = `${sherbertAssetLink(sherbertId)}?signature=${signature}`;

    const imageInfo = await getImageInfo(sherbertId, signature);
    if (imageInfo === undefined) {
      return undefined;
    }

    let width = imageInfo.width;
    let height = imageInfo.height;

    if (imageInfo.format === 'pdf') {
      const pageNum = 1; // Assuming pdf asset for background and clipart will always be 1
      const pdfInfo = await getPdfInfo(sherbertId, pageNum, signature);
      if (pdfInfo === undefined) {
        return undefined;
      }
      width = pdfInfo.width;
      height = pdfInfo.height;
    }

    return transformImageAssetForAGS(asset.assetKind, printUrl, previewUrl, originalSourceUrl, width, height);
  };

  const getAGSComponents = async (assets: AnyManagementAsset[]): Promise<any[]> => {
    const components = await Promise.all(
      assets.map(async (asset) => {
        switch (asset.assetKind) {
          case ComponentCompositionAssetKind.FontSchema:
            return await handleFontSchema(asset);
          case ComponentCompositionAssetKind.ColorPalette:
            return await handleColorPalette(asset);
          case ComponentCompositionAssetKind.Background:
          case ComponentCompositionAssetKind.Clipart:
            return await handleImageAsset(asset);
          default:
            throw new Error(`Unsupported asset kind: ${asset.assetKind}`);
        }
      }),
    );
    return components.filter((c) => c !== undefined);
  };

  const hasAnyLatestUnpublishedVersion = (assets: AnyManagementAsset[]): boolean => {
    let result = false;

    assets.forEach((a) => {
      const latestVersionNumber = a.latestVersion.versionNumber;
      const publishedVersion = a.discoverableVersionNumber;

      if (publishedVersion === undefined || latestVersionNumber !== publishedVersion) {
        result = true;
        return;
      }
    });

    return result;
  };

  const showUnpublishedWarningFlag = hasAnyLatestUnpublishedVersion(assets);

  useEffect(() => {
    const fetchData = async () => {
      const components = await getAGSComponents(assets);
      setComponents(components);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Card className="composed-sample-container">
        <h2>Generated Artwork Previews</h2>
        {components?.length === 0 && (
          <div className="alert-container">
            <div className="alert alert-warning alert-warning-no-assets-found">
              Please create at least one asset to see Generated Artwork previews
            </div>
          </div>
        )}
        {components && components.length !== 0 && (
          <div>
            {showUnpublishedWarningFlag && (
              <div className="alert alert-warning">
                Warning: Previews are rendered with at least one unpublished asset.
              </div>
            )}
            <div className="composed-sample-list">
              {targetTemplateTokens.map((targetTemplateToken) => {
                return (
                  <ComposedSample
                    key={targetTemplateToken}
                    templateToken={targetTemplateToken}
                    components={components}
                  />
                );
              })}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};
