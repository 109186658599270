import React from 'react';
import { AssetCategory } from '../AssetCategory';

interface Props {
  assetCategory: AssetCategory;
}

export const NoAssetsFound = ({ assetCategory }: Props): JSX.Element => {
  return <div className="cams-no-items">No {assetCategory.format({ plurality: 'plural' })} found</div>;
};
