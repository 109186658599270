import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import * as Logger from './Tools/Logging';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App history={history} />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

window.addEventListener('error', (e: ErrorEvent) => {
  Logger.error('Unhandled error occured in CAM', e);
});
