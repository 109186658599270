import React, { useContext, useEffect, useState } from 'react';
import { FileToUpload } from './FileToUpload';
import { createOrUpdateFontSchemaAsset } from '../api/Cams/CamsApiFacade';
import { AppContext } from '../App';
import { getAvailableFonts } from '../api/fonts/fonts-api';
import { isSuccessfulResponse } from '../Tools/ResponseHelper';
import { FontSelectionComponent } from '../components/FontSelectionComponent';
import { Font, FontSchema } from './FontSchema';
import { FontSchemaManagementAsset } from '../api/Cams/model/FontSchema/FontSchemaManagementAsset';
import { ErrorToast } from '../components/ErrorToast';
import { NonFileUploadButtonControl } from '../components/NonFileUploadButtonControl';
import { generatePreviewUrlForFonts } from '../Tools/CimDocUtil';
import { validateFontSchema, sanitizeFontSchema, getParameterCaseInsensitive } from '../Tools/FontSchemaUtils';
import * as dpsApi from '../api/Dps/dps-api';
import { useFormik } from 'formik';

interface Props {
  assetId?: string;
  masterAssetId?: string;
  fontSchemata?: FontSchema;
  onUpload: (assetId: string, version: number) => void;
  designConceptId?: string;
}

export const CreateOrUpdateFontAssetControl = ({
  assetId,
  masterAssetId,
  fontSchemata,
  onUpload,
  designConceptId,
}: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);
  const [availableFonts, setAvailableFonts] = useState<{ [fontFamily: string]: string[] }>({});
  const [fontSchema, setFontSchema] = useState<FontSchema>({
    fontRepositoryUrl: tenant.settings.fontConfiguration?.fontRepository,
    schemata: {},
  });
  const [isInProgress, setIsInProgress] = useState(false);
  const [uploadsError, setUploadsError] = useState<Error>();
  const [previewUrl, setPreviewUrl] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialFontSchemata, setInitialFontSchemata] = useState<FontSchema | undefined>(fontSchemata);
  const handleFileUpload = async (fileToUpload: FileToUpload): Promise<FontSchemaManagementAsset> => {
    //use creative asset to link to patternasset
    return await createOrUpdateFontSchemaAsset(
      tenant,
      fileToUpload,
      undefined,
      undefined,
      designConceptId,
      assetId,
      masterAssetId,
    );
  };

  const formik = useFormik({
    initialValues: {
      FontSchema: fontSchemata,
    },
    onSubmit: async () => {
      handleUpload();
    },
  });

  const handleUpload = async () => {
    try {
      setIsInProgress(true);
      const isValidFont = validateFontSchema(fontSchema);
      if (!isValidFont.isValid) {
        setUploadsError(new Error(isValidFont.error));
        return;
      }
      const sanitizedFontSchema = JSON.stringify(sanitizeFontSchema(fontSchema));
      const asset = await handleFileUpload({
        contentFile: new File([sanitizedFontSchema], `${sanitizedFontSchema.replace(/[^\w\s]/gi, '')}.json`, {
          type: 'application/json',
        }),
        isMainFile: true,
      });
      onUpload(asset.id, asset.latestVersion.versionNumber);
    } catch (e) {
      setUploadsError(e);
    } finally {
      setIsInProgress(false);
    }
  };

  useEffect(() => {
    let fonts: { [fontFamily: string]: string[] } = {};
    const fontConfiguration = tenant.settings.fontConfiguration;

    const loadData = async () => {
      if (fontConfiguration?.fontRepository) {
        const availableFonts = await getAvailableFonts(tenant.settings.fontConfiguration.fontRepository);
        if (isSuccessfulResponse(availableFonts)) {
          const disabledFonts = tenant.settings.fontConfiguration.disabledFonts ?? [];
          const filteredAvailableFonts = availableFonts.data.filter((x) =>
            disabledFonts.every((y) => y !== x.FontFamily),
          );
          fonts = Object.fromEntries(
            filteredAvailableFonts.map((item) => [
              item.FontFamily,
              item.SupportedVariants.map((varient) => varient.Style),
            ]),
          );
        }
      }
      setAvailableFonts(fonts);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFontChange = (fontCategory: string, font: Font) => {
    setFontSchema((prev) => ({ ...prev, schemata: { ...prev.schemata, [fontCategory]: font } }));
  };

  useEffect(() => {
    //check if initial is the same as current
    const schema = sanitizeFontSchema(fontSchema);
    setPreviewUrl(
      Object.entries(schema.schemata).length > 0
        ? generatePreviewUrlForFonts(schema, tenant.settings.fontConfiguration.fontRepository, 500)
        : '',
    );

    formik.setValues({ FontSchema: schema });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSchema]);

  return (
    <div className="row">
      <form onSubmit={formik.handleSubmit} data-testid="create-or-update-fontschema-form">
        <div className="col-sm-7">
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <FontSelectionComponent
              font={{
                fontFamily: `${getParameterCaseInsensitive('primary', fontSchemata)?.fontFamily ?? ''}`,
                fontStyle: `${getParameterCaseInsensitive('primary', fontSchemata)?.fontStyle ?? ''}`,
              }}
              fontCategory={'Primary'}
              availableFonts={availableFonts}
              onFontChange={onFontChange}
              disableInput={isInProgress}
              isRequired={true}
            />
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <FontSelectionComponent
              font={{
                fontFamily: `${getParameterCaseInsensitive('secondary', fontSchemata)?.fontFamily ?? ''}`,
                fontStyle: `${getParameterCaseInsensitive('secondary', fontSchemata)?.fontStyle ?? ''}`,
              }}
              fontCategory={'Secondary'}
              availableFonts={availableFonts}
              onFontChange={onFontChange}
              disableInput={isInProgress}
              isRequired={false}
            />
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <FontSelectionComponent
              font={{
                fontFamily: `${getParameterCaseInsensitive('tertiary', fontSchemata)?.fontFamily ?? ''}`,
                fontStyle: `${getParameterCaseInsensitive('tertiary', fontSchemata)?.fontStyle ?? ''}`,
              }}
              fontCategory={'Tertiary'}
              availableFonts={availableFonts}
              onFontChange={onFontChange}
              disableInput={isInProgress}
              isRequired={false}
            />
          </div>
          <NonFileUploadButtonControl
            isInProgress={isInProgress}
            isFormChanged={formik.dirty}
            initialLabel={'Save Changes'}
            inProgressLabel={'Saving Changes...'}
            onUpload={handleUpload}
          />
        </div>
        {previewUrl && (
          <img
            className="col-sm-4"
            src={previewUrl}
            alt="Preview"
            style={{ display: 'flex', background: dpsApi.Rgba.WhiteColor.toCssRgba() }}
          />
        )}
        <ErrorToast error={uploadsError ? [uploadsError] : undefined} onDismiss={() => setUploadsError(undefined)} />
      </form>
    </div>
  );
};
