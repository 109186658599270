import React, { useContext } from 'react';
import { ActionResult, createErrorResponse, createSuccessResponse } from '../Tools/ActionResult';
import { FileToUpload } from './FileToUpload';
import { UploadsControl } from './UploadsControl';
import { createOrUpdatePatternAsset } from '../api/Cams/CamsApiFacade';
import { AppContext } from '../App';
import { PatternManagementAsset } from '../api/Cams/model/Pattern/PatternManagementAsset';

interface Props {
  patternAssetId: string;
  masterAssetId: string;
  onUpload: (assetId: string, version: number) => void;
}

export const UpdatePatternAssetControl = ({ patternAssetId, masterAssetId, onUpload }: Props): JSX.Element => {
  const { tenant } = useContext(AppContext);

  const handleFileUpload = async (fileToUpload: FileToUpload): Promise<PatternManagementAsset> => {
    //use creative asset to link to patternasset
    return await createOrUpdatePatternAsset(tenant, fileToUpload, undefined, undefined, patternAssetId, masterAssetId);
  };

  const handleUpload = async (uploads: FileToUpload[]): Promise<ActionResult<never>> => {
    try {
      if (uploads.length !== 1) {
        return createErrorResponse(new Error('Please provide single artwork file.'));
      }

      const fileToUpload = uploads[0];
      const asset = await handleFileUpload(fileToUpload);

      onUpload(asset.id, asset.latestVersion.versionNumber);
      return createSuccessResponse();
    } catch (e) {
      return createErrorResponse(e);
    }
  };
  return (
    <>
      <UploadsControl allowMultiple={false} handleUpload={handleUpload} uploadButtonText={'Create new version'} />
    </>
  );
};
