import React, { useEffect, useState } from 'react';
import { isSuccessfulResponse } from '../../../Tools/ResponseHelper';
import { Accordion } from '@cimpress/react-components';
import ClearFilterButton from '../Components/ClearFilterButton';
import { FacetValue } from '../../../api/Cams/model/FacetValue';
import * as CamsApi from '../../../api/Cams/cams-api';
import { defaultPageNumber, maxPageSize } from '../../Model/Constants';
import { FacetName } from '../../../api/Cams/model/FacetName';
import { ApiResponseOrError } from '../../../api/ApiResponseOrError';
import { SearchInput } from '../Components/SearchInput';
import { TagsList } from '../Components/TagsList';
import { QueryStringState } from '../../QueryString/QueryStringState';

interface Props {
  pageState: QueryStringState;
  facetName: FacetName;
  label: string;
  onFilterChanged: (items: string[]) => void;
  isReload?: boolean;
  onIsReload?: (value: boolean) => void;
}

export const TagsFilterPanel = ({
  pageState,
  facetName,
  label,
  onFilterChanged,
  isReload,
  onIsReload,
}: Props): JSX.Element => {
  const [filteredItems, setFilteredItems] = useState<FacetValue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const loadFilterValues = (searchValue: string | undefined): Promise<ApiResponseOrError<FacetValue[]>> => {
    setSearchValue(searchValue || '');

    if (!searchValue && !pageState.tenant.settings.features.doInitialTagsLoad) {
      return Promise.resolve({ data: [], responseType: 'success', statusCode: 200 });
    }

    return CamsApi.getFacetValues(
      pageState.tenant,
      facetName,
      maxPageSize,
      defaultPageNumber,
      searchValue,
      pageState.assetKinds,
      pageState.assetType,
      pageState.filters,
      pageState.search,
    );
  };

  const loadAllFilters = async () => {
    const facetValues = await loadFilterValues(searchValue);

    if (isSuccessfulResponse(facetValues)) {
      const checkBoxItems = facetValues.data;

      pageState.filters.tags.forEach((element) => {
        if (checkBoxItems.find((x) => x.value == element) === undefined) {
          checkBoxItems.unshift({ label: `${element}`, value: element, usesCount: 0 });
        }
      });

      setFilteredItems(checkBoxItems);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  useEffect(() => {
    if (isReload && onIsReload) {
      loadAllFilters();
      onIsReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  return (
    <Accordion
      data-testid="tags-filters-panel"
      title={
        <>
          <span>{label}</span>
          <ClearFilterButton selectedItems={pageState.filters.tags} onFilterChanged={onFilterChanged} />
        </>
      }
      className="cams-accordion-card"
      defaultOpen
    >
      <SearchInput
        onFiltered={setFilteredItems}
        searchType="remote-call"
        onSearch={(searchValue) => loadFilterValues(searchValue)}
      />
      {!isLoading && !searchValue && !pageState.tenant.settings.features.doInitialTagsLoad && (
        <em className="text-muted">Start typing to look for tags ..</em>
      )}
      {!isLoading && searchValue && filteredItems.length === 0 && <span>No tags found.</span>}
      <TagsList allItems={filteredItems} selectedItems={pageState.filters.tags} onItemSelected={onFilterChanged} />
    </Accordion>
  );
};
