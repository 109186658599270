import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@cimpress/react-components';
import { Tenant } from '../api/model/Tenant';
import { AppContext } from '../App';
import config from '../config';

interface Props {
  designTemplateIds: string[];
}

export const LocalizationUIButton = ({ designTemplateIds }: Props): JSX.Element => {
  const { tenant: authoringTenant, availableTenants } = useContext(AppContext);
  const [buttonUrl, setButtonUrl] = useState<string>();

  useEffect(() => {
    const getRuntimeTenant = (tenant: Tenant) => {
      const realtimeTenant = availableTenants?.find(
        (x) =>
          x.contentAuthoringAreaId === tenant.contentAuthoringAreaId &&
          x.contentAuthoringAccountId === tenant.contentAuthoringAccountId,
      );
      return realtimeTenant;
    };

    const getTemplateTranslationsUri = (tenant: Tenant, designTemplateIds?: string[]) => {
      if (
        tenant.contentAuthoringAccountId &&
        tenant.contentAuthoringAreaId &&
        designTemplateIds &&
        designTemplateIds.length > 0
      ) {
        const filter = designTemplateIds.join();
        const runtimeTenant = getRuntimeTenant(tenant);
        return `${config.localizationUIUrl}/Accounts/${runtimeTenant?.accountId}/ContentArea/${runtimeTenant?.contentAreaId}/TemplatesTranslation/?filter=${filter}`;
      }
    };

    const url = getTemplateTranslationsUri(authoringTenant, designTemplateIds);
    setButtonUrl(url);
  }, [authoringTenant, designTemplateIds, availableTenants]);

  return (
    <Button
      disabled={!Boolean(buttonUrl)}
      href={buttonUrl}
      target="_blank"
      data-testid="show-localization-status-button"
    >
      {designTemplateIds && designTemplateIds.length === 1 ? 'Localization status' : 'Localizations status'}
    </Button>
  );
};
