import React from 'react';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';
import { AssetCategory, ToAssetCategory } from '../AssetCategory';
import { AssetLargeTile } from '../AssetViews/AssetLargeTile';
import './AssetsListTilesView.scss';

interface Props {
  assets: AnyAsset[];
  assetCategory: AssetCategory;
  onAssetSelected: (asset: AnyAsset, isSelected: boolean) => void;
}

export const AssetsListLargeTilesView = ({ assets, onAssetSelected }: Props): JSX.Element => {
  return (
    <div className="assets-list-grid" data-testid="assets-list-grid">
      {assets.map((asset) => {
        return (
          <AssetLargeTile
            asset={asset}
            assetCategory={ToAssetCategory(asset.assetType, asset.assetKind)}
            key={`${asset.id}-${asset.latestVersion.versionNumber}`}
            onAssetSelected={onAssetSelected}
          />
        );
      })}
    </div>
  );
};
