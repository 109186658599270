import React from 'react';
import { AnyAsset } from '../../api/Cams/model/AnyAsset';
import { DesignsListViewType } from '../../DesignPage/DesignsListViewType';
import { AssetCategory } from '../AssetCategory';
import { AssetsListLargeTilesView } from './AssetsListLargeTilesView';
import { AssetsListTabularView } from './AssetsListTabularView';
import { AssetsListTilesView } from './AssetsListTilesView';

interface AssetsListProps {
  assets: AnyAsset[];
  viewType: DesignsListViewType;
  assetCategory: AssetCategory;
  onAssetSelected: (asset: AnyAsset, isSelected: boolean) => void;
}

export const AssetsList = ({ assets, viewType, assetCategory, onAssetSelected }: AssetsListProps): JSX.Element => {
  switch (viewType) {
    case 'LargeTiles':
      return (
        <AssetsListLargeTilesView assets={assets} onAssetSelected={onAssetSelected} assetCategory={assetCategory} />
      );
    case 'Tiles':
      return <AssetsListTilesView assets={assets} onAssetSelected={onAssetSelected} assetCategory={assetCategory} />;
    case 'Table':
      return <AssetsListTabularView assets={assets} assetCategory={assetCategory} />;
  }
};
