/**
 * IMPORTANT: For `camsApiUrl`, you'll notice that the URL is actually that of CAM.
 * This is intended. On the AWS Cloudfront distribution, we have a reverse-proxy setup
 * that routes requests beginning with `/api/v4/` to CAMS. This helps us avoid
 * CAM to CAMS CORS pre-flight requests.
 */
const local = {
  //camsApiUrl: 'http://localhost:56332',
  camsApiUrl: 'https://dev.cam.contentauthoring.cimpress.io',
  dtecApiUrl: 'https://dev.templatecatalog.content.cimpress.io',
  dterApiUrl: 'https://dev.templaterealization.content.cimpress.io',
  dpsApiUrl: 'https://prod.designphysicalspec.content.cimpress.io',
  localizationUIUrl: 'https://dev.ui.localization.contentauthoring.cimpress.io',
  camRequestor: 'cam-local',
  dtsApiUrl: 'https://dev.designtemplate.content.cimpress.io',
  localizationApiUrl: 'https://dev.localization.content.cimpress.io',
  sherbertApiUrl: 'https://api.sherbert.cimpress.io',
  sumo: {
    endpoint:
      'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV1wbgerrDNf1bLU90rvub6Bib9Jyk024jFrPif0x83RBdSoS8_kt0_s4xrbjKakb13vO5FXqygyCnuBH2txrR-kNDXpCrd2s3DQYnULRZhWJw==',
    name: 'Cam/DEV',
    category: '260114/ContentManagement/ContentAssetManagement/Cam/DEV',
  },
  dexPageUrl: 'https://packaging-advanced.dex.cimpress.io/version/5.4.1/demo/initialize',
  uploadsApiUrl: 'https://uploads.documents.cimpress.io',
  prepressZipUrl: 'https://zip.prepress.cimpress.io',
  renderingFontCdnUrl: 'https://cdn.misc-rendering.documents.cimpress.io/fontlist',
  agsApiUrl: 'https://transformation.artworkgeneration.cimpress.io',
  previewUrl: 'https://previews.cimpress.io/v2/preview',
  instructionsDocumentsUrl: 'https://instructions.documents.cimpress.io',
  renderingUrl: 'https://rendering.documents.cimpress.io',
};

const dev = {
  camsApiUrl: 'https://dev.cam.contentauthoring.cimpress.io',
  dterApiUrl: 'https://dev.templaterealization.content.cimpress.io',
  dtecApiUrl: 'https://dev.templatecatalog.content.cimpress.io',
  dpsApiUrl: 'https://prod.designphysicalspec.content.cimpress.io',
  localizationUIUrl: 'https://dev.ui.localization.contentauthoring.cimpress.io',
  camRequestor: 'cam-dev',
  dtsApiUrl: 'https://dev.designtemplate.content.cimpress.io',
  localizationApiUrl: 'https://dev.localization.content.cimpress.io',
  sherbertApiUrl: 'https://api.sherbert.cimpress.io',
  sumo: {
    endpoint:
      'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV1wbgerrDNf1bLU90rvub6Bib9Jyk024jFrPif0x83RBdSoS8_kt0_s4xrbjKakb13vO5FXqygyCnuBH2txrR-kNDXpCrd2s3DQYnULRZhWJw==',
    name: 'Cam/DEV',
    category: '260114/ContentManagement/ContentAssetManagement/Cam/DEV',
  },
  dexPageUrl: 'https://packaging-advanced.dex.cimpress.io/version/5.4.1/demo/initialize',
  uploadsApiUrl: 'https://uploads.documents.cimpress.io',
  prepressZipUrl: 'https://zip.prepress.cimpress.io',
  renderingFontCdnUrl: 'https://cdn.misc-rendering.documents.cimpress.io/fontlist',
  agsApiUrl: 'https://transformation.artworkgeneration.cimpress.io',
  previewUrl: 'https://previews.cimpress.io/v2/preview',
  instructionsDocumentsUrl: 'https://instructions.documents.cimpress.io',
  renderingUrl: 'https://rendering.documents.cimpress.io',
};

const prod = {
  camsApiUrl: 'https://cam.contentauthoring.cimpress.io',
  dterApiUrl: 'https://prod.templaterealization.content.cimpress.io',
  dtecApiUrl: 'https://prod-ingest.templatecatalog.content.cimpress.io',
  dpsApiUrl: 'https://prod.designphysicalspec.content.cimpress.io',
  localizationUIUrl: 'https://ui.localization.contentauthoring.cimpress.io',
  camRequestor: 'cam-prod',
  dtsApiUrl: 'https://prod.designtemplate.content.cimpress.io',
  localizationApiUrl: 'https://prod.localization.content.cimpress.io',
  sherbertApiUrl: 'https://api.sherbert.cimpress.io',
  sumo: {
    endpoint:
      'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV0BCMSgSLnCB8AiOj54PEXVboeXIYggK1nnHcwh-HeVmaORzVJKOAoMmXJ1dgv3JIRAsgRsZrR3aE7MeE6095D4SbiQvMCIp_SWNEpPOrLnYw==',
    name: 'Cam/PRD',
    category: '260114/ContentManagement/ContentAssetManagement/Cam/PRD',
  },
  dexPageUrl: 'https://packaging-advanced.dex.cimpress.io/version/5.4.1/demo/initialize',
  uploadsApiUrl: 'https://uploads.documents.cimpress.io',
  prepressZipUrl: 'https://zip.prepress.cimpress.io',
  renderingFontCdnUrl: 'https://cdn.misc-rendering.documents.cimpress.io/fontlist',
  agsApiUrl: 'https://transformation.artworkgeneration.cimpress.io',
  previewUrl: 'https://previews.cimpress.io/v2/preview',
  instructionsDocumentsUrl: 'https://instructions.documents.cimpress.io',
  renderingUrl: 'https://rendering.documents.cimpress.io',
};

const test = {
  camsApiUrl: 'https://test.cams.contentauthoring.cimpress.io',
  dterApiUrl: 'https://dev.templaterealization.content.cimpress.io',
  dtecApiUrl: 'https://dev.templatecatalog.content.cimpress.io',
  dpsApiUrl: 'https://prod.designphysicalspec.content.cimpress.io',
  localizationUIUrl: 'https://dev.ui.localization.contentauthoring.cimpress.io',
  camRequestor: 'cam-test',
  dtsApiUrl: 'https://dev.designtemplate.content.cimpress.io',
  localizationApiUrl: 'https://dev.localization.content.cimpress.io',
  sherbertApiUrl: 'https://api.sherbert.cimpress.io',
  sumo: {
    endpoint:
      'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV1wbgerrDNf1bLU90rvub6Bib9Jyk024jFrPif0x83RBdSoS8_kt0_s4xrbjKakb13vO5FXqygyCnuBH2txrR-kNDXpCrd2s3DQYnULRZhWJw==',
    name: 'cam_test',
    category: 'vb/pulsar/test/cam',
  },
  dexPageUrl: 'https://packaging-advanced.dex.cimpress.io/version/5.4.1/demo/initialize',
  uploadsApiUrl: 'https://uploads.documents.cimpress.io',
  prepressZipUrl: 'https://zip.prepress.cimpress.io',
  renderingFontCdnUrl: 'https://cdn.misc-rendering.documents.cimpress.io/fontlist',
  agsApiUrl: 'https://transformation.artworkgeneration.cimpress.io',
  previewUrl: 'https://previews.cimpress.io/v2/preview',
  instructionsDocumentsUrl: 'https://instructions.documents.cimpress.io',
  renderingUrl: 'https://rendering.documents.cimpress.io',
};

function getConfig() {
  const envName = process.env.REACT_APP_STAGE;

  switch (envName) {
    case 'production':
      return prod;
    case 'development':
      return dev;
    case 'test':
      return test;
    case 'local':
    default:
      return local;
  }
}

const config = getConfig();

export default {
  ...config,
};
